import { Fragment, useEffect, useRef, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";
import CompTextTitle from "../../../../component/arjText-Title";
import CompButton from "../../../../component/arjButton";
import CompAlertSimpan from "../../../../component/arjAlert";
import CompAlertDelete from "../../../../component/arjAlert";
import CompAlert from "../../../../component/arjAlert";
import CompTextDs from "../../../../component/arjText-DataSource";

const Seksi = (props) => {
	//=================== Ref ============================
	const iRefKode = useRef(null);
	const iRefNama = useRef(null);
	const iRefPenjabat = useRef(null);
	const iRefKeterangan = useRef(null);
	const iRefSimpan = useRef(null);

	const iRefEditKode = useRef(null);
	const iRefEditNama = useRef(null);
	const iRefEditPenjabat = useRef(null);
	const iRefEditKeterangan = useRef(null);
	const iRefEditUpdate = useRef(null);
	//End =============== Ref ============================

	//=================== State ============================
	const [sRsPenjabat, setSRsPenjabat] = useState([]);
	const [sRsSeksi, setsRsSeksi] = useState([]);
	const [sAlertSimpan, setSAlertSimpan] = useState(false);
	const [sLoading, setSLoading] = useState(true);
	const [sDataSeksi, setsDataSeksi] = useState({
		eKode: { eText: "", eStatus: false },
		eNama: { eText: "", eStatus: false },
		ePenjabat: { eID: "", eNama: "" },
		eKeterangan: "",
		eStatus: false,
	});
	const [sDataEditSeksi, setsDataEditSeksi] = useState({
		eOldKode: "",
		eKode: { eText: "", eStatus: false },
		eNama: { eText: "", eStatus: false },
		ePenjabat: { eID: "", eNama: "" },
		eKeterangan: "",
		eStatus: false,
	});
	const [sAlert, setSAlert] = useState({
		eTitle: "",
		eKeterangan: "",
		eShow: false,
		eRef: useRef(null),
	});

	const [sEdit, setsEdit] = useState({ eIndex: 0, eStatus: false });
	const [sFocus, setsFocus] = useState("");
	const [sDelete, setsDelete] = useState({ eIndex: 0, eStatus: false });
	//END================ State ============================

	//=================== Effect ============================

	useEffect(() => {
		if (sEdit.eStatus) {
			iRefEditKode.current.focus();
			iRefEditKode.current.select();
		}
		// eslint-disable-next-line
	}, [sEdit]);

	useEffect(() => {
		if (sAlert.eRef && sAlert.eShow === false) {
			if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
				iRefKode.current.focus();
				iRefKode.current.select();
			}
		}
		// eslint-disable-next-line
	}, [sAlert]);

	useEffect(() => {
		if (sAlertSimpan === false && sLoading === false) {
			iRefKode.current.focus();
			iRefKode.current.select();
		}
		// eslint-disable-next-line
	}, [sAlertSimpan]);

	useEffect(() => {
		if (props._onActive) {
			props._onActive();
		}

		if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
			iRefKode.current.focus();
		}

		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/seksi`, {
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		})
			.then((xData) => {
				if (xData.status === Env.eHttpStatus.eOk) {
					// console.log(xData.data.message.penjabat);
					setSRsPenjabat(xData.data.message.penjabat);
					setsRsSeksi(xData.data.message.seksi);
					// console.log(xData.data.message.seksi);
				} else {
					alert("Error");
				}

				setSLoading(false);
			})
			.catch((xErr) => {
				setSLoading(false);
				alert(xErr);
			});
		// eslint-disable-next-line
	}, []);
	//END================ Effect ============================

	return (
		<Fragment>
			{sLoading === true ? <CompLoading /> : ""}
			{sAlert.eShow === true ? (
				<CompAlert
					_title={sAlert.eTitle}
					_keterangan={sAlert.eKeterangan}
					_onOk={() => {
						if (sEdit.eStatus) {
							setsEdit((xState) => {
								let iState = { ...xState };
								iState.eStatus = false;
								return iState;
							});
						}

						setSAlert((xState) => {
							let iState = { ...xState };
							iState.eShow = false;
							return iState;
						});
					}}
				/>
			) : (
				""
			)}
			{sAlertSimpan === true ? (
				<CompAlertSimpan
					_title={`Simpan Data Pengelola Keuangan / SEKSI`}
					_keterangan={` Apakah Data Sudah Benar ? `}
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setSAlertSimpan(false);
					}}
					_onOk={() => {
						setSLoading(true);
						setSAlertSimpan(false);
						let iData = {
							id: sDataSeksi.eKode.eText,
							nama: sDataSeksi.eNama.eText,
							account_id: sDataSeksi.ePenjabat.eID,
							keterangan: sDataSeksi.eKeterangan,
						};
						Axios.post(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/seksi`,
							iData,
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									setsDataSeksi((xState) => {
										let iState = { ...xState };
										iState.eKode.eText = "";
										iState.eKode.eStatus = false;
										iState.eNama.eText = "";
										iState.eNama.eStatus = false;
										iState.eKeterangan = "";
										iState.eStatus = false;
										return iState;
									});
									setsRsSeksi(xData.data.message);
									setsFocus(xData.data.id);
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Simpan Pengelola keuangan";
										iState.eKeterangan = "Sukses";
										iState.eShow = true;
										iState.eRef = iRefKode;

										return iState;
									});
								} else {
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = xData.data.title;
										iState.eKeterangan = xData.data.message;
										iState.eShow = true;
										iState.eRef = iRefKode;

										return iState;
									});
								}
							})
							.catch((xErr) => {
								setSLoading(false);
								setSAlert((xState) => {
									let iState = { ...xState };
									iState.eTitle = "Error";
									iState.eKeterangan = "Pengelola keuangan Gagal Disimpan";
									iState.eShow = true;
									iState.eRef = iRefKode;

									return iState;
								});
							});
					}}
				/>
			) : (
				""
			)}

			{sDelete.eStatus === true ? (
				<CompAlertDelete
					_title={`Delete Data Pengelola keuangan No.${sDelete.eIndex + 1}, Kode; ${
						sRsSeksi[sDelete.eIndex].id
					}`}
					_keterangan={` Apakah Data Sudah Benar ? `}
					_buttonOk="Ya"
					_buttonCancel="Tidak"
					_onCancel={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
					}}
					_onOk={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
						setSLoading(true);

						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/seksi`,
							{
								data: { id: sRsSeksi[sDelete.eIndex].id, index: sDelete.eIndex },
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									setsRsSeksi((xState) => {
										let iState = [...xState];
										iState.splice(xData.data.message.index, 1);
										return iState;
									});
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Delete Pengelola Keuangan / Seksi";
										iState.eKeterangan = "Sukses";
										iState.eShow = true;
										iState.eRef = null;

										return iState;
									});
								} else {
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Delete Pengelola Keuangan / Seksi";
										iState.eKeterangan = "Gagal";
										iState.eShow = true;
										iState.eRef = null;

										return iState;
									});
								}
							})
							.catch((xErr) => {
								setSAlert((xState) => {
									let iState = { ...xState };
									iState.eTitle = "Delete Pengelola Keuangan / Seksi Gagal";
									iState.eKeterangan = xErr;
									iState.eShow = true;
									iState.eRef = null;

									return iState;
								});
							});
					}}
				/>
			) : (
				""
			)}

			{/* =========================================================================== */}
			{/* EDIT DATA Pengelola Keuangan / Seksi ============================================================*/}
			{sEdit.eStatus === true ? (
				<div className="Modal py-20 pl-2 pr-4 md:px-20 ">
					<div className="flex flex-col space-y-2 shadow-lg w-full  bg-genkiBlue-cl_01 p-1  m-auto  rounded-xl overflow-hidden">
						<div className="mx-auto">
							{`Edit Data Pengelola Keuangan / Seksi No.${sEdit.eIndex + 1}, Kode: ${
								sDataEditSeksi.eKode.eText
							}`}{" "}
						</div>
						<hr style={{ color: "gray" }} />

						<CompTextTitle
							_title="Kode"
							_maxLength={200}
							_titleWidth="100px"
							_ref={iRefEditKode}
							_value={sDataEditSeksi.eKode.eText}
							_onChange={(xValue) => {
								setsDataEditSeksi((xState) => {
									let iState = { ...xState };
									iState.eKode.eText = xValue;
									if (xValue.length > 2) {
										iState.eKode.eStatus = true;
									} else {
										iState.eKode.eStatus = false;
									}
									if (iState.eKode.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataEditSeksi.eKode.eStatus === true) {
									iRefEditNama.current.focus();
									iRefEditNama.current.select();
								}
							}}
							_onKeyUp={() => {
								iRefEditKode.current.focus();
								iRefEditKode.current.select();
							}}
						/>

						<CompTextTitle
							_title="Nama"
							_maxLength={200}
							_titleWidth="100px"
							_ref={iRefEditNama}
							_value={sDataEditSeksi.eNama.eText}
							_onChange={(xValue) => {
								setsDataEditSeksi((xState) => {
									let iState = { ...xState };
									iState.eNama.eText = xValue;
									if (xValue.length > 2) {
										iState.eNama.eStatus = true;
									} else {
										iState.eNama.eStatus = false;
									}
									if (iState.eKode.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataEditSeksi.eNama.eStatus === true) {
									iRefEditPenjabat.current.focus();
									iRefEditPenjabat.current.select();
								}
							}}
							_onKeyUp={() => {
								iRefEditKode.current.focus();
								iRefEditKode.current.select();
							}}
						/>

						{/* ========== */}
						<div className="WadahFix" style={{ padding: "2px" }}>
							<div className="flex flex-col md:flex-row">
								<div className="flex mx-auto md:hidden font-medium">Penjabat</div>

								<div className="hidden md:flex font-medium items-center ">
									<div
										className="flex font-medium items-center ml-1 "
										style={{
											width: props._titleWidth || "100px",
										}}
									>
										Penjabat
									</div>
									<div className="mx-0.5">:</div>
								</div>

								<div className="flex flex-1">
									<div className="flex-1 flex-col items-center ">
										<CompTextDs
											_ref={iRefEditPenjabat}
											_dataSource={sRsPenjabat}
											_value={sDataEditSeksi.ePenjabat.eNama}
											_onKeyEnter={(xData) => {
												setsDataEditSeksi((xState) => {
													let iState = { ...xState };
													iState.ePenjabat.eID = xData[0];
													iState.ePenjabat.eNama = xData[1];
													return iState;
												});
												if (xData[0] !== "") {
													iRefEditKeterangan.current.focus();
													iRefEditKeterangan.current.select();
												}
											}}
											_onKeyUp={() => {
												iRefEditNama.current.focus();
												iRefEditNama.current.select();
											}}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* ========== */}

						<CompTextTitle
							_title="Keterangan"
							_maxLength={255}
							_titleWidth="100px"
							_ref={iRefEditKeterangan}
							_value={sDataEditSeksi.eKeterangan}
							_onChange={(xValue) => {
								setsDataEditSeksi((xState) => {
									let iState = { ...xState };
									iState.eKeterangan = xValue;
									return iState;
								});
							}}
							_onKeyEnter={() => {
								iRefEditUpdate.current.focus();
							}}
							_onKeyUp={() => {
								iRefEditNama.current.focus();
							}}
						/>

						<div className="flex items-center w-56 mx-auto ">
							<CompButton
								_text="Update"
								_ref={iRefEditUpdate}
								className="Button h-7 w-20 mx-auto"
								_imageType={
									sDataEditSeksi.eStatus
										? Env.buttonImageType.ok
										: Env.buttonImageType.stop
								}
								_onKeyUp={() => {
									iRefEditKeterangan.current.focus();
									iRefEditKeterangan.current.select();
								}}
								_onClick={() => {
									setSLoading(true);
									if (sDataEditSeksi.eStatus === true) {
										const iData = {
											// id: sRsSeksi[sEdit.eIndex].id,
											id: sDataEditSeksi.eOldKode,
											new_id: sDataEditSeksi.eKode.eText,
											nama: sDataEditSeksi.eNama.eText,
											keterangan: sDataEditSeksi.eKeterangan,
											account_id: sDataEditSeksi.ePenjabat.eID,
											index: sEdit.eIndex,
										};
										Axios.put(
											`${props.state.sPages.url}${Env.eRouteApi}/bendahara/seksi`,
											iData,
											{
												headers: {
													authorization: `bearer ${props.state.sToken}`,
												},
											}
										).then((xData) => {
											setSLoading(false);
											setsRsSeksi(xData.data.message);

											setSAlert((xState) => {
												let iState = { ...xState };
												iState.eTitle = "Update Pengelola Keuangan / Seksi";
												iState.eKeterangan = "Sukses";
												iState.eRef = null;
												iState.eShow = true;
												return iState;
											});
										});
									}
								}}
							/>

							<CompButton
								_text="Batal"
								className="Button h-7 w-20 mx-auto"
								_onClick={() => {
									setsEdit((xState) => {
										let iState = { ...xState };
										iState.eStatus = false;
										return iState;
									});

									iRefKode.current.focus();
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{/*=======================================================================  */}
			{/* MASUKKAN DATA Pengelola Keuangan / Seksi ===================================================*/}
			<div className="h-2"></div>
			{props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100 ? (
				<div className="flex flex-col space-y-2 shadow-lg   bg-genkiBlue-cl_01 max-w-screen-sm md:max-w-screen-md mx-auto  p-1 rounded-xl">
					<div className="m-auto">Masukkan Data Pengeloala Keuangan / SEKSI</div>
					<hr style={{ color: "gray" }} />
					<CompTextTitle
						_title="Kode"
						_maxLength={4}
						_titleWidth="100px"
						_ref={iRefKode}
						_value={sDataSeksi.eKode.eText}
						_onChange={(xValue) => {
							setsDataSeksi((xState) => {
								let iState = { ...xState };
								iState.eKode.eText = xValue;
								if (xValue.length > 2) {
									iState.eKode.eStatus = true;
								} else {
									iState.eKode.eStatus = false;
								}
								if (iState.eKode.eStatus === true && iState.eNama.eStatus === true) {
									iState.eStatus = true;
								} else {
									iState.eStatus = false;
								}
								return iState;
							});
						}}
						_onKeyUpEnter={() => {
							if (sDataSeksi.eKode.eStatus === true) {
								iRefNama.current.focus();
								iRefNama.current.select();
							}
						}}
					/>
					<CompTextTitle
						_title="Nama"
						_maxLength={200}
						_titleWidth="100px"
						_ref={iRefNama}
						_value={sDataSeksi.eNama.eText}
						_onChange={(xValue) => {
							setsDataSeksi((xState) => {
								let iState = { ...xState };
								iState.eNama.eText = xValue;
								if (xValue.length > 2) {
									iState.eNama.eStatus = true;
								} else {
									iState.eNama.eStatus = false;
								}
								if (iState.eKode.eStatus === true && iState.eNama.eStatus === true) {
									iState.eStatus = true;
								} else {
									iState.eStatus = false;
								}
								return iState;
							});
						}}
						_onKeyUpEnter={() => {
							if (sDataSeksi.eNama.eStatus === true) {
								iRefPenjabat.current.focus();
								iRefPenjabat.current.select();
							}
						}}
						_onKeyUp={() => {
							iRefKode.current.focus();
							iRefKode.current.select();
						}}
					/>

					{/* ========== */}
					<div className="WadahFix" style={{ padding: "2px" }}>
						<div className="flex flex-col md:flex-row">
							<div className="flex mx-auto md:hidden font-medium">Penjabat</div>

							<div className="hidden md:flex font-medium items-center ">
								<div
									className="flex font-medium items-center ml-1 "
									style={{
										width: props._titleWidth || "100px",
									}}
								>
									Penjabat
								</div>
								<div className="mx-0.5">:</div>
							</div>

							<div className="flex flex-1">
								<div className="flex-1 flex-col items-center ">
									<CompTextDs
										_ref={iRefPenjabat}
										_dataSource={sRsPenjabat}
										_onKeyEnter={(xData) => {
											setsDataSeksi((xState) => {
												let iState = { ...xState };
												iState.ePenjabat.eID = xData[0];
												iState.ePenjabat.eNama = xData[1];
												return iState;
											});
											if (xData[0] !== "") {
												iRefKeterangan.current.focus();
												iRefKeterangan.current.select();
											}
										}}
										_onKeyUp={() => {
											iRefNama.current.focus();
											iRefNama.current.select();
										}}
									/>
								</div>
							</div>
						</div>
					</div>
					{/* ========== */}

					<CompTextTitle
						_title="Keterangan"
						_maxLength={255}
						_titleWidth="100px"
						_ref={iRefKeterangan}
						_value={sDataSeksi.eKeterangan.eText}
						_onChange={(xValue) => {
							setsDataSeksi((xState) => {
								let iState = { ...xState };
								iState.eKeterangan = xValue;
								return iState;
							});
						}}
						_onKeyEnter={() => {
							iRefSimpan.current.focus();
						}}
						_onKeyUp={() => {
							iRefPenjabat.current.focus();
						}}
					/>
					<CompButton
						_text="Simpan"
						_ref={iRefSimpan}
						className="Button h-7 w-20 mx-auto"
						// _ref={mRefSimpan}
						// _value={sDataOrganisasi.url}
						_imageType={
							sDataSeksi.eStatus ? Env.buttonImageType.ok : Env.buttonImageType.stop
						}
						_onKeyUp={() => {
							iRefKeterangan.current.focus();
							iRefKeterangan.current.select();
						}}
						_onClick={() => {
							if (sDataSeksi.eStatus === true) {
								setSAlertSimpan(true);
							}
						}}
					/>
				</div>
			) : (
				<div className="flex flex-col space-y-2 shadow-lg   bg-genkiBlue-cl_01 max-w-screen-sm md:max-w-screen-md mx-auto  p-1 rounded-xl">
					<div className="m-auto">Data Pengeloala Keuangan / SEKSI</div>
				</div>
			)}

			{/* TABLE */}
			<div className="mt-4 mr-4 p-2 rounded-xl w-full WadahFix overflow-scroll ">
				<table className="Table">
					<thead>
						<tr className="flex">
							<th className=" w-8 ">No</th>
							<th className=" w-28 md:w-36 ">Kode</th>
							<th className="w-36 md:w-52 ">Nama</th>
							<th className="w-36 md:w-72 ">Penjabat</th>
							<th className="flex-1 ">Keterangan</th>
							{props.state.sUser.eStatus === 70 ||
							props.state.sUser.eStatus === 100 ? (
								<th className="w-28 ">Ubah</th>
							) : (
								""
							)}
						</tr>
					</thead>
					<tbody>
						{sRsSeksi.map((xData, xIndex) => {
							let iWarna = "";

							if (sFocus === xData.id) {
								iWarna = Env.color.colorPrimer02;
							}

							return (
								<tr key={xIndex} className="flex" style={{ background: iWarna }}>
									<td className="flex w-8 items-center ">
										<div className="flex-1"></div>
										<div className="pr-1 ">{xIndex + 1}</div>
									</td>
									<td className="flex w-28 md:w-36 overflow-hidden ">{xData.id}</td>
									<td className="w-36 md:w-52">{xData.nama}</td>
									<td className="w-36 md:w-72 ">
										{xData.account ? xData.account.members.name : ""}
									</td>
									<td className="flex-1  overflow-hidden">{xData.keterangan}</td>
									{props.state.sUser.eStatus === 70 ||
									props.state.sUser.eStatus === 100 ? (
										<td className="flex w-28 items-center  justify-evenly ">
											<CompButton
												_text="Edit"
												_onClick={() => {
													setsDataEditSeksi((xState) => {
														let iState = { ...xState };
														iState.eOldKode = sRsSeksi[xIndex].id;
														iState.eKode.eText = sRsSeksi[xIndex].id;
														iState.eNama.eText = sRsSeksi[xIndex].nama;
														iState.eKeterangan = sRsSeksi[xIndex].keterangan;
														iState.ePenjabat.eID = sRsSeksi[xIndex].account.id;
														iState.ePenjabat.eNama = sRsSeksi[xIndex].account.members.name;
														iState.eKode.eStatus = true;
														iState.eNama.eStatus = true;
														iState.eStatus = true;
														return iState;
													});

													setsEdit((xState) => {
														let iState = { ...xState };
														iState.eIndex = xIndex;
														iState.eStatus = true;
														return iState;
													});

													setsFocus(sRsSeksi[xIndex].id);
												}}
												className="Button h-6 w-12"
											/>
											<CompButton
												_text="Delete"
												_onClick={() => {
													setsDelete((xState) => {
														let iState = { ...xState };
														iState.eIndex = xIndex;
														iState.eStatus = true;
														return iState;
													});
													setsFocus(sRsSeksi[xIndex].id);
												}}
												className="Button h-6"
											/>
										</td>
									) : (
										""
									)}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

export default GlobalStateConsumer(Seksi);
