import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const ProgramTabungan = (props) => {
	const [sEdit, setSEdit] = useState({ index: -1, edit: false });
	const [sRsProgramTabungan, setSRsProgramTabungan] = useState([]);
	const [sRsAktiva, setSRsAktiva] = useState([]);
	const [sRsEkuitas, setSRsEkuitas] = useState([]);
	const [sRsKwajiban, setSRsKwajiban] = useState([]);

	const mNewData = {
		nama: "",
		bunga: "",
		akun_kas: { no_akun: "", nama: "" },
		akun_tabungan: { no_akun: "", nama: "" },
		akun_bunga_tabungan: { no_akun: "", nama: "" },
	};
	const [sNewData, setSNewData] = useState(mNewData);
	const [sAlert, setSAlert] = useState({
		title: "Data Program Tabungan",
		keterangan: "Data belum lengkap",
		focus: "",
		visible: false,
	});
	const [sAlertDelete, setSAlertDelete] = useState(false);
	const [sFocus, setSFocus] = useState("");
	const [sLoading, setSLoading] = useState(true);

	const refNama = useRef();
	const refBunga = useRef();
	const refAkunKas = useRef();
	const refAkunTabungan = useRef();
	const refAkunBunga = useRef();

	const refSimpan = useRef();
	const refUpdate = useRef();

	const fCekData = () => {
		let iFocus = "";
		if (sNewData.nama.length > 2) {
			if (sNewData.bunga !== "") {
				if (sNewData.akun_kas.no_akun !== "") {
					if (sNewData.akun_tabungan.no_akun !== "") {
						if (sNewData.akun_bunga_tabungan.no_akun === "") {
							iFocus = "akun_bunga_tabungan";
						}
					} else {
						iFocus = "akun_tabungan";
					}
				} else {
					iFocus = "akun_kas";
				}
			} else {
				iFocus = "bunga";
			}
		} else {
			iFocus = "nama";
		}

		if (iFocus === "") {
			// SIMPAN atau UPDATE DATA
			if (sEdit.edit) {
				// UPDATE DATA
				setSLoading(true);
				Axios.put(
					`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_tabungan`,
					{
						id: sRsProgramTabungan[sEdit.index].id,
						nama: sNewData.nama,
						bunga: sNewData.bunga,
						akun_kas: sNewData.akun_kas.no_akun,
						akun_tabungan: sNewData.akun_tabungan.no_akun,
						akun_bunga_tabungan: sNewData.akun_bunga_tabungan.no_akun,
					},
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				)
					.then((xData) => {
						setSLoading(false);
						setSRsProgramTabungan((xState) => {
							let iState = [...xState];
							iState.splice(sEdit.index, 1, xData.data.message);
							return iState;
						});
						setSEdit((xState) => {
							let iState = { ...xState };
							iState.edit = false;
							return iState;
						});
						setSNewData(mNewData);
						refNama.current.focus();
					})
					.catch(() => {
						setSLoading(false);
						setSAlert((xState) => {
							let iState = { ...xState };
							iState.title = "Data Program Tabungan";
							iState.keterangan = "Data Gagal DiUbah";
							iState.focus = "nama";
							iState.visible = true;
							return iState;
						});
					});
			} else {
				// SIMPAN DATA
				setSLoading(true);
				Axios.post(
					`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_tabungan`,
					{
						nama: sNewData.nama,
						bunga: sNewData.bunga,
						akun_kas: sNewData.akun_kas.no_akun,
						akun_tabungan: sNewData.akun_tabungan.no_akun,
						akun_bunga_tabungan: sNewData.akun_bunga_tabungan.no_akun,
					},
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				)
					.then((xData) => {
						// console.log(xData.data);
						setSLoading(false);
						if (xData.status === Env.eHttpStatus.eOk) {
							setSNewData(mNewData);
							setSEdit((xState) => {
								let iState = { ...xState };
								iState.index = -1;
								return iState;
							});
							setSRsProgramTabungan((xState) => {
								let iState = [...xState];
								iState.push(xData.data.message);
								return iState;
							});
							refNama.current.focus();
						} else {
							setSAlert((xState) => {
								let iState = { ...xState };
								iState.title = "Data Program Tabungan";
								iState.keterangan = "Data Gagal Disimpan";
								iState.focus = "nama";
								iState.visible = true;
								return iState;
							});
						}
					})
					.catch(() => {
						setSLoading(false);
						setSAlert((xState) => {
							let iState = { ...xState };
							iState.title = "Data Program Tabungan";
							iState.keterangan = "Data Gagal Disimpan";
							iState.focus = "nama";
							iState.visible = true;
							return iState;
						});
					});
			}
		} else {
			setSAlert((xState) => {
				let iState = { ...xState };
				iState.title = "Data Program Tabungan";
				iState.keterangan = "Data belum lengkap";
				iState.focus = iFocus;
				iState.visible = true;
				return iState;
			});
		}
	};

	useEffect(() => {
		switch (sFocus) {
			case "nama":
				refNama.current.focus();
				refNama.current.select();
				break;
			case "bunga":
				refBunga.current.focus();
				refBunga.current.select();
				break;
			case "akun_kas":
				refAkunKas.current.focus();
				refAkunKas.current.select();
				break;

			case "akun_tabungan":
				refAkunTabungan.current.focus();
				refAkunTabungan.current.select();
				break;

			case "akun_bunga_tabungan":
				refAkunBunga.current.focus();
				refAkunBunga.current.select();
				break;
			default:
				break;
		}
		// eslint-disable-next-line
	}, [sFocus]);

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/program_tabungan`,
			{
				params: { req: "rs_program_tabungan" },
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		)
			.then((xData) => {
				setSLoading(false);

				setSRsProgramTabungan(xData.data.message.rsProgramTabungan);
				setSRsAktiva(xData.data.message.rsAktiva);
				setSRsEkuitas(xData.data.message.rsEkuitas);
				setSRsKwajiban(xData.data.message.rsKwajiban);

				// console.log(xData.data.message);
			})
			.catch((xErr) => {
				setSLoading(false);
				alert(xErr);
			});

		if (refNama) {
			refNama.current.focus();
		}
	}, []);

	return (
		<div className="flex flex-col h-full ">
			<div className="flex-1 overflow-y-scroll">
				{sAlertDelete ? (
					<CompAlertDelete
						_title="Progaram Tabungan"
						_keterangan=" Apakah Benar Data Akan Dihapus"
						_buttonCancel="Ulang"
						_onCancel={() => {
							setSAlertDelete(false);
						}}
						_onOk={() => {
							setSAlertDelete(false);
							setSLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_tabungan`,
								{
									data: { id: sRsProgramTabungan[sEdit.index].id },
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							)
								.then((xData) => {
									setSLoading(false);
									setSRsProgramTabungan((xState) => {
										let iState = [...xState];
										iState.splice(sEdit.index, 1);
										return iState;
									});
									setSEdit((xState) => {
										let iState = { ...xState };
										iState.index = -1;
										iState.edit = false;
										return iState;
									});
									setSNewData(mNewData);
									refNama.current.focus();
								})
								.catch(() => {
									setSLoading(false);
									alert("Data Gagal Di Hapus");
									refNama.current.focus();
								});
						}}
					/>
				) : (
					""
				)}
				{sAlert.visible ? (
					<CompAlert
						_title={sAlert.title}
						_keterangan={sAlert.keterangan}
						_onOk={() => {
							setSAlert((xState) => {
								let iState = { ...xState };
								iState.visible = false;
								return iState;
							});
							setSFocus(sAlert.focus);
						}}
					/>
				) : (
					""
				)}
				{sLoading ? <CompLoading /> : ""}

				{/* TITLE */}
				<div
					className="flex flex-col p-2 mt-1 max-w-sm md:max-w-xl 
                    mx-auto bg-white rounded-xl shadow-md items-center "
				>
					<p>PROGRAM TABUNGAN</p>
				</div>

				{/* FORM ISIAN DATA */}
				<div
					className="flex flex-col p-2 mt-1 w-full md:max-w-7xl
                     bg-blue-100 rounded-xl shadow-md mx-auto space-y-2"
					style={{
						backgroundColor: sEdit.edit ? Env.color.orangeRed02 : "",
					}}
				>
					<div className=" mx-auto">
						<p>{sEdit.edit ? "Edit Data" : "Masukan Data"}</p>
					</div>
					<div className="flex">
						<div className="w-44">Nama Program</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refNama}
								_value={sNewData.nama}
								_onChange={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.nama = xData;
										return iState;
									});
								}}
								_onKeyEnterUp={(xData) => {
									if (xData.length > 2) {
										refBunga.current.focus();
										refBunga.current.select();
									}
								}}
							/>
						</div>
					</div>

					<div className="flex">
						<div className="w-44">Bunga</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refBunga}
								_dataType="pecahan"
								_value={sNewData.bunga}
								_onChange={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.bunga = xData;
										return iState;
									});
								}}
								_onKeyEnterUp={(xData) => {
									if (xData !== "") {
										refAkunKas.current.focus();
										refAkunKas.current.select();
									}
								}}
							/>
						</div>
						<div className="w-20 md:w-28 ml-1 truncate">% / bulan</div>
					</div>

					<div className="flex">
						<div className="w-44">Akun KAS</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunKas}
								_dataSource={sRsAktiva}
								_value={sNewData.akun_kas.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_kas.no_akun = xData[0];
										iState.akun_kas.nama = xData[1];
										return iState;
									});
									refAkunTabungan.current.focus();
									refAkunTabungan.current.select();
								}}
							/>
						</div>

						<p className="Text w-20 md:w-28 ml-1">{sNewData.akun_kas.no_akun}</p>
					</div>

					<div className="flex">
						<div className="w-44">Akun Tabungan</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunTabungan}
								_dataSource={sRsKwajiban}
								_value={sNewData.akun_tabungan.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_tabungan.no_akun = xData[0];
										iState.akun_tabungan.nama = xData[1];
										return iState;
									});
									refAkunBunga.current.focus();
									refAkunBunga.current.select();
								}}
							/>
						</div>

						<p className="Text w-20 md:w-28 ml-1">{sNewData.akun_tabungan.no_akun}</p>
					</div>

					<div className="flex">
						<div className="w-44">Akun Bunga Tabungan</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunBunga}
								_dataSource={sRsEkuitas}
								_value={sNewData.akun_bunga_tabungan.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_bunga_tabungan.no_akun = xData[0];
										iState.akun_bunga_tabungan.nama = xData[1];
										return iState;
									});
									if (xData !== "") {
										sEdit.edit ? refUpdate.current.focus() : refSimpan.current.focus();
									}
								}}
							/>
						</div>

						<p className="Text w-20 md:w-28 ml-1">
							{sNewData.akun_bunga_tabungan.no_akun}
						</p>
					</div>

					<div>
						{sEdit.edit ? (
							<div className="flex max-w-sm mx-auto justify-evenly">
								<button
									ref={refUpdate}
									className="Button mx-auto"
									onClick={() => {
										fCekData();
										// setSFocus("");
									}}
								>
									Update
								</button>
								<button
									className="Button mx-auto"
									onClick={() => {
										setSAlertDelete(true);
									}}
								>
									Delete
								</button>
								<button
									className="Button mx-auto"
									onClick={() => {
										setSEdit((xState) => {
											let iState = { ...xState };
											iState.edit = false;
											return iState;
										});
										setSNewData(mNewData);
										refNama.current.focus();
										refNama.current.select();
									}}
								>
									Cancel
								</button>
							</div>
						) : (
							<button
								ref={refSimpan}
								className="Button mx-auto"
								onClick={() => {
									fCekData();
								}}
							>
								Simpan
							</button>
						)}
					</div>
				</div>

				{/* TABLE DATA */}
				<div
					className=" w-full md:max-w-7xl md:p-2 mt-2 mx-auto 
							bg-blue-100 rounded-xl shadow-md"
				>
					<table className="Table shadow-md">
						<thead>
							<tr>
								<th>No</th>
								<th>Nama</th>
								<th>Bunga</th>
								<th>Akun KAS</th>
								<th>Akun Tabungan</th>
								<th>Akun Bunga Tabungan</th>
							</tr>
						</thead>
						<tbody>
							{sRsProgramTabungan.map((xData, xIndex) => {
								return (
									<tr
										key={xIndex}
										style={{
											backgroundColor: xIndex === sEdit.index ? Env.color.orangeRed02 : "",
										}}
									>
										<td className=" w-10 p-1">
											<button
												className="Button w-full h-full"
												onClick={() => {
													setSEdit((xState) => {
														let iState = { ...xState };
														iState.index = xIndex;
														iState.edit = true;
														return iState;
													});
													setSNewData((xState) => {
														let iState = { ...xState };
														iState.nama = sRsProgramTabungan[xIndex].nama;
														iState.bunga = sRsProgramTabungan[xIndex].bunga;
														iState.akun_kas = {
															no_akun: sRsProgramTabungan[xIndex].akun_kas.no_akun,
															nama: sRsProgramTabungan[xIndex].akun_kas.nama,
														};
														iState.akun_tabungan = {
															no_akun: sRsProgramTabungan[xIndex].akun_tabungan.no_akun,
															nama: sRsProgramTabungan[xIndex].akun_tabungan.nama,
														};
														iState.akun_bunga_tabungan = {
															no_akun: sRsProgramTabungan[xIndex].akun_bunga_tabungan.no_akun,
															nama: sRsProgramTabungan[xIndex].akun_bunga_tabungan.nama,
														};
														return iState;
													});
													refNama.current.focus();
													refNama.current.select();
												}}
											>
												{xIndex + 1}
											</button>
										</td>
										<td>{xData.nama}</td>
										<td className=" text-center">{`${xData.bunga} % / Bulan`}</td>
										<td>{`${xData.akun_kas.no_akun} - ${xData.akun_kas.nama}`}</td>
										<td>{`${xData.akun_tabungan.no_akun} - ${xData.akun_tabungan.nama}`}</td>
										<td>{`${xData.akun_bunga_tabungan.no_akun} - ${xData.akun_bunga_tabungan.nama}`}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="h-12"></div>
		</div>
	);
};

export default GlobalStateConsumer(ProgramTabungan);
