import { useRef, useEffect, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import Env from "../../../setting/env";
import CompText from "../../../component/arjText";
import CompTextDs from "../../../component/arjText-DataSource";
import CompLoading from "../../../component/arjLoading";
import CompAlertBatalSimpan from "../../../component/arjAlert";
// import CompAlertGagal from "../../../component/arjAlert";
import CompAlertDelete from "../../../component/arjAlert";
import CompAlertUpdate from "../../../component/arjAlert";

const Kelompok = (props) => {
	const [sRsDataKelompok, setsRsDataKelompok] = useState([]);
	const [sRsAnggota, setSRsAnggota] = useState([]);
	const iNewDataAnggota = {
		nama: "",
		kelompok: { no: 0, nama: "" },
		alamat: "",
		jabatan: { kode: 0, nama: "" },
		status_keluarga: { kode: 0, nama: "" },
		program_tabungan: 0,
		ref: "",
	};
	const [sNewDataAnggota, setSNewDataAnggota] = useState(iNewDataAnggota);

	const iEditDataAnggota = {
		title: "Masukkan Anggota",
		action: "Anggota Baru",
		id: "",
		no: 0,
		color: "bg-genkiBlue-cl_01",
	};
	const [sEditDataAnggota, setSEditDataAnggota] = useState(iEditDataAnggota);
	const [sRsProgramTabungan, setSRsProgramTabungan] = useState([]);
	// const [sDeleteData, setSDeleteData] = useState(0);
	const [sLoading, setSLoading] = useState(true);
	const [sAlertBatalSimpan, setSAlertBatalSimpan] = useState(false);
	// const [sAlertGagal, setsAlertGagal] = useState(false);
	const [sAlertUpdate, setSAlertUpdate] = useState(false);
	const [sAlertDelete, setSAlertDelete] = useState(false);
	const [sSelect, setSSelect] = useState(-1);
	const [sShowAddAnggota, setSShowAddAnggota] = useState(false);

	const refNama = useRef(null);
	const refKelompok = useRef(null);
	const refAlamat = useRef(null);
	const refJabatan = useRef(null);
	const refStatusKeluarga = useRef(null);
	const refProgramTabungan = useRef(null);
	const refSimpan = useRef(null);
	const refUpdate = useRef(null);

	let iNoKelompok = 0;
	let iNamaKelompok = "";
	let iBgKelompok = "";

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/members_group`,

			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		)
			.then((xData) => {
				if (xData.status === Env.eHttpStatus.eOk) {
					const iData = xData.data;
					let iRs = [];
					for (let i = 0; i < iData.length; i++) {
						iRs.push({ no: iData[i].no, nama: iData[i].nama });
					}
					setsRsDataKelompok(iRs);

					// ====Anggota==============================================
					Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/members_anggota`, {
						params: { req: "rs_anggota" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					})
						.then((xData) => {
							setSRsAnggota(xData.data.message);
							// console.log(xData.data.message);

							// ====REKOMENDASI TABUNGAN==============================================
							props.state.sUser.eStatus >= 70
								? Axios.get(
										`${props.state.sPages.url}${Env.eRouteApi}/admin/program_tabungan`,
										{
											params: { req: "rs_data_program_tabungan" },
											headers: {
												authorization: `bearer ${props.state.sToken}`,
											},
										}
								  )
										.then((xData) => {
											// console.log(xData.data.message);
											setSRsProgramTabungan(xData.data.message);
											setSLoading(false);
										})
										.catch((xErr) => {
											setSLoading(false);
											alert(xErr);
										})
								: setSLoading(false);
						})
						.catch((xErr) => {
							setSLoading(false);
							alert(xErr);
						});
				}
			})
			.catch(() => {
				setSLoading(false);
				alert("Error");
			});

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// console.log(sNewDataAnggota);
		try {
			switch (sNewDataAnggota.ref) {
				case "nama":
					refNama.current.focus();
					break;
				case "kelompok":
					if (sNewDataAnggota.nama === "") {
						refNama.current.focus();
					} else {
						refKelompok.current.focus();
					}
					break;
				case "alamat":
					refAlamat.current.focus();
					break;
				case "jabatan":
					refJabatan.current.focus();
					break;
				case "status_keluarga":
					refStatusKeluarga.current.focus();
					break;
				case "simpan":
					refSimpan.current.focus();
					break;
				case "update":
					refUpdate.current.focus();
					break;
				case "program_tabungan":
					refProgramTabungan.current.focus();
					break;
				default:
					refNama.current.focus();
					break;
			}
		} catch (error) {}
	}, [sNewDataAnggota]);

	useEffect(() => {
		try {
			if (sShowAddAnggota) {
				refNama.current.focus();
			}
		} catch (xErr) {}
	}, [sShowAddAnggota]);

	const fCekData = () => {
		let iRef = "";
		if (sNewDataAnggota.nama === "") {
			iRef = "nama";
		} else if (sNewDataAnggota.kelompok.no === 0) {
			iRef = "kelompok";
		} else if (sNewDataAnggota.jabatan.kode === 0) {
			iRef = "jabatan";
		} else if (sNewDataAnggota.status_keluarga.kode === 0) {
			iRef = "status_keluarga";
		}
		if (iRef === "") {
			return true;
		} else {
			return false;
		}
	};

	const fJabatan = (xStatus) => {
		const iJabatan = Env.eJabatanAnggota;
		for (let i = 0; i < iJabatan.length; i++) {
			if (iJabatan[i].kode === xStatus) {
				return iJabatan[i].jabatan;
			}
		}
	};

	const fDataJabatan = (xStatus) => {
		const iJabatan = Env.eJabatanAnggota;
		for (let i = 0; i < iJabatan.length; i++) {
			if (iJabatan[i].kode === xStatus) {
				return { kode: iJabatan[i].kode, nama: iJabatan[i].jabatan };
			}
		}
	};

	const fDataStatusKeluarga = (xStatus) => {
		const iStatusKeluarga = Env.eStatusKeluarga;
		for (let i = 0; i < iStatusKeluarga.length; i++) {
			if (iStatusKeluarga[i].status === xStatus) {
				return { kode: iStatusKeluarga[i].kode, nama: iStatusKeluarga[i].status };
			}
		}
	};

	const fDataKelompok = (xNama) => {
		let iDataKelompok = { no: 0, nama: "" };
		try {
			for (let i = 0; i < sRsDataKelompok.length; i++) {
				if (sRsDataKelompok[i].nama === xNama) {
					iDataKelompok.no = sRsDataKelompok[i].no;
					iDataKelompok.nama = sRsDataKelompok[i].nama;
					i = sRsDataKelompok.length;
				}
			}
		} catch (xErr) {}
		return iDataKelompok;
	};

	return (
		<div className="flex flex-col h-screen w-screen">
			{sLoading ? <CompLoading /> : ""}

			{sAlertBatalSimpan ? (
				<CompAlertBatalSimpan
					_title="Data Belum Lengkap / Salah"
					_keterangan="Mohon lengkapi data"
					_onOk={() => {
						setSAlertBatalSimpan(false);
						setSNewDataAnggota((xState) => {
							let iState = { ...xState };
							iState.ref = "";
							return iState;
						});
					}}
				/>
			) : (
				""
			)}

			{sAlertUpdate ? (
				<CompAlertUpdate
					_title={`Update Data No: ${sEditDataAnggota.no}`}
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Ulang"
					_onOk={() => {
						setSAlertUpdate(false);
						setSLoading(true);
						Axios.put(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/members_anggota`,
							{
								index: sEditDataAnggota.no - 1,
								id: sEditDataAnggota.id,
								nama: sNewDataAnggota.nama,
								kelompok: sNewDataAnggota.kelompok.no,
								alamat: sNewDataAnggota.alamat,
								jabatan: sNewDataAnggota.jabatan.kode,
								status_keluarga: sNewDataAnggota.status_keluarga.nama,
							},
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								if (xData.status === Env.eHttpStatus.eOk) {
									setSRsAnggota((xState) => {
										let iState = [...xState];
										iState[xData.data.index] = xData.data.message;
										return iState;
									});
								}
								// console.log(xData.data);
								setSNewDataAnggota(iNewDataAnggota);
								setSEditDataAnggota(iEditDataAnggota);
								setSLoading(false);
							})
							.catch(() => {
								alert("Update Gagal");
								setSNewDataAnggota(iNewDataAnggota);
								setSEditDataAnggota(iEditDataAnggota);
								setSLoading(false);
							});
					}}
					_onCancel={() => {
						setSAlertUpdate(false);
					}}
				/>
			) : (
				""
			)}

			{sAlertDelete ? (
				<CompAlertDelete
					_title={`Hapus Data No: ${sEditDataAnggota.no}`}
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Ulang"
					_onOk={() => {
						setSAlertDelete(false);
						setSLoading(true);
						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/members_anggota`,
							{
								data: { index: sEditDataAnggota.no - 1, id: sEditDataAnggota.id },
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						).then((xData) => {
							if (xData.status === Env.eHttpStatus.eOk) {
								setSLoading(false);
								setSRsAnggota((xState) => {
									let iState = [...xState];
									iState.splice(xData.data.message.index, 1);
									return iState;
								});
								setSSelect(-1);
								setSNewDataAnggota(iNewDataAnggota);
								setSEditDataAnggota(iEditDataAnggota);
							} else {
								setSLoading(false);
								alert("Hapus Data Gagal, Data masih terguna");
							}
						});
					}}
					_onCancel={() => {
						setSAlertDelete(false);
					}}
				/>
			) : (
				""
			)}

			{props.state.sUser.eStatus >= 70 ? (
				<div className="flex flex-col w-full">
					<div
						className={`flex flex-col  shadow-lg mt-2 w-full md:max-w-screen-xl  md:mx-auto  p-1 rounded-xl ${sEditDataAnggota.color}`}
					>
						<div className="flex flex-col space-y-1">
							<div className="flex w-full ">
								<div className="flex-1 flex justify-center">
									<p className="m-auto ">{sEditDataAnggota.title}</p>
								</div>
								{sEditDataAnggota.title === "Masukkan Anggota" ? (
									<button
										className="Button w-24 h-6"
										onClick={() => {
											setSShowAddAnggota(!sShowAddAnggota);
										}}
									>
										{sShowAddAnggota ? "Lihat" : "Sembunyi"}
									</button>
								) : null}
							</div>
							{/* Isi Data */}
							{sShowAddAnggota ? null : (
								<div className="flex flex-col space-y-1">
									<div className="flex  space-x-1">
										<div className="w-32">
											<p>Nama</p>
										</div>
										<p>:</p>
										<CompText
											_className="flex-1"
											_ref={refNama}
											_value={sNewDataAnggota.nama}
											_onChange={(xValue) => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.nama = xValue;
													iState.ref = "";
													return iState;
												});
											}}
											_onKeyEnterUp={() => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.ref = "kelompok";
													return iState;
												});
											}}
										/>
									</div>

									<div className="flex space-x-1">
										<p className="w-32">Kelompok</p>
										<p>:</p>
										<CompTextDs
											_ref={refKelompok}
											_value={sNewDataAnggota.kelompok.nama}
											_dataSource={sRsDataKelompok}
											_onKeyEnter={(xData) => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.kelompok.no = xData[0];
													iState.kelompok.nama = xData[1];
													iState.ref = "alamat";
													return iState;
												});
												refAlamat.current.select();
											}}
										/>
									</div>

									<div className="flex  space-x-1">
										<div className="w-32">
											<p>Alamat</p>
										</div>
										<p>:</p>
										<CompText
											_ref={refAlamat}
											_className="flex-1"
											_value={sNewDataAnggota.alamat}
											_onChange={(xValue) => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.alamat = xValue;
													iState.ref = "alamat";
													return iState;
												});
											}}
											_onKeyEnterUp={() => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.ref = "jabatan";
													return iState;
												});
											}}
										/>
									</div>

									<div className="flex space-x-1">
										<p className="w-32">Jabatan</p>
										<p>:</p>
										<CompTextDs
											_ref={refJabatan}
											_value={sNewDataAnggota.jabatan.nama}
											_dataSource={Env.eJabatanAnggota}
											_onKeyEnter={(xData) => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.jabatan.kode = xData[0];
													iState.jabatan.nama = xData[1];
													iState.ref = "status_keluarga";
													return iState;
												});
											}}
										/>
									</div>

									<div className="flex space-x-1">
										<p className="w-32">Status Keluarga</p>
										<p>:</p>
										<CompTextDs
											_ref={refStatusKeluarga}
											_value={sNewDataAnggota.status_keluarga.nama}
											_dataSource={Env.eStatusKeluarga}
											_onKeyEnter={(xData) => {
												setSNewDataAnggota((xState) => {
													let iState = { ...xState };
													iState.status_keluarga.kode = xData[0];
													iState.status_keluarga.nama = xData[1];
													sEditDataAnggota.action === "Anggota Baru"
														? (iState.ref = "program_tabungan")
														: (iState.ref = "update");
													return iState;
												});
											}}
										/>
									</div>

									{sEditDataAnggota.action === "Anggota Baru" ? (
										<div className="flex space-x-1">
											<p className="w-32">Rekomendasi Tabungan</p>
											<p>:</p>
											<CompTextDs
												_ref={refProgramTabungan}
												// _value={sNewDataAnggota.status_keluarga.nama}
												_dataSource={sRsProgramTabungan}
												_onKeyEnter={(xData) => {
													setSNewDataAnggota((xState) => {
														let iState = { ...xState };
														iState.program_tabungan = parseInt(xData[0]);
														iState.ref = "simpan";

														return iState;
													});
												}}
											/>
										</div>
									) : (
										""
									)}

									<div className="flex w-full ">
										{sEditDataAnggota.action === "Anggota Baru" ? (
											<button
												ref={refSimpan}
												className="Button h-6 m-auto"
												onClick={() => {
													if (fCekData()) {
														// setSLoading(true);
														Axios.post(
															`${props.state.sPages.url}${Env.eRouteApi}/admin/members_anggota`,
															{
																nama: sNewDataAnggota.nama,
																kelompok: sNewDataAnggota.kelompok.no,
																alamat: sNewDataAnggota.alamat,
																jabatan: sNewDataAnggota.jabatan.kode,
																status_keluarga: sNewDataAnggota.status_keluarga.nama,
																program_tabungan: sNewDataAnggota.program_tabungan,
															},
															{
																headers: {
																	authorization: `bearer ${props.state.sToken}`,
																},
															}
														).then((xData) => {
															// console.log(xData.data);
															setSRsAnggota((xState) => {
																let iState = [xData.data.message, ...xState];
																// console.log(iState);
																return iState;
															});
															setSSelect(0);
														});

														setSNewDataAnggota((xState) => {
															let iState = { ...xState };
															iState.nama = "";
															iState.ref = "";
															return iState;
														});
													} else {
														setSAlertBatalSimpan(true);
													}
												}}
											>
												Simpan
											</button>
										) : (
											<div className="flex justify-center w-full space-x-10">
												<button
													className="Button h-6"
													ref={refUpdate}
													onClick={() => {
														setSAlertUpdate(true);
													}}
												>
													Update
												</button>
												<button
													className="Button h-6"
													onClick={() => {
														setSAlertDelete(true);
													}}
												>
													Delete
												</button>
												<button
													className="Button h-6"
													onClick={() => {
														setSEditDataAnggota(iEditDataAnggota);
														setSNewDataAnggota(iNewDataAnggota);
													}}
												>
													Cancel
												</button>
											</div>
										)}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{/* ====TABLE DAFTAR ANGGOTA ===== */}
			<div
				className="flex flex-1  w-full md:p-2  mt-2 overflow-y-scroll 
			 rounded-xl border-solid border-4"
			>
				<table className="Table w-full">
					<thead>
						<tr>
							<th>No</th>
							<th>Nama</th>
							<th>No</th>
							<th>Kelompok</th>
							<th>Alamat</th>
							<th>Jabatan</th>
							<th>Status Keluarga</th>
						</tr>
					</thead>

					<tbody>
						{sRsAnggota.map((xData, xIndex) => {
							let iJabatan = fJabatan(xData.account.status);
							if (iNamaKelompok !== xData.members_group.nama) {
								iNamaKelompok = xData.members_group.nama;
								iNoKelompok = 1;
								if (iBgKelompok === "bg-blue-200") {
									iBgKelompok = "bg-blue-400";
								} else {
									iBgKelompok = "bg-blue-200";
								}
							} else {
								iNoKelompok += 1;
							}
							const iKelompok = fDataKelompok(xData.members_group.nama);
							const iDataJabatan = fDataJabatan(xData.account.status);
							const iDataStatusKeluarga = fDataStatusKeluarga(xData.status_keluarga);

							return (
								<tr
									style={{
										backgroundColor: xIndex === sSelect ? Env.color.orangeRed02 : "",
									}}
									key={xIndex}
								>
									<td className="text-center ">
										{props.state.sUser.eStatus >= 70 ? (
											<button
												className="Button h-6 w-full"
												onClick={() => {
													setSSelect(xIndex);
													setSEditDataAnggota((xState) => {
														let iState = { ...xState };
														iState.action = "Edit";
														iState.color = "bg-red-200";
														iState.id = xData.id;
														iState.no = xIndex + 1;
														iState.title = `Edit Anggota No.${xIndex + 1}`;
														return iState;
													});

													setSNewDataAnggota((xState) => {
														let iState = { ...xState };
														// console.log(xData);
														iState.nama = xData.name;
														iState.kelompok = iKelompok;
														iState.alamat = xData.alamat;
														iState.jabatan = iDataJabatan;
														iState.status_keluarga = iDataStatusKeluarga;
														iState.ref = "";
														return iState;
													});
												}}
											>
												{xIndex + 1}
											</button>
										) : (
											xIndex + 1
										)}
									</td>
									<td>{xData.name}</td>
									<td align="center" className={`${iBgKelompok}`}>
										{iNoKelompok}
									</td>
									<td>{xData.members_group.nama}</td>
									<td>{xData.alamat}</td>
									<td>{iJabatan}</td>
									<td>{xData.status_keluarga}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="flex h-20"></div>
			{/* END=TABLE DAFTAR ANGGOTA ===== */}
		</div>
	);
};

export default GlobalStateConsumer(Kelompok);
