import { useEffect, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import Env from "../../../setting/env";
import CompLoading from "../../../component/arjLoading";
import CompAlert from "../../../component/arjAlert";
import CompNeraca from "./neraca";

const LaporanKeuangan = (props) => {
  const [sTanggal, setSTanggal] = useState(Env.efTanggal());
  const [sTanggalLapored, setSTanggalLapored] = useState("");
  const [sRsTanggalLaporan, setSRsTanggalLaporan] = useState([]);
  const [sPariode, setsPariode] = useState("");
  // const [sNeraca, setSNeraca] = useState({ show: false, data: null });
  const [sViewLaporan, setSViewLaporan] = useState(null);

  const [sLoading, setSLoading] = useState(true);
  const [sAlert, setSAlert] = useState({
    show: false,
    title: "",
    keterangan: "",
    cancel: "",
    action: "",
    tanggal: "",
  });
  const [sPerifikasi, setSPerifikasi] = useState({
    show: false,
    tanggal_laporan: "",
    tanggal_perifikasi: Env.efTanggal(),
  });

  useEffect(() => {
    Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/laporan`, {
      params: { req: "tanggallaporan", tanggal: sTanggal },
      headers: {
        authorization: `bearer ${props.state.sToken}`,
      },
    })
      .then((xData) => {
        if (xData.status === Env.eHttpStatus.eOk) {
          setSRsTanggalLaporan(xData.data.message);
        }
        setSLoading(false);
      })
      .catch((xErr) => {
        setSLoading(false);
        alert(xErr);
      });

    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  // 	if (sRsTanggalLaporan.length > 0) {
  // 		setSTanggalLapored(sRsTanggalLaporan[0].lapored);
  // 	} else {
  // 		setSTanggalLapored("");
  // 	}
  // }, [sRsTanggalLaporan]);

  const fCekNilaiAkunInduk = (xRsAkunAnak, xNo_AkunInduk) => {
    const iRegEx = new RegExp(`^${xNo_AkunInduk}`);
    for (let i = 0; i < xRsAkunAnak.length; i++) {
      if (iRegEx.test(xRsAkunAnak[i].no_akun)) {
        return true;
      }
    }
  };

  const fNilaiAkun = (xRsAkunAnak, xNo_Akun) => {
    const iRegEx = new RegExp(`^${xNo_Akun}`);
    let iJumlah = 0;
    for (let i = 0; i < xRsAkunAnak.length; i++) {
      if (iRegEx.test(xRsAkunAnak[i].no_akun)) {
        iJumlah += xRsAkunAnak[i].jumlah;
      }
    }
    return iJumlah;
  };

  const fCekIndexSubAkun = (xRsAkun, xNoAkun, xIndexMulai) => {
    const iRegEx = new RegExp(`^${xNoAkun}`);
    for (let i = xIndexMulai; i < xRsAkun.length; i++) {
      if (iRegEx.test(xRsAkun[i].no_akun) === false) {
        return i;
      }
    }
    return xRsAkun.length;
  };

  const fClearSubAkun = (xRsAkun, xNoAkun, xIndexMulai) => {
    const iRegEx = new RegExp(`^${xNoAkun}`);

    for (let i = xIndexMulai; i < xRsAkun.length; i++) {
      // console.log(xRsAkun[i].no_akun);
      if (iRegEx.test(xRsAkun[i].no_akun) === true) {
        // console.log(xRsAkun[i].no_akun);
        xRsAkun.splice(i, 1);
        i -= 1;
      }
    }
  };

  const fViewAkun = (xRsAkun, xRsAkunAnak) => {
    const iViewAkun = [];
    for (let i3 = 0; i3 < xRsAkun.length; i3++) {
      if (xRsAkun[i3].type === "SubInduk") {
        iViewAkun.push({
          tab_view: xRsAkun[i3].tab_view,
          akun: `${xRsAkun[i3].no_akun}. ${xRsAkun[i3].nama}`,
          jumlah: "",
          bukuBesar: "",
        });
      } else if (xRsAkun[i3].type === "Anak") {
        iViewAkun.push({
          tab_view: xRsAkun[i3].tab_view,
          akun: `${xRsAkun[i3].no_akun}. ${xRsAkun[i3].nama}`,
          jumlah: Env.format.uang(fNilaiAkun(xRsAkunAnak, xRsAkun[i3].no_akun)),
          bukuBesar: xRsAkun[i3].no_akun,
        });
      } else if (xRsAkun[i3].type === "NilaiSubInduk") {
        iViewAkun.push({
          tab_view: xRsAkun[i3].tab_view,
          akun: `Jumlah ${xRsAkun[i3].nama}`,
          jumlah: Env.format.uang(fNilaiAkun(xRsAkunAnak, xRsAkun[i3].no_akun)),
          bukuBesar: "",
        });
      } else {
        iViewAkun.push({
          tab_view: 0,
          akun: "",
          jumlah: "",
          bukuBesar: "",
        });
      }
    }
    return iViewAkun;
  };

  const fLihatLaporan = (xTanggal) => {
    if (!sLoading) {
      setSTanggalLapored(xTanggal);
      setSLoading(true);
      Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/laporan`, {
        params: { req: "neraca", tanggal: xTanggal },
        headers: {
          authorization: `bearer ${props.state.sToken}`,
        },
      })
        .then(async (xData) => {
          // console.log(xData.data);
          if (xData.status === Env.eHttpStatus.eOk) {
            // Membuat List Neraca
            const iObjNeraca = xData.data.message;
            // console.log(iObjNeraca);
            // ==AKTIVA==================================
            const iRsAkunAktiva = iObjNeraca.aktiva.akun;
            const iRsAkunAnakAktiva = iObjNeraca.aktiva.akunAnak.data;
            setsPariode(iObjNeraca.pariode);

            // =============Restrukturisasi Akun Aktiva
            let iLength = 0;
            for (let i = 0; i < iRsAkunAktiva.length; i++) {
              iLength = i;
              const iAkun = iRsAkunAktiva[i].type;

              if (iAkun === "Induk") {
                //======-- Induk

                // ===============Cek Apakah Induk Akun Mempunyai Nilai=============
                if (
                  fCekNilaiAkunInduk(
                    iRsAkunAnakAktiva,
                    iRsAkunAktiva[i].no_akun
                  )
                ) {
                  iRsAkunAktiva[i].type = "SubInduk";
                  let iIndex = fCekIndexSubAkun(
                    iRsAkunAktiva,
                    iRsAkunAktiva[i].no_akun,
                    i
                  );
                  iRsAkunAktiva.splice(iIndex, 0, {
                    no_akun: iRsAkunAktiva[i].no_akun,
                    nama: iRsAkunAktiva[i].nama,
                    type: "NilaiSubInduk",
                    tab_view: iRsAkunAktiva[i].tab_view,
                  });
                } else {
                  fClearSubAkun(iRsAkunAktiva, iRsAkunAktiva[i].no_akun, i);
                  i -= 1;
                }
                // END ==========Cek Apakah Induk Akun Mempunyai Nilai==============
              }
            }
            iRsAkunAktiva.splice(iLength, 0, {
              no_akun: "",
              nama: "",
              type: "",
              tab_view: "",
            });
            // console.log(iRsAkunAktiva);
            // END ========Restrukturisasi Akun Aktiva
            // END AKTIVA========================================

            // ==KEWAJIBAN==================================
            const iRsAkunKewajiban = iObjNeraca.kewajiban.akun;
            const iRsAkunAnakKewajiban = iObjNeraca.kewajiban.akunAnak.data;

            // =============Restrukturisasi Akun KEWAJIBAN
            iLength = 0;
            for (let i = 0; i < iRsAkunKewajiban.length; i++) {
              iLength = i;
              const iAkun = iRsAkunKewajiban[i].type;

              if (iAkun === "Induk") {
                //======-- Induk

                // ===============Cek Apakah Induk Akun Mempunyai Nilai=============
                if (
                  fCekNilaiAkunInduk(
                    iRsAkunAnakKewajiban,
                    iRsAkunKewajiban[i].no_akun
                  )
                ) {
                  iRsAkunKewajiban[i].type = "SubInduk";
                  let iIndex = fCekIndexSubAkun(
                    iRsAkunKewajiban,
                    iRsAkunKewajiban[i].no_akun,
                    i
                  );
                  // console.log(iIndex);
                  iRsAkunKewajiban.splice(iIndex, 0, {
                    no_akun: iRsAkunKewajiban[i].no_akun,
                    nama: iRsAkunKewajiban[i].nama,
                    type: "NilaiSubInduk",
                    tab_view: iRsAkunKewajiban[i].tab_view,
                  });
                } else {
                  fClearSubAkun(
                    iRsAkunKewajiban,
                    iRsAkunKewajiban[i].no_akun,
                    i
                  );
                  i -= 1;
                }
                // END ==========Cek Apakah Induk Akun Mempunyai Nilai==============
              }
            }
            iRsAkunKewajiban.splice(iLength + 1, 0, {
              no_akun: "",
              nama: "",
              type: "",
              tab_view: "",
            });
            // console.log(iRsAkunKewajiban);
            // END ========Restrukturisasi Akun Kewajiban
            // END KEWAJIABAn========================================

            // ==EKUITAS==================================
            // const iRsAkunEkuitas = iObjNeraca.ekuitas.akun;
            const iEkuitas = iObjNeraca.ekuitas.detail;

            // END EKUITAS========================================

            const iViewAktiva = fViewAkun(iRsAkunAktiva, iRsAkunAnakAktiva);
            const iViewPasiva = fViewAkun(
              iRsAkunKewajiban,
              iRsAkunAnakKewajiban
            );

            let iTotalSaldoEkuitas = 0;
            if (iEkuitas) {
              iViewPasiva.push({
                tab_view: iEkuitas.akunInduk.tab_view,
                akun: `${iEkuitas.akunInduk.no_akun}. ${iEkuitas.akunInduk.nama}`,
                jumlah: "",
                bukuBesar: "",
              });

              iViewPasiva.push({
                tab_view: iEkuitas.akunAnak.tab_view,
                akun: `${iEkuitas.akunAnak.no_akun}. ${iEkuitas.akunAnak.nama}`,
                jumlah: Env.format.uang(iEkuitas.totalSaldoEkuitas),
                bukuBesar: iEkuitas.akunAnak.no_akun,
              });

              iViewPasiva.push({
                tab_view: iEkuitas.akunInduk.tab_view,
                akun: `Jumlah ${iEkuitas.akunInduk.nama}`,
                jumlah: Env.format.uang(iEkuitas.totalSaldoEkuitas),
                bukuBesar: "",
              });
              iTotalSaldoEkuitas = iEkuitas.totalSaldoEkuitas;
            }
            iViewPasiva.push({
              tab_view: 0,
              akun: "",
              jumlah: "",
              bukuBesar: "",
            });

            const iTotalNilaiPasiva =
              fNilaiAkun(iRsAkunAnakKewajiban, 2) + iTotalSaldoEkuitas;

            iViewPasiva.push({
              tab_view: 0,
              akun: "Jumlah PASIVA",
              jumlah: Env.format.uang(iTotalNilaiPasiva),
              bukuBesar: "",
            });

            setSViewLaporan((xState) => {
              let iState = { ...xState };
              iState.viewAktiva = iViewAktiva;
              iState.viewPasiva = iViewPasiva;
              iState.viewEkuitas = iEkuitas;
              iState.identitasPemilik = iObjNeraca.identitasPemilik;
              return iState;
            });
          }
          setSLoading(false);
        })
        .catch((xErr) => {
          setSLoading(false);
          // console.log(xErr);
          alert(xErr);
        });
    }
  };

  return (
    <div className="flex flex-col w-full h-screen">
      {/*=======ferifikasi =======  */}
      {sPerifikasi.show ? (
        <div className="Modal m-auto">
          <div className="flex w-full h-screen">
            <div className=" m-auto">
              <div className="flex justify-center w-full bg-red-200 text-lg rounded-t">
                Perifikasi Laporan tanggal:{" "}
                {Env.efFormatTanggal(sPerifikasi.tanggal_laporan)}
              </div>
              <div className="bg-gray-100 p-2">
                <div className="flex justify-center w-full  text-md mb-3">
                  Apakah data sudah benar
                </div>

                <div className="flex">
                  <div className="flex justify-center mr-2 h-f">
                    Tanggal Perifikasi :
                  </div>
                  <div>
                    <input
                      type="date"
                      className="Text"
                      value={sPerifikasi.tanggal_perifikasi}
                      onChange={(xData) => {
                        setSPerifikasi((xState) => {
                          let iState = { ...xState };
                          iState.tanggal_perifikasi =
                            xData.nativeEvent.target.value;
                          return iState;
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-evenly w-full bg-red-100 p-2 rounded-b ">
                <button
                  className="Button w-20"
                  onClick={() => {
                    if (!sLoading) {
                      setSLoading(true);
                      Axios.get(
                        `${props.state.sPages.url}${Env.eRouteApi}/bendahara/buatlaporan`,
                        {
                          params: {
                            req: "perifikasi_laporan",
                            tanggal_laporan: sPerifikasi.tanggal_laporan,
                            tanggal_perifikasi: sPerifikasi.tanggal_perifikasi,
                          },
                          headers: {
                            authorization: `bearer ${props.state.sToken}`,
                          },
                        }
                      )
                        .then((xData) => {
                          if (xData.status === Env.eHttpStatus.eOk) {
                            setSRsTanggalLaporan(xData.data.message);
                          }
                          setSLoading(false);
                          setSPerifikasi((xState) => {
                            let iState = { ...xState };
                            iState.show = false;
                            return iState;
                          });
                        })
                        .catch((xErr) => {
                          setSLoading(false);
                          alert(xErr);
                        });
                    }
                  }}
                >
                  OK
                </button>
                <button
                  className="Button w-20"
                  onClick={() => {
                    setSPerifikasi((xState) => {
                      let iState = { ...xState };
                      iState.show = false;
                      return iState;
                    });
                  }}
                >
                  Batal
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* ========NERACA======== */}
      {sViewLaporan ? (
        <CompNeraca
          _data={sViewLaporan}
          _tanggal={sTanggalLapored}
          _pariode={sPariode}
          _onClose={() => {
            setSViewLaporan(null);
          }}
        />
      ) : (
        ""
      )}

      {/* ====ALERT=============== */}
      {sAlert.show ? (
        <CompAlert
          _title={sAlert.title}
          _keterangan={sAlert.keterangan}
          _buttonCancel={sAlert.cancel}
          _onCancel={() => {
            setSAlert((xState) => {
              let iState = { ...xState };
              iState.show = false;
              return iState;
            });
          }}
          _onOk={() => {
            setSAlert((xState) => {
              let iState = { ...xState };
              iState.show = false;
              return iState;
            });
            if (sAlert.action === "Ulang Laporan") {
              let iTanggal = "";
              if (sRsTanggalLaporan.length > 0) {
                iTanggal = sAlert.tanggal;
              }
              if (iTanggal !== "") {
                setSLoading(true);
                Axios.get(
                  `${props.state.sPages.url}${Env.eRouteApi}/bendahara/buatlaporan`,
                  {
                    params: { req: "ulanglaporan", tanggal: iTanggal },
                    headers: {
                      authorization: `bearer ${props.state.sToken}`,
                    },
                  }
                )
                  .then((xData) => {
                    setSLoading(false);
                    if (xData.status === Env.eHttpStatus.eOk) {
                      setSRsTanggalLaporan(xData.data.message);
                    }
                  })
                  .catch((xErr) => {
                    setSLoading(false);
                    alert(xErr);
                  });
              }
            }
          }}
        />
      ) : (
        ""
      )}

      {/* ======LOADING ============ */}
      {sLoading ? <CompLoading /> : ""}

      <div
        className="flex h-10 items-center justify-center p-1 w-full  bg-white rounded-xl
						 shadow-md space-x-4 "
      >
        LAPORAN KEUANGAN
      </div>

      {props.state.sUser.eStatus === 100 || props.state.sUser.eStatus === 70 ? (
        <div className="WadahFix flex flex-col items-center justify-center my-2 md:m-2 p-1 h-20">
          <div className=" te">Laporan Baru</div>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className=" w-auto m-1">Tanggal </div>
                </td>
                <td>
                  <div className="flex-1 w-40">
                    <input
                      type="date"
                      className="Text"
                      value={sTanggal}
                      onChange={(xData) => {
                        setSTanggal(xData.nativeEvent.target.value);
                      }}
                    />
                  </div>
                </td>
                <td>
                  {/* ============ BUAT LAPORAN ============================ */}
                  <button
                    className="Button h-6 w-28 px-1 m-1"
                    onClick={() => {
                      if (!sLoading) {
                        setSLoading(true);
                        Axios.get(
                          `${props.state.sPages.url}${Env.eRouteApi}/bendahara/buatlaporan`,
                          {
                            params: { req: "buatlaporan", tanggal: sTanggal },
                            headers: {
                              authorization: `bearer ${props.state.sToken}`,
                            },
                          }
                        )
                          .then((xData) => {
                            setSLoading(false);
                            if (xData.status === Env.eHttpStatus.eOk) {
                              setSRsTanggalLaporan(xData.data.message);
                            } else {
                              setSAlert((xState) => {
                                let iState = { ...xState };
                                iState.show = true;
                                iState.title = "Buat Laporan Gagal";
                                iState.keterangan = xData.data.message;
                                iState.cancel = "";
                                iState.action = "";
                                return iState;
                              });
                            }
                          })
                          .catch((xErr) => {
                            setSLoading(false);
                            alert(xErr);
                          });
                      }
                    }}
                  >
                    Buat Laporan
                  </button>
                </td>
              </tr>
            </tbody>
          </table>

          {/* </div> */}
        </div>
      ) : (
        ""
      )}

      <div className="flex-1 overflow-y-scroll md:pr-4">
        <div className=" flex flex-col items-center my-2 md:m-2 p-1 w-full">
          <div className="flex flex-col w-auto">
            <div className=" text-lg flex justify-center">List Laporan</div>
            <div className="flex justify-center max-w-md">
              <table className="Table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Tanggal Laporan</th>
                    <th>Perifikasi</th>
                    <th>Lihat</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td width={40} align="center">
                      *
                    </td>
                    <td width={170} align="center">
                      Laporan Sementara
                    </td>
                    <td></td>
                    <td align="center">
                      <button
                        className="Button h-6 "
                        onClick={() => {
                          fLihatLaporan("");
                        }}
                      >
                        Lihat
                      </button>
                    </td>
                  </tr>
                  {sRsTanggalLaporan
                    .map((xData, xIndex) => {
                      return (
                        <tr key={xIndex}>
                          <td width={40}>{xIndex + 1}</td>
                          <td width={170} align="center">
                            {Env.efFormatTanggal(xData.lapored)}
                          </td>
                          {props.state.sUser.eStatus === 100 ||
                          props.state.sUser.eStatus === 70 ? (
                            xData.perifikasi === "" ? (
                              <td>
                                <div className="flex justify-center px-2 space-x-2  ">
                                  <button
                                    className="Button h-6 "
                                    onClick={() => {
                                      setSPerifikasi((xState) => {
                                        let iState = { ...xState };
                                        iState.show = true;
                                        iState.tanggal_laporan = xData.lapored;

                                        return iState;
                                      });
                                    }}
                                  >
                                    Perifikasi
                                  </button>
                                  <button
                                    className="Button h-6 "
                                    onClick={() => {
                                      setSAlert((xState) => {
                                        let iState = { ...xState };
                                        iState.action = "Ulang Laporan";
                                        iState.cancel = "Batal";
                                        iState.show = true;
                                        iState.tanggal = xData.lapored;
                                        iState.title = "Mengulang Laporan";
                                        iState.keterangan = `Apakah Benar Akan Mengulang Laporan tertanggal: ${Env.efFormatTanggal(
                                          xData.lapored
                                        )} ?`;
                                        return iState;
                                      });
                                    }}
                                  >
                                    Ulang
                                  </button>
                                </div>
                              </td>
                            ) : (
                              <td align="center">
                                {Env.efFormatTanggal(xData.perifikasi)}
                              </td>
                            )
                          ) : xData.perifikasi === "" ? (
                            <td>
                              <div className="flex justify-center px-2 ">
                                Belum
                              </div>
                            </td>
                          ) : (
                            <td>
                              <div className="flex justify-center px-2 ">
                                {Env.efFormatTanggal(xData.perifikasi)}
                              </div>
                            </td>
                          )}

                          <td>
                            <div className="flex justify-center px-2 w-full ">
                              <button
                                className="Button h-6 "
                                onClick={() => {
                                  fLihatLaporan(xData.lapored);
                                }}
                              >
                                Lihat
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                    .reverse()}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className=" h-10">coba</div>
  
    </div>
  );
};

export default GlobalStateConsumer(LaporanKeuangan);
