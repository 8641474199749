import {  useEffect, useState } from "react";
import { GlobalStateConsumer } from "../../../state.global";
import Axios from "axios";

import Env from "../../../setting/env";
const Home = (props) => {
	const [sImages, setSImages] = useState([]);
	useEffect(() => {
		if (props._onActive) {
			props._onActive();
		}

		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/photo`,

			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		).then((xRes) => {
			setSImages(xRes.data.message);
		});

		// eslint-disable-next-line
	}, []);
	try {
		return (
			<div className=" fixed flex flex-col w-screen h-screen">
				<div
					className="flex flex-col p-1 mb-1 
				max-w-sm 
				md:max-w-xl 
				mx-auto 
				bg-white rounded-xl 
				shadow-md 
				items-center 
				space-x-4 "
				>
					<h1>Wellcome To</h1>
					<h1>{props.state.sPages.nama}</h1>
				</div>

				<div className=" flex-1 flex flex-wrap justify-evenly overflow-auto space-y-2">
					{sImages.map((xImage, xIndex) => {
						return (
							<img
								className="flex flex-col p-1 mb-1 
							max-w-sm 
							md:max-w-xl 
							mx-auto 
							bg-white rounded-xl 
							shadow-md 
							items-center 
							space-x-4 "
								key={xIndex}
								src={xImage.url}
								alt=""
							/>
						);
					})}
				</div>
				<div className=" h-20"></div>
			</div>
		);
	} catch (xErr) {
		return (
			<div className=" fixed flex flex-col w-screen h-screen">
				<div
					className="flex flex-col p-1 mb-1 
				max-w-sm 
				md:max-w-xl 
				mx-auto 
				bg-white rounded-xl 
				shadow-md 
				items-center 
				space-x-4 "
				>
					<h1>Wellcome To</h1>
					<h1>{props.state.sPages.nama}</h1>
				</div>
			</div>
		);
	}
};

export default GlobalStateConsumer(Home);
