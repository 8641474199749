import React, { Component, createContext } from "react";
import Cookie from "js-cookie";
import PhotoAcount from "../assets/image/acount.svg";
import Env from "../setting/env";

const glContext = createContext();

const Provider = glContext.Provider;
export const GlobalStateProvider = (Children) => {
	const mUser = {
		eUserName: "",
		eUserLogin: "",
		ePhoto: PhotoAcount,
		eStatus: 0,
	};
	let mUserCookie = Cookie.get("user");
	if (mUserCookie) {
		mUserCookie = JSON.parse(mUserCookie);
	} else {
		mUserCookie = mUser;
	}

	const mPages = {
		nama: "",
		url: "",
	};
	let mPagesCookie = Cookie.get("pages") || "";
	if (mPagesCookie) {
		mPagesCookie = JSON.parse(mPagesCookie);
	} else {
		mPagesCookie = mPages;
	}

	const mTokenCookie = Cookie.get("token") || "";
	const mPageCookie = Cookie.get("page") || "home";

	return class CompProvider extends Component {
		state = {
			sUser: {
				eUserName: mUserCookie.eUserName,
				eUserLogin: mUserCookie.eUserLogin,
				ePhoto: mUserCookie.ePhoto,
				eStatus: mUserCookie.eStatus,
			},
			sPages: { nama: mPagesCookie.nama, url: mPagesCookie.url },
			sToken: mTokenCookie,
			sPage: mPageCookie,
			sLayarSize: "",
		};

		fDispatch = (xAction) => {
			const iData = xAction.data;
			// console.log(Env.url + iData.photo);
			switch (xAction.type) {
				case "user":
					// console.log(iData);
					let iPhoto = iData.photo;
					iPhoto ? (iPhoto = Env.url + iPhoto) : (iPhoto = PhotoAcount);
					// console.log(iPhoto);
					const iUser = { ...this.state.sUser };

					iUser.eUserName = iData.user_name;
					iUser.eUserLogin = iData.user_login;
					iUser.ePhoto = iPhoto;
					iUser.eStatus = iData.status;
					this.setState({
						sUser: iUser,
					});
					Cookie.set("user", iUser);
					break;
				case "token":
					this.setState({
						sToken: iData,
					});
					Cookie.set("token", iData);
					break;

				case "page":
					this.setState({
						sPage: iData,
					});
					Cookie.set("page", iData);
					break;
				case "pages":
					let iStatePages = { ...this.state.sPages };
					iStatePages.nama = iData.nama;
					iStatePages.url = iData.url;
					this.setState({ sPages: iStatePages });
					Cookie.set("pages", iStatePages);
					break;
				case "logOut":
					this.setState({
						sUser: mUser,
						sToken: "",
						sPages: mPages,
					});
					Cookie.remove("user");
					Cookie.remove("token");
					Cookie.remove("pages");
					break;
				case "layarSize":
					this.setState({
						sLayarSize: iData,
					});
					break;
				default:
					break;
			}
		};

		render() {
			return (
				<Provider value={{ state: this.state, dispatch: this.fDispatch }}>
					<Children {...this.props} />
				</Provider>
			);
		}
	};
};

const Consumer = glContext.Consumer;
export const GlobalStateConsumer = (Children) => {
	return class CompConsumer extends Component {
		render() {
			return (
				<Consumer>
					{(value) => {
						return <Children {...this.props} {...value} />;
					}}
				</Consumer>
			);
		}
	};
};
