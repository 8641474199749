import React, { useRef } from "react";
import "./imageEdit.css";

function Index(props) {
	const mCariGambarRef = useRef(null);
	const fValidateImage = (e) =>
		e.target.files[0].type === "image/gif" ||
		e.target.files[0].type === "image/jpeg" ||
		e.target.files[0].type === "image/jpg" ||
		e.target.files[0].type === "image/png";

	const fGetImage = (e) => {
		let iFiles = e.target.files[0];
		let iReader = new FileReader();

		iReader.onload = function (e) {
			// ------------------------
			let iImage = new Image();
			iImage.onload = function () {
				let iCanvas = document.createElement("canvas");
				iCanvas.width = 450;
				iCanvas.height = 600;
				let iContex = iCanvas.getContext("2d");

				iContex.drawImage(iImage, 0, 0, iCanvas.width, iCanvas.height);

				if (props._onNewImage) {
					props._onNewImage(
						{ file: iCanvas.toDataURL(), type: "image", ext: ".png" },
						props._index
					);
				}
			};

			iImage.src = e.target.result;
			// --------------------
		};
		iReader.readAsDataURL(iFiles);
	};

	return (
		<div className="wadahImageEdit">
			<img
				style={{ width: 375, height: 500 }}
				className="imageEdit"
				src={props._image}
				alt=""
			/>

			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					marginTop: 2,
					flex: 1,
				}}
			>
				<input
					type="file"
					style={{ display: "none" }}
					onChange={(e) => {
						let iFiles = e.target.files;
						if (iFiles.length === 1 && fValidateImage(e)) {
							fGetImage(e);
						} else {
							e.target.value = "";
							alert("Mohon pilih hanya file gambar");
						}
					}}
					ref={mCariGambarRef}
					accept="image/gif, image/jpeg, image/jpg, image/png"
				/>

				<button
					className="btnImageEdit"
					onClick={() => {
						mCariGambarRef.current.click();
					}}
				>
					Ganti
				</button>

				<button
					className="btnImageEdit"
					onClick={() => {
						if (props._onOK) {
							props._onOK(props._index);
						}
					}}
				>
					OK
				</button>

				<button
					className="btnImageEdit"
					onClick={() => {
						if (props._onCancel) {
							props._onCancel(props._index);
						}
					}}
				>
					Cancel
				</button>
			</div>
		</div>
	);
}

export default Index;
