import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../src/state.global";
import Env from "../../setting/env.js";
// import CompCreateAccount from "./createAccount.components.jsx";
import CompText from "../../component/textConfermasi";
import CompButton from "../../component/arjButton";
import CompAlert from "../../component/arjAlert";
import CompLoading from "../../component/arjLoading";

const ValidasiEmail = require("../../setting/validasi");

const Index = (props) => {
	const [sData, setData] = useState({
		eUserLogin: { eText: "", eBol: false },
		ePassword: { eText: "", eBol: false },
		eCaptcha: { eText: "", eBol: false },
		eBolAllDataOk: false,
	});
	const [sToken, setToken] = useState("");
	// const [sBtnColor, setBtnColor] = useState(Env.color.orangeRed05);
	const [sAlert, setAlert] = useState({
		display: false,
		title: "",
		keterangan: "",
	});
	const [sLoading, setLoading] = useState(false);

	const [sRefTemp, setRefTemp] = useState(useRef(null));
	const mRefTxtUserLogin = useRef(null);
	const mRefTxtPassword = useRef(null);
	const mRefTxtCaptcha = useRef(null);
	const mRefBtnLogin = useRef(null);

	useEffect(() => {
		mRefTxtUserLogin.current.focus();
	}, []);

	const fBolAllDataOk = (xData) => {
		const { eUserLogin, ePassword, eCaptcha } = xData;
		if (eUserLogin.eBol) {
			if (ePassword.eBol) {
				if (eCaptcha.eBol) {
					setRefTemp(mRefTxtUserLogin);
					return true;
				} else {
					setRefTemp(mRefTxtCaptcha);
					return false;
				}
			} else {
				setRefTemp(mRefTxtPassword);
				return false;
			}
		} else {
			setRefTemp(mRefTxtUserLogin);
			return false;
		}
	};

	return (
		<div className="Modal mt-14 pb-14 md:mt-10 md:pb-10 ">
			<div className="flex justify-center pb-28  w-full overflow-y-scroll">
				<div
					className="flex flex-col  mt-4 p-4 
				bg-gradient-to-t from-genkiBlue-cl_01 to-genkiBlue-light 
				rounded-lg border-genkiBlue-dark border-solid border-2 h-96 "
				>
					<div className="flex justify-center items-center">
						<h1>LOGIN</h1>
					</div>
					<hr style={{ color: "gray", height: 5 }} />
					<div className="flex flex-1 flex-col justify-evenly">
						<CompText
							_title="User Login (Nohp / Email)"
							_placeholder="No Hp / Email harus di isi"
							_harusDiIsi={true}
							_ref={mRefTxtUserLogin}
							_validasi={sData.eUserLogin.eBol}
							_onChange={(xEvent) => {
								if (xEvent.length > 7) {
									// Cek Jika Text Merupakan alamat EMAIl
									let iBolEmail =
										ValidasiEmail.ValidasiEmail(xEvent) ||
										ValidasiEmail.ValidasiNoHp(xEvent);
									if (iBolEmail) {
										setData((sData) => {
											let iData = { ...sData };
											iData.eUserLogin.eBol = true;
											iData.eUserLogin.eText = xEvent;
											iData.eBolAllDataOk = fBolAllDataOk(iData);
											return iData;
										});
									} else {
										setData((sData) => {
											let iData = { ...sData };
											iData.eUserLogin.eBol = false;
											iData.eUserLogin.eText = "";
											iData.eBolAllDataOk = false;
											return iData;
										});
									}
								} else {
									setData((sData) => {
										let iData = { ...sData };
										iData.eUserLogin.eBol = false;
										iData.eUserLogin.eText = "";
										iData.eBolAllDataOk = false;
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.eUserLogin.eBol) {
									mRefTxtPassword.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.eUserLogin.eBol) {
									mRefTxtPassword.current.focus();
								}
							}}
						/>

						<CompText
							_title="Password"
							_placeholder="Password harus di isi"
							_type="password"
							_harusDiIsi={true}
							_ref={mRefTxtPassword}
							_validasi={sData.ePassword.eBol}
							_onChange={(xEvent) => {
								if (xEvent.length > 5) {
									setData((sData) => {
										let iData = { ...sData };
										iData.ePassword.eBol = true;
										iData.ePassword.eText = xEvent;
										iData.eBolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setData((sData) => {
										let iData = { ...sData };
										iData.ePassword.eBol = false;
										iData.ePassword.eText = "";
										iData.eBolAllDataOk = false;
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.eUserLogin.eBol) {
									mRefTxtCaptcha.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtUserLogin.current.focus();
							}}
							_onKeyArrowDown={() => {
								if (sData.eUserLogin.eBol) {
									mRefTxtCaptcha.current.focus();
								}
							}}
						/>

						<CompText
							// CHAPTCHA
							_title="Masukkan Kode: "
							// _captcha={props._captcha}
							_captcha={true}
							_placeholder="Kode harus di isi"
							_harusDiIsi={true}
							_ref={mRefTxtCaptcha}
							_onChange={(xEvent) => {
								if (xEvent.length === 6) {
									setData((sData) => {
										let iData = { ...sData };
										iData.eCaptcha.eText = xEvent;
										iData.eCaptcha.eBol = true;
										iData.eBolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setData((sData) => {
										let iData = { ...sData };
										iData.eCaptcha.eText = "";
										iData.eCaptcha.eBol = false;
										iData.eBolAllDataOk = false;
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.eCaptcha.eBol) {
									mRefBtnLogin.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.eCaptcha.eBol) {
									mRefBtnLogin.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtPassword.current.focus();
							}}
							_onNewToken={(xToken) => {
								setToken(xToken);
							}}
							_validasi={sData.eCaptcha.eBol}
						/>

						<div className="flex w-full justify-evenly">
							<CompButton
								className="Button h-7 w-20 mx-auto"
								_text="LOGIN"
								// _style={{ width: "100px" }}
								_imageType={
									sData.eBolAllDataOk ? Env.buttonImageType.ok : Env.buttonImageType.stop
								}
								_ref={mRefBtnLogin}
								_onClick={() => {
									if (!fBolAllDataOk(sData)) {
										setAlert((xState) => {
											const isState = { ...xState };
											isState.display = "true";
											isState.title = "Login Belum Bisa";
											isState.keterangan = "Data Belum Lengkap / Format Tidak Sesuai ...!";
											return isState;
										});
									} else {
										setLoading(true);
										const iData = {
											user_login: sData.eUserLogin.eText,
											password: sData.ePassword.eText,
										};
										// ============ LOGIN ================================
										// console.log(props._pages.url);
										Axios.post(`${props._pages.url}${Env.eRouteApi}/login`, iData, {
											headers: {
												authorization: `bearer ${sToken}`,
												captchacode: sData.eCaptcha.eText,
											},
										})
											.then((xRes) => {
												// ========= RESPON DATA ================================
												setLoading(false);
												if (xRes.status === Env.eHttpStatus.eOk) {
													props.dispatch({
														type: "token",
														data: xRes.data.message.token,
													});
													props.dispatch({
														type: "user",
														data: xRes.data.message.user,
													});

													props.dispatch({
														type: "pages",
														data: props._pages,
													});

													props._close();
												} else {
													// alert(`Gagal ${xRes.data.message}`);
													// ;
													if (xRes.data.title === "Login Gagal") {
														setRefTemp(mRefTxtUserLogin);
													} else {
														setRefTemp(mRefTxtCaptcha);
													}
													setAlert((xState) => {
														const isState = { ...xState };
														isState.display = "true";
														isState.title = "Login Gagal";
														isState.keterangan = xRes.data.message;
														return isState;
													});
												}
												// END====== RESPON DATA ================================
											})
											.catch((xErr) => {
												setLoading(false);
												props._close();
												alert(xErr);
											});
										// END========= LOGIN ================================
									}
								}}
								// _onFocus={() => {
								// 	setBtnColor("Red");
								// }}
								// _onBlur={() => {
								// 	setBtnColor(Env.color.orangeRed05);
								// }}
								_onKeyUp={() => {
									mRefTxtCaptcha.current.focus();
								}}
							/>
							<CompButton
								className="Button h-7 w-20 mx-auto"
								// _style={{ width: "100px" }}
								_text="Batal"
								_onClick={() => {
									props._close();
								}}
							/>
						</div>
					</div>
				</div>

				{sAlert.display ? (
					<CompAlert
						_title={sAlert.title}
						_keterangan={sAlert.keterangan}
						_onOk={() => {
							setAlert((xState) => {
								const isState = { ...xState };
								isState.display = false;
								return isState;
							});
							if (sRefTemp.current) {
								sRefTemp.current.focus();
								sRefTemp.current.select();
							} else {
								mRefTxtUserLogin.current.focus();
								mRefTxtUserLogin.current.select();
							}
						}}
					/>
				) : (
					""
				)}
				{sLoading ? <CompLoading /> : ""}
			</div>
		</div>
	);
};

export default GlobalStateConsumer(Index);
