// Catatan PROPS
// * props_dataJurnal = {
// 	- eIndex: 0,----------------------------------------------- Optional
// 	- eId: "",
// 	- eTanggal: { value: "", status: false },
// 	- eDebet: { value: "", akun: "", ref: "", status: false },
// 	- eKredit: { value: "", akun: "", ref: "", status: false },
// 	- eNilai: { value: 0, status: false },
// 	- eKeterangan: { value: "", status: false },
// 	- eNota: { no: 0, value: [] },
// };

import { useEffect, useState, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";

import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";
import CompAlertSimpan from "../../../../component/arjAlert";
import CompAlertDelete from "../../../../component/arjAlert";
import CompAlert from "../../../../component/arjAlert";
import CompInput from "./InputDataJurnal";
import CompText from "../../../../component/arjText";
import CompNota from "./nota";

const JurnalEdit = (props) => {
	const [sDataJurnal, setSDataJurnal] = useState(props._dataJurnal);
	// eslint-disable-next-line
	const [sTempDataJurnal, setSTempDataJurnal] = useState({
		eNilai: { ...props._dataJurnal.eNilai },
		eNota: { ...props._dataJurnal.eNota },
	});
	const [sBtnNota, setSBtnNota] = useState({ eVisible: false, eActive: false });

	const [sAlertSimpan, setSAlertSimpan] = useState({
		eStatus: false,
		eRef: useRef(null),
	});

	const [sRsAkun, setSRsAkun] = useState([]);
	const [sRsSeksi, setSRsSeksi] = useState([]);
	const [sLoading, setSLoading] = useState(false);
	const [sAlertDelete, setSAlertDelete] = useState(false);
	const [sAlert, setSAlert] = useState({
		eTitle: "",
		eKeterangan: "",
		eShow: false,
	});

	const iRefTanggal = useRef(null);
	const iRefDebet = useRef(null);
	const iRefKredit = useRef(null);
	const iRefNilai = useRef(null);
	const iRefKeterangan = useRef(null);
	const iRefSimpan = useRef(null);

	useEffect(() => {
		iRefTanggal.current.focus();
		// Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/akun`, {
		// 	headers: {
		// 		authorization: `bearer ${props.state.sToken}`,
		// 	},
		// 	params: { type: "Anak" },
		// })
		// 	.then((xData) => {
		// 		setSRsAkun(xData.data.message);
		// 	})
		// 	.catch((xErr) => {
		// 		alert(xErr);
		// 	});

		// Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/seksi`, {
		// 	params: { type: "short" },
		// 	headers: {
		// 		authorization: `bearer ${props.state.sToken}`,
		// 	},
		// })
		// 	.then((xData) => {
		// 		setSRsSeksi(xData.data.message.seksi);
		// 	})
		// 	.catch((xErr) => {
		// 		alert(xErr);
		// 	});

		setSRsAkun(props._rsAkun);
		setSRsSeksi(props._rsSeksi);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setSBtnNota((xState) => {
			let iState = { ...xState };
			if (sDataJurnal.eDebet.ref === sDataJurnal.eKredit.ref) {
				iState.eVisible = true;
			} else {
				iState.eVisible = false;
			}
			if (sDataJurnal.eNota.value.length > 0) {
				iState.eActive = true;
			}
			//  else {
			// 	if (sTempDataJurnal.eNota.value.length > 0) {
			// 		iState.eActive = false;
			// 	} else {
			// 		if (iState.eActive === false) {
			// 			// mendapatkan No Nota Jika No nota = 0 / nota kosong

			// 			iState.eActive = true;
			// 		} else {
			// 			iState.eActive = false;
			// 		}
			// 	}
			// }
			return iState;
		});

		try {
			if (sDataJurnal.eFocus !== null) {
				sDataJurnal.eFocus.current.focus();
				sDataJurnal.eFocus.current.select();
			}
		} catch (xErr) {}

		//eslint-disable-next-line
	}, [sDataJurnal]);

	const fSetAlertSimpan = (xRef) => {
		setSAlertSimpan((xState) => {
			let iState = { ...xState };
			iState.eStatus = true;
			iState.eRef = xRef;
			return iState;
		});
	};

	return (
		<div className="Modal z-75 w-full">
			{/* ==============LOADING======================================== */}
			{sLoading ? <CompLoading /> : ""}

			{/* ==============ALERT SIMPAN==================================  */}
			{sAlertSimpan.eStatus ? (
				<CompAlertSimpan
					_title="Data Belum Lengkap"
					_keterangan="Mohon Lengkapi Data"
					_onOk={() => {
						sAlertSimpan.eRef.current.focus();
						setSAlertSimpan((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
					}}
				/>
			) : (
				""
			)}

			{sAlert.eShow === true ? (
				<CompAlert
					_title={sAlert.eTitle}
					_keterangan={sAlert.eKeterangan}
					_onOk={() => {
						setSAlert((xState) => {
							let iState = { ...xState };
							iState.eShow = false;
							return iState;
						});
					}}
				/>
			) : (
				""
			)}

			{sAlertDelete ? (
				<CompAlertDelete
					_title={`Delete Data Jurnal Umum`}
					_keterangan={` Apakah Benar Akan Menghapus Data Jurnal Umum No:${
						props._dataJurnal.eIndex + 1
					}`}
					_buttonOk="Ya"
					_buttonCancel="Tidak"
					_onCancel={() => {
						setSAlertDelete(false);
					}}
					_onOk={() => {
						setSLoading(true);

						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/jurnalumum`,
							{
								data: { id: sDataJurnal.eId, index: sDataJurnal.eIndex },
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									if (props._onDelete) {
										props._onDelete(xData.data);
									}
								} else {
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Delete Data Jurnal Umum";
										iState.eKeterangan = "Gagal";
										iState.eShow = true;

										return iState;
									});
								}
							})
							.catch((xErr) => {
								setSAlert((xState) => {
									let iState = { ...xState };
									iState.eTitle = "Delete Data Jurnal Umum Gagal";
									iState.eKeterangan = xErr;
									iState.eShow = true;

									return iState;
								});
							});
						setSAlertDelete(false);
					}}
				/>
			) : (
				""
			)}
			{/*=======================================================================  */}
			{/* MASUKKAN DATA Jurnal Umum ===================================================*/}
			<div
				className="flex flex-col space-y-2 bg-red-200
				w-full mx-auto  p-1 rounded-xl mt-12 ml-2 mr-2 mb-2 
				 overflow-y-auto"
			>
				<div className="flex justify-center">
					Edit Data Jurnal Umum{" "}
					{props._dataJurnal.eIndex ? ` No: ${props._dataJurnal.eIndex + 1}` : ""}
				</div>
				<hr style={{ color: "gray" }} />

				{/* ================TANGGAL======================================= */}
				<div className="WadahFix flex w-full">
					<div className="flex w-24 h-auto items-center">Tanggal</div>
					<div className="flex w-3 h-auto items-center">:</div>
					<div className=" w-44 p-1">
						<input
							className="Text flex h-8"
							ref={iRefTanggal}
							type="date"
							value={sDataJurnal.eTanggal.value}
							onChange={(xData) => {
								setSDataJurnal((xState) => {
									let iState = { ...xState };
									iState.eTanggal.value = xData.nativeEvent.target.value;
									iState.eTanggal.status = true;
									iState.eFocus = null;
									return iState;
								});
							}}
							onKeyUp={(xEvent) => {
								if (xEvent.nativeEvent.key === "Enter") {
									iRefDebet.current.focus();
									iRefDebet.current.select();
								}
							}}
							onClick={() => {
								iRefTanggal.current.focus();
							}}
						/>
					</div>
				</div>
				{/* =================DEBET========================================= */}
				{sRsAkun.length > 0 ? (
					sRsSeksi.length > 0 ? (
						<CompInput
							_title="Debet"
							_ref={iRefDebet}
							_dataSourceAkun={sRsAkun}
							_dataSourceSeksi={sRsSeksi}
							_valueAkun={sDataJurnal.eDebet.value}
							_valueRef={sDataJurnal.eDebet.ref}
							_onKeyEnter={(xData) => {
								setSDataJurnal((xState) => {
									let iState = { ...xState };
									iState.eDebet.akun = xData[1]; //Nama Akun
									iState.eDebet.value = xData[0]; // NoAkun
									iState.eDebet.ref = xData[2]; // Ref Seksi / Pengelola Keuangan
									iState.eDebet.status = true;
									iState.eFocus = iRefKredit;
									return iState;
								});
							}}
						/>
					) : (
						""
					)
				) : (
					""
				)}

				{/*================= KREDIT ==========================================*/}
				{sRsAkun.length > 0 ? (
					sRsSeksi.length > 0 ? (
						<CompInput
							_title="Kredit"
							_ref={iRefKredit}
							_dataSourceAkun={sRsAkun}
							_dataSourceSeksi={sRsSeksi}
							_valueAkun={sDataJurnal.eKredit.value}
							_valueRef={sDataJurnal.eKredit.ref}
							_onKeyEnter={(xData) => {
								if (xData[0] !== sDataJurnal.eDebet.akun) {
									setSDataJurnal((xState) => {
										let iState = { ...xState };
										iState.eKredit.akun = xData[1]; //Nama Akun
										iState.eKredit.value = xData[0]; // NoAkun
										iState.eKredit.ref = xData[2]; // Ref Seksi / Pengelola Keuangan
										iState.eKredit.status = true;
										iState.eFocus = iRefNilai;
										return iState;
									});
								} else {
									iRefDebet.current.focus();
								}
							}}
						/>
					) : (
						""
					)
				) : (
					""
				)}

				{/* =================NILAI=========================================== */}
				<div
					className="WadahFix flex  space-y-1 md:flex-row "
					style={{ padding: "2px" }}
				>
					<div className="w-24 flex m-auto">
						<div className="flex-1">Nilai</div>
						<div className="w-6">Rp</div>
					</div>
					<div className="w-3">:</div>
					<div className="flex-1">
						<CompText
							_ref={iRefNilai}
							_dataType="ribuan"
							_value={sDataJurnal.eNilai.value}
							_onChange={(xData) => {
								setSDataJurnal((xState) => {
									let iState = { ...xState };
									iState.eNilai.value = xData;
									if (xData > 0) {
										iState.eNilai.status = true;
									} else {
										iState.eNilai.status = false;
									}
									iState.eFocus = null;
									return iState;
								});
							}}
							_onKeyEnter={() => {
								iRefKeterangan.current.focus();
								iRefKeterangan.current.select();
							}}
						/>
					</div>
				</div>

				{/* =================KETERANGAN======================================= */}
				<div
					className="WadahFix flex flex-col space-y-1 "
					style={{ padding: "2px" }}
				>
					{/* ============TEXT KETERANGAN===================================== */}
					<div className="flex">
						<div className="flex h-auto items-center ">
							<div className=" w-28">Keterangan</div>
							<div className="w-3">:</div>
						</div>

						<textarea
							ref={iRefKeterangan}
							className="Text h-16 flex-1"
							name=""
							id=""
							cols="30"
							rows="10"
							value={sDataJurnal.eKeterangan.value}
							// =====onChange=====
							onChange={(xData) => {
								const iData = xData.nativeEvent.target.value;
								setSDataJurnal((xState) => {
									let iState = { ...xState };
									iState.eKeterangan.value = iData;
									if (iData !== "") {
										iState.eKeterangan.status = true;
									} else {
										iState.eKeterangan.status = false;
									}
									return iState;
								});
							}}
							// =====onKeyPress
							onKeyPress={(xEvent) => {
								if (
									xEvent.nativeEvent.key === "Enter" &&
									sDataJurnal.eKeterangan.status
								) {
									iRefSimpan.current.focus();
								}
							}}
						></textarea>
					</div>

					{/*================== NOTA ==============================================*/}
					<div className="flex flex-col md:flex-row">
						<div className="flex mb-1 md:mb-0 w-full justify-center md:w-28">
							{sBtnNota.eVisible ? (
								<button
									className={
										sBtnNota.eActive ? "ButtonActive h-6 w-auto" : "Button h-6 w-auto"
									}
									onClick={async () => {
										if (sTempDataJurnal.eNota.no === 0) {
											const iNoNota = await Axios.get(
												`${props.state.sPages.url}${Env.eRouteApi}/bendahara/jurnalumum`,
												{
													headers: {
														authorization: `bearer ${props.state.sToken}`,
													},
													params: {
														req: "no_nota",
														debet_ref: sDataJurnal.eDebet.ref,
													},
												}
											);
											setSTempDataJurnal((xState) => {
												let iState = { ...xState };
												iState.eNota.no = iNoNota.data.no_nota;
												return iState;
											});
										}
										setSDataJurnal((xState) => {
											let iState = { ...xState };
											if (sBtnNota.eActive) {
												if (sTempDataJurnal.eNota.value.length > 0) {
													iState.eNilai.value = 0;
												} else {
													iState.eNilai.value = sTempDataJurnal.eNilai.value;
												}
												// iState.eNilai.status = false;
												iState.eFocus = iRefNilai;
												iState.eNota.value = [];
												// console.log(1);
												setSBtnNota((xState) => {
													let iState = { ...xState };
													iState.eActive = false;
													return iState;
												});
												return iState;
											} else {
												// console.log(2);
												iState.eNilai = { ...sTempDataJurnal.eNilai };
												iState.eNota = { ...sTempDataJurnal.eNota };

												setSBtnNota((xState) => {
													let iState = { ...xState };
													iState.eActive = true;
													return iState;
												});

												if (iState.eNota.no === 0) {
													return iState;
												} else {
													return iState;
												}
											}
										});
									}}
								>
									{sBtnNota.eActive ? "Hapus Nota" : "+ Nota"}
								</button>
							) : (
								""
							)}
						</div>

						{/* ============ISI NOTA =============================================*/}
						{sBtnNota.eActive ? (
							<CompNota
								_noNota={sDataJurnal.eNota.no}
								_debetRef={sDataJurnal.eDebet.ref}
								_value={sDataJurnal.eNota.value}
								_dataNota={(xData) => {
									// console.log(xData);
									let iTotal = 0;
									for (let i = 0; i < xData.eUraian.length; i++) {
										iTotal += xData.eUraian[i].jumlah;
									}
									setSDataJurnal((xState) => {
										let iState = { ...xState };
										iState.eNota.no = xData.eNoNota;
										iState.eNota.value = xData.eUraian;
										iState.eNilai.value = iTotal;
										iState.eNilai.status = true;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									iRefSimpan.current.focus();
								}}
							/>
						) : (
							""
						)}
					</div>
				</div>

				<div className="w-full flex justify-center">
					<div className="flex justify-evenly w-64  md:w-96">
						{/* =====================UPDATE============================================= */}
						<button
							className="Button w-20 h-6"
							ref={iRefSimpan}
							onClick={() => {
								// =======Cek Data============================
								if (!sDataJurnal.eTanggal.status) {
									fSetAlertSimpan(iRefTanggal);
								} else if (!sDataJurnal.eDebet.status) {
									fSetAlertSimpan(iRefDebet);
								} else if (!sDataJurnal.eKredit.status) {
									fSetAlertSimpan(iRefKredit);
								} else if (!sDataJurnal.eNilai.status) {
									fSetAlertSimpan(iRefNilai);
								} else if (!sDataJurnal.eKeterangan.status) {
									fSetAlertSimpan(iRefKeterangan);
								} else {
									// ========UPDATE DATA JURNAL UMUM=========================
									setSLoading(true);

									const iData = {
										jurnal: {
											id: sDataJurnal.eId,
											index: sDataJurnal.eIndex,
											data: {
												tanggal: sDataJurnal.eTanggal.value,
												debet: sDataJurnal.eDebet.value,
												debet_ref: sDataJurnal.eDebet.ref,
												kredit: sDataJurnal.eKredit.value,
												kredit_ref: sDataJurnal.eKredit.ref,
												nilai: sDataJurnal.eNilai.value,
												keterangan: sDataJurnal.eKeterangan.value,
												no_nota: sDataJurnal.eNota.no,
											},
										},
										nota: sDataJurnal.eNota.value,
									};

									// console.log(iData);
									Axios.put(
										`${props.state.sPages.url}${Env.eRouteApi}/bendahara/jurnalumum`,
										iData,
										{
											headers: {
												authorization: `bearer ${props.state.sToken}`,
											},
										}
									)
										.then((xData) => {
											setSLoading(false);
											if (props._onUpdate) {
												props._onUpdate(xData.data);
											}
										})
										.catch(() => {
											setSLoading(false);
											alert("Data Gagal Disimpan");
										});
								}
							}}
						>
							Update
						</button>

						<button
							className="Button h-6"
							onClick={() => {
								setSAlertDelete(true);
							}}
						>
							Delete
						</button>

						<button
							className="Button h-6"
							onClick={() => {
								if (props._onCancel) {
									props._onCancel();
								}
							}}
						>
							Cancel
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(JurnalEdit);
