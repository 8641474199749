import { useState, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";

// import CompTextDs from "../../../../../component/arjText-DataSource";
// import CompText from "../../../../../component/arjText";
import CompLoading from "../../../../../component/arjLoading";
// import CompAlertSimpan from "../../../../../component/arjAlert";
// import CompAlertUpdate from "../../../../../component/arjAlert";
// import CompAlertDelete from "../../../../../component/arjAlert";

const AbsenKegiatanTerAkhir = (props) => {
	let mGroupId = 0;
	let mBg = false;
	const fClassName = () => {
		mBg = !mBg;
		if (mBg) {
			return "flex h-full w-full bg-blue-200";
		} else {
			return "flex h-full w-full bg-blue-100";
		}
	};
	const [sLoading, setSLoading] = useState(true);
	const [sRsDataBayar, setSRsDataBayar] = useState([]);
	const [sNewRsDataBayar, setSNewRsDataBayar] = useState([]);
	const [sRsRealisasiKegiatan, setSRsRealisasiKegiatan] = useState([]);
	const [sSelectedIndex, setSSelectedIndex] = useState([-1, -1]);

	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,
					{
						params: { req: "absen_kegiatan_terakhir" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);

				if (iData.status === Env.eHttpStatus.eOk) {
					const iRsDataBayar = iData.data.message;
					if (iRsDataBayar) {
						setSRsDataBayar(iRsDataBayar);
						// console.log(iRsDataBayar);

						const iNewRsDataBayar = [];
						for (let i = 0; i < iRsDataBayar.length; i++) {
							const iDataBayar = iRsDataBayar[i];

							const iRsKegiatanBayar = iDataBayar.kegiatan_bayar;
							const iNewRsKegiatanBayar = [];
							for (let ii = 0; ii < iRsKegiatanBayar.length; ii++) {
								const iKegiatanBayar = iRsKegiatanBayar[ii];
								let iStatus = false;
								if (iKegiatanBayar.nilai === 0) {
									iStatus = true;
								}

								iNewRsKegiatanBayar.push({
									id: iKegiatanBayar.id,
									nilai: iKegiatanBayar.nilai,
									status: iStatus,
									kegiatan_jenis: iKegiatanBayar.kegiatan_jenis.jenis,
								});
							}

							iNewRsDataBayar.push({
								name: iDataBayar.name,
								kegiatan_bayar: iNewRsKegiatanBayar,
							});
						}
						setSNewRsDataBayar(iNewRsDataBayar);
						// console.log(iNewRsDataBayar);
					}
					// console.log(iData.data.realisasi_kegiatan);
					setSRsRealisasiKegiatan(iData.data.realisasi_kegiatan);
				}

				setSLoading(false);
			};
			fGetData();
		} catch (xErr) {}
		// eslint-disable-next-line
	}, []);

	const fSave = async (xIndexKegiatan, xIndexBayar) => {
		// if (sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].nilai !== 0) {
		setSLoading(true);
		let iData = null;
		let iStatus = false;
		if (sNewRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].status) {
			// Ulang Bayar
			iData = {
				req: "ulang_bayar",
				id: sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].id,
				jenis:
					sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].kegiatan_jenis
						.jenis,
				kegiatan_id:
					sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].kegiatan.id,
			};
		} else {
			// Bayar
			iStatus = true;

			iData = {
				req: "bayar",
				nilai: sNewRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].nilai,
				id: sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].id,
				nama: sRsDataBayar[xIndexKegiatan].name,
				jenis:
					sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].kegiatan_jenis
						.jenis,
				kegiatan_jenis_id:
					sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].kegiatan_jenis.id,
				kegiatan_id:
					sRsDataBayar[xIndexKegiatan].kegiatan_bayar[xIndexBayar].kegiatan.id,
			};
		}

		Axios.put(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/absen_kegiatan_terakhir`,
			iData,
			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		).then((xRes) => {
			// console.log(xRes.data);
			setSNewRsDataBayar((xStete) => {
				let iState = [...xStete];
				let iStateKegiatanBayar = {
					...iState[xIndexKegiatan].kegiatan_bayar[xIndexBayar],
				};
				iStateKegiatanBayar.status = iStatus;
				iStateKegiatanBayar.nilai = xRes.data.message;
				iState[xIndexKegiatan].kegiatan_bayar[xIndexBayar] = iStateKegiatanBayar;
				return iState;
			});
			// console.log(xRes.data.realisasi_kegiatan);
			setSRsRealisasiKegiatan(xRes.data.realisasi_kegiatan);
			setSSelectedIndex([xIndexKegiatan, xIndexBayar]);
			setSLoading(false);
		});
		// }
	};

	return (
		<div className="flex flex-col w-full h-screen">
			{sLoading ? <CompLoading /> : null}

			{/* TITLE */}
			<div
				className="flex flex-col p-2 mt-1 max-w-sm md:max-w-xl h-8 
							mx-auto bg-white rounded-xl shadow-md items-center "
			>
				<p>Absen Kegiatan Terakhir</p>
			</div>
			<div className="flex-1 md:p-2 overflow-y-auto mt-2 bg-blue-300">
				{sNewRsDataBayar.map((xDataBayar, xIndex) => {
					const iDataBayar = sRsDataBayar[xIndex];
					if (mGroupId !== iDataBayar.members_group.id) {
						mGroupId = iDataBayar.members_group.id;
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className="flex justify-center w-full border-gray-900 border-solid border-1 text-xl text-genkiFocus">
									{iDataBayar.members_group.nama}
								</div>
								<div className={fClassName()}>
									<div
										className={`flex items-center w-56 md:w-64  border-gray-900 border-solid border-1 ${
											xIndex === sSelectedIndex[0] ? " bg-yellow-300" : null
										}`}
									>
										{iDataBayar.name}
									</div>
									<div className=" flex-1 border-gray-900 border-solid border-1">
										{xDataBayar.kegiatan_bayar.map((xKegiatanBayar, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? `flex items-center  p-0.5 ${
																	xIndex === sSelectedIndex[0] && xxIndex === sSelectedIndex[1]
																		? " bg-yellow-300"
																		: null
															  }`
															: `flex items-center border-gray-900 border-solid border-t-2 p-0.5 ${
																	xIndex === sSelectedIndex[0] && xxIndex === sSelectedIndex[1]
																		? " bg-yellow-300"
																		: null
															  }`
													}
												>
													<div className="flex-1">
														{iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.nama}
													</div>
													{iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.jenis === 1 || //jika kegiatan merupakan iuran
													iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.jenis === 3 ? ( //jika kegiatan merupakan tabungan wajib
														<div className="Text bg-gray-100 w-20 md:w-32  mr-1 md:mr-4">
															{Env.format.uang(xKegiatanBayar.nilai)}
														</div>
													) : xKegiatanBayar.status ? (
														<div className="Text bg-gray-100 w-20 md:w-32  mr-1 md:mr-4">
															Hadir
														</div>
													) : (
														<div className="Text bg-gray-100 w-20 md:w-32 mr-1 md:mr-4">
															Hadir ?
														</div>
													)}

													<input
														type="checkbox"
														checked={xKegiatanBayar.status}
														className="mr-1 md:mr-2"
														onChange={() => {
															fSave(xIndex, xxIndex);
														}}
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					} else {
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className={fClassName()}>
									<div
										className={`flex items-center w-56 md:w-64  border-gray-900 border-solid border-1 ${
											xIndex === sSelectedIndex[0] ? " bg-yellow-300" : null
										}`}
									>
										{iDataBayar.name}
									</div>
									<div className=" flex-1 border-gray-900 border-solid border-1">
										{xDataBayar.kegiatan_bayar.map((xKegiatanBayar, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? `flex items-center  p-0.5 ${
																	xIndex === sSelectedIndex[0] && xxIndex === sSelectedIndex[1]
																		? " bg-yellow-300"
																		: null
															  }`
															: `flex items-center border-gray-900 border-solid border-t-2 p-0.5 ${
																	xIndex === sSelectedIndex[0] && xxIndex === sSelectedIndex[1]
																		? " bg-yellow-300"
																		: null
															  }`
													}
												>
													<div className=" flex-1">
														{iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.nama}
													</div>
													{iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.jenis === 1 || //jika kegiatan merupakan iuran
													iDataBayar.kegiatan_bayar[xxIndex].kegiatan_jenis.jenis === 3 ? ( //jika kegiatan merupakan tabungan wajib
														<div className="Text bg-gray-100 w-20 md:w-32 mr-1 md:mr-4">
															{Env.format.uang(xKegiatanBayar.nilai)}
														</div>
													) : xKegiatanBayar.status ? (
														<div className="Text bg-gray-100 w-20 md:w-32 mr-1 md:mr-4">
															Hadir
														</div>
													) : (
														<div className="Text bg-gray-100 w-20 md:w-32 mr-1 md:mr-4">
															Hadir ?
														</div>
													)}

													<input
														type="checkbox"
														checked={xKegiatanBayar.status}
														className="mr-1 md:mr2"
														onChange={() => {
															fSave(xIndex, xxIndex);
														}}
													/>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						);
					}
				})}
			</div>

			<div className=" w-full mt-2 md:px-2 ">
				<table className="Table">
					<thead>
						<tr>
							<th>Jenis</th>
							<th>Target</th>
							<th>Realisasi</th>
							<th>Min</th>
							<th>%</th>
						</tr>
					</thead>
					<tbody>
						{sRsRealisasiKegiatan.map((xData, xIndex) => {
							return (
								<tr key={xIndex}>
									<td>{xData.kegiatan}</td>
									<td align="right">
										{xData.jenis === 1
											? Env.format.uang(xData.target)
											: `${Env.format.uang(xData.target)} orang`}
									</td>
									<td align="right">
										{xData.jenis === 1
											? Env.format.uang(xData.realisasi)
											: `${Env.format.uang(xData.realisasi)} orang`}
									</td>
									<td align="right">
										{xData.jenis === 1
											? Env.format.uang(xData.target - xData.realisasi)
											: `${Env.format.uang(xData.target - xData.realisasi)} orang`}
									</td>
									<td align="right">
										{Env.format.uang((xData.realisasi / xData.target) * 100)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			<div className=" h-14 "></div>
		</div>
	);
};

export default GlobalStateConsumer(AbsenKegiatanTerAkhir);
