import { useEffect, useState, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import CompPrint from "react-to-print";
import CompLoading from "../../../component/arjLoading";
import Env from "../../../setting/env";
import CompExel from "../../../component/exportToExel";

const BukuBesar = (props) => {
	const [sLoading, setSLoading] = useState(false);
	const [sRsViewBukuBesar, setSRsViewBukuBesar] = useState([]);
	const [sExel, setSExel] = useState([]);
	const refComponent = useRef();

	const fCol = (xValue, xColMarger = 1) => {
		return { value: xValue, colMarger: xColMarger };
	};

	useEffect(() => {
		setSLoading(true);
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/laporan`, {
			params: {
				req: "bukuBesarKwajiban",
				no_akun: props._noAkun,
				tanggal: props._tanggal,
			},
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		}).then((xData) => {
			const iRsData = xData.data.message;
			const iRsViewData = [];
			let iSaldo = 0;
			let iRef = "";
			let iDebet = "";
			let iKredit = "";
			for (let i = 0; i < iRsData.length; i++) {
				if (iRsData[i].debet === props._noAkun) {
					iSaldo -= parseFloat(iRsData[i].nilai);
					iDebet = Env.format.uang(iRsData[i].nilai);
					iKredit = "";
					iRef = iRsData[i].kredit;
				} else {
					iSaldo += parseFloat(iRsData[i].nilai);
					iKredit = Env.format.uang(iRsData[i].nilai);
					iDebet = "";
					iRef = iRsData[i].debet;
				}

				if (iRef === "0") {
					iRef = "";
				}

				const iData = {
					tanggal: iRsData[i].tanggal,
					keterangan: iRsData[i].keterangan,
					debet: iDebet,
					kredit: iKredit,
					saldo: Env.format.uang(iSaldo),
					ref: iRef,
				};
				iRsViewData.push(iData);
			}
			// console.log(iRsViewData);
			setSRsViewBukuBesar(iRsViewData);

			// ======== Untuk Export ke EXEL===================================================
			let iExel = [
				{
					row: 1,
					col: [
						fCol("Buku Besar", 2),
						fCol(props._keteranganAkun, 2),
						fCol(`Lpr: ${Env.efFormatTanggal(props._tanggal)}`, 3),
					],
				},
				{
					row: 3,
					col: [
						fCol("No"),
						fCol("Tanggal"),
						fCol("Keterangan"),
						fCol("Debet"),
						fCol("Kredit"),
						fCol("Saldo"),
						fCol("Ref"),
					],
				},
			];
			let iRow = 3;
			for (let i = 0; i < iRsViewData.length; i++) {
				iRow += 1;
				iExel.push({
					row: iRow,
					col: [
						fCol(i + 1),
						fCol(iRsViewData[i].tanggal),
						fCol(iRsViewData[i].keterangan),
						fCol(iRsViewData[i].debet),
						fCol(iRsViewData[i].kredit),
						fCol(iRsViewData[i].saldo),
						fCol(iRsViewData[i].ref),
					],
				});
			}
			setSExel(iExel);

			setSLoading(false);
		});

		// eslint-disable-next-line
	}, []);
	return (
		<div className="fixed flex flex-col z-10 w-full h-screen bg-red-100 top-10">
			{/* LOADING */}
			{sLoading ? <CompLoading /> : ""}

			<div
				className="flex absolute right-1 justify-center
                             items-center w-34 h-10 space-x-1"
			>
				<CompExel
					_data={sExel}
					_fileName={`${props._tanggal}_${props._keteranganAkun}`}
				/>

				<CompPrint
					trigger={() => {
						return <div className="Button h-6">cetak</div>;
					}}
					content={() => refComponent.current}
				/>
				<div
					className="flex justify-center items-center
                                bg-green-300 rounded-xl w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100"
					onClick={() => {
						props._onClose();
					}}
				>
					X
				</div>
			</div>

			<div className="flex flex-col w-full overflow-y-scroll" ref={refComponent}>
				<div className="flex w-full h-8 bg-yellow-200 items-center mt-8 md:mt-0">
					<div className="flex items-center ml-2">Buku Besar</div>
					<div className="flex-1 flex justify-center items-center truncate">
						{props._keteranganAkun}
					</div>
					<div className="flex justify-center mr-1">{`Lpr: ${Env.efFormatTanggal(
						props._tanggal
					)}`}</div>
					<div className="flext md:w-32"></div>
				</div>
				<div className="flex flex-col w-full justify-center md:p-1">
					<table className="Table">
						<thead>
							<tr>
								<th>No </th>
								<th>Tanggal</th>
								<th>Keterangan</th>
								<th>Debet</th>
								<th>Kredit</th>
								<th>Saldo</th>
								<th>Ref</th>
							</tr>
						</thead>
						<tbody>
							{sRsViewBukuBesar.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td className="text-right w-10">{xIndex + 1}</td>
										<td className="text-center w-24">
											{Env.efFormatTanggal(xData.tanggal)}
										</td>
										<td>{xData.keterangan}</td>
										<td className="text-right w-24">{xData.debet}</td>
										<td className="text-right w-24">{xData.kredit}</td>
										<td className="text-right w-28">{xData.saldo}</td>
										<td className="text-center w-10">{xData.ref}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="h-20 "></div>
		</div>
	);
};

export default GlobalStateConsumer(BukuBesar);
