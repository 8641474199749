import React from "react";

import ImgStop from "../../assets/image/stop.svg";
import ImgOk from "../../assets/image/ok.svg";
import Env from "../../setting/env.js";

const Index = (props) => {
	const fButtonImage = () => {
		switch (props._imageType) {
			case Env.buttonImageType.stop:
				return ImgStop;
			case Env.buttonImageType.ok:
				return ImgOk;
			default:
				return false;
		}
	};
	return (
		<button
			className={props.className}
			style={props._style}
			ref={props._ref}
			onClick={() => {
				if (props._onClick) {
					props._onClick();
				}
			}}
			onFocus={() => {
				if (props._onFocus) {
					props._onFocus();
				}
			}}
			onBlur={() => {
				if (props._onBlur) {
					props._onBlur();
				}
			}}
			onMouseOver={() => {
				if (props._onMouseOver) {
					props._onMouseOver();
				}
			}}
			onMouseOut={() => {
				if (props._onMouseOut) {
					props._onMouseOut();
				}
			}}
			onKeyUp={(xEvent) => {
				if (xEvent.nativeEvent.key === "ArrowUp") {
					if (props._onKeyUp) {
						props._onKeyUp();
					}
				}
			}}
			onKeyDown={(xEvent) => {
				if (xEvent.nativeEvent.key === "ArrowDown") {
					if (props._onKeyDown) {
						props._onKeyDown();
					}
				}
			}}
		>
			<div
				style={{
					display: "Flex",
					height: "100%",
					width: "100%",
				}}
			>
				<div
					style={{
						display: "Flex",
						height: "100%",
						flex: 1,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{props._text}
				</div>

				{fButtonImage() ? (
					<div
						style={{
							display: "Flex",
							alignItems: "center",
							justifyContent: "center",
							width: "20px",
						}}
					>
						<img
							src={fButtonImage()}
							alt="gambar"
							style={{
								height: "20px",
								width: "20px",
								margin: "0 2px 2px 2px",
							}}
						/>
					</div>
				) : (
					""
				)}
			</div>
		</button>
	);
};

export default Index;
