import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import Env from "../../setting/env";
import { GlobalStateConsumer } from "../../state.global";
import CompText from "../../component/textConfermasi";
// import CompImage from "../../component/image/imageRead.jsx";
import CompButton from "../../component/arjButton";
import CompAlert from "../../component/arjAlert";
import CompLoading from "../../component/arjLoading";

// import ImgReff from "../../assets/image/acount.svg";

const ValidasiEmail = require("../../setting/validasi");
const LoginRegistrasiAcccount = (props) => {
	// validasi
	const [sData, setSData] = useState({
		userName: { text: "", bol: false },
		userLogin: { text: "", type: "", bol: false },
		password: { text: "", bol: false },
		newPassword: { text: "", bol: false },
		passwordKonfermasi: { text: "", bol: false },
		photo: null,
		captcha: { text: "", bol: false },
		bolAllDataOk: false,
	});
	const [sToken, setStoken] = useState("");
	const [sAlert, setSAlert] = useState({
		display: false,
		title: "Pembuatan Account Gagal",
		keterangan: "Data Belum Lengkap / Format Tidak Sesuai",
	});
	const [sLoading, setSLoading] = useState(false);

	// ref
	const [sRefTemp, setSRefTemp] = useState(useRef(null));
	const mRefTxtUserName = useRef(null);
	const mRefTxtUserLogin = useRef(null);
	const mRefTxtPassword = useRef(null);
	const mRefTxtNewPassword = useRef(null);
	const mRefTxtPasswordKonfermasi = useRef(null);
	const mRefTxtCaptcha = useRef(null);
	const mRefCmdBuatAkun = useRef(null);

	const fBolAllDataOk = (xData) => {
		const {
			userName,
			userLogin,
			password,
			newPassword,
			passwordKonfermasi,
			captcha,
		} = xData;
		//
		if (userName.bol) {
			if (userLogin.bol) {
				if (password.bol) {
					if (newPassword.bol) {
						if (passwordKonfermasi.bol) {
							if (captcha.bol) {
								return true;
							} else {
								setSRefTemp(mRefTxtCaptcha);
								return false;
							}
						} else {
							setSRefTemp(mRefTxtPasswordKonfermasi);
							return false;
						}
					} else {
						setSRefTemp(mRefTxtNewPassword);
						return false;
					}
				} else {
					setSRefTemp(mRefTxtPassword);
					return false;
				}
			} else {
				setSRefTemp(mRefTxtUserLogin);
				return false;
			}
		} else {
			setSRefTemp(mRefTxtUserName);
			return false;
		}
	};

	useEffect(() => {
		mRefTxtUserName.current.focus();
		mRefTxtUserName.current.select();
	}, []);

	return (
		<div className="Modal mt-14 pb-14 md:mt-10 md:pb-10 ">
			<div className="flex justify-center pb-28  w-full overflow-y-scroll">
				<div
					className="flex flex-col  mt-4 p-4 
				bg-gradient-to-t from-genkiBlue-cl_01 to-genkiBlue-light 
				rounded-lg border-genkiBlue-dark border-solid border-2 h-h01"
				>
					<div className="flex justify-center items-center">
						<h1>Ubah Akun</h1>
					</div>
					<hr style={{ color: "gray", height: 5 }} />
					<div className="flex flex-1 flex-col justify-between">
						<CompText
							// USER NAME max30
							_title="Nama Akun / User Name"
							_value={props.state.sUser.eUserName}
							_placeholder="Nama akun / user name harus di isi"
							_harusDiIsi={true}
							_maxLength={30}
							_onChange={(xEvent) => {
								setSData((sData) => {
									let iData = { ...sData };
									if (xEvent.length > 2 && xEvent.length < 31) {
										iData.userName.bol = true;
										iData.userName.text = xEvent;
									} else {
										iData.userName.bol = false;
										iData.userName.text = "";
									}
									iData.bolAllDataOk = fBolAllDataOk(iData);
									return iData;
								});
							}}
							_validasi={sData.userName.bol}
							_ref={mRefTxtUserName}
							_onEnter={() => {
								if (sData.userName.bol) {
									mRefTxtUserLogin.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.userName.bol) {
									mRefTxtUserLogin.current.focus();
								}
							}}
						/>

						<CompText
							//  EMAIL ATAU NOHP LOGIN max30
							_title="User Login (Nohp / Email)"
							_placeholder="No Hp / Email harus di isi"
							_value={props.state.sUser.eUserLogin}
							_harusDiIsi={true}
							_ref={mRefTxtUserLogin}
							_validasi={sData.userLogin.bol}
							_maxLength={30}
							_onChange={(xEvent) => {
								if (xEvent.length > 7) {
									// Cek Jika Text Merupakan alamat EMAIl
									let iBolEmail = ValidasiEmail.ValidasiEmail(xEvent);
									if (iBolEmail) {
										setSData((sData) => {
											let iData = { ...sData };
											iData.userLogin.bol = true;
											iData.userLogin.text = xEvent;
											iData.userLogin.type = "email";
											iData.bolAllDataOk = fBolAllDataOk(iData);
											return iData;
										});
									} else {
										//  Cek Jika Text Merupakan  NoHp
										let iBolNoHp = ValidasiEmail.ValidasiNoHp(xEvent);
										if (iBolNoHp) {
											setSData((sData) => {
												let iData = { ...sData };
												iData.userLogin.bol = true;
												iData.userLogin.text = xEvent;
												iData.userLogin.type = "telephone";
												iData.bolAllDataOk = fBolAllDataOk(iData);
												return iData;
											});
										} else {
											setSData((sData) => {
												let iData = { ...sData };
												iData.userLogin.bol = false;
												iData.userLogin.text = "";
												iData.userLogin.type = "";
												iData.bolAllDataOk = fBolAllDataOk(iData);
												return iData;
											});
										}
									}
								} else {
									setSData((sData) => {
										let iData = { ...sData };
										iData.userLogin.bol = false;
										iData.userLogin.text = "";
										iData.userLogin.type = "";
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.userLogin.bol) {
									mRefTxtPassword.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.userLogin.bol) {
									mRefTxtPassword.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtUserName.current.focus();
							}}
						/>

						<CompText
							// PASSWORD max20
							_title="Password"
							_placeholder="Password harus di isi"
							_harusDiIsi={true}
							_type="password"
							_ref={mRefTxtPassword}
							_maxLength={20}
							_onChange={(xEvent) => {
								if (xEvent.length > 5) {
									setSData((sData) => {
										let iData = { ...sData };
										iData.password.text = xEvent;
										iData.password.bol = true;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setSData((sData) => {
										let iData = { ...sData };
										iData.password.text = "";
										iData.password.bol = false;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.password.bol) {
									mRefTxtNewPassword.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.password.bol) {
									mRefTxtNewPassword.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtUserLogin.current.focus();
							}}
							_validasi={sData.password.bol}
						/>

						<CompText
							// NEW PASSWORD max20
							_title="Password Baru"
							_placeholder="Password baru harus di isi"
							_harusDiIsi={true}
							_type="password"
							_ref={mRefTxtNewPassword}
							_maxLength={20}
							_onChange={(xEvent) => {
								if (xEvent.length > 5) {
									setSData((sData) => {
										let iData = { ...sData };
										iData.newPassword.text = xEvent;
										iData.newPassword.bol = true;
										if (xEvent !== iData.passwordKonfermasi.text) {
											iData.passwordKonfermasi.bol = false;
										} else {
											iData.passwordKonfermasi.bol = true;
										}
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setSData((sData) => {
										let iData = { ...sData };
										iData.newPassword.text = "";
										iData.newPassword.bol = false;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.newPassword.bol) {
									mRefTxtPasswordKonfermasi.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.password.bol) {
									mRefTxtPasswordKonfermasi.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtPassword.current.focus();
							}}
							_validasi={sData.newPassword.bol}
						/>

						<CompText
							// PASSWORD KONFERMASI max20
							_maxLength={20}
							_title="Password Baru Konfermasi"
							_placeholder="Password konfermasi Harus di isi"
							_harusDiIsi={true}
							_type="password"
							_ref={mRefTxtPasswordKonfermasi}
							_onChange={(xEvent) => {
								if (xEvent === sData.newPassword.text && xEvent.length > 5) {
									setSData((sData) => {
										let iData = { ...sData };
										iData.passwordKonfermasi.bol = true;
										iData.passwordKonfermasi.text = xEvent;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setSData((sData) => {
										let iData = { ...sData };
										iData.passwordKonfermasi.bol = false;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.passwordKonfermasi.bol) {
									mRefTxtCaptcha.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.passwordKonfermasi.bol) {
									mRefTxtCaptcha.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtNewPassword.current.focus();
							}}
							_validasi={sData.passwordKonfermasi.bol}
						/>

						{/* <CompImage
						// IMAGE ACOUNT
						_image={sData.photo}
						_imageReff={ImgReff}
						_onNewImage={(xData) => {
							setSData((sData) => {
								let iData = { ...sData };
								iData.photo = xData;
								return iData;
							});
							mRefTxtCaptcha.current.focus();
						}}
						_onHapus={() => {
							setSData((sData) => {
								let iData = { ...sData };
								iData.photo = null;
								return iData;
							});
							mRefTxtCaptcha.current.focus();
						}}
						_photoOnly={true}
					/> */}

						<CompText
							// CHAPTCHA max6
							_title="Masukkan Kode: "
							// _captcha={props._captcha}
							_maxLength={6}
							_captcha={true}
							_placeholder="Kode harus di isi"
							_harusDiIsi={true}
							_ref={mRefTxtCaptcha}
							_onChange={(xEvent) => {
								if (xEvent.length === 6) {
									setSData((sData) => {
										let iData = { ...sData };
										iData.captcha.text = xEvent;
										iData.captcha.bol = true;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								} else {
									setSData((sData) => {
										let iData = { ...sData };
										iData.captcha.text = "";
										iData.captcha.bol = false;
										iData.bolAllDataOk = fBolAllDataOk(iData);
										return iData;
									});
								}
							}}
							_onEnter={() => {
								if (sData.captcha.bol) {
									mRefCmdBuatAkun.current.focus();
								}
							}}
							_onKeyArrowDown={() => {
								if (sData.captcha.bol) {
									mRefCmdBuatAkun.current.focus();
								}
							}}
							_onKeyArrowUp={() => {
								mRefTxtPasswordKonfermasi.current.focus();
							}}
							_onNewToken={(xToken) => {
								setStoken(xToken);
							}}
							_validasi={sData.captcha.bol}
						/>

						<div className="flex justify-evenly w-full mt-2">
							<CompButton
								className="Button h-7 w-20"
								_text="Update Akun"
								_imageType={
									sData.bolAllDataOk ? Env.buttonImageType.ok : Env.buttonImageType.stop
								}
								_style={{
									width: "140px",
									marginTop: "2px",
								}}
								_ref={mRefCmdBuatAkun}
								_onClick={() => {
									if (!fBolAllDataOk(sData)) {
										setSAlert((sState) => {
											let isState = { ...sState };
											isState.display = true;
											isState.title = "Pembuatan Account Gagal";
											isState.keterangan = "Data Belum Lengkap / Format Tidak Sesuai";
											return isState;
										});
									} else {
										setSLoading(true);
										const iData = {
											user_name: sData.userName.text,
											user_login: props.state.sUser.eUserLogin,
											newUser_login: sData.userLogin.text,
											user_login_type: sData.userLogin.type,
											password: sData.password.text,
											newPassword: sData.newPassword.text,
											photo: sData.photo,
										};
										// ============ UPDATE ACCOUNT ================================
										Axios.post(
											`${props.state.sPages.url}${Env.eRouteApi}/updateAccount`,
											iData,
											{
												headers: {
													authorization: `bearer ${sToken}`,
													captchacode: sData.captcha.text,
												},
											}
										)
											.then((xRes) => {
												console.log(xRes.data);
												console.log(xRes.status);
												console.log("===============");
												// 		// ========= RESPON DATA ================================
												// if (xRes.status === Env.eHttpStatus.eCreate) {
												// 	setSLoading(false);
												// 	let { token, user } = xRes.data.message;
												// 	props.dispatch({ type: "token", data: token });
												// 	props.dispatch({ type: "user", data: user });
												// 	props.dispatch({
												// 		type: "pages",
												// 		data: props._pages,
												// 	});
												// 	props._close();
												// } else if (xRes.status === Env.eHttpStatus.eGagal) {
												// 	// console.log(xRes.data.title);
												// 	if (xRes.data.title === "User Login") {
												// 		setSRefTemp(mRefTxtUserLogin);
												// 	} else if (xRes.data.title === "Invalid Captcha") {
												// 		setSRefTemp(mRefTxtCaptcha);
												// 	} else {
												// 		setSRefTemp(mRefTxtUserName);
												// 	}
												// 	// alert(`Gagal ${xRes.data.message}`);
												// 	setSLoading(false);
												// 	setSAlert((sState) => {
												// 		let isState = { ...sState };
												// 		isState.display = true;
												// 		isState.title = "Pembuatan Account Gagal";
												// 		isState.keterangan = xRes.data.message;
												// 		return isState;
												// 	});
												// } else {
												// 	setSLoading(false);
												// 	setSAlert((sState) => {
												// 		let isState = { ...sState };
												// 		isState.display = true;
												// 		isState.title = "Pembuatan Account Gagal";
												// 		isState.keterangan = "Account Gagal Dibuat";
												// 		return isState;
												// 	});
												// }
												// END====== RESPON DATA ================================
												if (xRes.status === Env.eHttpStatus.eGagal) {
													setSLoading(false);
													setSAlert((sState) => {
														let isState = { ...sState };
														isState.display = true;
														isState.title = xRes.data.title;
														isState.keterangan =
															xRes.data.message.keterangan || xRes.data.message;
														return isState;
													});
													let iRef = xRes.data.message.code;
													switch (iRef) {
														case 1:
															setSRefTemp(mRefTxtUserLogin);
															break;
														case 2:
															setSRefTemp(mRefTxtPassword);
															break;

														default:
															if (xRes.data.title === "Invalid Captcha") {
																setSRefTemp(mRefTxtCaptcha);
															} else {
																setSRefTemp(mRefTxtUserName);
															}

															break;
													}
												} else if (xRes.status === Env.eHttpStatus.eOk) {
													setSLoading(false);
													let { token, user } = xRes.data.message;
													props.dispatch({ type: "token", data: token });
													props.dispatch({ type: "user", data: user });
													props._onBatal(); //Tutup Componen // bukan gagal
												}
											})
											.catch((xErr) => {
												alert(xErr);
												setSLoading(false);
												props._close();
											});
										// END========= CREATE ACCOUNT ================================
									}
								}}
								_onKeyArrowUp={() => {
									mRefTxtCaptcha.current.focus();
								}}
							/>
							<CompButton
								className="Button h-7 w-20"
								_text="Batal"
								_style={{ width: "100px " }}
								_onClick={() => {
									props._onBatal();
								}}
							/>
						</div>

						{sAlert.display ? (
							<CompAlert
								_title={sAlert.title}
								_keterangan={sAlert.keterangan}
								_onOk={() => {
									setSAlert((sState) => {
										let isState = { ...sState };
										isState.display = false;

										return isState;
									});
									if (sRefTemp.current) {
										sRefTemp.current.focus();
										sRefTemp.current.select();
									} else {
										mRefTxtUserName.current.focus();
										mRefTxtUserName.current.select();
									}
								}}
							/>
						) : (
							""
						)}

						{sLoading ? <CompLoading /> : ""}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(LoginRegistrasiAcccount);
