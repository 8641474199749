import { useState, useRef, useEffect } from "react";
import Axios from "axios";
import CompPrint from "react-to-print";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";

// import CompTextDs from "../../../../../component/arjText-DataSource";
import CompLoading from "../../../../component/arjLoading";

// import CompAlertKegiatan from "../../../../../component/arjAlert";

const BayarRekapKegiatan = (props) => {
	let mGroupId = 0;
	let mBg = false;
	const fClassName = () => {
		mBg = !mBg;
		if (mBg) {
			return "flex h-full w-full bg-blue-200";
		} else {
			return "flex h-full w-full bg-blue-100";
		}
	};
	const [sLoading, setSLoading] = useState(true);
	const [sRsDataBayar, setSRsDataBayar] = useState([]);

	const [sKegiatan, setSKegiatan] = useState({
		show: false,
		kegiatan: null,
	});

	const fSetSKegiatan = (xKegiatan) => {
		let iKegiatanTerbayar = 0;
		xKegiatan.kegiatan_bayar_detail.forEach((xElement) => {
			iKegiatanTerbayar += xElement.nilai;
		});
		let iNilaiKegiatan = xKegiatan.nilai + iKegiatanTerbayar;
		xKegiatan.nilaiAwal = iNilaiKegiatan;
		// console.log(xKegiatan);
		setSKegiatan({
			show: true,
			kegiatan: xKegiatan,
		});
	};

	const refIsiRekap = useRef(null);

	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
					{
						params: { req: "all" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);

				if (iData.status === Env.eHttpStatus.eOk) {
					const iRsDataMembers = iData.data.message.rekap_kegiatan_detail;
					// console.log(iRsDataMembers);
					if (iRsDataMembers) {
						const iRsOlahDataBayar = [];
						for (let i = 0; i < iRsDataMembers.length; i++) {
							const iDatas = iRsDataMembers[i];
							const iMembers_group = iDatas.members_group;
							const iObj = {};
							const iKegiatan_bayar = iDatas.kegiatan_bayar;
							let iJumlah = 0;
							let iTotal = 0;
							for (let ii = 0; ii < iKegiatan_bayar.length; ii++) {
								let iDataBayar = iKegiatan_bayar[ii];
								iDataBayar.status = true;
								let iArr = [];
								if (iObj[iDataBayar.kegiatan_jenis.nama]) {
									iArr = [...iObj[iDataBayar.kegiatan_jenis.nama].detail, iDataBayar];
									iJumlah += parseInt(iDataBayar.nilai);
									iTotal += parseInt(iDataBayar.nilai);
									iObj[iDataBayar.kegiatan_jenis.nama] = {
										detail: iArr,
										jumlah: iJumlah,
									};
								} else {
									iJumlah = parseInt(iDataBayar.nilai);
									iTotal += parseInt(iDataBayar.nilai);
									iObj[iDataBayar.kegiatan_jenis.nama] = {
										detail: [iDataBayar],
										jumlah: iJumlah,
									};
								}
							}

							iRsOlahDataBayar.push({
								tabungan: {
									id: iDatas.tabungan[0].id,
									saldo: iDatas.tabungan[0].saldo,
									gunakan: 0,
								},
								name: iDatas.name,
								total: iTotal,
								tempTotal: iTotal,
								bayar: false,
								ulang: {},
								members_group: iMembers_group,
								bayar_group: iObj,
							});
						}
						setSRsDataBayar(iRsOlahDataBayar);
						// console.log("*******************");
						// console.log(iRsOlahDataBayar);
					}
				}

				setSLoading(false);
			};
			fGetData();
		} catch (xErr) {}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		sRsDataBayar.map((xData, xIndex) => {
			if (xData.total > xData.tempTotal) {
				xData.total = xData.tempTotal;
				setSRsDataBayar((xState) => {
					let iStete = [...xState];
					iStete[xIndex].total = xData.tempTotal;
					return iStete;
				});
			}
			return null;
		});
		// console.log("^^^^^^^^^^^^^^^^");
		// console.log(sRsDataBayar);
	}, [sRsDataBayar]);

	return (
		<div className="flex flex-col w-full h-screen">
			{sLoading ? <CompLoading /> : null}

			{/* === NILAI KEGIATAN =========== */}
			{sKegiatan.show ? (
				<div className="Modal z-100">
					<div className="flex overflow-y-auto h-full items-center pt-12 pb-4">
						<div
							className="flex flex-col max-w-sm  bg-genkiGray-lightest mt-10
						rounded-xl border-genkiBlue-dark border-solid border-2"
						>
							<div
								// ==== TITLE ====================
								className="flex justify-center items-center px-2 text-xl
							rounded-t-xl h-10 bg-gradient-to-t from-genkiBlue-light to-genkiBlue border-1 border-solid border-genkiBlue-dark"
							>
								{`Kegiatan Tanggal: ${Env.efFormatTanggal2(
									sKegiatan.kegiatan.kegiatan.id.substring(0, 8)
								)}`}
							</div>

							<div
								// ======= KEGIATAN =======================
								className="flex  items-center justify-center w-full text-center text-lg
							 p-2 border-1 border-solid border-genkiBlue-dark"
							>
								{sKegiatan.kegiatan.kegiatan.kegiatan}
							</div>

							{/* =====KETARANGAN======= */}
							{sKegiatan.kegiatan.kegiatan.keterangan ? (
								<div className="flex  w-full  p-2 border-1 border-solid border-genkiBlue-dark">
									{`Keterangan: ${sKegiatan.kegiatan.kegiatan.keterangan}`}
								</div>
							) : null}

							{/* ====== NILAI ======== */}
							<div className="flex  w-full  p-2 border-1 border-solid border-genkiBlue-dark text-xl">
								<div className="flex-1 text-xl">Nilai :</div>
								<div className="text-xl">
									{`${Env.format.uang(sKegiatan.kegiatan.nilaiAwal)}`}
								</div>
							</div>

							{/* ====== DATA BAYAR ======== */}
							{sKegiatan.kegiatan.nilaiAwal !== sKegiatan.kegiatan.nilai ? (
								<div>
									<table className="Table">
										<thead>
											<tr>
												<th>No</th>
												<th>Tanggal</th>
												<th>Bayar</th>
											</tr>
										</thead>
										<tbody>
											{sKegiatan.kegiatan.kegiatan_bayar_detail.map((xData, xIndex) => {
												const iTanggal = Env.efFormatTanggal2(xData.id.substring(0, 8));
												const iNilai = Env.format.uang(xData.nilai);
												return (
													<tr key={xIndex}>
														<td width={30}>{xIndex + 1}</td>
														<td>{iTanggal}</td>
														<td align="right">{iNilai}</td>
													</tr>
												);
											})}
											<tr>
												<td width={30}></td>
												<td align="right">Jumlah Terbayar</td>
												<td align="right">
													{Env.format.uang(
														sKegiatan.kegiatan.nilaiAwal - sKegiatan.kegiatan.nilai
													)}
												</td>
											</tr>
											<tr>
												<td align="center" width={30}>
													*
												</td>
												<td align="right">Sisa Tunggakan</td>
												<td align="right" className=" text-lg font-bold bg-red-300">
													{Env.format.uang(sKegiatan.kegiatan.nilai)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							) : null}

							<div className="flex items-center justify-evenly h-10 w-full border-1 border-solid border-genkiBlue-dark rounded-b-xl">
								<button
									className="Button h-8 w-16 "
									onClick={() => {
										setSKegiatan({ show: false, kegiatan: null });
									}}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{/* TITLE */}
			<div className="flex mx-1 p-1">
				<div
					className="flex flex-1 p-2 mt-1 max-w-sm md:max-w-xl h-8
							mx-auto bg-white rounded-xl shadow-md justify-center items-center"
				>
					<p>Data Kewajiban Anggota</p>
				</div>

				<div className="flex justify-evenly items-center  w-40">
					<CompPrint
						trigger={() => {
							return <div className="Button h-6">Cetak</div>;
						}}
						content={() => refIsiRekap.current}
					/>
				</div>
			</div>

			<div
				className="flex-1 md:p-2 overflow-y-auto mt-2 bg-blue-300"
				ref={refIsiRekap}
			>
				{sRsDataBayar.map((xDataBayar, xIndex) => {
					const iDataBayarGroup = xDataBayar.bayar_group;
					const iRsDataBayarJenis = Object.keys(iDataBayarGroup);
					// let iTotalBayar = 0;
					if (mGroupId !== xDataBayar.members_group.id) {
						mGroupId = xDataBayar.members_group.id;
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className="flex justify-center w-full border-gray-900 border-solid border-1 text-xl bg-red-500 text-white">
									{xDataBayar.members_group.nama}
								</div>
								<div className={fClassName()}>
									{/* Nama Anggota Penunggak */}
									<div className="flex items-center w-44 md:w-72  border-gray-900 border-solid border-1">
										{xDataBayar.name}
									</div>

									{/* Detail Tunggakan */}
									<div className="flex-1 border-gray-900 border-solid border-1">
										{iRsDataBayarJenis.map((xKey, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? "flex items-center "
															: "flex items-center border-gray-900 border-solid border-t-2 "
													}
												>
													{/* Jenis Kegiatan */}
													<div className=" flex-1 md:pl-1">{xKey}</div>

													{/* Nilai Kegiatan */}
													<div className="flex flex-col border-gray-900 border-solid border-l-2 ">
														{iDataBayarGroup[xKey].detail.map((xBayar, xxxIndex) => {
															return (
																<div
																	key={xxxIndex}
																	className={
																		xxxIndex === 0
																			? "flex items-center border-gray-900 border-solid  border-r-2 "
																			: "flex items-center border-gray-900 border-solid border-t-2 border-r-2 "
																	}
																>
																	<button
																		className="Button h-6 flex-1"
																		onClick={async () => {
																			if (!sLoading) {
																				const iData = await Axios.get(
																					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
																					{
																						params: {
																							req: "kegiatan",
																							// id: xBayar.kegiatan.id,
																							id: xBayar.id,
																						},
																						headers: {
																							authorization: `bearer ${props.state.sToken}`,
																						},
																					}
																				);

																				fSetSKegiatan(iData.data.message[0]);
																			}
																		}}
																	>
																		{Env.format.uang(xBayar.nilai)}
																	</button>
																</div>
															);
														})}
													</div>

													{/* Jumlah Nilai Kegiatan */}
													<div className="flex w-20 md:w-32 justify-end md:pr-1">
														{Env.format.uang(iDataBayarGroup[xKey].jumlah)}
													</div>
												</div>
											);
										})}

										{/* Bayar Tunggakan */}
										<div
											className={
												xDataBayar.bayar
													? `flex-1 flex w-full border-gray-900 border-solid border-t-1 `
													: `flex-1 flex w-full border-gray-900 border-solid border-t-1 bg-white`
											}
										>
											<div className=" flex-1"></div>

											<div className="flex w-52 justify-end md:pr-0.5 ">
												<div className="flex flex-1  items-center mr-0.5 md:mr-1">
													Jumlah :
												</div>
												<div className="Text h-7 w-32 flex items-center text-lg justify-end ">
													{Env.format.uang(xDataBayar.total)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					} else {
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className={fClassName()}>
									{/* Nama Anggota Penunggak */}
									<div className="flex items-center w-44 md:w-72  border-gray-900 border-solid border-1">
										{xDataBayar.name}
									</div>

									{/* Detail Tunggakan */}
									<div className="flex-1 border-gray-900 border-solid border-1">
										{iRsDataBayarJenis.map((xKey, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? "flex items-center "
															: "flex items-center border-gray-900 border-solid border-t-2 "
													}
												>
													{/* Jenis Kegiatan */}
													<div className=" flex-1 md:pl-1">{xKey}</div>

													{/* Nilai Kegiatan */}
													<div className="flex flex-col border-gray-900 border-solid border-l-2 ">
														{iDataBayarGroup[xKey].detail.map((xBayar, xxxIndex) => {
															return (
																<div
																	key={xxxIndex}
																	className={
																		xxxIndex === 0
																			? "flex items-center border-gray-900 border-solid  border-r-2 "
																			: "flex items-center border-gray-900 border-solid border-t-2 border-r-2 "
																	}
																>
																	<button
																		className="Button h-6 flex-1"
																		onClick={async () => {
																			if (!sLoading) {
																				const iData = await Axios.get(
																					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
																					{
																						params: { req: "kegiatan", id: xBayar.id },
																						headers: {
																							authorization: `bearer ${props.state.sToken}`,
																						},
																					}
																				);
																				// alert(iData.data.message);
																				fSetSKegiatan(iData.data.message[0]);
																			}
																		}}
																	>
																		{Env.format.uang(xBayar.nilai)}
																	</button>
																</div>
															);
														})}
													</div>

													{/* Jumlah Nilai Kegiatan */}
													<div className="flex w-20 md:w-32 justify-end md:pr-1">
														{Env.format.uang(iDataBayarGroup[xKey].jumlah)}
													</div>
												</div>
											);
										})}

										{/* Bayar Tunggakan */}
										<div className="flex-1 flex w-full border-gray-900 border-solid border-t-1 bg-white">
											<div className="flex-1"></div>
											<div className="flex w-52 justify-end md:pr-0.5 ">
												<div className="flex flex-1 items-center mr-0.5 md:mr-1">
													Jumlah :
												</div>
												<div className="Text h-7 w-32 flex items-center text-lg justify-end ">
													{Env.format.uang(xDataBayar.total)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						);
					}
				})}
			</div>

			<div className=" h-14 "></div>
		</div>
	);
};

export default GlobalStateConsumer(BayarRekapKegiatan);
