import React from "react";

// import * as FileSaver from "file-saver";

import * as XLSX from "xlsx";

const Exel = (props) => {
	const fExportToCSV = () => {
		const iColoum = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"];

		const fFormatRow = () => {
			const iDataRow = props._data;
			let iFormatRow = {};
			let iMarger = [];
			let iColLength = 0;

			//row (Atas Bawah)
			for (let iR = 0; iR < iDataRow.length; iR++) {
				let iCol = iDataRow[iR].col;
				let iColMarger = 0;
				for (let iC = 0; iC < iCol.length; iC++) {
					//Mengisi Cell kiri kanan
					iFormatRow = {
						...iFormatRow,
						[`${iColoum[iColMarger]}${iDataRow[iR].row}`]: {
							t: "s",
							v: iCol[iC].value,
						},
					};

					// Nge marger Cell
					if (iCol[iC].colMarger > 1) {
						const iColAwal = iColMarger;
						iColMarger += iCol[iC].colMarger - 1;
						iMarger.push({
							s: { r: iDataRow[iR].row - 1, c: iColAwal },
							e: { r: iDataRow[iR].row - 1, c: iColMarger },
						});
					}
					iColMarger += 1;
					if (iColLength < iColMarger) {
						iColLength = iColMarger - 1;
					}
				}
			}
			const iRef = {
				"!ref": `A1:${iColoum[iColLength]}${iDataRow[iDataRow.length - 1].row}`,
			};

			let iResult = {
				...iRef,
				...iFormatRow,
				"!merges": iMarger,
			};
			return iResult;
		};
		// console.log(fFormatRow());

		XLSX.writeFile(
			{
				SheetNames: ["Sheet1"],
				Sheets: {
					Sheet1: fFormatRow(),
					// {
					// 	"!ref": `A${0}:${"F"}${10}`,
					// 	A1: { t: "s", v: "BUKU BESAR" },
					// 	C1: { t: "s", v: "AKUN" },
					// 	F1: { t: "s", v: "LPR:0000001" },

					// 	A2: { t: "s", v: "REKAP SALDO" },
					// 	"!merges": [
					// 		{ s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },
					// 		{ s: { r: 0, c: 2 }, e: { r: 0, c: 4 } },
					// 	],
					// },
				},
			},
			` ${props._fileName || "data"}.xlsx`
		);
	};

	return (
		<div
			className="flex justify-center items-center rounded-xl pr-1 pl-1
            bg-blue-300  border-genkiGray-light cursor-pointer hover:bg-blue-100"
			onClick={() => {
				if (props._data.length > 0) {
					fExportToCSV();
				}
			}}
		>
			Exel
		</div>
	);
};

export default Exel;
