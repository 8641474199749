import React, { Fragment, useState, useEffect } from "react";
import Env from "../../setting/env";

const MenuBarger = (props) => {
	const [sBurger, setBurger] = useState({});
	useEffect(() => {
		if (props._setStatus) {
			setBurger((xBurger) => {
				let iBurger = { ...xBurger };
				iBurger.eStatus = true;
				iBurger.eSpanRotate = "45deg";
				iBurger.eSpanAtasTranslate = "1px,-5px";
				iBurger.eSpanBawahTranslate = "3px,4px";
				iBurger.eSpanTengahOpacity = "0";
				return iBurger;
			});
		} else {
			setBurger((xBurger) => {
				let iBurger = { ...xBurger };
				iBurger.eStatus = false;
				iBurger.eSpanRotate = "0";
				iBurger.eSpanAtasTranslate = "0,0";
				iBurger.eSpanBawahTranslate = "0,0";
				iBurger.eSpanTengahOpacity = "1";
				return iBurger;
			});
		}
	}, [props._setStatus]);

	return (
		<Fragment>
			<style jsx="true">
				{`
					.hambargerMenuComponent${props._name} {
						height: 26px;
						width: 26px;
						margin: 3px;
						padding: 3px;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						border: 2px solid ${Env.color.orangeRed04};
						border-radius: 5px;
						display: flex;
					}

					.hambargerMenuComponent${props._name}:hover {
						cursor: pointer;
						background-image: linear-gradient(
							${Env.color.orangeRed03},
							${Env.color.orangeRed04}
						);
					}

					.hambargerMenuComponent${props._name} span {
						width: 20px;
						height: 3px;
						background-color: ${Env.color.colorAbuAbu03};
						border-radius: 1px;
						transition: all 0.5s;
					}

					.hambargerMenuComponent${props._name} span:nth-child(1) {
						transform-origin: 0 0;
						transform: rotate(${sBurger.eSpanRotate})
							translate(${sBurger.eSpanAtasTranslate});
					}
					.hambargerMenuComponent${props._name} span:nth-child(3) {
						transform-origin: 0 100%;
						transform: rotate(-${sBurger.eSpanRotate})
							translate(${sBurger.eSpanBawahTranslate});
					}

					.hambargerMenuComponent${props._name} span:nth-child(2) {
						margin: 2px 0;
						opacity: ${sBurger.eSpanTengahOpacity};
					}
				`}
			</style>

			{/* HAMBARGER MENU */}
			<div
				className={`hambargerMenuComponent${props._name}`}
				onClick={() => {
					let iStatus = sBurger.eStatus;
					if (props._status) {
						props._status(!iStatus);
					}
					setBurger((xBurger) => {
						let iBurger = { ...xBurger };
						if (iStatus) {
							iBurger.eStatus = false;
							iBurger.eSpanRotate = "0";
							iBurger.eSpanAtasTranslate = "0,0";
							iBurger.eSpanBawahTranslate = "0,0";
							iBurger.eSpanTengahOpacity = "1";
						} else {
							iBurger.eStatus = true;
							iBurger.eSpanRotate = "45deg";
							iBurger.eSpanAtasTranslate = "1px,-5px";
							iBurger.eSpanBawahTranslate = "3px,4px";
							iBurger.eSpanTengahOpacity = "0";
						}
						return iBurger;
					});
				}}
			>
				<span></span>
				<span></span>
				<span></span>
			</div>
		</Fragment>
	);
};

export default MenuBarger;
