import React from "react";
// import ClipLoader from "react-spinners/ClipLoader";
import RingLoader from "react-spinners/RingLoader";
// import Env from "../../src/env.js";

const Index = (props) => {
	return (
		<div className="Modal z-101">
			{/* <div className="flex justify-center items-center bg-genkiBlue-cl_01 rounded-xl p-3 m-auto text-center "> */}
			{/* SEDANG LOADING MOHON TUNGGU */}

			{/* </div> */}
			<div className="m-auto">
				{/* <ClipLoader color="#ffffff" loading={true} size={40} loader="BarLoader" /> */}
				<RingLoader color="#ffffff" size={50} />
			</div>
		</div>
	);
};
export default Index;
