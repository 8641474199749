import { useEffect, useState, useRef } from "react";
import Axios from "axios";
import CompPrint from "react-to-print";

import { GlobalStateConsumer } from "../../../state.global";
import Env from "../../../setting/env";

import CompBukuBesar from "./ekuitasBukuBesar";
import CompExel from "../../../component/exportToExel";

const Ekuitas = (props) => {
	const [sRsView, setSRsView] = useState([]);
	const [sExel, setSExel] = useState([]);
	const refToPrint = useRef();

	const [sRsAkunPenerimaan, setSRsAkunPenerimaan] = useState([]);
	const [sRsAkunPengeluaran, setSRsAkunPengeluaran] = useState([]);
	const [sBukuBesar, setSBukuBesar] = useState(null);

	const fCol = (xValue, xColMarger = 1) => {
		return { value: xValue, colMarger: xColMarger };
	};

	const fTab = (xValue) => {
		let iTab = "";
		for (let i = 0; i < xValue; i++) {
			iTab += "  ";
		}
		return iTab;
	};

	const fCekNilaiAkunInduk = (xRsAkunAnak, xNo_AkunInduk) => {
		const iRegEx = new RegExp(`^${xNo_AkunInduk}`);
		for (let i = 0; i < xRsAkunAnak.length; i++) {
			if (iRegEx.test(xRsAkunAnak[i].no_akun)) {
				return true;
			}
		}
	};

	const fCekIndexSubAkun = (xRsAkun, xNoAkun, xIndexMulai) => {
		const iRegEx = new RegExp(`^${xNoAkun}`);
		for (let i = xIndexMulai; i < xRsAkun.length; i++) {
			if (iRegEx.test(xRsAkun[i].no_akun) === false) {
				return i;
			}
		}
		return xRsAkun.length;
	};

	const fClearSubAkun = (xRsAkun, xNoAkun, xIndexMulai) => {
		const iRegEx = new RegExp(`^${xNoAkun}`);

		for (let i = xIndexMulai; i < xRsAkun.length; i++) {
			// console.log(xRsAkun[i].no_akun);
			if (iRegEx.test(xRsAkun[i].no_akun) === true) {
				// console.log(xRsAkun[i].no_akun);
				xRsAkun.splice(i, 1);
				i -= 1;
			}
		}
	};

	const RestrukturisasiAkunEkuitas = (xRsAkun, xRsAliranAkun) => {
		let iRsAkun = [];
		for (let i = 0; i < xRsAkun.length; i++) {
			let iData = xRsAkun[i];
			iRsAkun.push({ ...iData });
		}

		for (let i = 0; i < iRsAkun.length; i++) {
			// iLength = i;
			const iAkun = iRsAkun[i].type;

			if (iAkun === "Induk") {
				//======-- Induk
				// ===============Cek Apakah Induk Akun Mempunyai Nilai=============
				if (fCekNilaiAkunInduk(xRsAliranAkun, iRsAkun[i].no_akun)) {
					iRsAkun[i].type = "SubInduk";
					let iIndex = fCekIndexSubAkun(iRsAkun, iRsAkun[i].no_akun, i);
					iRsAkun.splice(iIndex, 0, {
						no_akun: iRsAkun[i].no_akun,
						nama: iRsAkun[i].nama,
						type: "NilaiSubInduk",
						tab_view: iRsAkun[i].tab_view,
					});
				} else {
					fClearSubAkun(iRsAkun, iRsAkun[i].no_akun, i);
					i -= 1;
				}
				// END ==========Cek Apakah Induk Akun Mempunyai Nilai==============
			}
		}
		return iRsAkun;
	};

	const fNilaiAkun = (xRsAkunAnak, xNo_Akun) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		let iAkun = false;
		let iJumlah = 0;
		for (let i = 0; i < xRsAkunAnak.length; i++) {
			if (iRegEx.test(xRsAkunAnak[i].no_akun)) {
				iJumlah += parseFloat(xRsAkunAnak[i].jumlah);
				iAkun = true;
			}
		}

		if (iAkun) {
			return Env.format.uang(iJumlah);
		} else {
			return null;
		}
	};

	const fAddRsView = (xRs, xRsAkun, xRsAkunIdenty, xJenis) => {
		for (let i = 0; i < xRsAkun.length; i++) {
			if (xRsAkun[i].type === "SubInduk") {
				xRs.push({
					akun: `${xRsAkun[i].no_akun}. ${xRsAkun[i].nama}`,
					jumlah: "",
					tab_view: xRsAkun[i].tab_view + 1,
					bukuBesar: "",
				});
			} else {
				const iJumlah = fNilaiAkun(xRsAkunIdenty, xRsAkun[i].no_akun);

				if (iJumlah) {
					if (xRsAkun[i].type === "Anak") {
						xRs.push({
							akun: `${xRsAkun[i].no_akun}. ${xRsAkun[i].nama}`,
							jumlah: iJumlah,
							tab_view: xRsAkun[i].tab_view + 1,
							bukuBesar: xRsAkun[i].no_akun,
							jenis: xJenis,
						});
					} else {
						xRs.push({
							akun: `Jumlah ${xRsAkun[i].nama}`,
							jumlah: iJumlah,
							tab_view: xRsAkun[i].tab_view + 1,
							bukuBesar: "",
						});
					}
				}
			}
		}
	};

	const fBukuBesar = (xAkun, xAkunBukuBesar, xJenis) => {
		if (xAkunBukuBesar !== "") {
			Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/laporan`, {
				params: {
					req: "bukuBesarEkuitas",
					no_akun: xAkunBukuBesar,
					jenis: xJenis,
					tanggal: props._tanggal,
				},
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}).then((xData) => {
				// console.log("================================");
				// console.log(xData.data);
				if (xData.data.title === "error") {
					alert(xData.data.message);
				} else {
					setSBukuBesar({ akun: xAkun, jenis: xJenis, data: xData.data.message });
				}
			});
			// alert(props._tanggal);
		}
	};

	useEffect(() => {
		setSRsAkunPenerimaan(() => {
			return RestrukturisasiAkunEkuitas(
				props._data.rsAkunPenerimaanPengeluaran,
				props._data.rsPenerimaan
			);
		});

		setSRsAkunPengeluaran(() => {
			return RestrukturisasiAkunEkuitas(
				props._data.rsAkunPenerimaanPengeluaran,
				props._data.rsPengeluaran
			);
		});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// if (sRsAkunPenerimaan.length > 0 || sRsAkunPengeluaran.length > 0) {

		setSRsView(() => {
			const iRs = [];
			// Penerimaan
			iRs.push({ akun: "PENERIMAAN", jumlah: "", tab_view: 0, bukuBesar: "" });
			fAddRsView(iRs, sRsAkunPenerimaan, props._data.rsPenerimaan, "Penerimaan");
			iRs.push({
				akun: "JUMLAH PENERIMAAN",
				jumlah: Env.format.uang(props._data.totalPenerimaan),
				tab_view: 0,
				bukuBesar: "",
			});
			iRs.push({ akun: "-", jumlah: "", tab_view: 0, bukuBesar: "" });

			// Pengeluaran
			iRs.push({ akun: "PENGELUARAN", jumlah: "", tab_view: 0, bukuBesar: "" });
			fAddRsView(
				iRs,
				sRsAkunPengeluaran,
				props._data.rsPengeluaran,
				"Pengeluaran"
			);
			iRs.push({
				akun: "JUMLAH PENGELUARAN",
				jumlah: Env.format.uang(props._data.totalPengeluaran),
				tab_view: 0,
				bukuBesar: "",
			});
			iRs.push({ akun: "-", jumlah: "", tab_view: 0, bukuBesar: "" });

			// Surplus / Defisit
			iRs.push({
				akun: "Surplus / Defisit Sekarang",
				jumlah: Env.format.uang(props._data.saldoSekarang),
				tab_view: 0,
				bukuBesar: "",
			});
			iRs.push({
				akun: "Surplus / Defisit Laporan Sebelumnya",
				jumlah: Env.format.uang(props._data.saldoSebelumnya),
				tab_view: 0,
				bukuBesar: "",
			});
			iRs.push({ akun: "-", jumlah: "", tab_view: 0, bukuBesar: "" });
			iRs.push({
				akun: props._keteranganAkun,
				jumlah: Env.format.uang(props._data.totalSaldoEkuitas),
				tab_view: 0,
				bukuBesar: "",
			});

			// console.log("--------------------------------------");
			// console.log(iRs);
			return iRs;
		});
		// }
		// eslint-disable-next-line
	}, [sRsAkunPengeluaran]);

	useEffect(() => {
		if (sRsView.length > 0) {
			let iData = [
				{
					row: 1,
					col: [
						fCol("EKUITAS"),
						fCol(props._keteranganAkun),
						fCol(`Lpr: ${Env.efFormatTanggal(props._tanggal)}`),
					],
				},
				{
					row: 3,
					col: [fCol("Akun", 2), fCol("Jumlah")],
				},
			];
			let iRow = 3;
			for (let i = 0; i < sRsView.length; i++) {
				iRow += 1;
				iData.push({
					row: iRow,
					col: [
						fCol(`${fTab(sRsView[i].tab_view)}${sRsView[i].akun}`, 2),
						fCol(`${fTab(sRsView[i].tab_view)}${sRsView[i].jumlah}`),
					],
				});
			}

			setSExel(iData);
		}
		// eslint-disable-next-line
	}, [sRsView]);

	return (
		<div className="w-full justify-center bg-gray-400">
			{sBukuBesar ? (
				<CompBukuBesar
					_data={sBukuBesar}
					_tanggal={props._tanggal}
					_onClose={() => {
						setSBukuBesar(null);
					}}
				/>
			) : (
				""
			)}

			<div className="relative flex flex-col h-screen w-full">
				<div
					className="flex absolute right-1   justify-center
                             items-center w-32 h-9 space-x-1"
				>
					<CompExel
						_data={sExel}
						_fileName={`${props._tanggal}_${props._keteranganAkun}`}
					/>

					<CompPrint
						trigger={() => {
							return <div className="Button h-6">Cetak</div>;
						}}
						content={() => refToPrint.current}
					/>
					<div
						className="flex justify-center items-center
                                bg-green-300 rounded-xl w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100"
						onClick={() => {
							props._onClose();
						}}
					>
						X
					</div>
				</div>
				<div className="flex-1 overflow-y-scroll" ref={refToPrint}>
					<div className="flex w-full h-8 bg-yellow-200 items-center mt-8 md:mt-0">
						<div className="flex items-center ml-2">EKUITAS</div>
						<div className="flex-1 flex justify-center items-center truncate mx-2">
							{props._keteranganAkun}
						</div>
						<div className="flex justify-center mr-1">{`Lpr: ${Env.efFormatTanggal(
							props._tanggal
						)}`}</div>
						<div className="flex md:w-32"></div>
					</div>
					<div className="flex w-full justify-center md:p-1">
						<table className="Table">
							<thead>
								<tr>
									<th>Akun </th>
									<th>Jumlah</th>
								</tr>
							</thead>
							<tbody>
								{sRsView.map((xData, xIndex) => {
									let iStyle = "text-gray-800";
									if (xData.bukuBesar !== "") {
										iStyle = "underline text-blue-900 cursor-pointer";
									}
									return (
										<tr key={xIndex}>
											<td
												className={iStyle}
												style={{
													paddingLeft: `${20 * xData.tab_view}px`,
												}}
												onClick={() => {
													fBukuBesar(xData.akun, xData.bukuBesar, xData.jenis);
												}}
											>
												{xData.akun}
											</td>
											<td
												style={{
													paddingLeft: `${20 * xData.tab_view}px`,
												}}
											>
												{xData.jumlah}
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
				<div className="h-12"></div>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(Ekuitas);
