import { Fragment, useEffect } from "react";
import { GlobalStateConsumer } from "../../../state.global";

const Home = (props) => {
	useEffect(() => {
		if (props._onActive) {
			props._onActive();
		}
		// eslint-disable-next-line
	}, []);
	return (
		<Fragment>
			<div
				className="flex flex-col p-6 
				max-w-sm 
				md:max-w-xl 
				mx-auto 
				bg-white rounded-xl 
				shadow-md 
				items-center 
				space-x-4 "
			>
				<h1>Wellcome To</h1>
				<h1>{props.state.sPages.nama}</h1>
			</div>
		</Fragment>
	);
};

export default GlobalStateConsumer(Home);
