import { useRef, useEffect, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import Env from "../../../setting/env";
import CompText from "../../../component/arjText";
import CompLoading from "../../../component/arjLoading";
import CompAlert from "../../../component/arjAlert";
import CompAlertGagal from "../../../component/arjAlert";
import CompAlertDelete from "../../../component/arjAlert";

const Kelompok = (props) => {
	const [sData, setSData] = useState([]);
	const [sNewData, setSNewData] = useState({ no: 1, nama: "" });
	const [sDeleteData, setSDeleteData] = useState(0);
	const [sLoading, setSLoading] = useState(true);
	const [sAlert, setSAlert] = useState(false);
	const [sAlertGagal, setsAlertGagal] = useState(false);

	const refNo = useRef(null);
	const refNama = useRef(null);

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/members_group`,

			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		)
			.then((xData) => {
				if (xData.status === Env.eHttpStatus.eOk) {
					setSData(xData.data);
					setSLoading(false);
				}
			})
			.catch(() => {
				alert("Error");
				setSLoading(false);
			});
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (sData.length > 1) {
			setSNewData((xState) => {
				let iState = { ...xState };
				iState.no = sData.length + 1;
				return iState;
			});
		}
	}, [sData]);

	const fUpdate = (xIndex) => {
		setSLoading(true);
		Axios.put(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/members_group`,
			sData[xIndex],
			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		)
			.then((xData) => {
				if (xData.status === Env.eHttpStatus.eOk) {
					setSData(xData.data);
				}
				setSLoading(false);
			})
			.catch(() => {
				setSLoading(false);
			});
	};

	return (
		<div>
			{sLoading ? <CompLoading /> : ""}
			{sAlert ? (
				<CompAlert
					_title="Memasukkan Data Kelompok"
					_keterangan="Apakah Data Sudah Benar ?"
					_buttonCancel="Batal"
					_onCancel={() => {
						setSAlert(false);
					}}
					_onOk={() => {
						setSAlert(false);
						setSLoading(true);
						Axios.post(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/members_group`,
							sNewData,
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								// console.log(xData.data);
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									setSData(xData.data);
								} else if (xData.status === Env.eHttpStatus.eGagal) {
									setsAlertGagal(true);
								}
							})
							.catch((xErr) => {
								setSLoading(false);
								alert("Error");
							});
					}}
				/>
			) : (
				""
			)}

			{sAlertGagal ? (
				<CompAlertGagal
					_title="No Atau Nama Telah Digunakan"
					_keterangan="Silahkan ganti No atau Nama"
					_onOk={() => {
						setsAlertGagal(false);
					}}
				/>
			) : (
				""
			)}

			{sDeleteData > 0 ? (
				<CompAlertDelete
					_title={`Hapus Data No ${sDeleteData}`}
					_keterangan={`Apakah Benar akan menghapus data No ${sDeleteData} ?`}
					_buttonCancel="Batal"
					_onCancel={() => {
						setSDeleteData(0);
					}}
					_onOk={() => {
						setSLoading(true);
						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/members_group`,
							{
								data: { no: sDeleteData },
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								if (xData.status === Env.eHttpStatus.eOk) {
									setSData(xData.data);
									setSDeleteData(0);
									setSLoading(false);
								} else {
									setSDeleteData(0);
									setSLoading(false);
									alert(`Data Gagal dihapus, ${xData.data.message}`);
								}
							})
							.catch(() => {
								setSDeleteData(0);
								setSLoading(false);
								alert("Error");
							});
					}}
				/>
			) : (
				""
			)}

			<div className="mx-3">
				<div
					className="flex flex-col space-y-1 shadow-lg mt-10  bg-genkiBlue-cl_01
                  max-w-screen-sm mx-auto  p-1 rounded-xl "
				>
					<div className="flex flex-col w-full justify-center">
						<div className="flex w-full justify-center">
							<h1 className="truncate">Masukkan Data Kelompok</h1>
						</div>

						<div className="flex flex-col space-y-2 truncate">
							<div className="flex w-full justify-center ">
								<div className="flex items-center ml-2 w-16">
									<p className="flex w-14">No </p>
									<p className="mr-1">:</p>
								</div>

								<CompText
									_dataType="angka"
									_ref={refNo}
									_onKeyEnter={() => {
										refNama.current.focus();
										refNama.current.select();
									}}
									_value={sNewData.no}
									_onChange={(xValue) => {
										setSNewData((xState) => {
											let iState = { ...xState };
											iState.no = xValue;
											return iState;
										});
									}}
								/>
							</div>
							<div className="flex w-full justify-center ">
								<div className="flex items-center ml-2 w-16">
									<p className="flex w-14">Nama </p>
									<p className="mr-1">:</p>
								</div>

								<CompText
									_ref={refNama}
									_value={sNewData.nama}
									_onChange={(xValue) => {
										setSNewData((xState) => {
											let iState = { ...xState };
											iState.nama = xValue;
											return iState;
										});
									}}
									_onKeyEnter={() => {
										setSAlert(true);
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<table className="Table max-w-screen-sm mx-auto rounded-xl mt-2">
					<thead>
						<tr>
							<th>No</th>
							<th>Nama</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{sData.length > 0 ? (
							sData.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td className="w-12 ">
											<CompText
												_className="h-6 "
												_dataType="angka"
												_value={sData[xIndex].no}
												_onChange={(xValue) => {
													setSData((xState) => {
														let iState = [...xState];
														iState[xIndex].no = xValue;
														return iState;
													});
												}}
												_onKeyEnter={() => {
													fUpdate(xIndex);
												}}
											/>
										</td>
										<td>
											<CompText
												_value={sData[xIndex].nama}
												_className="w-full h-6"
												_onChange={(xValue) => {
													setSData((xState) => {
														let iState = [...xState];
														iState[xIndex].nama = xValue;
														return iState;
													});
												}}
												_onKeyEnter={() => {
													fUpdate(xIndex);
												}}
											/>
										</td>
										<td className="w-16">
											<button
												className="Button m-auto h-6"
												onClick={() => {
													setSDeleteData(xIndex + 1);
												}}
											>
												Hapus
											</button>
										</td>
									</tr>
								);
							})
						) : (
							<tr></tr>
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(Kelompok);
