import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const BukaRekeningTabungan = (props) => {
	const [sTanggal, setSTanggal] = useState("");

	const mNewData = {
		nama: { id: "", nama: "", alamat: "", kelompok: "" },
		program_tabungan: { id: "", nama: "", bunga: "" },
		id: "",
	};

	const [sNewData, setSNewData] = useState(mNewData);
	const [sLoading, setSLoading] = useState(true);
	const [sAnggota, setSAnggota] = useState([]);
	const [sRsProgramTabungan, setSRsProgramTabungan] = useState([]);
	const [sRsRekeningTabungan, setSRsRekeningTabungan] = useState([]);
	const [sNamaFocus, setSNamaFocus] = useState(false);
	const [sProgramTabunganFocus, setSProgramTabunganFocus] = useState(false);
	const [sAlert, setSAlert] = useState(false);
	const [sDelete, setSDelete] = useState({ show: false, index: 0, data: "" });

	const refTanggal = useRef();
	const refNama = useRef();
	const refProgramTabungan = useRef();
	const refKeterangan = useRef();
	const refSimpan = useRef();

	const fGetPreData = async () => {
		try {
			const iTanggal = await Axios.get(`${props.state.sPages.url}/date`);
			setSTanggal(iTanggal.data);

			const iRsRekeningTabungan = await Axios.get(
				`${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
				{
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			);
			setSRsRekeningTabungan(iRsRekeningTabungan.data.message);
			// console.log(iRsRekeningTabungan);

			const iRsAngota = await Axios.get(
				`${props.state.sPages.url}${Env.eRouteApi}/members_anggota`,
				{
					params: { req: "rs_data_anggota" },
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			);
			setSAnggota(iRsAngota.data.message);

			const iRsDataProgramTabungan = await Axios.get(
				`${props.state.sPages.url}${Env.eRouteApi}/admin/program_tabungan`,
				{
					params: { req: "rs_data_program_tabungan" },
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			);
			setSRsProgramTabungan(iRsDataProgramTabungan.data.message);

			setSLoading(false);
			refNama.current.focus();
		} catch (xErr) {
			alert("Error");
		}
	};

	useEffect(() => {
		fGetPreData();
		// eslint-disable-next-line
	}, []);

	const fCekData = () => {
		if (sNewData.nama.id !== "") {
			if (sNewData.program_tabungan.id === "") {
				refProgramTabungan.current.focus();
			} else {
				setSAlert(true);
			}
		} else {
			refNama.current.focus();
		}
	};

	return (
		<div className="flex flex-col h-full w-full px-2">
			{sLoading ? <CompLoading /> : ""}
			{sAlert ? (
				<CompAlert
					_title="Data Buka Rekening Tabungan"
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Ulang"
					_onCancel={() => {
						setSAlert(false);
						refNama.current.focus();
						refNama.current.select();
					}}
					_onOk={() => {
						setSAlert(false);
						// setSLoading(true);
						Axios.post(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
							{
								members: sNewData.nama.id,
								keuangan_tabungan_program: sNewData.program_tabungan.id,
								keterangan: sNewData.keterangan,
							},
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xRes) => {
								// console.log(xRes.data);
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsRekeningTabungan((xState) => {
										let iState = [xRes.data.message, ...xState];
										return iState;
									});
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.nama.id = "";
										iState.nama.nama = "";
										iState.nama.alamat = "";
										iState.nama.kelompok = "";
										iState.program_tabungan.id = "";
										iState.program_tabungan.nama = "";
										iState.program_tabungan.bunga = "";
										return iState;
									});
								} else {
									alert("Data Realisasi Tabungan Gagal Disimpan");
								}
								setSLoading(false);
								refTanggal.current.focus();
							})
							.catch(() => {
								alert("Rekening Tabungan Gagal Dibuat");
								setSLoading(false);
								refTanggal.current.focus();
							});
					}}
				/>
			) : (
				""
			)}

			{sDelete.show ? (
				<CompAlertDelete
					_title="Menghapus Data Tabungan"
					_keterangan={sDelete.data}
					_buttonCancel="Batal"
					_onCancel={() => {
						setSDelete((xState) => {
							let iState = { ...xState };
							iState.show = false;
							return iState;
						});
					}}
					_onOk={() => {
						setSLoading(true);
						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
							{
								data: { id: sRsRekeningTabungan[sDelete.index].id },
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xRes) => {
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsRekeningTabungan((xState) => {
										let iState = [...xState];
										iState.splice(sDelete.index, 1);
										return iState;
									});
								} else {
									alert("Data Rekening Tabungan Gagal Di Hapus");
								}
								setSDelete((xState) => {
									let iState = { ...xState };
									iState.show = false;
									return iState;
								});
								refTanggal.current.focus();
								setSLoading(false);
							})
							.catch(() => {
								alert("Data Rekening Tabungan Gagal Di Hapus");
								setSDelete((xState) => {
									let iState = { ...xState };
									iState.show = false;
									return iState;
								});
								refTanggal.current.focus();
								setSLoading(false);
							});
					}}
				/>
			) : (
				""
			)}
			<div className=" h-72 my-1">
				{/* TITLE */}
				<div
					className="flex flex-col p-2 mt-1 w-full
                    mx-auto bg-white rounded-xl shadow-md items-center "
				>
					<p>MEMBUKA REKENING TABUNGAN</p>
				</div>

				{/* FORM ISIAN DATA */}
				<div
					className="flex flex-col p-2 mt-2 w-full 
                     bg-blue-100 rounded-xl shadow-md mx-auto space-y-2"
				>
					<div className=" mx-auto">
						<p>{"Masukan Data"}</p>
					</div>
					<hr />

					<div className=" flex">
						<div className=" w-36">Tanggal</div>
						<div className=" w-1 text-center">:</div>
						<div className="ml-1 w-64 md:w-80 ">
							<input
								type="text"
								ref={refTanggal}
								className="Text"
								value={sTanggal}
								readOnly={true}
								onKeyUp={(xKey) => {
									if (xKey.nativeEvent.key === "Enter") {
										refNama.current.focus();
									}
								}}
							/>
						</div>
					</div>

					<div className=" flex">
						<div className="w-36">Nama</div>
						<div className=" w-1 text-center">:</div>
						<div className={sNamaFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}>
							<CompTextDs
								_ref={refNama}
								_value={sNewData.nama.nama}
								_dataSource={sAnggota}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.nama.id = xData[0];
										iState.nama.nama = xData[1];
										iState.nama.alamat = xData[2];
										iState.nama.kelompok = xData[3];
										return iState;
									});
									refProgramTabungan.current.focus();
									refProgramTabungan.current.select();
								}}
								_onFocus={() => {
									setSNamaFocus(true);
								}}
								_onLossFocus={() => {
									setSNamaFocus(false);
								}}
							/>
						</div>
						{!sNamaFocus ? (
							<div className="flex-1 pl-1 truncate">
								{`
								 Alamat: ${sNewData.nama.alamat || "....."};	
								 Kelompok: ${sNewData.nama.kelompok || "....."}`}
							</div>
						) : (
							""
						)}
					</div>

					<div className=" flex">
						<div className="w-36">Program Tabungan</div>
						<div className=" w-1 text-center">:</div>
						<div
							className={sProgramTabunganFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}
						>
							<CompTextDs
								_ref={refProgramTabungan}
								_dataSource={sRsProgramTabungan}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.program_tabungan.id = xData[0];
										iState.program_tabungan.nama = xData[1];
										iState.program_tabungan.bunga = xData[2];
										return iState;
									});
									refKeterangan.current.focus();
									refKeterangan.current.select();
								}}
								_onFocus={() => {
									setSProgramTabunganFocus(true);
								}}
								_onLossFocus={() => {
									setSProgramTabunganFocus(false);
								}}
							/>
						</div>
						{!sNamaFocus ? (
							<div className="flex-1 pl-1 truncate">
								{`
								 Bunga: ${sNewData.program_tabungan.bunga || "....."}% / bulan	
								 `}
							</div>
						) : (
							""
						)}
					</div>

					<div className=" flex">
						<div className="w-36">Keterangan</div>
						<div className=" w-1 text-center">:</div>
						<div className="ml-1 flex-1">
							<CompText
								_ref={refKeterangan}
								_onKeyEnterUp={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.keterangan = xData;
										return iState;
									});

									refSimpan.current.focus();
								}}
							/>
						</div>
					</div>

					<div className=" flex w-full">
						<button
							ref={refSimpan}
							className="Button mx-auto"
							onClick={() => {
								fCekData();
							}}
						>
							Simpan
						</button>
					</div>
				</div>
			</div>
			<div className=" flex-1 overflow-y-auto bg-blue-300 md:p-1 rounded-lg shadow-sm">
				<table className="Table">
					<thead>
						<tr>
							<th>No</th>
							<th>Rekening</th>
							<th>Nama</th>
							<th>Program Tabungan</th>

							<th>Keterangan</th>
						</tr>
					</thead>
					<tbody>
						{sRsRekeningTabungan.map((xData, xIndex) => {
							return (
								<tr key={xIndex}>
									<td width={40}>
										<button
											className="Button h-6 w-full"
											onClick={() => {
												setSDelete((xState) => {
													let iState = { ...xState };
													iState.show = true;
													iState.index = xIndex;
													iState.data = `Apakah benar akan menghapus data no: ${
														xIndex + 1
													}, NoRex: ${sRsRekeningTabungan[xIndex].id}, atas nama: ${
														sRsRekeningTabungan[xIndex].members.name
													}`;
													return iState;
												});
											}}
										>
											{xIndex + 1}
										</button>
									</td>
									<td>{xData.id}</td>
									<td>{xData.members.name}</td>
									<td>{xData.keuangan_tabungan_program.nama}</td>
									<td>{xData.keterangan}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="h-12"></div>
		</div>
	);
};

export default GlobalStateConsumer(BukaRekeningTabungan);
