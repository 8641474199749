import React, { Fragment, useState, useRef, useEffect } from "react";

import Env from "../../setting/env";

const Index = (props) => {
	const [sValue, setValue] = useState("");
	const [sDataSourceDisplay, setDataSourceDisplay] = useState(false);
	const [sTextInputFocus, setTextInputFocus] = useState(false);
	const [sTandaColour, setTandaColour] = useState(Env.color.colorPrimer01);
	const [sDataSource, setDataSource] = useState(null);
	const [sDataSourceValues, setDataSourceValues] = useState(null);
	// const [sDataSourceView, setDataSourceView] = useState(false);

	const mRefTemp = useRef(null);
	const mRefTxtInput = props._ref || mRefTemp;
	let mNilai = 0;

	useEffect(() => {
		// setValue(props._value);
		fCekData(props._value || "");

		// eslint-disable-next-line
	}, [props._value]);

	useEffect(() => {
		// setValue(props._value);
		setDataSource(props._dataSource);
		if (props._dataSource) {
			setDataSourceValues((xData) => {
				const isData = { ...xData };
				isData.values = props._dataSource.values;
				isData.index = 0;
				return isData;
			});
		} else {
			setDataSourceValues(null);
		}
	}, [props._dataSource]);

	const fCekData = (xData) => {
		let iValue = String(xData);
		if (props._dataType === "ribuan") {
			iValue = Env.format.ribuan(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		} else if (props._dataType === "angka") {
			iValue = Env.format.angka(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		} else if (props._dataType === "pecahan") {
			iValue = Env.format.pecahan(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		}

		if (props._tanda) {
			const iLength = props._minLength_minValue || 0;
			if (
				props._dataType === "ribuan" ||
				props._dataType === "angka" ||
				props._dataType === "pecahan"
			) {
				if (iLength === 0) {
					if (mNilai > iLength) {
						setTandaColour(Env.color.colorPrimer02);
						fValidasi(true);
					} else {
						setTandaColour(Env.color.colorPrimer01);
						fValidasi(false);
					}
				} else {
					if (mNilai >= iLength) {
						setTandaColour(Env.color.colorPrimer02);
						fValidasi(true);
					} else {
						setTandaColour(Env.color.colorPrimer01);
						fValidasi(false);
					}
				}
			} else {
				if (iLength === 0) {
					if (iValue.length > iLength) {
						setTandaColour(Env.color.colorPrimer02);
						fValidasi(true);
					} else {
						setTandaColour(Env.color.colorPrimer01);
						fValidasi(false);
					}
				} else {
					if (iValue.length >= iLength) {
						setTandaColour(Env.color.colorPrimer02);
						fValidasi(true);
					} else {
						setTandaColour(Env.color.colorPrimer01);
						fValidasi(false);
					}
				}
			}
		}
		setValue(iValue);
		if (props._dataType === "ribuan") {
			return mNilai;
		} else {
			return iValue;
		}
	};

	const fValidasi = (xValue) => {
		if (props._onValidasi) {
			props._onValidasi(xValue);
		}
	};

	const fFilter = (xValue) => {
		const iRegExp = new RegExp(`${xValue}`);
		const iDataSourceValues = sDataSource.values.filter((xValues) => {
			return iRegExp.test(xValues[0]);
		});

		setDataSourceValues((xData) => {
			const isData = { ...xData };
			isData.values = iDataSourceValues;
			return isData;
		});
	};

	return (
		<Fragment>
			<div className="WadahFix" style={{ padding: "2px" }}>
				<div className="flex flex-col md:flex-row">
					<div className="flex mx-auto md:hidden font-medium">{props._title}</div>

					<div className="hidden md:flex font-medium items-center ">
						<div
							className="flex font-medium items-center ml-1 "
							style={{
								width: props._titleWidth || "140px",
							}}
						>
							{props._title}
						</div>
						<div className="mx-0.5">:</div>
					</div>

					<div className="flex flex-1">
						<div className="flex-1 flex-col items-center ">
							<input
								type={props._type || "text"}
								maxLength={props._maxLength || null}
								placeholder={props._placeholder}
								ref={mRefTxtInput}
								className="Text"
								value={sValue}
								onChange={(xValue) => {
									let iValue = xValue.nativeEvent.target.value;
									iValue = fCekData(iValue);
									// setValue(iValue);
									if (props._dataSource) {
										fFilter(iValue);
									}

									if (props._onChange) {
										props._onChange(iValue);
									}
								}}
								onFocus={() => {
									if (props._dataSource) {
										// fFilter(sValue);
										setTextInputFocus(true);
									}
								}}
								onBlur={() => {
									if (!sDataSourceDisplay) {
										setTextInputFocus(false);
									}
								}}
								onKeyPress={(pKey) => {
									if (pKey.nativeEvent.key === "Enter") {
										// if (props._onKeyEnter) {
										if (props._dataType === "pecahan") {
											let iValue = true;
											let i = sValue.indexOf("/");
											if (i === 0) {
												iValue = false;
											} else if (i > 0) {
												let ii = (i += 1);
												let iX = sValue.substring(ii);
												if (iX === "") {
													iValue = false;
												} else {
													if (parseFloat(iX.replace(",", ".")) <= 0) {
														iValue = false;
													}
												}
											}
											if (!iValue) {
												let iiValue = sValue.substring(0, (i -= 1));
												setValue(iiValue);
												if (props._onChange) {
													props._onChange(iiValue);
												}
											}
										}

										let iKeyEnter = false;
										if (props._tanda) {
											if (sTandaColour === Env.color.colorPrimer02) {
												iKeyEnter = true;
											}
										} else {
											iKeyEnter = true;
										}

										if (sDataSourceValues) {
											if (sDataSourceValues.values.length > 0) {
												if (iKeyEnter) {
													fFilter(sDataSourceValues.values[sDataSourceValues.index][0]);
													setValue(sDataSourceValues.values[sDataSourceValues.index][0]);
													setTextInputFocus(false);
													if (props._onKeyEnter) {
														props._onKeyEnter();
													}
												}
											} else {
												setTextInputFocus(false);
												if (props._onKeyEnter) {
													props._onKeyEnter();
												}
											}
										} else {
											if (iKeyEnter) {
												setTextInputFocus(false);
												if (props._onKeyEnter) {
													props._onKeyEnter();
												}
											}
										}
									}
									// }
								}}
								onKeyUp={(pKey) => {
									if (pKey.nativeEvent.key === "ArrowUp") {
										if (sDataSourceValues) {
											if (sDataSourceValues.values.length === 0) {
												if (props._onKeyUp) {
													props._onKeyUp();
												}
											} else {
												setDataSourceValues((xData) => {
													let isData = { ...xData };
													if (isData.index > 0) {
														isData.index = isData.index -= 1;
													}
													return isData;
												});
											}
										} else {
											if (props._onKeyUp) {
												props._onKeyUp();
											}
										}
									} else if (pKey.nativeEvent.key === "Enter") {
										if (props._onKeyUpEnter) {
											props._onKeyUpEnter();
										}
									}

									if (props._dataSource) {
										setTextInputFocus(true);
									}
								}}
								onKeyDown={(pKey) => {
									if (pKey.nativeEvent.key === "ArrowDown") {
										if (sDataSourceValues) {
											if (sDataSourceValues.values.length === 0) {
												if (props._onKeyDown) {
													props._onKeyDown();
												}
											} else {
												setDataSourceValues((xData) => {
													let isData = { ...xData };
													if (isData.index < isData.values.length - 1) {
														isData.index = isData.index += 1;
													}
													return isData;
												});
											}
										} else {
											if (props._onKeyDown) {
												props._onKeyDown();
											}
										}
									}
								}}
							/>
							{/* DIV DATA SOURCE */}
							{sDataSourceValues && sTextInputFocus ? (
								sDataSourceValues.values.length ? (
									<div
										style={{
											position: "relative",
											width: "100%",
											marginLeft: "-2px",
										}}
										onMouseOver={() => {
											setDataSourceDisplay(true);
										}}
										onMouseOut={() => {
											setDataSourceDisplay(false);
											mRefTxtInput.current.focus();
										}}
									>
										<div
											style={{
												width: "100%",
												borderRadius: "5px",
												border: "solid",
												borderWidth: "2px",
												position: "absolute",
												top: "100%",
												display: "flex",
												backgroundColor: "gray",
											}}
										>
											<table width="100%">
												<thead>
													<tr className="myContainer">
														<th></th>
														{sDataSource.header.map((xData, xIndex) => {
															let iWidth = "";
															if (props._dataSourceOption) {
																iWidth = props._dataSourceOption.colWidth[xIndex];
																iWidth = String(iWidth);
															}
															return (
																<th key={xIndex}>
																	{iWidth === "0" || iWidth === "0px" ? "" : xData}
																</th>
															);
														})}
													</tr>
												</thead>
												<tbody>
													{sDataSourceValues.values.map((xDatas, xIndex) => {
														const iTanda = "-";
														let iColor = "#eee";
														let iActived = false;
														if (xIndex % 2 !== 0) {
															iColor = "#bbb";
														}
														if (sDataSourceValues.index === xIndex) {
															iColor = Env.color.orangeRed04;
															iActived = true;
														}
														return (
															<tr bgcolor={iColor} key={xIndex}>
																{iActived ? (
																	<td style={{ width: "25px" }}>
																		<div
																			className="tombol"
																			style={{
																				display: "flex",
																				justifyContent: "center",
																				alignItems: "center",
																				height: "100%",
																				width: "24px",
																			}}
																			onClick={() => {
																				setValue(sDataSourceValues.values[xIndex][0]);
																				fFilter(sDataSourceValues.values[xIndex][0]);
																				mRefTxtInput.current.focus();
																			}}
																		>
																			<img
																				style={{ width: "15px", height: "18px" }}
																				src={Env.image.arrow}
																				alt=""
																			></img>
																		</div>
																	</td>
																) : (
																	<td style={{ width: "25px" }}>
																		<div
																			className="tombol"
																			style={{
																				display: "flex",
																				justifyContent: "center",
																				alignItems: "center",
																				height: "100%",
																				width: "24px",
																			}}
																			onClick={() => {
																				setValue(sDataSourceValues.values[xIndex][0]);
																				fFilter(sDataSourceValues.values[xIndex][0]);
																				mRefTxtInput.current.focus();
																			}}
																		>
																			{iTanda}
																		</div>
																	</td>
																)}

																{xDatas.map((xData, xxIndex) => {
																	let iWidth = "";
																	let iAlign = "flex-start";
																	if (props._dataSourceOption) {
																		iWidth = props._dataSourceOption.colWidth[xxIndex];
																		iWidth = String(iWidth);
																		if (props._dataSourceOption.colAlign) {
																			iAlign =
																				props._dataSourceOption.colAlign[xxIndex] || "flex-start";
																			iAlign = iAlign.toLowerCase();
																			switch (iAlign) {
																				case "l":
																					iAlign = "flex-start";
																					break;
																				case "c":
																					iAlign = "center";
																					break;

																				case "r":
																					iAlign = "flex-end";

																					break;

																				default:
																					break;
																			}
																		}
																	}

																	return (
																		<td
																			key={xxIndex}
																			style={{
																				width: iWidth,
																			}}
																		>
																			<div
																				className="row-hover"
																				style={{
																					width: "100%",
																					display: "flex",
																					justifyContent: iAlign,
																					paddingLeft: "1px",
																				}}
																				onClick={() => {
																					setValue(sDataSourceValues.values[xIndex][xxIndex]);
																					fFilter(sDataSourceValues.values[xIndex][xxIndex]);
																					mRefTxtInput.current.focus();
																				}}
																			>
																				{iWidth === "0" || iWidth === "0px" ? "" : xData}
																			</div>
																		</td>
																	);
																})}
															</tr>
														);
													})}
												</tbody>
											</table>
										</div>
									</div>
								) : (
									""
								)
							) : (
								""
							)}
						</div>

						{/* OPTION */}
						{props._option ? (
							<select
								style={{
									margin: "3px 1px",
									borderRadius: "5px",
									border: "none",
									fontSize: "19px",
									height: "25px",
									outlineColor: Env.color.orangeRed01,
									outlineWidth: "1px",
								}}
								onClick={(xData) => {
									if (props._ref) {
										props._ref.current.focus();
									}
									if (props._onOptionChangeValue) {
										props._onOptionChangeValue(xData.nativeEvent.target.value);
									}
								}}
							>
								{props._option.map((xData, xIndex) => {
									return (
										<option key={xIndex} value={xData.value}>
											{" "}
											{xData.name}
										</option>
									);
								})}
							</select>
						) : (
							""
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};
export default Index;
