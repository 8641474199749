import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";

import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompLoading from "../../../../../component/arjLoading";
import CompAlertSimpan from "../../../../../component/arjAlert";
import CompAlertUpdate from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";

const JenisKegiatan = (props) => {
	const [sRsKegiatan, setSRsKegiatan] = useState([]);
	const mJenisKegiatan = [
		{ No: 1, Jenis: "Iuran" },
		{ No: 2, Jenis: "Salahan" },
		{ No: 3, Jenis: "Tabungan Wajib" },
	];
	const mNewJabatan = {
		0: 100,
		1: 100,
		2: 100,
		10: 100,
		20: 100,
		30: 100,
		50: 100,
		60: 100,
		65: 100,
		66: 100,
		70: 100,
		80: 100,
		90: 100,
		95: 100,
	};
	const [sJabatan, setsJabatan] = useState(mNewJabatan);
	const mNewKegiatan = {
		nama: "",
		jenis_kegiatan: { no: 0, jenis: "" },
		akun_debet: { no_akun: "", nama_akun: "" },
		akun_kredit: { no_akun: "", nama_akun: "" },
		keterangan: "",
	};
	const [sKegiatan, setsKegiatan] = useState(mNewKegiatan);

	const [sEdit, setsEdit] = useState({ index: -1, id: 0 });
	const [sLoading, setsLoading] = useState(true);
	const [sAlertSimpan, setsAlertSimpan] = useState(false);
	const [sAlertUpdate, setsAlertUpdate] = useState(false);
	const [sAlertDelete, setsAlertDelete] = useState(false);
	const [sRsAktiva, setSRsAktiva] = useState([]);
	const [sRsPasiva, setSRsPasiva] = useState([]);

	const refJenisKegiatan = useRef();
	const refNama = useRef();
	const refKetua = useRef();
	const refWakilKetua = useRef();
	const refSekretaris = useRef();
	const refBendahara = useRef();
	const refPemangku = useRef();
	const refPenasehat = useRef();
	const refParuman = useRef();
	const refJuru = useRef();
	const refJabatanLain = useRef();
	const refAnggotaTidakAktif = useRef();
	const refAnggota = useRef();
	const refDuda = useRef();
	const refJanda = useRef();
	const refBeliAyahan = useRef();
	const refAkunDebet = useRef();
	const refAkunKredit = useRef();
	const refKeterangan = useRef();
	const refSimpan = useRef();
	const refUpdate = useRef();

	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
					{
						params: { req: "akun" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);
				setSRsAktiva(iData.data.message.rsAktiva);
				setSRsPasiva(iData.data.message.rsPasiva);

				const iRsKegiatanJenis = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
					{
						params: { req: "rs" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);
				// console.log(iRsKegiatanJenis);
				setSRsKegiatan(iRsKegiatanJenis.data.message);

				setsLoading(false);
			};
			fGetData();

			refNama.current.focus();
		} catch (xErr) {
			// setSLoading(false);
			alert(xErr);
			setsLoading(false);
		}
		// eslint-disable-next-line
	}, []);

	let iClassNameEdit =
		"flex flex-col  w-full p-1 md:p-2 bg-red-200 rounded-xl shadow-xl";
	let iTitleEdit = "Edit Data";
	if (sEdit.index === -1) {
		iClassNameEdit =
			"flex flex-col  w-full p-1 md:p-2 bg-blue-200 rounded-xl shadow-xl";
		iTitleEdit = "Masukkan Data";
	}

	const fJabatan = (xKegiatanDetails) => {
		let iData = "";
		xKegiatanDetails.sort((a, b) => {
			return b.jabatan - a.jabatan;
		});
		for (let i = 0; i < xKegiatanDetails.length; i++) {
			let xJabatan = xKegiatanDetails[i].jabatan;
			let xPeruntukan = xKegiatanDetails[i].peruntukan;
			switch (xJabatan) {
				case 0:
					iData += `Beli Ayahan: ${xPeruntukan}%, `;
					break;
				case 1:
					iData += `Janda: ${xPeruntukan}%, `;
					break;
				case 2:
					iData += `Duda: ${xPeruntukan}%, `;
					break;
				case 20:
					iData += `Anggota: ${xPeruntukan}%, `;
					break;
				case 10:
					iData += `Anggota Tidak Aktif: ${xPeruntukan}%, `;
					break;
				case 30:
					iData += `Jabatan Lain: ${xPeruntukan}%, `;
					break;
				case 50:
					iData += `Juru: ${xPeruntukan}%, `;
					break;
				case 60:
					iData += `Paruman: ${xPeruntukan}%, `;
					break;
				case 65:
					iData += `Penasehat: ${xPeruntukan}%, `;
					break;
				case 66:
					iData += `Pemangku: ${xPeruntukan}%, `;
					break;
				case 70:
					iData += `Bendahara: ${xPeruntukan}%, `;
					break;
				case 80:
					iData += `Sekretaris: ${xPeruntukan}%, `;
					break;
				case 90:
					iData += `Ketua: ${xPeruntukan}%, `;
					break;
				case 95:
					iData += `Wakil Ketua: ${xPeruntukan}%, `;
					break;
				default:
					break;
			}
		}
		return iData;
	};

	const fJenisKegiatan = (xJenis) => {
		switch (xJenis) {
			case 1:
				return "Iuran";

			case 3:
				return "Tabungan Wajib";

			default:
				return "Salahan";
		}
	};

	return (
		<div className=" overflow-y-scroll h-screen">
			{sLoading ? <CompLoading /> : ""}
			{sAlertSimpan ? (
				<CompAlertSimpan
					_title="Menyimpan Data Jenis Kegiatan"
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Batal"
					_onCancel={() => {
						setsAlertSimpan(false);
						refNama.current.focus();
						refNama.current.select();
					}}
					_onOk={() => {
						if (!sLoading) {
							setsLoading(true);
							Axios.post(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
								{
									jabatan: sJabatan,
									kegiatan: sKegiatan,
								},
								{
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							).then((xRes) => {
								// console.log(xRes.data);
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsKegiatan(xRes.data.message);

									setsJabatan(mNewJabatan);
									setsKegiatan(mNewKegiatan);
								} else {
									alert("Error");
								}
								setsAlertSimpan(false);
								setsLoading(false);
								refNama.current.focus();
							});
						}
					}}
				/>
			) : (
				""
			)}

			{sAlertUpdate ? (
				<CompAlertUpdate
					_title="Update Data Jenis Kegiatan"
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Batal"
					_onCancel={() => {
						setsAlertUpdate(false);
						refNama.current.focus();
						refNama.current.select();
					}}
					_onOk={() => {
						if (!sLoading) {
							setsLoading(true);
							const iKegiatan = sKegiatan;
							iKegiatan.id = sEdit.id;
							Axios.put(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
								{
									jabatan: sJabatan,
									kegiatan: iKegiatan,
								},
								{
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							).then((xRes) => {
								// console.log(xRes.data);
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsKegiatan(xRes.data.message);
									setsEdit((xState) => {
										let iState = { ...xState };
										iState.index = -1;
										return iState;
									});
									setsJabatan(mNewJabatan);
									setsKegiatan(mNewKegiatan);
								} else {
									alert("Error");
								}
								setsAlertUpdate(false);
								setsLoading(false);
								refNama.current.focus();
							});
						}
					}}
				/>
			) : (
				""
			)}

			{sAlertDelete ? (
				<CompAlertDelete
					_title="Hapus Data Jenis Kegiatan"
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Batal"
					_onCancel={() => {
						setsAlertDelete(false);
						refNama.current.focus();
						refNama.current.select();
					}}
					_onOk={() => {
						if (!sLoading) {
							setsLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
								{
									data: { id: sEdit.id },
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							).then((xRes) => {
								// console.log(xRes.data);
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsKegiatan(xRes.data.message);
									setsEdit((xState) => {
										let iState = { ...xState };
										iState.index = -1;
										return iState;
									});
									setsJabatan(mNewJabatan);
									setsKegiatan(mNewKegiatan);
								} else {
									alert("Error");
								}
								setsAlertDelete(false);
								setsLoading(false);
								refNama.current.focus();
							});
						}
					}}
				/>
			) : (
				""
			)}

			<div className=" flex justify-center w-full text-xl mt-2">
				Jenis Kegiatan
			</div>

			{/* Masukkan Data */}
			<div className=" w-full md:p-2">
				<div className={iClassNameEdit}>
					<div className="flex justify-center text-lg w-full">{iTitleEdit}</div>
					<hr />
					{/* Nama */}
					<div className="flex mt-2">
						<div className=" w-28">Nama Kegiatan</div>
						<div className="w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refNama}
								_value={sKegiatan.nama}
								_onKeyEnterUp={(xData) => {
									if (xData !== "") {
										setsKegiatan((xState) => {
											let iState = { ...xState };
											iState.nama = xData;
											return iState;
										});
										refJenisKegiatan.current.focus();
									}
								}}
							/>
						</div>
					</div>

					{/* Jenis Kegiatan */}
					<div className="flex mt-2">
						<div className=" w-28">Jenis Kegiatan</div>
						<div className="w-2">:</div>
						<div className=" max-w-sm">
							<CompTextDs
								_dataSource={mJenisKegiatan}
								_ref={refJenisKegiatan}
								_value={sKegiatan.jenis_kegiatan.jenis}
								_onKeyEnter={(xData) => {
									setsKegiatan((xState) => {
										let iState = { ...xState };
										let iKegiatan = { no: xData[0], jenis: xData[1] };
										iState.jenis_kegiatan = iKegiatan;
										return iState;
									});
									refKetua.current.focus();
									refKetua.current.select();
								}}
							/>
						</div>
					</div>

					{/* Peruntukan */}
					<div className="flex mt-2">
						<div className=" w-28">Peruntukan</div>
						<div className="w-2">:</div>
						<div
							className="flex flex-1 border-gray-400 
							border-solid border-1 rounded-xl pr-2 pb-2 bg-blue-100 flex-wrap"
						>
							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Ketua</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[90]}
										_ref={refKetua}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[90] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refWakilKetua.current.focus();
											refWakilKetua.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Wakil Ketua</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[95]}
										_ref={refWakilKetua}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[95] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refSekretaris.current.focus();
											refSekretaris.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Sekretaris</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[80]}
										_ref={refSekretaris}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[80] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refBendahara.current.focus();
											refBendahara.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Bendahara</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[70]}
										_ref={refBendahara}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[70] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refPemangku.current.focus();
											refPemangku.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Pemangku</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[66]}
										_ref={refPemangku}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[66] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refPenasehat.current.focus();
											refPenasehat.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Penasehat</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[65]}
										_ref={refPenasehat}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[65] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refParuman.current.focus();
											refParuman.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Paruman</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[60]}
										_ref={refParuman}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[60] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refJuru.current.focus();
											refJuru.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Juru</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[50]}
										_ref={refJuru}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[50] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refJabatanLain.current.focus();
											refJabatanLain.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Jabatan Lain</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[30]}
										_ref={refJabatanLain}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[30] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refAnggotaTidakAktif.current.focus();
											refAnggotaTidakAktif.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Anggota Tidak Aktif</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[10]}
										_ref={refAnggotaTidakAktif}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[10] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refAnggota.current.focus();
											refAnggota.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Anggota</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[20]}
										_ref={refAnggota}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[20] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refDuda.current.focus();
											refDuda.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Duda</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[2]}
										_ref={refDuda}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[2] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refJanda.current.focus();
											refJanda.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Janda</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[1]}
										_ref={refJanda}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[1] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refBeliAyahan.current.focus();
											refBeliAyahan.current.select();
										}}
									/>
								</div>
								%
							</div>

							{/* ------------ */}
							<div
								className="flex  border-blue-300 mt-2 ml-3 items-center 
							border-solid border-1 rounded-xl p-1 bg-blue-100"
							>
								<div>Beli Ayahan</div>
								<div className="flex justify-center w-2">:</div>
								<div className=" w-14">
									<CompText
										_value={sJabatan[0]}
										_ref={refBeliAyahan}
										_dataType="angka"
										_onNilai={(xData) => {
											if (xData !== "") {
												setsJabatan((xState) => {
													let iState = { ...xState };
													iState[0] = xData;
													return iState;
												});
											}
										}}
										_onKeyEnterUp={() => {
											refAkunDebet.current.focus();
											refAkunDebet.current.select();
										}}
									/>
								</div>
								%
							</div>
						</div>
					</div>

					{/* Akun Debet */}
					<div className="flex mt-2 items-cente">
						<div className=" w-28">Akun Debet</div>
						<div className="w-2">:</div>
						<div className=" max-w-sm">
							<CompTextDs
								_dataSource={sRsAktiva}
								_ref={refAkunDebet}
								_value={sKegiatan.akun_debet.nama_akun}
								_onKeyEnter={(xData) => {
									setsKegiatan((xState) => {
										let iState = { ...xState };
										let iAkunDebet = { no_akun: xData[0], nama_akun: xData[1] };
										iState.akun_debet = iAkunDebet;
										return iState;
									});
									refAkunKredit.current.focus();
									refAkunKredit.current.select();
								}}
							/>
						</div>
						<div className=" ml-1">{sKegiatan.akun_debet.no_akun}</div>
					</div>

					{/* Akun Kredit */}
					<div className="flex mt-2 items-center">
						<div className=" w-28">Akun Kredit</div>
						<div className="w-2">:</div>
						<div className=" max-w-sm">
							<CompTextDs
								_dataSource={sRsPasiva}
								_ref={refAkunKredit}
								_value={sKegiatan.akun_kredit.nama_akun}
								_onKeyEnter={(xData) => {
									setsKegiatan((xState) => {
										let iState = { ...xState };
										let iAkunKredit = { no_akun: xData[0], nama_akun: xData[1] };
										iState.akun_kredit = iAkunKredit;
										return iState;
									});
									refKeterangan.current.focus();
									refKeterangan.current.select();
								}}
							/>
						</div>
						<div className=" ml-1">{sKegiatan.akun_kredit.no_akun}</div>
					</div>

					{/* Keterangan */}
					<div className="flex mt-2">
						<div className=" w-28">Keterangan</div>
						<div className="w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refKeterangan}
								_value={sKegiatan.keterangan}
								_onKeyEnterUp={(xData) => {
									setsKegiatan((xState) => {
										let iState = { ...xState };
										iState.keterangan = xData;
										return iState;
									});
									if (sEdit.index > -1) {
										refUpdate.current.focus();
									} else {
										refSimpan.current.focus();
									}
								}}
							/>
						</div>
					</div>

					{/* Ection */}
					{sEdit.index > -1 ? (
						<div className="flex w-full justify-center">
							<div className="flex justify-evenly w-52 md:w-72">
								<button
									className="Button mt-2 h-8 w-20"
									ref={refUpdate}
									onClick={() => {
										setsAlertUpdate(true);
									}}
								>
									Update
								</button>
								<button
									className="Button mt-2 h-8 w-20"
									onClick={() => {
										setsAlertDelete(true);
									}}
								>
									Delete
								</button>

								<button
									className="Button mt-2 h-8 w-20"
									onClick={() => {
										setsEdit((xState) => {
											let iState = { ...xState };
											iState.index = -1;
											return iState;
										});
									}}
								>
									Batal
								</button>
							</div>
						</div>
					) : (
						<div className="flex w-full justify-center">
							<button
								className="Button mt-2 h-8 w-20"
								ref={refSimpan}
								onClick={() => {
									if (sKegiatan.nama === "") {
										refNama.current.focus();
									} else if (sKegiatan.jenis_kegiatan.jenis === "") {
										refJenisKegiatan.current.focus();
									} else if (sKegiatan.akun_debet.nama_akun === "") {
										refAkunDebet.current.focus();
									} else if (sKegiatan.akun_kredit.nama_akun === "") {
										refAkunKredit.current.focus();
									} else {
										setsAlertSimpan(true);
									}
								}}
							>
								Simpan
							</button>
						</div>
					)}
				</div>
			</div>
			<div className=" mt-2 md:mt-0 md:p-2 ">
				<table className="Table">
					<thead>
						<tr>
							<th>No</th>
							<th>Nama Kegiatan</th>
							<th>Jenis Kegiatan</th>
							<th>Peruntukan</th>
							<th>Akun Debet</th>
							<th>Akun Kredit</th>
							<th>Keterangan</th>
						</tr>
					</thead>
					<tbody>
						{sRsKegiatan.map((xData, xIndex) => {
							return (
								<tr
									key={xIndex}
									style={{
										backgroundColor: sEdit.index === xIndex ? Env.color.orangeRed02 : "",
									}}
								>
									<td>
										<button
											className="Button h-6"
											onClick={() => {
												setsKegiatan((xState) => {
													let iState = { ...xState };
													iState.nama = xData.nama;
													iState.jenis_kegiatan.no = xData.jenis;
													iState.jenis_kegiatan.jenis = fJenisKegiatan(xData.jenis);
													iState.akun_debet.no_akun = xData.akun_debet.no_akun;
													iState.akun_debet.nama_akun = xData.akun_debet.nama;
													iState.akun_kredit.no_akun = xData.akun_kredit.no_akun;
													iState.akun_kredit.nama_akun = xData.akun_kredit.nama;
													iState.keterangan = xData.keterangan;
													return iState;
												});

												setsJabatan(() => {
													const iObj = {};
													let iDataPeruntukan = xData.kegiatan_jenis_details;
													for (let i = 0; i < iDataPeruntukan.length; i++) {
														iObj[iDataPeruntukan[i].jabatan] = iDataPeruntukan[i].peruntukan;
													}
													return iObj;
												});

												setsEdit((xState) => {
													let iState = { ...xState };
													iState.index = xIndex;
													iState.id = xData.id;
													return iState;
												});

												refNama.current.focus();
												refNama.current.select();
											}}
										>
											{xIndex + 1}
										</button>
									</td>
									<td>{xData.nama}</td>
									<td>{fJenisKegiatan(xData.jenis)}</td>
									<td>{fJabatan(xData.kegiatan_jenis_details)}</td>
									<td>{xData.akun_debet.no_akun + " / " + xData.akun_debet.nama}</td>
									<td>{xData.akun_kredit.no_akun + " / " + xData.akun_kredit.nama}</td>
									<td>{xData.keterangan}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className=" h-14"></div>
		</div>
	);
};

export default GlobalStateConsumer(JenisKegiatan);
