const mJenisPiutang = [
	{ kode: 1, value: "Menetap" },
	{ kode: 2, value: "Menetap Efektif" },
	{ kode: 3, value: "Menurun" },
	{ kode: 4, value: "Tradisional" },
];

module.exports = {
	eJenisPiutang: mJenisPiutang,
	efGetValueJenisPiutang: (xKode) => {
		for (let i = 0; i < mJenisPiutang.length; i++) {
			if (mJenisPiutang[i].kode === xKode) {
				return mJenisPiutang[i].value;
			}
		}
		return "";
	},
};
