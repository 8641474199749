// ========catatan PROPS=================================================
// _noNota={sDataJurnal.eNota.no}------------------------> Nomer Nota
// _debetRef={sDataJurnal.eDebet.ref}--------------------> Referensi Nomer Nota
// _value={sDataJurnal.eNota.value}----------------------> Isi Nota (Optional)
// 	_dataNota={(xData) => {	-----------------------------> Return Callback Data Nota
// 			setSDataJurnal((xState) => {
//				let iState = { ...xState };
//				iState.eNota.no = xData.eNoNota;-------->Nomer Nota
//				iState.eNota.value = xData.eUraian;----->Isi Nota berupa Array :
//					[{banyak: 0,satuan: "",nama_barang: "",harga: 0,jumlah: 0},{...}]
// 				return iState;
// 			});
//_onKeyEnter={() => {-----------------------------------> Enter
//		iRefSimpan.current.focus();
//	}}

import { Fragment, useState, useRef, useEffect } from "react";

import CompText from "../../../../component/arjText";
import Env from "../../../../setting/env";

const Nota = (props) => {
	// const [sRefBanyak, setsRefBanyak] = useState(useRef(null));
	const iRefBanyak = useRef(null);
	const iRefSatuan = useRef(null);
	const iRefNamaBarang = useRef(null);
	const iRefHarga = useRef(null);
	const iRefJumlah = useRef(null);
	const iRefOkNata = useRef(null);

	const iRefBanyakEdit = useRef(null);
	const iRefSatuanEdit = useRef(null);
	const iRefNamaBarangEdit = useRef(null);
	const iRefHargaEdit = useRef(null);
	const iRefJumlahEdit = useRef(null);
	const iRefUpdateNota = useRef(null);

	const [sRsDataNota, setSRsDataNota] = useState({
		eNoNota: props._noNota || 0,
		eUraian: props._value || [],
	});

	const [sNotaEdit, setSNotaEdit] = useState({
		status: false,
		index: 0,
	});

	const [sDataNota, setSDataNota] = useState({
		banyak: 0,
		satuan: "",
		nama_barang: "",
		harga: 0,
		jumlah: 0,
	});
	const [sDataNotaEdit, setSDataNotaEdit] = useState({
		status: false,
		index: 0,
		banyak: 0,
		satuan: "",
		nama_barang: "",
		harga: 0,
		jumlah: 0,
	});

	const [sTotalNota, setSTotalNota] = useState(0);

	useEffect(() => {
		if (sNotaEdit.status === true) {
			setSDataNotaEdit((xState) => {
				let iState = { ...xState };
				iState.banyak = sRsDataNota.eUraian[sNotaEdit.index].banyak;
				iState.satuan = sRsDataNota.eUraian[sNotaEdit.index].satuan;
				iState.nama_barang = sRsDataNota.eUraian[sNotaEdit.index].nama_barang;
				iState.harga = sRsDataNota.eUraian[sNotaEdit.index].harga;
				iState.jumlah = sRsDataNota.eUraian[sNotaEdit.index].jumlah;
				return iState;
			});
			iRefBanyakEdit.current.focus();
			iRefBanyakEdit.current.select();
		}
		// eslint-disable-next-line
	}, [sNotaEdit]);

	useEffect(() => {
		setSDataNota((xState) => {
			let iState = { ...xState };
			iState.banyak = 0;
			iState.nama_barang = "";
			iState.harga = 0;
			iState.jumlah = 0;
			return iState;
		});

		if (props._dataNota) {
			if (sRsDataNota.eUraian.length > 0) {
				props._dataNota(sRsDataNota);
			}
		}

		if (sNotaEdit.status === false) {
			iRefBanyak.current.focus();
		}

		let iJumlah = 0;
		for (let i = 0; i < sRsDataNota.eUraian.length; i++) {
			iJumlah += sRsDataNota.eUraian[i].jumlah;
		}

		setSTotalNota(iJumlah);
		// console.log(props.value);
		// eslint-disable-next-line
	}, [sRsDataNota]);

	return (
		<Fragment>
			{/* =========================EDIT NOTA========================================== */}
			{sNotaEdit.status ? (
				<div className="Modal pt-10 pl-2 pr-2 md:pl-28 md:pr-16">
					<div className="WadahFix  m-auto w-full">
						<div className="flex w-full bg-genkiGray justify-center">
							<h1>{`Edit Nota ${sNotaEdit.index + 1}`}</h1>
						</div>
						<div className="flex flex-col md:flex-row ">
							{/* ========================BANYAK edit============================================== */}
							<div
								className="flex md:flex-col border-transparent border-l-2 border-t-2 border-r-2 md:border-r-1 border-b-2
             					md:border-solid md:border-genkiGray-light"
							>
								<div className="flex w-28 md:w-auto md:justify-center">Banyak</div>
								<div className="w-3 md:hidden">:</div>
								<div className="flex flex-1 md:w-16  md:bg-genkiGray-cl_01">
									<CompText
										_ref={iRefBanyakEdit}
										_dataType="ribuan"
										_value={sDataNotaEdit.banyak}
										_onChange={(xValue) => {
											setSDataNotaEdit((xState) => {
												let iState = { ...xState };
												iState.banyak = xValue;
												if (iState.harga > 0) {
													iState.jumlah = iState.harga * xValue;
												} else if (iState.jumlah > 0) {
													iState.harga = iState.jumlah / xValue;
												}
												return iState;
											});
										}}
										_onKeyEnter={() => {
											if (sDataNotaEdit.banyak > 0) {
												iRefSatuanEdit.current.focus();
												iRefSatuanEdit.current.select();
											}
										}}
									/>
								</div>
							</div>

							{/* =======================SATUAN edit============================ */}
							<div
								className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             						md:border-solid md:border-genkiGray-light md:w-e117"
							>
								<div className="flex w-28 md:w-auto md:justify-center">Satuan</div>
								<div className="w-3 md:hidden">:</div>
								<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
									<CompText
										_ref={iRefSatuanEdit}
										_value={sDataNotaEdit.satuan}
										_onChange={(xValue) => {
											setSDataNotaEdit((xState) => {
												let iState = { ...xState };
												iState.satuan = xValue;
												return iState;
											});
										}}
										_onKeyEnter={() => {
											iRefNamaBarangEdit.current.focus();
											iRefNamaBarangEdit.current.select();
										}}
									/>
								</div>
							</div>

							{/* =======================NAMA BARANG edit======================= */}
							<div
								className="flex md:flex-1 md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
            						 md:border-solid md:border-genkiGray-light"
							>
								<div className="flex w-28 md:w-auto md:justify-center">Nama Barang</div>
								<div className="w-3 md:hidden">:</div>
								<div className="flex flex-1   md:bg-genkiGray-cl_01">
									<CompText
										_ref={iRefNamaBarangEdit}
										_value={sDataNotaEdit.nama_barang}
										_onChange={(xValue) => {
											setSDataNotaEdit((xState) => {
												let iState = { ...xState };
												iState.nama_barang = xValue;
												return iState;
											});
										}}
										_onKeyEnter={() => {
											if (sDataNotaEdit.nama_barang.length > 0) {
												iRefHargaEdit.current.focus();
												iRefHargaEdit.current.select();
											}
										}}
									/>
								</div>
							</div>

							{/* =======================HARGA Edit============================= */}
							<div
								className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             						md:border-solid md:border-genkiGray-light md:w-e116"
							>
								<div className="flex w-28 md:w-auto md:justify-center">Harga</div>
								<div className="w-3 md:hidden">:</div>
								<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
									<CompText
										_ref={iRefHargaEdit}
										_dataType="ribuan"
										_value={sDataNotaEdit.harga}
										_onChange={(xValue) => {
											setSDataNotaEdit((xState) => {
												let iState = { ...xState };
												iState.harga = xValue;
												return iState;
											});
										}}
										_onKeyEnter={() => {
											if (sDataNotaEdit.harga > 0) {
												setSDataNotaEdit((xState) => {
													let iState = { ...xState };
													iState.jumlah = iState.banyak * iState.harga;
													return iState;
												});
											}
											iRefJumlahEdit.current.focus();
											iRefJumlahEdit.current.select();
										}}
									/>
								</div>
							</div>

							{/* =======================JUMLAH edit============================ */}
							<div
								className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             								md:border-solid md:border-genkiGray-light md:w-e116"
							>
								<div className="flex w-28 md:w-auto md:justify-center">Jumlah</div>
								<div className="w-3 md:hidden">:</div>
								<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
									<CompText
										_ref={iRefJumlahEdit}
										_dataType="ribuan"
										_value={sDataNotaEdit.jumlah}
										_onChange={(xValue) => {
											setSDataNotaEdit((xState) => {
												let iState = { ...xState };
												iState.jumlah = xValue;
												return iState;
											});
										}}
										_onKeyEnter={() => {
											if (sDataNotaEdit.jumlah > 0) {
												setSDataNotaEdit((xState) => {
													// let iState = { ...xState };
													// iState.harga = iState.jumlah / iState.banyak;

													let iState = { ...xState };
													let iHarga1 = (iState.jumlah / iState.banyak).toFixed();
													let iHarga2 = (iState.jumlah / iState.banyak).toFixed(2);
													if (parseFloat(iHarga1) !== parseFloat(iHarga2)) {
														iHarga1 = iHarga2;
													}
													iState.harga = iHarga1;
													return iState;
												});

												iRefUpdateNota.current.focus();
											}
										}}
									/>
								</div>
							</div>
						</div>

						{/* ============================BUTTON ACTION EDIT======================== */}
						<div className="flex w-full bg-genkiGray justify-evenly p-1">
							{/* ========================UPDATE edit=============================== */}
							<button
								className="Button h-6"
								ref={iRefUpdateNota}
								onClick={() => {
									setSRsDataNota((xState) => {
										let iState = { ...xState };
										let iUraian = iState.eUraian;
										iUraian[sNotaEdit.index].banyak = sDataNotaEdit.banyak;
										iUraian[sNotaEdit.index].satuan = sDataNotaEdit.satuan;
										iUraian[sNotaEdit.index].nama_barang = sDataNotaEdit.nama_barang;
										iUraian[sNotaEdit.index].harga = sDataNotaEdit.harga;
										iUraian[sNotaEdit.index].jumlah = sDataNotaEdit.jumlah;

										iState.eUraian = iUraian;
										return iState;
									});

									setSNotaEdit((xState) => {
										let iState = { ...xState };
										iState.status = false;
										return iState;
									});
									iRefBanyak.current.focus();
								}}
							>
								Update
							</button>
							{/* ========================DELETE edit=============================== */}
							<button
								className="Button h-6"
								onClick={() => {
									setSRsDataNota((xState) => {
										let iState = { ...xState };
										let iUraian = iState.eUraian;
										iUraian.splice(sNotaEdit.index, 1);
										iState.eUraian = iUraian;
										return iState;
									});
									setSNotaEdit((xState) => {
										let iState = { ...xState };
										iState.status = false;
										return iState;
									});
									iRefBanyak.current.focus();
								}}
							>
								Delete
							</button>
							{/* ========================Cancel Edit=============================== */}
							<button
								className="Button h-6"
								onClick={() => {
									setSNotaEdit((xState) => {
										let iState = { ...xState };
										iState.status = false;
										return iState;
									});
									iRefBanyak.current.focus();
								}}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			) : (
				""
			)}
			{/* ==========================END EDIT NOTA========================================= */}

			{/* ==============================NOTA===================================== */}
			<div className="w-3 hidden md:flex">:</div>
			{/* =================================================================== */}

			{/* =======================NO NATA============================================ */}
			<div className="WadahFix flex flex-col flex-1 p-1">
				<div className="flex p-1">
					<div className="w-28 md:w-20">No Nota</div>
					<div className="w-3">:</div>
					<div className="Text flex  items-center w-auto px-2 bg-genkiGray-light">
						{`${props._noNota} - ${props._debetRef}`}
					</div>
				</div>

				{/* ==================NOTA====================================================== */}
				<div className="flex flex-col md:flex-row ">
					{/* =========================BANYAK============================================= */}
					<div
						className="flex md:flex-col border-transparent border-l-2 border-t-2 border-r-2 md:border-r-1 border-b-2
             			md:border-solid md:border-genkiGray-light"
					>
						<div className="flex w-28 md:w-auto md:justify-center">Banyak</div>
						<div className="w-3 md:hidden">:</div>
						<div className="flex flex-1 md:w-e61 md:bg-genkiGray-cl_01">
							<CompText
								_ref={iRefBanyak}
								_dataType="ribuan"
								_value={sDataNota.banyak}
								_onChange={(xValue) => {
									setSDataNota((xState) => {
										let iState = { ...xState };
										iState.banyak = xValue;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									if (sDataNota.banyak > 0) {
										iRefSatuan.current.focus();
										iRefSatuan.current.select();
									} else {
										if (props._onKeyEnter) {
											props._onKeyEnter();
										}
									}
								}}
							/>
						</div>
					</div>

					{/* =========================SATUAN============================================= */}
					<div
						className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             						md:border-solid md:border-genkiGray-light md:w-e112"
					>
						<div className="flex w-28 md:w-auto md:justify-center">Satuan</div>
						<div className="w-3 md:hidden">:</div>
						<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
							<CompText
								_ref={iRefSatuan}
								_value={sDataNota.satuan}
								_onChange={(xValue) => {
									setSDataNota((xState) => {
										let iState = { ...xState };
										iState.satuan = xValue;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									iRefNamaBarang.current.focus();
								}}
							/>
						</div>
					</div>

					{/* =========================NAMA BARANG============================================= */}
					<div
						className="flex md:flex-1 md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
            						 md:border-solid md:border-genkiGray-light"
					>
						<div className="flex w-28 md:w-auto md:justify-center">Nama Barang</div>
						<div className="w-3 md:hidden">:</div>
						<div className="flex flex-1   md:bg-genkiGray-cl_01">
							<CompText
								_ref={iRefNamaBarang}
								_value={sDataNota.nama_barang}
								_onChange={(xValue) => {
									setSDataNota((xState) => {
										let iState = { ...xState };
										iState.nama_barang = xValue;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									if (sDataNota.nama_barang.length > 0) {
										iRefHarga.current.focus();
									}
								}}
							/>
						</div>
					</div>

					{/* =========================HARGA============================================== */}
					<div
						className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             						md:border-solid md:border-genkiGray-light md:w-e112"
					>
						<div className="flex w-28 md:w-auto md:justify-center">Harga</div>
						<div className="w-3 md:hidden">:</div>
						<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
							<CompText
								_ref={iRefHarga}
								_dataType="ribuan"
								_value={sDataNota.harga}
								_onChange={(xValue) => {
									setSDataNota((xState) => {
										let iState = { ...xState };
										iState.harga = xValue;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									if (sDataNota.harga > 0) {
										setSDataNota((xState) => {
											let iState = { ...xState };
											iState.jumlah = iState.banyak * iState.harga;
											return iState;
										});
									}
									iRefJumlah.current.focus();
									iRefJumlah.current.select();
								}}
							/>
						</div>
					</div>

					{/* =========================JUMLAH============================================= */}
					<div
						className="flex md:flex-col border-transparent border-l-1 border-t-2 border-r-2 md:border-r-1 border-b-2
             			md:border-solid md:border-genkiGray-light md:w-e112"
					>
						<div className="flex w-28 md:w-auto md:justify-center">Jumlah</div>
						<div className="w-3 md:hidden">:</div>
						<div className="flex flex-1 md:w-full  md:bg-genkiGray-cl_01">
							<CompText
								_ref={iRefJumlah}
								_dataType="ribuan"
								_value={sDataNota.jumlah}
								_onChange={(xValue) => {
									setSDataNota((xState) => {
										let iState = { ...xState };
										iState.jumlah = xValue;
										return iState;
									});
								}}
								_onKeyEnter={() => {
									if (sDataNota.jumlah > 0) {
										setSDataNota((xState) => {
											let iState = { ...xState };
											let iHarga1 = (iState.jumlah / iState.banyak).toFixed();
											let iHarga2 = (iState.jumlah / iState.banyak).toFixed(2);
											if (parseFloat(iHarga1) !== parseFloat(iHarga2)) {
												iHarga1 = iHarga2;
											}
											iState.harga = iHarga1;
											return iState;
										});
										iRefOkNata.current.focus();
									}
								}}
							/>
						</div>
					</div>

					{/* ==========================OK / isi nota============================================ */}
					<div
						className="flex flex-col justify-center  h-full  border-transparent border-l-1 border-t-2 border-r-2 md:border-r-2 border-b-2
             			md:border-solid md:border-genkiGray-light "
					>
						<div className="hidden md:flex w-full justify-center">Act</div>
						<div className="flex md:flex-1   md:bg-genkiGray-cl_01">
							{/* ====================BUTON OK Isi Nota================================================== */}
							<button
								ref={iRefOkNata}
								className="Button w-10 h-6"
								style={{ margin: "2px 0.5px" }}
								onClick={() => {
									setSRsDataNota((xState) => {
										let iState = { ...xState };
										iState.eNoNota = props._noNota;
										if (iState.eUraian.length === 0) {
											iState.eUraian = [
												{
													banyak: sDataNota.banyak,
													satuan: sDataNota.satuan,
													nama_barang: sDataNota.nama_barang,
													harga: sDataNota.harga,
													jumlah: sDataNota.jumlah,
												},
											];
										} else {
											let iData = {
												banyak: sDataNota.banyak,
												satuan: sDataNota.satuan,
												nama_barang: sDataNota.nama_barang,
												harga: sDataNota.harga,
												jumlah: sDataNota.jumlah,
											};
											let iUraian = [...iState.eUraian, iData];
											iState.eUraian = iUraian;
										}
										return iState;
									});

									iRefBanyak.current.focus();
								}}
							>
								OK
							</button>
						</div>
					</div>
				</div>

				{/* ==================TABLE Isi Nota==================================================== */}
				<div>
					<table className="Table w-full">
						<thead className="md:hidden">
							<tr className="flex w-full">
								<th className="w-9">Byk</th>
								<th className="w-10">Sat</th>
								<th className="flex-1">Nama Barang</th>
								<th className="w-16">Harga</th>
								<th className=" w-16">Juml</th>
								<th className="w-11">Act</th>
							</tr>
						</thead>
						<tbody className="flex  flex-col-reverse">
							{/* =============Map Isi Nota========================================================= */}
							{sRsDataNota.eUraian.map((xData, xIndex) => {
								return (
									<tr key={xIndex} className="flex">
										<td className="w-9 md:w-16">
											<div className="w-full pl-1  text-right">
												<p className="truncate">{Env.format.uang(String(xData.banyak))}</p>
											</div>
										</td>
										<td className="w-10 md:w-28">
											<div className="w-full">
												<p className="truncate">{xData.satuan}</p>
											</div>
										</td>
										<td className="flex-1">
											<div>{xData.nama_barang}</div>
										</td>
										<td className="w-16 md:w-28">
											<div className="w-full  text-right">
												<p className="truncate">{Env.format.uang(String(xData.harga))}</p>
											</div>
										</td>
										<td className="w-16 md:w-28">
											<div className="w-full  text-right">
												<p className="truncate">{Env.format.uang(String(xData.jumlah))}</p>
											</div>
										</td>
										<td className="flex w-11 items-start md:items-center">
											{/* ================BUTTON action ====================================================== */}
											<button
												className="Button w-10 h-5"
												onClick={() => {
													setSNotaEdit((xState) => {
														let iState = { ...xState };
														iState.status = true;
														iState.index = xIndex;
														return iState;
													});
												}}
											>
												{xIndex + 1}
											</button>
										</td>
									</tr>
								);
							})}
							<tr className="flex">
								<td className="w-9 md:w-16"></td>
								<td className="w-10 md:w-28"></td>
								<td className="flex-1"></td>
								<td className="w-16 md:w-28 text-center">TOTAL :</td>
								<td className="w-16 md:w-28 text-right">
									{Env.format.uang(sTotalNota)}
								</td>
								<td className="flex w-11 items-start md:items-center"></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			{/* =================================================================== */}
		</Fragment>
	);
};

export default Nota;
