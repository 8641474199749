import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
// import CompAlert from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";
import CompAlertDelete from "../../../../../component/arjAlert";

const TarikTabungan = (props) => {
  const [sTanggal, setSTanggal] = useState("");
  const mNewDataTarikTabungan = {
    nama: "",
    no_rekening: "",
    tarik_tabungan: 0,
    keterangan: "",
    ref: useRef(null),
  };
  const [sDataTarikTabungan] = useState({
    mNewDataTarikTabungan,
  });
  const [sRsDocumentTabungan, setsRsDocumentTabungan] = useState([]);
  const [sLoading, setSLoading] = useState(true);
  const [sRsRekeningTabunganWajib, setsRsRekeningTabunganWajib] = useState([]);

  const iDataTabunganWajib = {
    id: null,
    Nama: "",
    Alamat: "",
    Kelompok: "",
    Saldo: 0,
    index: -1,
  };
  const [sDataTabunganWajib, setsDataTabunganWajib] =
    useState(iDataTabunganWajib);

  const [sKeterangan, setsKeterangan] = useState("");

  const [sNamaFocus, setSNamaFocus] = useState(false);
  // const [sAlert, setSAlert] = useState({
  // 	title: "",
  // 	keterangan: "",
  // 	show: false,
  // });
  const [sSelect, setSSelect] = useState({
    index: -1,
    tabungan_wajib_id: null,
    members_name: "",
  });

  const refTanggal = useRef();
  const refNama = useRef();
  const refKeterangan = useRef();
  const refTarik = useRef();
  const refBatal = useRef();

  useEffect(() => {
    if (sDataTabunganWajib.id) {
      refKeterangan.current.focus();
    }
  }, [sDataTabunganWajib]);

  const fGetPreData = async () => {
    try {
      const iTanggal = await Axios.get(`${props.state.sPages.url}/date`);
      setSTanggal(iTanggal.data);

      // Realisasi piutang harus berdasarkan atas kepemilikan rekening tabungan
      const iRsRekeningTabunganWajib = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan_wajib`,
        {
          params: { req: "members" },
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      // console.log(iRsRekeningTabunganWajib.data.message);
      setsRsRekeningTabunganWajib(
        iRsRekeningTabunganWajib.data.message.members
      );
      setsRsDocumentTabungan(
        iRsRekeningTabunganWajib.data.message.tabungan_wajib
      );

      setSLoading(false);
      refNama.current.focus();
    } catch (xErr) {
      // console.log(xErr);
      alert("Error");
    }
  };

  useEffect(() => {
    fGetPreData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      sDataTarikTabungan.ref.current.focus();
    } catch (xErr) {}
  }, [sDataTarikTabungan]);

  try {
    return (
      <div className="h-screen w-full px-2">
        {sLoading ? <CompLoading /> : ""}
        {sSelect.tabungan_wajib_id ? (
          <CompAlertDelete
            _title={`Hapus Data Penarikan No: ${sSelect.index + 1}`}
            _keterangan={`Apakah Benar Akan Membatalkan Penarikan Tabungan Wajib an: ${sSelect.members_name} ?`}
            _buttonCancel="Tidak"
            _buttonOk="Ya"
            _onCancel={() => {
              setSSelect((xState) => {
                let iState = { ...xState };
                iState.tabungan_wajib_id = null;
                return iState;
              });
              refNama.current.focus();
              refNama.current.select();
            }}
            _onOk={() => {
              if (!sLoading) {
                setSLoading(true);
                Axios.delete(
                  `${props.state.sPages.url}${Env.eRouteApi}/bendahara/tarik_tabungan_wajib`,

                  {
                    data: { id: sSelect.tabungan_wajib_id },
                    headers: {
                      authorization: `bearer ${props.state.sToken}`,
                    },
                  }
                )
                  .then((iRes) => {
                    if (iRes.status === Env.eHttpStatus.eOk) {
                      setsRsRekeningTabunganWajib((xState) => {
                        const iState = [iRes.data.message, ...xState];
                        return iState;
                      });
                      setsRsDocumentTabungan((xState) => {
                        const iState = [...xState];
                        iState.splice(sSelect.index, 1);
                        return iState;
                      });
                      // console.log(iRes.data);
                    } else {
                      alert("Tarik Tabungan Wajib Gagal");
                    }
                    setSLoading(false);
                    setSSelect((xState) => {
                      let iState = { ...xState };
                      iState.index = -1;
                      iState.tabungan_wajib_id = null;
                      return iState;
                    });
                    refTanggal.current.focus();
                  })
                  .catch((xErr) => {
                    alert("Simpam Data Tabungan Gagal");
                    setSLoading(false);
                    setSSelect((xState) => {
                      let iState = { ...xState };
                      iState.tabungan_wajib_id = null;
                      return iState;
                    });
                  });
              }
            }}
          />
        ) : (
          ""
        )}
        <div className="flex flex-col h-full w-full md:p2 ">
          <div className="h-2"></div>
          <div className=" h-auto w-full bg-blue-200 space-y-2 rounded-lg shadow-md md:p-2">
            {/* TITLE */}
            <div className="flex flex-col  w-full mx-auto  items-center ">
              <p>TARIK TABUNGAN WAJIB</p>
            </div>
            <hr />

            {/*============== Tanggal ================  */}
            <div className=" flex">
              <div className=" w-20 md:w-32">Tanggal</div>
              <div className=" w-1 text-center">:</div>
              <div className="ml-1 w-64 md:w-80 ">
                <input
                  type="text"
                  ref={refTanggal}
                  className="Text bg-gray-100 "
                  value={sTanggal}
                  readOnly={true}
                  onKeyUp={(xKey) => {
                    if (xKey.nativeEvent.key === "Enter") {
                      refNama.current.focus();
                    }
                  }}
                />
              </div>
            </div>

            {/* ============= Nama ==================== */}
            <div className=" flex">
              <div className=" w-20 md:w-32">Nama</div>
              <div className=" w-1 text-center">:</div>
              <div className={sNamaFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}>
                <CompTextDs
                  _ref={refNama}
                  _value={sDataTarikTabungan.nama}
                  _dataSource={sRsRekeningTabunganWajib}
                  _onKeyEnter={(xData) => {
                    for (let i = 0; i < sRsRekeningTabunganWajib.length; i++) {
                      const iElement = sRsRekeningTabunganWajib[i];

                      if (iElement.id === xData[0]) {
                        iElement.index = i;
                        setsDataTabunganWajib(iElement);
                        return;
                      }
                    }
                  }}
                  _onFocus={() => {
                    setSNamaFocus(true);
                    // setsDataTabungan(mNewDataTabungan);
                    // setsDataTarikTabungan(mNewDataTarikTabungan);
                  }}
                  _onLossFocus={() => {
                    setSNamaFocus(false);
                  }}
                />
              </div>
            </div>
          </div>

          {/* ===========DATA PENARIKAN===================================== */}
          {sDataTabunganWajib.id ? (
            <div className="Modal z-20 flex justify-center items-center overflow-x-auto">
              <div
                className="flex flex-col bg-blue-400 rounded-xl 
								 border-black border-solid border-1"
              >
                <div className="flex justify-center p-2 ">
                  DATA PENARIKAN TABUNGAN WAJIB
                </div>
                <div className=" bg-white p-2 ">
                  <div className="flex mt-1">
                    <div className=" w-24">Nama</div>
                    <div className=" w-3">:</div>
                    <div className=" w-80 truncate">
                      {sDataTabunganWajib.Nama}
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" w-24">Alamat</div>
                    <div className=" w-3">:</div>
                    <div className=" w-80 truncate">
                      {sDataTabunganWajib.Alamat}
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" w-24">Kelompok</div>
                    <div className=" w-3">:</div>
                    <div className=" w-80 truncate">
                      {sDataTabunganWajib.Kelompok}
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" w-24">Saldo</div>
                    <div className=" w-3">:</div>
                    <div className=" w-80 truncate">
                      {Env.format.uang(sDataTabunganWajib.Saldo)}
                    </div>
                  </div>
                  <div className="flex">
                    <div className=" w-24">Keterangan</div>
                    <div className=" w-3">:</div>
                    <div className=" flex-1">
                      <CompText
                        _ref={refKeterangan}
                        _value={sKeterangan}
                        _onChange={(xData) => {
                          setsKeterangan(xData);
                        }}
                        _onKeyEnter={() => {
                          refTarik.current.focus();
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className=" bg-blue-200 rounded-b-xl flex justify-evenly p-2">
                  <button
                    className="Button h-8 w-20"
                    ref={refTarik}
                    onClick={() => {
                      if (!sLoading) {
                        setSLoading(true);
                        Axios.put(
                          `${props.state.sPages.url}${Env.eRouteApi}/bendahara/tarik_tabungan_wajib`,
                          {
                            id: sDataTabunganWajib.id,
                            nama: sDataTabunganWajib.Nama,
                            tarik_tabungan: sDataTabunganWajib.Saldo,
                            keterangan: sKeterangan,
                          },
                          {
                            headers: {
                              authorization: `bearer ${props.state.sToken}`,
                            },
                          }
                        )
                          .then((xRes) => {
                            if (xRes.status === Env.eHttpStatus.eOk) {
                              setsRsDocumentTabungan(xRes.data.message);

                              setsRsRekeningTabunganWajib((xState) => {
                                const iState = [...xState];
                                iState.splice(sDataTabunganWajib.index, 1);
                                return iState;
                              });

                              setsDataTabunganWajib(iDataTabunganWajib);
                            } else alert("Error");
                            // console.log(xRes.data);
                            setSLoading(false);
                          })
                          .catch(() => {
                            alert("Error");
                            setSLoading(false);
                          });
                      }
                    }}
                  >
                    Tarik
                  </button>
                  <button
                    className="Button h-8 w-20"
                    ref={refBatal}
                    onClick={() => {
                      setsDataTabunganWajib(iDataTabunganWajib);
                    }}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
          ) : null}

          {/* ============DOCUMENT TARIK TABUNGAN ========================== */}
          <div className="flex justify-center w-full mt-4 bg-blue-100 rounded-t-md">
            <div className="text-lg"> Dokumen Tarik Tabungan </div>
          </div>

          {/* TABLE */}
          <div className="flex-1 w-full   overflow-y-scroll bg-blue-100">
            <table className="Table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>Nama</th>
                  <th>Tarik Tabungan</th>
                  <th>Saldo</th>
                  <th>Keterangan</th>
                </tr>
              </thead>
              <tbody>
                {sRsDocumentTabungan
                  .map((xData, xIndex) => {
                    let iTanggal = xData.id.substring(0, 8);
                    iTanggal =
                      iTanggal.substring(6, 8) +
                      "-" +
                      iTanggal.substring(4, 6) +
                      "-" +
                      iTanggal.substring(0, 4);
                    return (
                      <tr
                        key={xIndex}
                        style={
                          sSelect.index === xIndex
                            ? { backgroundColor: Env.color.orangeRed02 }
                            : null
                        }
                        onClick={() => {
                          setSSelect((xState) => {
                            const iState = { ...xState };
                            iState.index = xIndex;
                            iState.tabungan_wajib_id = xData.id;
                            iState.members_name = xData.nama;
                            // console.log(iState);
                            return iState;
                          });
                        }}
                      >
                        <td>{xIndex + 1}</td>
                        <td>{iTanggal}</td>
                        <td>{xData.nama}</td>
                        <td align="right">{Env.format.uang(xData.debet)}</td>
                        <td align="right">{Env.format.uang(xData.saldo)}</td>
                        <td>{xData.keterangan}</td>
                      </tr>
                    );
                  })
                  .reverse()}
              </tbody>
            </table>
          </div>
          <div className=" h-16 w-full "></div>
        </div>
      </div>
    );
  } catch (xErr) {
    return null;
  }
};

export default GlobalStateConsumer(TarikTabungan);
