import React from "react";
import { useRef, useEffect } from "react";

const Index = (props) => {
	const mRef = useRef(null);

	useEffect(() => {
		mRef.current.focus();
	}, []);

	return (
		<div className="Modal z-100">
			<div className="flex flex-col w-96 h-52 bg-genkiGray-lightest m-auto rounded-xl border-genkiBlue-dark border-solid border-2">
				<div
					// ==== TITLE ====================
					className="flex justify-center items-center rounded-t-xl h-10 bg-gradient-to-t from-genkiBlue-light to-genkiBlue border-1 border-solid border-genkiBlue-dark"
				>
					{props._title}
				</div>

				<div
					// ======= KETERANGAN =======================
					className="flex flex-1 items-center justify-center w-full text-center border-1 border-solid border-genkiBlue-dark"
				>
					<p>{props._keterangan}</p>
				</div>

				<div className="flex items-center justify-evenly h-10 w-full border-1 border-solid border-genkiBlue-dark rounded-b-xl">
					<button
						className="Button w-20 h-8"
						ref={mRef}
						onClick={() => {
							if (props._onOk) {
								props._onOk();
							}
						}}
					>
						{props._buttonOk || "OK"}
					</button>

					{props._buttonCancel ? (
						<button
							className="Button w-20 h-8"
							onClick={() => {
								if (props._onCancel) {
									props._onCancel();
								}
							}}
						>
							{props._buttonCancel}
						</button>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};
export default Index;
