import { useState, useEffect, useRef } from "react";
import CompPrint from "react-to-print";

import Env from "../../../setting/env";
import CompExel from "../../../component/exportToExel";

const BukuBesar = (props) => {
	const [sData, setSData] = useState([]);
	const [sExel, setSExel] = useState([]);
	let refComponent = useRef(null);

	const fCol = (xValue, xColMarger = 1) => {
		return { value: xValue, colMarger: xColMarger };
	};

	const fGetIsiNota = (xNota) => {
		let iText = "";
		let iTotal = 0;
		xNota.map((xData, xIndex) => {
			let iKoma = ",";
			if (xIndex === 0) {
				iKoma = "";
			}
			iText += `${iKoma} ${Env.format.uang(xData.banyak)}${xData.satuan} ${
				xData.nama_barang
			} * ${Env.format.uang(xData.harga)} = ${Env.format.uang(xData.jumlah)}`;
			iTotal += xData.jumlah;
			return "";
		});
		return `${iText} , Jumlah = ${Env.format.uang(iTotal)}`;
	};

	useEffect(() => {
		const iData = props._data.data;
		const iNewData = [];
		if (iData.length > 0) {
			let iJumlah = 0;
			let iRef = "";
			let iNotaRef = "";
			for (let i = 0; i < iData.length; i++) {
				if (props._data.jenis === "Penerimaan") {
					iRef = iData[i].debet;
					iNotaRef = iData[i].kredit_ref;
				} else {
					iRef = iData[i].kredit;
					iNotaRef = iData[i].debet_ref;
				}
				iJumlah += iData[i].nilai;
				// ========================
				let iNota = "";
				if (iData[i].nota.length > 0) {
					iNota = ` ; Nota No.${iData[i].no_nota}${iNotaRef}: ${fGetIsiNota(
						iData[i].nota
					)} `;
				}
				// console.log(iNota);

				// ===========================
				iNewData.push({
					tanggal: Env.efFormatTanggal(iData[i].tanggal),
					keterangan: iData[i].keterangan + iNota,
					nilai: Env.format.uang(iData[i].nilai),
					jumlah: Env.format.uang(iJumlah),
					ref: iRef,
				});
			}
		}

		setSData(iNewData);

		// ======== Untuk Export ke EXEL===================================================
		let iExel = [
			{
				row: 1,
				col: [
					fCol(props._data.jenis, 2),
					fCol(props._data.akun),
					fCol(`Lpr: ${Env.efFormatTanggal(props._tanggal)}`, 3),
				],
			},
			{
				row: 3,
				col: [
					fCol("No"),
					fCol("Tanggal"),
					fCol("Keterangan"),
					fCol("Nilai"),
					fCol("Jumlah"),
					fCol("Ref"),
				],
			},
		];
		let iRow = 3;
		for (let i = 0; i < iNewData.length; i++) {
			iRow += 1;
			iExel.push({
				row: iRow,
				col: [
					fCol(i + 1),
					fCol(iNewData[i].tanggal),
					fCol(iNewData[i].keterangan),
					fCol(iNewData[i].nilai),
					fCol(iNewData[i].jumlah),
					fCol(iNewData[i].ref),
				],
			});
		}
		setSExel(iExel);
		// console.log(iNewData);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="fixed flex flex-col z-10 w-full h-screen bg-red-100 top-10">
			<div
				className="flex absolute right-1 justify-center
                             items-center w-32 h-10 space-x-1"
			>
				<CompExel
					_data={sExel}
					_fileName={`${props._tanggal}_${props._data.jenis}_${props._data.akun}`}
				/>
				<CompPrint
					trigger={() => {
						return <div className="Button h-6">cetak</div>;
					}}
					content={() => refComponent.current}
				/>
				<div
					className="flex justify-center items-center
                                bg-green-300 rounded-xl w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100"
					onClick={() => {
						props._onClose();
					}}
				>
					X
				</div>
			</div>
			<div
				className="flex flex-col w-full h-full overflow-y-scroll"
				ref={refComponent}
			>
				<div className="flex w-full h-8 bg-yellow-200 items-center mt-8 md:mt-1">
					<div className="flex items-center ml-2">{props._data.jenis}</div>
					<div className="flex-1 flex justify-center items-center truncate mx-2">
						{props._data.akun}
					</div>
					<div className="flex justify-center mr-1">{`Lpr: ${Env.efFormatTanggal(
						props._tanggal
					)}`}</div>
					<div className="flex md:w-32 "></div>
				</div>
				<div className="flex flex-col w-full justify-center md:p-1">
					<table className="Table">
						<thead>
							<tr>
								<th>No </th>
								<th>Tanggal</th>
								<th>Keterangan</th>
								<th>Nilai</th>
								<th>Jumlah</th>
								<th>Ref</th>
							</tr>
						</thead>
						<tbody>
							{sData.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td className="text-right w-10">{xIndex + 1}</td>
										<td className="text-center w-24">{xData.tanggal}</td>
										<td>{xData.keterangan}</td>
										<td className="text-right w-24">{xData.nilai}</td>
										<td className="text-right w-24">{xData.jumlah}</td>
										<td className="text-center w-10">{xData.ref}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="h-14"></div>
		</div>
	);
};

export default BukuBesar;
