import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";

import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompLoading from "../../../../../component/arjLoading";
import CompAlertSimpan from "../../../../../component/arjAlert";
// import CompAlertUpdate from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";

const Kegiatan = (props) => {
  const iTanggal = Env.efTanggal();

  const refKegiatan = useRef(null);
  const refJenis = useRef(null);
  const refNilai = useRef(null);
  const refSimpan = useRef(null);
  const refTanggal = useRef(null);

  const [sRsJenisKegiatan, setSRsJenisKegiatan] = useState([]);
  const [sRsNilaiKegiatan, setSRsNilaiKegiatan] = useState([]);
  const [sRsGroup, setSRsGroup] = useState([]);
  const [sRsNewDataJenisKegiatan, setSRsNewDataJenisKegiatan] = useState([]);
  const [sRsKegiatan, setSRsKegiatan] = useState([]);
  const [sSaved, setSSaved] = useState(false);

  // const [sRsDataJenisKegiatan, setSRsDataJenisKegiatan] = useState([]);
  const [sJenisKegiatan, setSJenisKegiatan] = useState({ id: 0, nama: "" });
  const [sKegiatan, setSKegiatan] = useState("");
  const [sTanggal, setSTanggal] = useState(iTanggal);
  const [sNilaiKegiatan, setSNilaiKegiatan] = useState(0);
  const [sLoading, setSLoading] = useState(true);
  // const [sJenisFocus, setSJenisFocus] = useState(false);
  const [sAlertSimpan, setSAlertSimpan] = useState(false);
  const [sDelete, setSDelete] = useState({ index: -1, show: false });

  const fPeserta = (xRsPeserta) => {
    let iData = "";
    for (let i = 0; i < xRsPeserta.length; i++) {
      iData += xRsPeserta[i].group.nama + ", ";
    }
    return iData;
  };

  const fJenis = (xRsJenisKegiatan) => {
    let iData = "";
    for (let i = 0; i < xRsJenisKegiatan.length; i++) {
      const iNilai = xRsJenisKegiatan[i].nilai;
      const iJenis = xRsJenisKegiatan[i].kegiatan_jenis.nama;
      const iTotalNilai = xRsJenisKegiatan[i].total_nilai;
      const iTotalAnggota = xRsJenisKegiatan[i].total_anggota;
      // iData += iJenis + ": " + Env.format.uang(iNilai) + ", Total: " + "; ";
      iData += `${iJenis}:@ ${Env.format.uang(
        iNilai
      )}, Anggota: ${Env.format.uang(iTotalAnggota)}, Total: ${Env.format.uang(
        iTotalNilai
      )}; `;
    }
    return iData;
  };

  const fNilaiJenisKegiatan = (xJenisKegiatanID) => {
    for (let i = 0; i < sRsNilaiKegiatan.length; i++) {
      if (sRsNilaiKegiatan[i].jenis_id === xJenisKegiatanID) {
        return sRsNilaiKegiatan[i].nilai;
      }
    }
  };

  useEffect(() => {
    try {
      const fGetData = async () => {
        const iData = await Axios.get(
          `${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
          {
            params: { req: "jenis_kegiatan", tanggal: sTanggal },
            headers: {
              authorization: `bearer ${props.state.sToken}`,
            },
          }
        );

        if (iData.status === Env.eHttpStatus.eOk) {
          setSRsJenisKegiatan([
            { id: "", nama: "", keterangan: "" },
            ...iData.data.message.rsJenisKegiatan,
          ]);

          setSRsNilaiKegiatan(iData.data.message.rsNilaiKegiatan);
          // console.log(iData.data.message.rsNilaiKegiatan);

          const iRsGroup = iData.data.message.rsGroup;
          for (let i = 0; i < iRsGroup.length; i++) {
            iRsGroup[i].pelaksana = true;
          }
          // console.log(iRsGroup);
          setSRsGroup(iRsGroup);
          setSRsKegiatan(iData.data.message.rsKegiatan);
          // console.log(iData.data.message.rsKegiatan);
        }
        setSLoading(false);
      };
      fGetData();
      refKegiatan.current.focus();
    } catch (xErr) {}
    // eslint-disable-next-line
  }, [sTanggal]);
  return (
    <div className="flex flex-col w-full h-screen md:p-2">
      {sLoading ? <CompLoading /> : null}
      {sAlertSimpan ? (
        <CompAlertSimpan
          _title="Menyimpan Data Jenis Kegiatan"
          _keterangan="Apakah data sudah benar ?"
          _buttonCancel="Batal"
          _onCancel={() => {
            setSAlertSimpan(false);
            refKegiatan.current.focus();
            refKegiatan.current.select();
          }}
          _onOk={() => {
            if (!sLoading) {
              setSLoading(true);
              const iGroup = [];
              for (let i = 0; i < sRsGroup.length; i++) {
                if (sRsGroup[i].pelaksana) {
                  iGroup.push(sRsGroup[i].id);
                }
              }

              const iJenisKegiatan = [];
              for (let i = 0; i < sRsNewDataJenisKegiatan.length; i++) {
                iJenisKegiatan.push({
                  id: sRsNewDataJenisKegiatan[i].jenis_kegiatan.id,
                  nilai: sRsNewDataJenisKegiatan[i].nilai,
                });
              }
              Axios.post(
                `${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,
                {
                  req: "post",
                  tanggal: sTanggal,
                  kegiatan: sKegiatan,
                  group: iGroup,
                  jenis_kegiatan: iJenisKegiatan,
                },
                {
                  headers: {
                    authorization: `bearer ${props.state.sToken}`,
                  },
                }
              ).then((xRes) => {
                console.log("--------------------------------------------");
                console.log(xRes.data);
                console.log("--------------------------------------------");
                if (xRes.status === Env.eHttpStatus.eOk) {
                  setSRsKegiatan(xRes.data.message);
                  setSSaved(true);
                } else {
                  alert("Error");
                }
                setSAlertSimpan(false);
                setSLoading(false);
              });
            }
          }}
        />
      ) : null}

      {sDelete.show ? (
        <CompAlertDelete
          _title="Hapus Data Kegiatan"
          _keterangan={`Apakah benar akan menhapus  ${sDelete.index + 1} ?`}
          _buttonCancel="Batal"
          _onCancel={() => {
            setSDelete({ index: -1, show: false });
          }}
          _onOk={() => {
            if (!sLoading) {
              setSDelete({ index: -1, show: false });
              setSLoading(true);
              Axios.delete(
                `${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,

                {
                  data: { id: sRsKegiatan[sDelete.index].id },
                  headers: {
                    authorization: `bearer ${props.state.sToken}`,
                  },
                }
              )
                .then((xRes) => {
                  if (xRes.status === Env.eHttpStatus.eOk) {
                    setSRsKegiatan(xRes.data.message);
                    setSRsNewDataJenisKegiatan([]);
                    setSKegiatan("");
                    setSSaved(false);
                    refKegiatan.current.focus();
                  } else {
                    alert("Error");
                  }
                  setSDelete({ index: -1, show: false });
                  setSLoading(false);
                  // console.log(xRes.data);
                })
                .catch((xErr) => {
                  setSDelete({ index: -1, show: false });
                  setSLoading(false);
                });
            }
          }}
        />
      ) : null}

      <div className=" flex justify-center  md:text-xl w-full mt-2 h-6 md:h-8">
        Buat Kegiatan Bersama
      </div>
      <div className="flex-1 w-full bg-blue-300 overflow-y-scroll rounded-lg relative">
        {sSaved ? (
          <div className=" bg-genkiGray-darkest bg-opacity-20 z-20 w-full h-full absolute"></div>
        ) : null}

        <div className=" flex justify-center  md:text-lg w-full">
          {sSaved ? "Data Tersimpan" : "Data Baru"}
        </div>
        <hr />

        {/* Tanggal */}
        <div className="flex w-full mt-2 md:px-2">
          <div className=" w-20">Tanggal</div>
          <div className=" flex justify-center w-4 ">:</div>
          <div className="flex-1">
            <input
              className="Text flex h-8 w-40"
              ref={refTanggal}
              type="date"
              value={sTanggal}
              onChange={(xData) => {
                setSTanggal(xData.target.value);
                // iRefDebet.current.focus();
              }}
              onKeyUp={(xEvent) => {
                if (xEvent.nativeEvent.key === "Enter") {
                  refKegiatan.current.focus();
                  refKegiatan.current.select();
                }
              }}
              onClick={() => {
                refKegiatan.current.focus();
                refKegiatan.current.select();
              }}
            />
          </div>
        </div>

        {/* Kegiatan */}
        <div className="flex w-full mt-2 md:px-2">
          <div className=" w-20">Kegiatan</div>
          <div className=" flex justify-center w-4 ">:</div>
          <div className="flex-1">
            <CompText
              _ref={refKegiatan}
              _value={sKegiatan}
              _onChange={(xData) => {
                setSKegiatan(xData);
                // console.log(xData);
              }}
              _onKeyEnterUp={(xData) => {
                // setSKegiatan(xData);
                refJenis.current.focus();
                refJenis.current.select();
              }}
            />
          </div>
        </div>

        {/* Peserta */}
        <div className="flex w-full mt-2 md:px-2">
          <div className=" w-20">Peserta</div>
          <div className=" flex justify-center w-4 ">:</div>
          <div>
            {sRsGroup.map((xData, xIndex) => {
              return (
                <div key={xIndex} className="flex h-6 items-center">
                  <input
                    type="checkbox"
                    key={xIndex}
                    checked={xData.pelaksana}
                    onChange={() => {
                      setSRsGroup((xState) => {
                        let iState = [...xState];
                        let iObj = { ...iState[xIndex] };
                        iObj.pelaksana = !iObj.pelaksana;
                        iState[xIndex] = iObj;
                        return iState;
                      });
                    }}
                  />
                  <div className="flex-1">{xData.nama}</div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Jenis */}
        <div className=" mt-2 md:px-2 w-full bg-blue-100 p-1">
          <div className="flex justify-center w-full pb-1 md:space-x-2">
            <div className="flex flex-col flex-1  md:max-w-4xl">
              <div className="flex justify-center">Jenis</div>
              <div>
                <CompTextDs
                  _ref={refJenis}
                  _dataSource={sRsJenisKegiatan}
                  _value={sJenisKegiatan.nama}
                  _onKeyEnter={(xData) => {
                    if (xData[0] !== "") {
                      setSJenisKegiatan({ id: xData[0], nama: xData[1] });
                      setSNilaiKegiatan(fNilaiJenisKegiatan(xData[0]));
                      refNilai.current.focus();
                      refNilai.current.select();
                    } else if (sRsNewDataJenisKegiatan.length > 0) {
                      refSimpan.current.focus();
                    } else {
                      refKegiatan.current.focus();
                      refKegiatan.current.select();
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col w-28 md:w-44">
              <div className="flex justify-center">Nilai</div>
              <div>
                <CompText
                  _ref={refNilai}
                  _dataType="ribuan"
                  _value={sNilaiKegiatan}
                  _onNilai={(xData) => {
                    setSNilaiKegiatan(xData);
                  }}
                  _onKeyEnterUp={async () => {
                    if (!sLoading && sNilaiKegiatan !== 0) {
                      setSLoading(true);
                      const iGroup = [];
                      for (let i = 0; i < sRsGroup.length; i++) {
                        if (sRsGroup[i].pelaksana) {
                          iGroup.push(sRsGroup[i].id);
                        }
                      }

                      const iTotalPerolehan = await Axios.post(
                        `${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,
                        {
                          req: "get",
                          jenis_kegiatan_id: sJenisKegiatan.id,
                          nilai: sNilaiKegiatan,
                          group: iGroup,
                        },
                        {
                          headers: {
                            authorization: `bearer ${props.state.sToken}`,
                          },
                        }
                      );
                      // console.log(iTotalPerolehan.data.message);

                      const iObjJenisKegiatan = {
                        jenis_kegiatan: { ...sJenisKegiatan },
                        nilai: sNilaiKegiatan,
                        totalPerolehan: iTotalPerolehan.data.message,
                      };
                      setSRsNewDataJenisKegiatan((xState) => {
                        let iState = [...xState];
                        iState.push(iObjJenisKegiatan);
                        // console.log(iState);
                        return iState;
                      });
                      setSJenisKegiatan({ id: "", nama: "" });
                      setSNilaiKegiatan(0);
                      setSLoading(false);
                      refJenis.current.focus();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {/* {sJenisFocus ? <div className=" h-20"></div> : null} */}
          {/* Table Jenis Kegiatan */}
          <table className="Table">
            <thead>
              <tr>
                <th>No</th>
                <th>Jenis</th>
                <th>Nilai</th>
                <th>Total Perolehan</th>
                <th>Hapus</th>
              </tr>
            </thead>

            <tbody>
              {sRsNewDataJenisKegiatan.map((xData, xIndex) => {
                return (
                  <tr key={xIndex}>
                    <td>{xIndex + 1}</td>
                    <td>{xData.jenis_kegiatan.nama}</td>
                    <td>{Env.format.uang(xData.nilai)}</td>
                    <td align="right">
                      {Env.format.uang(xData.totalPerolehan)}
                    </td>
                    <td width={50}>
                      {sSaved ? null : (
                        <button
                          className="Button h-6"
                          onClick={() => {
                            setSRsNewDataJenisKegiatan((xState) => {
                              let iState = [...xState];
                              iState.splice(xIndex, 1);
                              return iState;
                            });
                          }}
                        >
                          Hapus
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* End Table Jenis Kegiatan */}

          <div className="flex w-full justify-end pt-1">
            {sRsNewDataJenisKegiatan.length > 0 && !sSaved ? (
              <div
                className="z-10 rounded-xl p-1  bg-gradient-to-t from-red-200 to-red-400 
							 hover:to-yellow-200"
              >
                <button
                  className="Button  "
                  ref={refSimpan}
                  onClick={() => {
                    setSAlertSimpan(true);
                  }}
                >
                  Simpan
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div className=" max-h-32 overflow-y-scroll">
        <table className="Table">
          <thead>
            <tr>
              <th>No</th>
              <th>Kegiatan</th>
              <th>Peserta</th>
              <th>Jenis</th>
            </tr>
          </thead>
          <tbody>
            {sRsKegiatan.map((xData, xIndex) => {
              return (
                <tr
                  key={xIndex}
                  style={{
                    backgroundColor:
                      sDelete.index === xIndex ? Env.color.orangeRed02 : "",
                  }}
                >
                  <td width={40}>
                    <button
                      className="Button h-5 w-10"
                      onClick={() => {
                        setSDelete({
                          index: xIndex,
                          show: true,
                        });
                      }}
                    >
                      {xIndex + 1}
                    </button>
                  </td>
                  <td>{xData.kegiatan}</td>
                  <td>{fPeserta(xData.kegiatan_peserta)}</td>
                  <td>{fJenis(xData.kegiatan_details)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className=" h-14"></div>
    </div>
  );
};

export default GlobalStateConsumer(Kegiatan);
