// import Laporan from "./laporan";
// import Home from "./home";

// export { Home, Laporan };

import { Fragment, useEffect, useState } from "react";

import {
	BrowserRouter as Router,
	Link,
	// Redirect,
	Route,
	Switch,
} from "react-router-dom";

// State Global Dengan Contex
import { GlobalStateConsumer } from "../../state.global";

import MenuBarger from "../../component/menuBarger";
import Home from "./home";
import Akun from "./akutansi/akun";
import Jurnal from "./akutansi/jurnal";
import PengelolaKeuangan from "./akutansi/pengelola_keuangan";
import Laporan from "./laporan";
import CompAnggota from "./organisasi/anggota";
import CompKelompok from "./organisasi/kelompok";
import CompProgramPiutang from "./keuangan/piutang/programPiutang";
import CompRealisasiPiutang from "./keuangan/piutang/realisasiPiutang";
import CompRestrukturisasiPiutang from "./keuangan/piutang/restrukturisasiPiutang";
import CompPosisiPiutang from "./keuangan/piutang/posisiPiutang";
import CompBayarPiutang from "./keuangan/piutang/bayarPiutang";
import CompAccount from "../account";
import CompGetAccount from "./organisasi/account";

import CompProgramTabungan from "./keuangan/tabungan/programTabungan";
import CompBukaRekeningTabungan from "./keuangan/tabungan/bukaRekening";
import CompMenabung from "./keuangan/tabungan/menabung";
import CompTarikTabungan from "./keuangan/tabungan/tarikTabungan";
import CompTarikTabunganWajib from "./keuangan/tabunganWajib/tarikTabunganWajib";
import CompJenisKegiatan from "./kegiatan/buatKegiatan/jenisKegiatan";
import CompKegiatanBersama from "./kegiatan/buatKegiatan/kegiatan";
import CompKegiatanPribadi from "./kegiatan/buatKegiatan/kegiatanPribadi";
import CompAbsenKegiatanTerakhir from "./kegiatan/absen/absenKegiatanTerakhir";
import CompBayarRekapKegiatan from "./kegiatan/absen/bayarRekapKegiatan";
import CompRekapKegiatan from "./kegiatan/rekapKegiatan";
import CompCekKewajiban from "./kegiatan/cekKewajiban";
import CompBeliKegiatan from "./kegiatan/beliKegiatan";
import CompKelolaPhoto from "./tools/kelola_photo";

// import Organisasi from "./organisasi";
import Login from "../../components/login";
import LoginRegistrasiAccount from "../../components/loginRegistrasiAccount";
import Env from "../../setting/env";
import ImgArrowDown from "../../assets/image/downArrow.svg";
import ImgArrow from "../../assets/image/Arrow.svg";

function Pages(props) {
	const [sAccount, setSAccount] = useState("");
	const [sBorderColor, setsBorderColor] = useState({
		eHome: "",
		eAkun: "",
	});
	const [sShowSmUl, setSShowSmUl] = useState(false);
	const [sAkutansi, setSAkutansi] = useState(false);
	const [sOrganisasi, setSOrganisasi] = useState(false);

	const [sKeuangan, setSKeuangan] = useState(false);
	const [sPiutang, setSPiutang] = useState(false);
	const [sTabungan, setSTabungan] = useState(false);
	// const [sTabunganWajib, setSTabunganWajib] = useState(false);

	const [sKegiatan, setSKegiatan] = useState(false);
	const [sAbsen, setSAbsen] = useState(false);
	const [sBuatKegiatan, setSBuatKegiatan] = useState(false);
	const [sTools, setSTools] = useState(false);

	const [sCompAccount, setSCompAccount] = useState(false);

	useEffect(() => {
		fResetMenu();
	}, [props.state.sLayarSize]);

	useEffect(() => {
		if (sOrganisasi) {
			setSAkutansi(false);
			setSKeuangan(false);
			setSKegiatan(false);
			setSTools(false);
		}
	}, [sOrganisasi]);

	useEffect(() => {
		if (sAkutansi) {
			setSOrganisasi(false);
			setSKeuangan(false);
			setSKegiatan(false);
			setSTools(false);
		}
	}, [sAkutansi]);

	useEffect(() => {
		if (sKeuangan) {
			setSOrganisasi(false);
			setSAkutansi(false);
			setSKegiatan(false);
			setSTools(false);
		}
	}, [sKeuangan]);

	useEffect(() => {
		if (sKegiatan) {
			setSOrganisasi(false);
			setSKeuangan(false);
			setSAkutansi(false);
			setSTools(false);
		}
	}, [sKegiatan]);

	useEffect(() => {
		if (setSTools) {
			setSOrganisasi(false);
			setSKeuangan(false);
			setSAkutansi(false);
			setSKegiatan(false);
		}
	}, [sTools]);

	const fResetMenu = () => {
		setSShowSmUl(false);
		setSAkutansi(false);
		setSOrganisasi(false);
		setSKeuangan(false);
		setSPiutang(false);
		setSTabungan(false);
		// setSTabunganWajib(false);
		setSKegiatan(false);
		setSAbsen(false);
		setSBuatKegiatan(false);
		setSTools(false);
	};

	return (
		<div className="overflow-hidden">
			<Router>
				{sCompAccount ? (
					<CompAccount
						_onClose={() => {
							setSCompAccount(false);
						}}
					/>
				) : (
					""
				)}
				<div className="z-100 fixed w-screen">
					<nav className="Nav">
						{/* 101 Kiri  Title*/}
						<div className="WadahFix hidden  items-center justify-center mx-1 p-1  w-auto md:flex">
							<p className="truncate">{props.state.sPages.nama}</p>
						</div>

						{/* 201 Tengah Barger Menu */}
						<div
							className="flex flex-col  md:justify-center"
							onMouseLeave={() => {
								fResetMenu();
							}}
						>
							<div className="flex md:hidden">
								<MenuBarger
									_status={(xValue) => {
										setSShowSmUl(xValue);
										if (!xValue) {
											setSAkutansi(false);
										}
									}}
									_setStatus={sShowSmUl}
								/>
							</div>

							<ul
								className="NavUl"
								style={
									props.state.sLayarSize === "sm"
										? sShowSmUl
											? { display: "flex", left: "-1px" }
											: { display: "none" }
										: { display: "flex" }
								}
							>
								{/*menu============ Home ========= */}
								<li
									className="Li"
									style={{ borderColor: sBorderColor.eHome }}
									onClick={() => {
										// setSShowSmUl(false);
										// setSAkutansi(false);
										fResetMenu();
									}}
								>
									<Link to="/">Home</Link>
								</li>

								{/* menu============ Organisasi ======== */}
								<li
									onMouseLeave={() => {
										if (props.state.sLayarSize === "xl") {
											setSOrganisasi(false);
										}
									}}
									onMouseOver={() => {
										if (props.state.sLayarSize === "xl") {
											setSOrganisasi(true);
										}
									}}
									className=" cursor-pointer "
									onClick={() => {
										setSOrganisasi(!sOrganisasi);
									}}
								>
									<div
										className="Li flex flex-col "
										// onClick={() => {
										// 	setSOrganisasi(!sOrganisasi);
										// }}
									>
										Organisasi
										<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrowDown} alt="" />
									</div>
									{sOrganisasi ? (
										<ul className="relative md:absolute bg-genkiBlue-light">
											<li
												className="Li"
												onClick={() => {
													fResetMenu();
												}}
											>
												<div className="w-3"></div>
												<Link to="/anggota">Anggota</Link>
											</li>
											{props.state.sUser.eStatus >= 70 ? (
												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<div className="w-3"></div>
													<Link to="/kelompok">Kelompok</Link>
												</li>
											) : (
												""
											)}

											{props.state.sUser.eStatus === 70 ||
											props.state.sUser.eStatus === 90 ||
											props.state.sUser.eStatus === 100 ? (
												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<div className="w-3"></div>
													<Link to="/getaccount">User Account</Link>
												</li>
											) : (
												""
											)}
										</ul>
									) : (
										""
									)}
								</li>

								{/*menu============ Akutansi ========= */}
								{props.state.sUser.eStatus >= 70 ? (
									<li
										onMouseLeave={() => {
											if (props.state.sLayarSize === "xl") {
												setSAkutansi(false);
											}
										}}
										onMouseOver={() => {
											if (props.state.sLayarSize === "xl") {
												setSAkutansi(true);
											}
										}}
										className=" cursor-pointer "
										onClick={() => {
											setSAkutansi(!sAkutansi);
										}}
									>
										<div className="Li flex flex-col ">
											Akutansi
											<img
												className="h-2 w-2 ml-8 md:ml-6 -mt-1"
												src={ImgArrowDown}
												alt=""
											/>
										</div>
										{sAkutansi ? (
											<ul className="relative md:absolute bg-genkiBlue-light">
												{/* {props.state.sUser.eStatus === 70 ||
												props.state.sUser.eStatus === 100 ? ( */}
												<li
													className="Li"
													onClick={() => {
														setSShowSmUl(false);
														setSAkutansi(false);
													}}
												>
													<div className="w-3"></div>
													<Link to="/jurnal">Jurnal Umum</Link>
												</li>
												{/* ) : (
													""
												)} */}

												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<div className="w-3"></div>
													<Link to="/akun">Akun</Link>
												</li>
												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<div className="w-3"></div>
													<Link to="/pengelola_keuangan">Pengelola Keuangan</Link>
												</li>
											</ul>
										) : (
											""
										)}
									</li>
								) : (
									""
								)}

								{/*menu============ Laporan ========= */}
								<li
									className="Li"
									style={{ borderColor: sBorderColor.eHome }}
									onClick={() => {
										fResetMenu();
									}}
								>
									<Link to="/laporankeuangan">Laporan</Link>
								</li>

								{/* menu============ Utang Piutang ============= */}
								{props.state.sUser.eStatus === 70 ? (
									<li
										onMouseLeave={() => {
											if (props.state.sLayarSize === "xl") {
												setSKeuangan(false);
											}
										}}
										onMouseOver={() => {
											if (props.state.sLayarSize === "xl") {
												setSKeuangan(true);
											}
										}}
										className=" cursor-pointer "
									>
										<div
											className="Li flex flex-col "
											onClick={() => {
												setSKeuangan(!sKeuangan);
											}}
										>
											Utang Piutang
											<img className="h-2 w-2 ml-10 -mt-1" src={ImgArrowDown} alt="" />
										</div>
										{sKeuangan ? (
											<ul
												className="relative md:absolute bg-genkiBlue-light"
												onMouseLeave={() => {
													if (props.state.sLayarSize === "xl") {
														setSPiutang(false);
														setSTabungan(false);
													}
												}}
											>
												{/* LIST PIUTANG */}
												<li
													className="flex items-start ml-4 md:ml-2"
													onClick={() => {
														setSPiutang(!sPiutang);
													}}
													// onMouseOver={() => {
													// 	if (props.state.sLayarSize ==="xl") {
													// 		setSPiutang(true);
													// 	}
													// }}
												>
													<div className="hover:text-genkiWhite flex items-center">
														<div className=" w-14">Piutang</div>
														<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrow} alt="" />
													</div>
													<div className="w-1"></div>
													{sPiutang ? (
														<ul>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/bayarpiutang">Bayar Piutang</Link>
															</li>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/realisasipiutang">Realisasi Piutang</Link>
															</li>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/programpiutang">Program Piutang</Link>
															</li>
															{/* <div className=" -mt-4 text-gray-400">_____________________</div> */}
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/restrukturisasi_piutang">
																	Restrukturisasi Piutang
																</Link>
															</li>
														</ul>
													) : (
														""
													)}
												</li>

												{/* LIST TABUNGAN */}
												<li
													className="flex items-start ml-4 md:ml-2"
													onClick={() => {
														setSTabungan(!sTabungan);
													}}
													// onMouseOver={() => {
													// 	if (props.state.sLayarSize ==="xl") {
													// 		setSTabungan(true);
													// 	}
													// }}
												>
													<div className="hover:text-genkiWhite flex items-center">
														<div className=" w-14">Tabungan</div>
														<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrow} alt="" />
													</div>
													<div className="w-1"></div>
													{sTabungan ? (
														<ul>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/menabung">Menabung</Link>
															</li>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/tarik_tabungan">Tarik Tabungan</Link>
															</li>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/bukarekeningtabungan">Buka Rekening</Link>
															</li>
															<li
																className="Li"
																onClick={() => {
																	fResetMenu();
																}}
															>
																<Link to="/programtabungan">Program Tabungan</Link>
															</li>
														</ul>
													) : (
														""
													)}
												</li>

												{/* LIST TABUNGAN Wajib */}
												<li
													className="Li ml-2"
													onClick={() => {
														fResetMenu();
													}}
												>
													<Link to="/tarik_tabungan_wajib">Tarik Tabung Wajib</Link>
												</li>

												{/* Posisi Utang Piutang */}
												<li
													className="Li flex items-start ml-4 md:ml-2"
													onClick={() => {
														fResetMenu();
													}}
												>
													<Link to="/posisipiutang">Posisi Utang Piutang</Link>
												</li>
											</ul>
										) : (
											""
										)}
									</li>
								) : (
									<li
										onMouseLeave={() => {
											if (props.state.sLayarSize === "xl") {
												setSKeuangan(false);
											}
										}}
										onMouseOver={() => {
											if (props.state.sLayarSize === "xl") {
												setSKeuangan(true);
											}
										}}
										className=" cursor-pointer "
									>
										<div
											className="Li flex flex-col "
											onClick={() => {
												setSKeuangan(!sKeuangan);
											}}
										>
											Utang Piutang
											<img className="h-2 w-2 ml-10 -mt-1" src={ImgArrowDown} alt="" />
										</div>
										{sKeuangan ? (
											<ul
												className="relative md:absolute bg-genkiBlue-light"
												onMouseLeave={() => {
													if (props.state.sLayarSize === "xl") {
														setSPiutang(false);
														setSTabungan(false);
													}
												}}
											>
												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<Link to="/posisipiutang">Posisi Utang Piutang</Link>
												</li>
											</ul>
										) : (
											""
										)}
									</li>
								)}

								{/* menu =========== Kegiatan =========== */}

								<li
									onMouseLeave={() => {
										if (props.state.sLayarSize === "xl") {
											setSKegiatan(false);
										}
									}}
									onMouseOver={() => {
										if (props.state.sLayarSize === "xl") {
											setSKegiatan(true);
										}
									}}
									className=" cursor-pointer "
								>
									<div
										className="Li flex flex-col "
										onClick={() => {
											setSKegiatan(!sKegiatan);
										}}
									>
										Kegiatan
										<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrowDown} alt="" />
									</div>
									{sKegiatan ? (
										<ul
											className="relative md:absolute bg-genkiBlue-light"
											onMouseLeave={() => {
												if (props.state.sLayarSize === "xl") {
													setSKegiatan(false);
													setSBuatKegiatan(false);
												}
											}}
										>
											{/* ******************************************* */}
											{props.state.sUser.eStatus >= 70 ? (
												<Fragment>
													{/* LIST ABSEN */}
													<li
														className="flex items-start ml-4 md:ml-2"
														onClick={() => {
															setSAbsen(!sAbsen);
														}}
													>
														<div className="hover:text-genkiWhite flex items-center">
															<div className=" w-28">Absen</div>
															<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrow} alt="" />
														</div>
														<div className="w-1"></div>
														{sAbsen ? (
															<ul>
																<li
																	className="Li"
																	onClick={() => {
																		fResetMenu();
																	}}
																>
																	<Link to="/absen_kegiatan_terakhir">
																		Absen Kegiatan Terakhir
																	</Link>
																</li>
																<li
																	className="Li"
																	onClick={() => {
																		fResetMenu();
																	}}
																>
																	<Link to="/bayar_rekap_kegiatan">Bayar Rekap Kegiatan </Link>
																</li>
															</ul>
														) : (
															""
														)}
													</li>

													{/* LIST BUAT KEGIATAN */}
													<li
														className="flex items-start ml-4 md:ml-2"
														onClick={() => {
															setSBuatKegiatan(!sBuatKegiatan);
														}}
													>
														<div className="hover:text-genkiWhite flex items-center">
															<div className=" w-28">Buat Kegiatan</div>
															<img className="h-2 w-2 ml-8 -mt-1" src={ImgArrow} alt="" />
														</div>
														<div className="w-1"></div>
														{sBuatKegiatan ? (
															<ul>
																<li
																	className="Li"
																	onClick={() => {
																		fResetMenu();
																	}}
																>
																	<Link to="/kegiatan_bersama">Buat Kegiatan Bersama</Link>
																</li>

																<li
																	className="Li"
																	onClick={() => {
																		fResetMenu();
																	}}
																>
																	<Link to="/kegiatan_pribadi">Buat Kegiatan Pribadi</Link>
																</li>

																<li
																	className="Li"
																	onClick={() => {
																		fResetMenu();
																	}}
																>
																	<Link to="/jenis_kegiatan">Jenis Kegiatan</Link>
																</li>
															</ul>
														) : (
															""
														)}
													</li>
													<li
														className="Li"
														onClick={() => {
															fResetMenu();
														}}
													>
														<Link to="/beli_kegiatan">
															<div className=" pl-4 md:pl-2">Beli Kegiatan</div>
														</Link>
													</li>
												</Fragment>
											) : null}

											<li
												className="Li"
												onClick={() => {
													fResetMenu();
												}}
											>
												<Link to="/rekap_kegiatan">
													<div className=" pl-4 md:pl-2">Rekap Kegiatan</div>
												</Link>
											</li>

											<li
												className="Li"
												onClick={() => {
													fResetMenu();
												}}
											>
												<Link to="/cek_kewajiban">
													<div className=" pl-4 md:pl-2">Cek Kewajiban</div>
												</Link>
											</li>
											{/* ******************************************* */}
										</ul>
									) : (
										""
									)}
								</li>

								{/* menu =========== tools ============== */}
								<li
									onMouseLeave={() => {
										if (props.state.sLayarSize === "xl") {
											setSTools(false);
										}
									}}
									onMouseOver={() => {
										if (props.state.sLayarSize === "xl") {
											setSTools(true);
										}
									}}
									className=" cursor-pointer "
								>
									<div
										className="Li flex flex-col "
										onClick={() => {
											setSTools(!sTools);
										}}
									>
										Tools
										<img className="h-2 w-2 ml-4 -mt-1" src={ImgArrowDown} alt="" />
									</div>
									{sTools ? (
										<ul
											className="relative md:absolute bg-genkiBlue-light"
											onMouseLeave={() => {
												if (props.state.sLayarSize === "xl") {
													// setSKegiatan(false);
													// setSBuatKegiatan(false);
												}
											}}
										>
											{/* ******************************************* */}
											{props.state.sUser.eStatus >= 70 ? (
												<li
													className="Li"
													onClick={() => {
														fResetMenu();
													}}
												>
													<div className="w-3"></div>
													<Link to="/kelola_photo">Kelola Photo</Link>
												</li>
											) : null}

											{/* ******************************************* */}
										</ul>
									) : (
										""
									)}
								</li>
							</ul>
						</div>
						<div className="WadahFix flex md:hidden items-center mx-1 p-1 justify-center overflow-hidden">
							<p className="truncate">{props.state.sPages.nama}</p>
						</div>

						{/* 301 Kanan */}
						<div className="flex flex-1">
							<div className="flex-1"></div>
							<div className="flex text-base mr-1 md:mr-3 space-x-1">
								<div
									className="Button rounded-xl w-20 md:w-28"
									onClick={() => {
										setSCompAccount(true);
									}}
								>
									<p className="truncate">{props.state.sUser.eUserName}</p>
								</div>
								<div
									className="Button rounded-xl"
									onClick={() => {
										props.dispatch({ type: "logOut" });
										props._onLogOut();
									}}
								>
									<Link to="/">Logout</Link>
								</div>
							</div>
						</div>
					</nav>
				</div>

				{/* =======================SWITCH LINK===================================================================== */}
				<div className="relative  w-full h-screen top-10 overflow-y-hidden">
					<Switch>
						{/* ========================Tools============================= */}
						<Route path="/kelola_photo">
							<CompKelolaPhoto />
						</Route>

						{/* ========================PIUTANG============================ */}
						<Route path="/bayarpiutang">
							<CompBayarPiutang />
						</Route>
						<Route path="/realisasipiutang">
							<CompRealisasiPiutang />
						</Route>
						<Route path="/restrukturisasi_piutang">
							<CompRestrukturisasiPiutang />
						</Route>
						<Route path="/programpiutang">
							<CompProgramPiutang />
						</Route>
						<Route path="/posisipiutang">
							<CompPosisiPiutang />
						</Route>
						{/* END======================PIUTANG============================ */}
						{/* ========================TABUNGAN============================ */}
						<Route path="/programtabungan">
							<CompProgramTabungan />
						</Route>
						<Route path="/bukarekeningtabungan">
							<CompBukaRekeningTabungan />
						</Route>
						<Route path="/menabung">
							<CompMenabung />
						</Route>
						<Route path="/tarik_tabungan">
							<CompTarikTabungan />
						</Route>
						{/* END======================TABUNGAN============================ */}
						{/* ========================TABUNGAN WAJIB============================ */}

						<Route path="/tarik_tabungan_wajib">
							<CompTarikTabunganWajib />
						</Route>
						{/* END======================TABUNGAN WAJIB============================ */}
						{/* ========================= KEGIATAN ========================== */}
						<Route path="/kegiatan_bersama">
							<CompKegiatanBersama />
						</Route>

						<Route path="/kegiatan_pribadi">
							<CompKegiatanPribadi />
						</Route>

						<Route path="/jenis_kegiatan">
							<CompJenisKegiatan />
						</Route>
						<Route path="/absen_kegiatan_terakhir">
							<CompAbsenKegiatanTerakhir />
						</Route>
						<Route path="/bayar_rekap_kegiatan">
							<CompBayarRekapKegiatan />
						</Route>
						<Route path="/rekap_kegiatan">
							<CompRekapKegiatan />
						</Route>
						<Route path="/cek_kewajiban">
							<CompCekKewajiban />
						</Route>

						<Route path="/beli_kegiatan">
							<CompBeliKegiatan />
						</Route>

						{/* END====================== KEGIATAN ========================== */}

						<Route path="/akun">
							{/* <Redirect to="/" /> */}
							<Akun
								_onActive={() => {
									setsBorderColor((xState) => {
										let iState = { ...xState };
										iState.eHome = "";
										iState.eAkun = Env.color.orangeRed02;
										return iState;
									});
								}}
							/>
						</Route>
						<Route path="/laporankeuangan">
							<Laporan />
						</Route>
						<Route path="/jurnal">
							<Jurnal />
						</Route>
						<Route path="/pengelola_keuangan">
							<PengelolaKeuangan />
						</Route>
						<Route path="/anggota">
							<CompAnggota />
						</Route>
						<Route path="/kelompok">
							<CompKelompok />
						</Route>
						<Route path="/getaccount">
							<CompGetAccount />
						</Route>
						<Route path="/">
							<Home
								_onActive={() => {
									setsBorderColor((xState) => {
										let iState = { ...xState };
										iState.eHome = Env.color.orangeRed02;
										iState.eAkun = "";
										return iState;
									});
								}}
							/>
						</Route>
					</Switch>
					{sAccount === "login" ? (
						<Login
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eLogin = "";
									return iState;
								});
							}}
						/>
					) : sAccount === "Registrasi" ? (
						<LoginRegistrasiAccount
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eRegister = "";
									return iState;
								});
							}}
						/>
					) : (
						""
					)}
				</div>
			</Router>
		</div>
	);
}

export default GlobalStateConsumer(Pages);
