exports.ValidasiEmail = function (xData) {
	// Validasi jika merupakan email
	let iAt1 = xData.indexOf("@");
	let iAt2 = xData.lastIndexOf("@");
	let iDot1 = xData.indexOf(".");
	let iDot2 = xData.lastIndexOf(".");

	// Jika Panjang karakter telah lebih dari 9
	if (xData.length > 9) {
		// memastiakan hanya ada 1 karakter @
		if (iAt1 === iAt2 && iAt1 > 3) {
			if (iAt1 < iDot1 + 2) {
				if (iDot1 === iDot2) {
					if (iDot2 + 2 < xData.length) {
						return true;
					} else {
						return false;
					}
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
};

exports.ValidasiNoHp = function (xData) {
	if (xData.length > 7 && xData.length < 15) {
		// Cek jika text merupakan No Hp
		let iPlus = xData.substring(0, 1);
		if (iPlus === "+" || iPlus === "0") {
			let iTxt = xData.substring(1);
			let iOnlyNumber = /^[0-9]+$/;
			if (iOnlyNumber.test(iTxt)) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	} else {
		return false;
	}
};
