const orangeRed01 = "orangered";
const orangeRed02 = "#ffbb00";
const orangeRed03 = "#d86e31";
const orangeRed04 = "#f0c473";
const orangeRed05 = "#f0c473";

// const mUrl = "https://genky-foundation.herokuapp.com";
// const mUrl = "http://localhost:5000";
const mUrl = process.env.REACT_APP_GENKY_FOUNDATION_API_HOST||"http://localhost:7000";

const ImgArrow = require("../assets/image/Arrow.svg");

const fPecahan = (xAngka, xNilai) => {
	let iOnlyNumber = /^[0-9]+$/;
	let iData = "";
	let iiData = "";
	let iiiData = "";
	let iSen = false;
	let iPecahan = false;

	for (let i = 0; i < xAngka.length; i++) {
		iData = xAngka.substring(i, i + 1);
		if (iOnlyNumber.test(iData)) {
			if (iPecahan) {
				iiiData = iiiData + iData;
			} else {
				iiData = iiData + iData;
			}
		} else if (iData === "/") {
			if (!iPecahan) {
				// iiData = iiData + iData;
				iPecahan = true;
				if (iSen) {
					iSen = false;
				}
			}
		} else if (iData === ",") {
			if (!iSen) {
				if (iPecahan) {
					iiiData = iiiData + iData;
				} else {
					iiData = iiData + iData;
				}
				iSen = true;
			}
		}
	}

	const iNilai = parseFloat(iiData.replace(/,/g, ".")) || 0;
	let iiNilai = 0;
	if (iPecahan) {
		let iPembagi = parseFloat(iiiData.replace(/,/g, ".")) || 0;
		iPembagi === 0 ? (iiNilai = 0) : (iiNilai = iNilai / iPembagi);
		if (xNilai) {
			xNilai(iiNilai);
		}
		return `${fRibuan(iiData)}/${fRibuan(iiiData)}`;
	} else {
		iiNilai = iNilai;
		if (xNilai) {
			xNilai(iiNilai);
		}
		return fRibuan(iiData);
	}
};

const fUang = (xNilai) => {
	let iMin = false;
	if (xNilai < 0) {
		iMin = true;
	}

	let iUang = String(xNilai);
	iUang = iUang.replace(".", ",");
	if (iMin) {
		return `- ${fRibuan(iUang)}`;
	} else {
		return fRibuan(iUang);
	}
};

const fRibuan = (xData, xNilai) => {
	let iAngka = fAngka(xData, xNilai);
	let iAdaKoma = iAngka.indexOf(",");
	let iSen = "";
	let iDesimal = iAngka;
	if (iAdaKoma > -1) {
		iSen = iAngka.substring(iAdaKoma, iAdaKoma + 3);
		iDesimal = iAngka.substring(0, iAdaKoma);
	}

	let iHasil = "";
	let iPanjang = iDesimal.length;
	let iSub = iPanjang;
	for (let i = 0; i < Math.floor((iPanjang - 1) / 3); i++) {
		iHasil = "." + iDesimal.substring(iSub - 3, iSub) + iHasil;
		iSub -= 3;
	}
	iHasil = iDesimal.substring(0, iSub) + iHasil;
	return iHasil + iSen;
};

const fAngka = (xData, xNilai) => {
	let iOnlyNumber = /^[0-9]+$/;
	let iData = "";
	let iiData = "";
	let iSen = false;

	for (let i = 0; i < xData.length; i++) {
		iData = xData.substring(i, i + 1);
		if (iOnlyNumber.test(iData)) {
			iiData = iiData + iData;
		} else if (iData === ",") {
			if (!iSen) {
				iiData = iiData + iData;
				iSen = true;
			}
		}
	}
	if (xNilai) {
		xNilai(parseFloat(iiData.replace(/,/g, ".")) || 0);
	}
	return iiData;
};

// Mendapatka nilai dari ribuan, anggka dan pecahan, misal 1.000.000 => 1000000, 1,2 => 1.2, atau 1/2 => 0.5
const fNilai = (xValue) => {
	try {
		const iValue = String(xValue);
		const iPecahan = iValue.indexOf("/");
		let iXNilai1 = "";
		let iXNilai2 = "";
		if (iPecahan > 0) {
			iXNilai1 = iValue.substring(0, iPecahan);
			iXNilai2 = iValue.substring(iPecahan + 1);
		} else {
			iXNilai1 = iValue;
		}
		iXNilai1 = iXNilai1.replace(/\./g, "");
		let iNilai1 = parseFloat(iXNilai1.replace(/,/g, ".")) || 0;
		iXNilai2 = iXNilai2.replace(/\./g, "");
		let iNilai2 = parseFloat(iXNilai2.replace(/,/g, ".")) || 0;

		if (iNilai2 !== 0 && iPecahan > 0) {
			iNilai1 = iNilai1 / iNilai2;
		}

		if (iNilai1) {
			return iNilai1;
		} else {
			return 0;
		}
	} catch (xErr) {
		return 0;
	}
};

const fLength = (xData, xLength = 2) => {
	let iData = String(xData);
	while (iData.length < xLength) {
		iData = `0${iData}`;
	}
	return iData;
};

module.exports = {
	color: {
		orangeRed01: orangeRed01,
		orangeRed02: orangeRed02,
		orangeRed03: orangeRed03,
		orangeRed04: orangeRed04,
		orangeRed05: orangeRed05,
		colorAbuAbu01: "#cccaca",
		colorAbuAbu02: "#d3bdb9ef",
		colorAbuAbu03: "#443c39",
		colorAbuAbu04: "#161110ef",
		colorPrimer01: "red",
		colorPrimer02: "green",
		genkiBlue: "#1fb6ff",
	},
	ukuran: { topHeight: "40px", topHeight2: "80px", mediaWidth: "600px" },
	url: mUrl,
	eRouteApi: `/api`,
	urlCaptcha: `${mUrl}/captcha`,
	namaToko: "WARUNG GENKY",
	buttonImageType: { stop: 1, ok: 2 },
	status: { berhasil: 200, err: 500, gagal: 202 },
	eGenkiFoundationPage: {
		nama: "Genki Foundation",
		url: mUrl,
	},
	eHttpStatus: {
		eOk: 200,
		eCreate: 201,
		eGagal: 202,
		eBadRequest: 400,
		eServerError: 500,
	},
	image: { arrow: ImgArrow },
	format: {
		uang: fUang,
		ribuan: fRibuan,
		angka: fAngka,
		pecahan: fPecahan,
	},
	eNilai: fNilai, // Mendapatka nilai dari ribuan, anggka dan pecahan, misal 1.000.000 => 1000000, 1,2 => 1.2, atau 1/2 => 0.5
	account: {
		status: { owner: 100, admin: 90, sales: 80, default: 0 },
	},
	efTanggal: () => {
		var today = new Date();
		var date =
			today.getFullYear() +
			"-" +
			fLength(today.getMonth() + 1) +
			"-" +
			fLength(today.getDate());

		return date;
	},
	efFormatTanggal: (xTanggal) => {
		let iTanggal = xTanggal.substring(8, 10);
		iTanggal = `${iTanggal}-${xTanggal.substring(5, 7)}`;
		iTanggal = `${iTanggal}-${xTanggal.substring(0, 4)}`;
		return iTanggal;
	},
	efFormatTanggal2: (xTanggal) => {
		let iTanggal = xTanggal.substring(6, 8);
		iTanggal = `${iTanggal}-${xTanggal.substring(4, 6)}`;
		iTanggal = `${iTanggal}-${xTanggal.substring(0, 4)}`;
		return iTanggal;
	},

	eJabatanAnggota: [
		{ kode: 20, jabatan: "Anggota" },
		{ kode: 10, jabatan: "Anggota Tidak Aktif" },
		{ kode: 50, jabatan: "Juru" },
		{ kode: 60, jabatan: "Paruman" },
		{ kode: 65, jabatan: "Penasehat" },
		{ kode: 66, jabatan: "Pemangku" },
		{ kode: 70, jabatan: "Bendahara" },
		{ kode: 80, jabatan: "Sekretaris" },
		{ kode: 90, jabatan: "Ketua" },
		{ kode: 95, jabatan: "Wakil Ketua" },
	],

	eStatusKeluarga: [
		{ kode: 1, status: "Menikah" },
		{ kode: 2, status: "Remaja" },
		{ kode: 3, status: "Duda" },
		{ kode: 4, status: "Janda" },
	],
	cloudinary: {
		upload_url: "https://api.cloudinary.com/v1_1/dy4tff82i/upload",
		preset: { ngiring_ngayah: "j7wh8ufd" },
	},
};
