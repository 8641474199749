import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const BayarPiutang = (props) => {
  const [sTanggal, setSTanggal] = useState("");
  const mNewDataPembayaranPiutang = {
    totalBayarPiutang: 0,
    gunakanTabungan: false,
    penggunaanTabungan: 0,
    tunai: 0,
    kembali: 0,
    ditabungkan: 0,
    ref: useRef(null),
    showKembali: false,
  };
  const [sDataPembayaranPiutang, setSDataPembayaranPiutang] = useState({
    mNewDataPembayaranPiutang,
  });
  const [sRsBayarPiutang, setSRsBayarPiutang] = useState([]);
  const [sLoading, setSLoading] = useState(true);
  const [sRsPiutang, setsRsPiutang] = useState([]);
  const [sRsDataPersiapanBayar, setSRsDataPersiapanBayar] = useState([]);
  const [sBayarPiutang, setSBayarPiutang] = useState(false);
  const [sExekusiPembayaran, setSExekusiPembayaran] = useState(false);
  const [sDataTabungan, setSDataTabungan] = useState({
    group_nama: "",
    members_alamat: "",
    members_name: "",
    tabungan_id: "",
    tabungan_saldo: 0,
  });
  const [sNamaFocus, setSNamaFocus] = useState(false);
  const [sAlert, setSAlert] = useState({
    title: "",
    keterangan: "",
    show: false,
    index: 0,
  });

  const refTanggal = useRef();
  const refNama = useRef();
  const refBtnBayarPiutang = useRef();
  const refBtnOk = useRef();
  const refGunakanTabungan = useRef();
  const refTunai = useRef();
  const refKembali = useRef();
  const refYa = useRef();
  const refTidak = useRef();

  const fGetPreData = async () => {
    try {
      const iTanggal = await Axios.get(`${props.state.sPages.url}/date`);
      setSTanggal(iTanggal.data);

      // Realisasi piutang harus berdasarkan atas kepemilikan rekening tabungan
      const iRsPiutang = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/admin/realisasi_piutang`,
        {
          params: { req: "pembayaran" },
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      setsRsPiutang(iRsPiutang.data.message);
      // console.log(iRsPiutang.data.message);

      const iRsBayarPiutang = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/admin/bayar_piutang`,
        {
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      setSRsBayarPiutang(iRsBayarPiutang.data.message);
      // console.log(iRsBayarPiutang.data.message);

      setSLoading(false);
      refNama.current.focus();
    } catch (xErr) {
      // console.log(xErr);
      alert("Error");
    }
  };
  useEffect(() => {
    if (sExekusiPembayaran) {
      refYa.current.focus();
    }
  }, [sExekusiPembayaran]);

  useEffect(() => {
    // console.log(sRsDataPersiapanBayar);
    if (sRsDataPersiapanBayar.length > 0) {
      setSDataPembayaranPiutang((xState) => {
        let iTotalPembayaranPiutang = 0;
        for (let i = 0; i < sRsDataPersiapanBayar.length; i++) {
          if (sRsDataPersiapanBayar[i].bayar) {
            iTotalPembayaranPiutang += parseFloat(
              sRsDataPersiapanBayar[i].bayar_total
            );
          }
        }
        let iState = { ...xState };
        iState.totalBayarPiutang = iTotalPembayaranPiutang;
        return iState;
      });
    } else {
      setSDataPembayaranPiutang(() => {
        return { ...mNewDataPembayaranPiutang };
      });
    }
    // eslint-disable-next-line
  }, [sRsDataPersiapanBayar]);

  useEffect(() => {
    if (sBayarPiutang) {
      setSDataPembayaranPiutang((xState) => {
        let iState = { ...xState };
        iState.ref = refTunai;
        return iState;
      });
    }
  }, [sBayarPiutang]);

  useEffect(() => {
    try {
      sDataPembayaranPiutang.ref.current.focus();
      sDataPembayaranPiutang.ref.current.select();
    } catch (xErr) {}
  }, [sDataPembayaranPiutang]);

  useEffect(() => {
    fGetPreData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      refTanggal.current.focus();
    } catch (error) {}
  }, [sRsBayarPiutang]);

  try {
    return (
      <div className="h-screen w-full px-2">
        {sLoading ? <CompLoading /> : ""}
        {sAlert.show ? (
          <CompAlert
            _title={sAlert.title}
            _keterangan={sAlert.keterangan}
            _buttonCancel="Batal"
            _onCancel={() => {
              setSAlert(false);
              refNama.current.focus();
              refNama.current.select();
            }}
            _onOk={() => {
              setSRsDataPersiapanBayar((xState) => {
                let iArState = [...xState];
                let iOjState = { ...iArState[sAlert.index] };
                iOjState.bayar_pokok = iOjState.sisa_piutang;
                iOjState.bayar_total =
                  iOjState.sisa_piutang +
                  iOjState.bayar_bunga +
                  iOjState.bayar_denda;
                iOjState.option.edit = false;
                iOjState.option.maximal_bayar = 0;
                iOjState.status = "Pelunasan";
                iArState[sAlert.index] = iOjState;
                return iArState;
              });
              setSAlert(false);
            }}
          />
        ) : (
          ""
        )}
        <div className="flex flex-col h-full w-full md:p2 ">
          <div className="h-2"></div>
          <div className=" h-36 w-full bg-blue-200 space-y-2 rounded-lg shadow-md md:p-2">
            {/* TITLE */}
            <div className="flex flex-col  w-full mx-auto  items-center ">
              <p>BAYAR PIUTANG</p>
            </div>
            <hr />

            {/*============== Tanggal ================  */}
            <div className=" flex">
              <div className=" w-20 md:w-32">Tanggal</div>
              <div className=" w-1 text-center">:</div>
              <div className="ml-1 w-64 md:w-80 ">
                <input
                  type="text"
                  ref={refTanggal}
                  className="Text"
                  value={sTanggal}
                  readOnly={true}
                  onKeyUp={(xKey) => {
                    if (xKey.nativeEvent.key === "Enter") {
                      refNama.current.focus();
                    }
                  }}
                />
              </div>
            </div>

            {/* ============= Nama ==================== */}
            <div className=" flex">
              <div className=" w-20 md:w-32">Nama</div>
              <div className=" w-1 text-center">:</div>
              <div className={sNamaFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}>
                <CompTextDs
                  _ref={refNama}
                  _dataSource={sRsPiutang}
                  _onKeyEnter={async (xData) => {
                    if (!sLoading) {
                      setSLoading(true);
                      await Axios.get(
                        `${props.state.sPages.url}${Env.eRouteApi}/admin/realisasi_piutang`,
                        {
                          params: { req: "bayar", data: xData[0] },
                          headers: {
                            authorization: `bearer ${props.state.sToken}`,
                          },
                        }
                      )
                        .then((xRes) => {
                          // console.log(xRes.data.message);
                          if (xRes.status === Env.eHttpStatus.eOk) {
                            // console.log(xRes.data.message.dataPersiapanBayar);
                            setSRsDataPersiapanBayar(
                              xRes.data.message.dataPersiapanBayar
                            );
                            setSDataTabungan(xRes.data.message.dataTabungan);
                          } else {
                            alert("error");
                          }
                          refBtnBayarPiutang.current.focus();
                          setSLoading(false);
                        })
                        .catch((xErr) => {
                          alert(xErr);
                          setSLoading(false);
                        });
                    }
                  }}
                  _onFocus={() => {
                    setSNamaFocus(true);
                  }}
                  _onLossFocus={() => {
                    setSNamaFocus(false);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full mt-4 bg-blue-100 rounded-t-md">
            <div className="text-lg"> Dokumen Pembayaran Piutang</div>
          </div>
          <div className="flex-1 w-full   overflow-y-scroll bg-blue-100">
            <table className="Table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Tanggal</th>
                  <th>ID Tabungan</th>
                  <th>Nama</th>
                  <th>Bayar Tunai</th>
                  <th>Tarik Tabungan</th>
                  <th>Kembali</th>
                  <th>Menabung</th>
                </tr>
              </thead>
              <tbody>
                {sRsBayarPiutang
                  .map((xData, xIndex) => {
                    let iTanggal = xData.piutang_id.substring(0, 8);
                    iTanggal =
                      iTanggal.substring(6, 8) +
                      "-" +
                      iTanggal.substring(4, 6) +
                      "-" +
                      iTanggal.substring(0, 4);
                    return (
                      <tr key={xIndex}>
                        <td>{xIndex + 1}</td>
                        <td>{iTanggal}</td>
                        <td>{xData.tabungan_id}</td>
                        <td>{xData.members_name}</td>
                        <td align="right">
                          {Env.format.uang(xData.piutang_tunai)}
                        </td>
                        <td align="right">
                          {Env.format.uang(xData.piutang_tarik_tabungan)}
                        </td>
                        <td align="right">
                          {Env.format.uang(xData.piutang_kembali)}
                        </td>
                        <td align="right">
                          {Env.format.uang(xData.piutang_menabung)}
                        </td>
                      </tr>
                    );
                  })
                  .reverse()}
              </tbody>
            </table>
          </div>
          <div className=" h-16 w-full "></div>
        </div>

        {/* Data Persipan Bayar Piutang */}
        {sRsDataPersiapanBayar.length > 0 ? (
          <div className="Modal z-10 flex flex-col md:p-2">
            <div className=" h-10"></div>
            <div className="flex flex-col h-40 p-2 mt-1 w-full bg-blue-200 rounded-t-md">
              <div className="flex">
                <div className=" text-lg w-32">Nama</div>
                <div className=" text-lg w-2">:</div>
                <div className=" text-lg">{sDataTabungan.members_name}</div>
              </div>
              <div className="flex">
                <div className=" text-lg  w-32">Kelompok</div>
                <div className=" text-lg w-2">:</div>
                <div className=" text-lg">{sDataTabungan.group_nama}</div>
              </div>
              <div className="flex">
                <div className=" text-lg  w-32">Alamat</div>
                <div className=" text-lg w-2">:</div>
                <div className=" text-lg">{sDataTabungan.members_alamat}</div>
              </div>
              <div className="flex">
                <div className=" text-lg  w-32">Rex Tabungan</div>
                <div className=" text-lg w-2">:</div>
                <div className=" text-lg">{sDataTabungan.tabungan_id}</div>
              </div>
              <div className="flex">
                <div className=" text-lg  w-32">Saldo</div>
                <div className=" text-lg w-2">:</div>
                <div className=" text-lg">
                  {Env.format.uang(sDataTabungan.tabungan_saldo)}
                </div>
              </div>
            </div>
            <div className="flex justify-center w-full bg-blue-100 text-xl">
              BAYAR PIUTANG
            </div>

            {/* Tabel Data Persiapan Bayar */}
            <div className=" flex-1 overflow-y-scroll bg-blue-100 md:p-1 shadow-sm">
              <table className="Table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Bayar Bulan</th>
                    <th>Status</th>
                    <th>Nama</th>
                    <th>Rek Piutang</th>
                    <th>Nilai</th>
                    <th>Sisa Piutang</th>
                    <th>Bayar Pokok</th>
                    <th>Bayar Bunga</th>
                    <th>Bayar Denda</th>
                    <th>Jumlah </th>
                    <th>Bayar</th>
                  </tr>
                </thead>
                <tbody>
                  {sRsDataPersiapanBayar.map((xData, xIndex) => {
                    let iColor = {};
                    if (xData.status === "Pelunasan") {
                      iColor = { backgroundColor: Env.color.orangeRed03 };
                    }
                    return (
                      <tr key={xIndex} style={iColor}>
                        <td width={40} align="center">
                          {xData.option.edit ? (
                            <button
                              className="Button h-6 w-full"
                              onClick={() => {
                                setSAlert((xState) => {
                                  let iState = { ...xState };
                                  iState.title = `Pelunasan Piutang No: ${
                                    xIndex + 1
                                  }`;
                                  iState.keterangan = `Apakah anda yakin ingin melunasi rekening piutang no: ${xData.id}`;
                                  iState.show = true;
                                  iState.index = xIndex;
                                  return iState;
                                });
                              }}
                            >
                              {xIndex + 1}
                            </button>
                          ) : (
                            xIndex + 1
                          )}
                        </td>
                        <td width={105}>{xData.bayar_bulan}</td>
                        <td width={85} align="center">
                          {xData.status}
                        </td>
                        <td>{xData.members_name}</td>
                        <td width={100}>{xData.id}</td>
                        <td align="right" width={110}>
                          {Env.format.uang(xData.nilai)}
                        </td>
                        <td align="right" width={110}>
                          {Env.format.uang(xData.sisa_piutang)}
                        </td>
                        <td align="right" width={150}>
                          {xData.option.maximal_bayar > 0 ? (
                            <CompText
                              _value={xData.bayar_pokok}
                              _dataType="ribuan"
                              _onKeyEnter={(xNilai) => {
                                const iNilai = Env.eNilai(xNilai);
                                if (iNilai > xData.option.maximal_bayar) {
                                  alert(
                                    `Nilai pembayaran pokok tidak boleh lebih dari ${Env.format.uang(
                                      xData.option.maximal_bayar
                                    )}`
                                  );
                                }
                              }}
                              _onNilai={(xNilai) => {
                                if (xNilai <= xData.option.maximal_bayar) {
                                  setSRsDataPersiapanBayar((xState) => {
                                    let iArState = [...xState];
                                    let iObjState = { ...iArState[xIndex] };
                                    iObjState.bayar_pokok = xNilai;
                                    iObjState.bayar_total =
                                      iObjState.bayar_pokok +
                                      iObjState.bayar_bunga +
                                      iObjState.bayar_denda;
                                    iArState[xIndex] = iObjState;
                                    return iArState;
                                  });
                                } else {
                                  alert(
                                    `Nilai pembayaran pokok tidak boleh lebih dari ${Env.format.uang(
                                      xData.option.maximal_bayar
                                    )}`
                                  );
                                  setSRsDataPersiapanBayar((xState) => {
                                    let iArState = [...xState];
                                    let iObjState = { ...iArState[xIndex] };
                                    iObjState.bayar_pokok = 0;
                                    iObjState.bayar_total =
                                      iObjState.bayar_bunga +
                                      iObjState.bayar_denda;
                                    iArState[xIndex] = iObjState;
                                    return iArState;
                                  });
                                }
                              }}
                            />
                          ) : (
                            Env.format.uang(xData.bayar_pokok)
                          )}
                        </td>
                        <td align="right" width={110}>
                          {Env.format.uang(xData.bayar_bunga)}
                        </td>
                        <td align="right" width={110}>
                          {Env.format.uang(xData.bayar_denda)}
                        </td>
                        <td align="right" width={110}>
                          {Env.format.uang(xData.bayar_total)}
                        </td>
                        <td width={42} align="center">
                          <input
                            type="checkbox"
                            checked={xData.bayar}
                            onChange={() => {
                              setSRsDataPersiapanBayar((xState) => {
                                let iArState = [...xState];
                                let iObjState = { ...iArState[xIndex] };
                                let iBayar = !iObjState.bayar;
                                try {
                                  if (iBayar === true) {
                                    if (
                                      iArState[xIndex - 1].id ===
                                      iArState[xIndex].id
                                    ) {
                                      if (iArState[xIndex - 1].bayar !== true) {
                                        iBayar = false;
                                      }
                                    }
                                  } else {
                                    if (
                                      iArState[xIndex + 1].id ===
                                      iArState[xIndex].id
                                    ) {
                                      if (iArState[xIndex + 1].bayar === true) {
                                        iBayar = true;
                                      }
                                    }
                                  }
                                } catch (xErr) {}
                                iObjState.bayar = iBayar;
                                iArState[xIndex] = iObjState;
                                return iArState;
                              });
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Aksi */}
            <div className="flex flex-col  h-28  bg-blue-100  shadow-sm rounded-b-md">
              <div className="flex-1 flex w-full">
                <div className=" flex-1"></div>
                <div className="flex  w-56 border-gray-100 border-solid border-1">
                  <div className="flex-1 flex justify-end py-2 ">Total :</div>
                  <div className="Text w-40 h-10  flex justify-end items-center pr-1">
                    {Env.format.uang(sDataPembayaranPiutang.totalBayarPiutang)}
                  </div>
                </div>
                <div className=" w-16 ml-1 "></div>
              </div>

              <div className="flex h-20  w-full justify-center items-center bg-blue-300 rounded-b-md">
                <div className="flex justify-evenly  w-52 md:w-96 ">
                  <button
                    className="Button h-10 w-16"
                    ref={refBtnBayarPiutang}
                    onClick={() => {
                      setSBayarPiutang(true);
                    }}
                  >
                    Bayar
                  </button>
                  <button
                    className="Button h-10 w-16"
                    onClick={() => {
                      setSRsDataPersiapanBayar([]);
                      refNama.current.focus();
                      refNama.current.select();
                    }}
                  >
                    Batal
                  </button>
                </div>
              </div>
            </div>
            <div className=" h-14"></div>
          </div>
        ) : (
          ""
        )}

        {sBayarPiutang ? (
          <div className="Modal z-20 h-screen w-screen ">
            <div className=" w-full h-full flex flex-col ">
              <div className=" h-10"></div>
              <div className="flex flex-col h-40 p-2 mt-1 w-full"></div>
              <div className="flex flex-1 w-full justify-center">
                <div
                  className="flex flex-col max-w-sm h-72  rounded-xl  
										border-blue-500 border-2 border-solid bg-blue-200 "
                >
                  <div className="flex w-full justify-center text-lg bg-blue-300 rounded-t-xl">
                    RINCIAN PEMBAYARAN
                  </div>
                  <div
                    className=" w-full  bg-black"
                    style={{ height: "1px" }}
                  ></div>
                  <div className="flex h-10 w-full px-2 items-center bg-blue-200">
                    <div className=" h-10 flex items-center w-52 text-lg">
                      Total
                    </div>
                    <div className=" h-10 flex items-center w-2 text-lg">:</div>
                    <div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
                      {Env.format.uang(
                        sDataPembayaranPiutang.totalBayarPiutang
                      )}
                    </div>
                  </div>
                  <div className="flex w-full ">
                    <div className=" w-52 text-lg"> </div>
                    <div className=" w-2 text-lg"> </div>
                    <div
                      className="flex-1 bg-black"
                      style={{ height: "1px" }}
                    ></div>
                  </div>
                  <div className="flex w-full px-2 bg-blue-100">
                    <div className=" h-10 flex items-center w-52 ">
                      <div className="flex-1 text-lg">Gunakan Tabungan</div>
                      <div className=" flex items-center w-6">
                        <input
                          type="checkbox"
                          checked={sDataPembayaranPiutang.gunakanTabungan}
                          onChange={() => {
                            setSDataPembayaranPiutang((xState) => {
                              let iState = { ...xState };
                              if (sDataTabungan.tabungan_saldo > 0) {
                                iState.gunakanTabungan =
                                  !iState.gunakanTabungan;
                                if (iState.gunakanTabungan) {
                                  if (
                                    sDataTabungan.tabungan_saldo >
                                    sDataPembayaranPiutang.totalBayarPiutang
                                  ) {
                                    iState.penggunaanTabungan =
                                      sDataPembayaranPiutang.totalBayarPiutang;
                                  } else {
                                    iState.penggunaanTabungan =
                                      sDataTabungan.tabungan_saldo;
                                  }
                                } else {
                                  iState.penggunaanTabungan = 0;
                                }
                              }
                              if (iState.penggunaanTabungan > 0) {
                                iState.ref = refGunakanTabungan;
                              } else {
                                iState.ref = refTunai;
                              }
                              iState.tunai = 0;
                              iState.kembali = 0;
                              iState.ditabungkan = 0;
                              return iState;
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className=" h-10 flex items-center  w-2 text-lg">
                      :
                    </div>
                    <div className=" h-10 flex items-center  flex-1 text-lg justify-end">
                      {sDataPembayaranPiutang.gunakanTabungan ? (
                        <CompText
                          _ref={refGunakanTabungan}
                          _dataType="ribuan"
                          _value={sDataPembayaranPiutang.penggunaanTabungan}
                          _textAlign={"right"}
                          _className="text-right"
                          _onNilai={(xValue) => {
                            let iNilai = xValue;
                            if (xValue > sDataTabungan.tabungan_saldo) {
                              iNilai = 0;
                            }
                            setSDataPembayaranPiutang((xState) => {
                              let iState = { ...xState };
                              iState.penggunaanTabungan = iNilai;
                              iState.ref = null;
                              return iState;
                            });
                          }}
                          _onKeyEnter={() => {
                            setSDataPembayaranPiutang((xState) => {
                              let iState = { ...xState };
                              // console.log(iState.penggunaanTabungan);
                              // console.log(iState.totalBayarPiutang);
                              if (
                                iState.penggunaanTabungan ===
                                iState.totalBayarPiutang
                              ) {
                                iState.ref = refBtnOk;
                              } else if (
                                iState.penggunaanTabungan >
                                iState.totalBayarPiutang
                              ) {
                                iState.kembali =
                                  iState.penggunaanTabungan -
                                  iState.totalBayarPiutang;
                                iState.ref = refKembali;
                                iState.showKembali = true;
                              } else {
                                iState.ref = refTunai;
                                iState.showKembali = false;
                              }
                              return iState;
                            });
                          }}
                        />
                      ) : (
                        <div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
                          0
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full px-2 bg-blue-100 ">
                    <div className=" h-10 flex items-center  w-52 text-lg">
                      Tunai
                    </div>
                    <div className=" h-10 flex items-center  w-2 text-lg">
                      :
                    </div>
                    <div className=" h-10 flex items-center  flex-1 text-lg justify-end">
                      <CompText //===========Text Tunai =======================
                        _dataType={"ribuan"}
                        _value={sDataPembayaranPiutang.tunai}
                        _ref={refTunai}
                        _textAlign={"right"}
                        _className="text-right"
                        _onNilai={(xNilai) => {
                          setSDataPembayaranPiutang((xState) => {
                            let iState = { ...xState };
                            iState.tunai = xNilai;
                            let iKembali =
                              iState.totalBayarPiutang -
                              (iState.tunai + iState.penggunaanTabungan);
                            if (iKembali < 0) {
                              //jika ada kembalian
                              iState.kembali = Math.abs(iKembali);
                              iState.showKembali = true; //tampilkan text kembalian
                            } else {
                              iState.showKembali = false; //Sembunyikan text kembalian
                              iState.kembali = 0;
                            }
                            iState.ditabungkan = 0;
                            iState.ref = null;
                            return iState;
                          });
                        }}
                        _onKeyEnter={() => {
                          if (
                            //Jika Pembayaran melebihi kwajiban bayar piutang --> di exekusi
                            sDataPembayaranPiutang.tunai +
                              sDataPembayaranPiutang.penggunaanTabungan >=
                            sDataPembayaranPiutang.totalBayarPiutang
                          ) {
                            setSDataPembayaranPiutang((xState) => {
                              let iState = { ...xState };
                              if (iState.kembali === 0) {
                                refBtnOk.current.focus();
                              } else {
                                iState.ref = refKembali;
                              }

                              return iState;
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex w-full ">
                    <div className=" w-52 text-lg"> </div>
                    <div className=" w-2 text-lg"> </div>
                    <div
                      className="flex-1 bg-black"
                      style={{ height: "1px" }}
                    ></div>
                  </div>
                  <div className="flex w-full px-2 bg-blue-200">
                    <div className=" h-10 flex items-center  w-52 text-lg">
                      Kembali
                    </div>
                    <div className=" h-10 flex items-center  w-2 text-lg">
                      :
                    </div>
                    <div className=" h-10 flex items-center  flex-1 text-lg justify-end">
                      {sDataPembayaranPiutang.showKembali ? (
                        <CompText
                          _ref={refKembali}
                          _textAlign={"right"}
                          _className="text-right"
                          _dataType={"ribuan"}
                          _value={sDataPembayaranPiutang.kembali}
                          _onNilai={(xNilai) => {
                            setSDataPembayaranPiutang((xState) => {
                              let iState = { ...xState };
                              let iKembali =
                                iState.totalBayarPiutang -
                                (iState.tunai + iState.penggunaanTabungan);
                              iKembali = Math.abs(iKembali);
                              if (xNilai > iKembali) {
                                iState.kembali = 0;
                              } else {
                                iState.kembali = xNilai;
                              }
                              iState.ditabungkan = iKembali - iState.kembali;
                              iState.ref = null;
                              return iState;
                            });
                          }}
                          _onKeyEnter={() => {
                            refBtnOk.current.focus();
                          }}
                        />
                      ) : (
                        <div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
                          0
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className=" w-52 text-lg"> </div>
                    <div className=" w-2 text-lg"> </div>
                    <div
                      className="flex-1 bg-black"
                      style={{ height: "1px" }}
                    ></div>
                  </div>
                  <div className="flex items-center w-full px-2 bg-blue-100">
                    <div className=" h-10 flex items-center  w-52 text-lg">
                      Ditabungkan
                    </div>
                    <div className=" h-10 flex items-center  w-2 text-lg">
                      :
                    </div>
                    <div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
                      {Env.format.uang(sDataPembayaranPiutang.ditabungkan)}
                    </div>
                  </div>
                  <div
                    className=" w-full  bg-black"
                    style={{ height: "1px" }}
                  ></div>

                  <div className="flex flex-1 w-full justify-evenly items-center bg-gray-200 rounded-b-xl">
                    {/* <div className="flex justify-evenly w-52"> */}
                    {sDataPembayaranPiutang.totalBayarPiutang +
                      sDataPembayaranPiutang.ditabungkan +
                      sDataPembayaranPiutang.kembali ===
                    sDataPembayaranPiutang.tunai +
                      sDataPembayaranPiutang.penggunaanTabungan ? (
                      <button
                        ref={refBtnOk}
                        className="Button w-16"
                        onClick={() => {
                          setSExekusiPembayaran(true);
                        }}
                      >
                        OK
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="Button w-16"
                      onClick={() => {
                        setSBayarPiutang(false);
                        setSDataPembayaranPiutang((xState) => {
                          let iState = { ...xState };
                          iState.tunai = 0;
                          iState.penggunaanTabungan = 0;
                          iState.kembali = 0;
                          iState.ref = null;
                          iState.gunakanTabungan = false;
                          iState.ditabungkan = 0;
                          iState.showKembali = false;
                          return iState;
                        });
                        refBtnBayarPiutang.current.focus();
                      }}
                    >
                      Ulang
                    </button>
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        {sExekusiPembayaran ? (
          <div className="Modal z-30 h-screen w-screen ">
            <div className=" w-full h-full flex flex-col ">
              <div className=" h-16"></div>

              <div className="flex flex-1 w-full justify-center ">
                <div
                  className="flex flex-col w-96 h-28  bg-red-300 rounded-2xl r
										border-blue-500 border-2 border-solid bg-blue-2 "
                >
                  <div className="flex justify-center text-2xl w-full bg-blue-300 rounded-2xl rounded-b-none ">
                    Apakah Data Sudah Benar
                  </div>
                  <hr />
                  <div className="flex flex-1 items-center justify-evenly">
                    <button
                      className="Button w-16 text-xl"
                      ref={refYa}
                      onClick={() => {
                        setSExekusiPembayaran(false);
                        if (!sLoading) {
                          setSLoading(true);
                          let iRsDataBayarPiutang = [];
                          for (
                            let i = 0;
                            i < sRsDataPersiapanBayar.length;
                            i++
                          ) {
                            if (sRsDataPersiapanBayar[i].bayar) {
                              iRsDataBayarPiutang.push({
                                id: sRsDataPersiapanBayar[i].id,
                                bayar_bulan:
                                  sRsDataPersiapanBayar[i].bayar_bulan,
                                bayar_bunga:
                                  sRsDataPersiapanBayar[i].bayar_bunga,
                                bayar_denda:
                                  sRsDataPersiapanBayar[i].bayar_denda,
                                bayar_pokok:
                                  sRsDataPersiapanBayar[i].bayar_pokok,
                                sisa_piutang:
                                  sRsDataPersiapanBayar[i].sisa_piutang,
                                status: sRsDataPersiapanBayar[i].status,
                              });
                            }
                          }
                          // console.log(iRsDataBayarPiutang);

                          let iDataBayarPiutang = {
                            nama: sDataTabungan.members_name,
                            tabungan_id: sDataTabungan.tabungan_id,
                            menabung: sDataPembayaranPiutang.ditabungkan,
                            tarik_tabungan:
                              sDataPembayaranPiutang.penggunaanTabungan,
                            tunai: sDataPembayaranPiutang.tunai,
                            kembali: sDataPembayaranPiutang.kembali,
                            // total_bayar_piutang: sDataPembayaranPiutang.totalBayarPiutang,
                          };
                          // console.log(iDataBayarPiutang);

                          Axios.post(
                            `${props.state.sPages.url}${Env.eRouteApi}/bendahara/bayar_piutang`,
                            {
                              bayar_piutang: iDataBayarPiutang,
                              bayar_piutang_detail: iRsDataBayarPiutang,
                            },
                            {
                              headers: {
                                authorization: `bearer ${props.state.sToken}`,
                              },
                            }
                          )
                            .then((xRes) => {
                              //   console.log(xRes.data.message);
                              if (xRes.status === Env.eHttpStatus.eOk) {
                                setsRsPiutang(xRes.data.message);

                                const iBayarPiutang = {
                                  members_name: iDataBayarPiutang.nama,
                                  piutang_id: xRes.data.id,
                                  piutang_kembali: iDataBayarPiutang.kembali,
                                  piutang_menabung: iDataBayarPiutang.menabung,
                                  piutang_tarik_tabungan:
                                    iDataBayarPiutang.tarik_tabungan,
                                  piutang_tunai: iDataBayarPiutang.tunai,
                                  tabungan_id: iDataBayarPiutang.tabungan_id,
                                };
                                setSRsBayarPiutang((xState) => {
                                  let iState = [...xState, iBayarPiutang];
                                  return iState;
                                });
                              } else {
                                alert("Pembayaran Piutang Gagal");
                              }
                              setSRsDataPersiapanBayar([]);
                              setSBayarPiutang(false);
                              setSLoading(false);
                            })
                            .catch((xRes) => {
                              alert("Pembayaran Piutang Gagal");
                            });
                        }
                      }}
                    >
                      Ya
                    </button>
                    <button
                      className="Button w-16 text-xl"
                      ref={refTidak}
                      onClick={() => {
                        setSExekusiPembayaran(false);
                      }}
                    >
                      Tidak
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  } catch (xErr) {
    return null;
  }
};

export default GlobalStateConsumer(BayarPiutang);
