import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const Menabung = (props) => {
	const [sTanggal, setSTanggal] = useState("");
	const mNewDataMenabung = {
		nama: "",
		no_rekening: "",
		setor: 0,
		keterangan: "",
		ref: useRef(null),
	};
	const [sDataMenabung, setsDataMenabung] = useState({
		mNewDataMenabung,
	});
	const [sRsDocumentTabungan, setsRsDocumentTabungan] = useState([]);
	const [sLoading, setSLoading] = useState(true);
	const [sRsRekeningTabungan, setsRsRekeningTabungan] = useState([]);

	const mNewDataTabungan = {
		nama: "",
		alamat: "",
		kelompok: "",
		no_rekening: "",
		jenis_tabungan: "",
		saldo: 0,
	};
	const [sDataTabungan, setsDataTabungan] = useState(mNewDataTabungan);

	const [sNamaFocus, setSNamaFocus] = useState(false);
	const [sAlert, setSAlert] = useState({
		title: "",
		keterangan: "",
		show: false,
	});

	const refTanggal = useRef();
	const refNama = useRef();
	const refSetor = useRef();
	const refKeterangan = useRef();

	const fGetPreData = async () => {
		try {
			const iTanggal = await Axios.get(`${props.state.sPages.url}/date`);
			setSTanggal(iTanggal.data);

			// Realisasi piutang harus berdasarkan atas kepemilikan rekening tabungan
			const iRsRekeningTabungan = await Axios.get(
				`${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
				{
					params: { req: "members" },
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			);
			// console.log(iRsRekeningTabungan.data.message);
			setsRsRekeningTabungan(iRsRekeningTabungan.data.message);

			const iRsTabunganDetail = await Axios.get(
				`${props.state.sPages.url}${Env.eRouteApi}/bendahara/document_tabungan_detail`,
				{
					params: { req: "menabung" },
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			);
			setsRsDocumentTabungan(iRsTabunganDetail.data.message);
			// console.log(iRsTabunganDetail.data);

			setSLoading(false);
			refNama.current.focus();
		} catch (xErr) {
			// console.log(xErr);
			alert("Error");
		}
	};

	useEffect(() => {
		fGetPreData();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		try {
			sDataMenabung.ref.current.focus();
		} catch (xErr) {}
	}, [sDataMenabung]);

	try {
		return (
			<div className="h-screen w-full px-2">
				{sLoading ? <CompLoading /> : ""}
				{sAlert.show ? (
					<CompAlert
						_title={sAlert.title}
						_keterangan={sAlert.keterangan}
						_buttonCancel="Batal"
						_onCancel={() => {
							setSAlert((xState) => {
								let iState = { ...xState };
								iState.show = false;
								return iState;
							});
							refNama.current.focus();
							refNama.current.select();
						}}
						_onOk={() => {
							if (!sLoading) {
								setSLoading(true);
								Axios.post(
									`${props.state.sPages.url}${Env.eRouteApi}/bendahara/menabung_tabungan_detail`,

									{
										nama: sDataMenabung.nama,
										no_rekening: sDataTabungan.no_rekening,
										setor: sDataMenabung.setor,
										keterangan: sDataMenabung.keterangan,
									},
									{
										headers: {
											authorization: `bearer ${props.state.sToken}`,
										},
									}
								)
									.then((iRes) => {
										if (iRes.status === Env.eHttpStatus.eOk) {
											setsRsDocumentTabungan((xState) => {
												let iNewState = [...xState, iRes.data.message];
												return iNewState;
											});
											setsDataTabungan(mNewDataTabungan);
											setsDataMenabung(mNewDataMenabung);
										} else {
											alert("Simpam Data Tabungan Gagal");
										}
										setSLoading(false);
										setSAlert((xState) => {
											let iState = { ...xState };
											iState.show = false;
											return iState;
										});
										refTanggal.current.focus();
									})
									.catch((xErr) => {
										alert("Simpam Data Tabungan Gagal");
										setSLoading(false);
										setSAlert((xState) => {
											let iState = { ...xState };
											iState.show = false;
											return iState;
										});
									});
							}
						}}
					/>
				) : (
					""
				)}
				<div className="flex flex-col h-full w-full md:p2 ">
					<div className="h-2"></div>
					<div className=" h-auto w-full bg-blue-200 space-y-2 rounded-lg shadow-md md:p-2">
						{/* TITLE */}
						<div className="flex flex-col  w-full mx-auto  items-center ">
							<p>MENABUNG</p>
						</div>
						<hr />

						{/*============== Tanggal ================  */}
						<div className=" flex">
							<div className=" w-20 md:w-32">Tanggal</div>
							<div className=" w-1 text-center">:</div>
							<div className="ml-1 w-64 md:w-80 ">
								<input
									type="text"
									ref={refTanggal}
									className="Text bg-gray-100 "
									value={sTanggal}
									readOnly={true}
									onKeyUp={(xKey) => {
										if (xKey.nativeEvent.key === "Enter") {
											refNama.current.focus();
										}
									}}
								/>
							</div>
						</div>

						{/* ============= Nama ==================== */}
						<div className=" flex">
							<div className=" w-20 md:w-32">Nama</div>
							<div className=" w-1 text-center">:</div>
							<div className={sNamaFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}>
								<CompTextDs
									_ref={refNama}
									_value={sDataMenabung.nama}
									_dataSource={sRsRekeningTabungan}
									_onKeyEnter={async (xData) => {
										if (!sLoading) {
											setSLoading(true);
											try {
												const iRsRekeningTabungan = await Axios.get(
													`${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
													{
														params: { req: "saldo", id: xData[0] },
														headers: {
															authorization: `bearer ${props.state.sToken}`,
														},
													}
												);
												setsDataTabungan(iRsRekeningTabungan.data.message);
												setsDataMenabung((xState) => {
													let iState = { ...xState };
													iState.no_rekening = xData[0];
													iState.nama = xData[1];
													iState.ref = refSetor;
													return iState;
												});
												setSLoading(false);
											} catch (xErr) {
												alert("Rekening Tabungan Tidak Ditemukan");
												setSLoading(false);
											}
										}
									}}
									_onFocus={() => {
										setSNamaFocus(true);
										setsDataTabungan(mNewDataTabungan);
										setsDataMenabung(mNewDataMenabung);
									}}
									_onLossFocus={() => {
										setSNamaFocus(false);
									}}
								/>
							</div>
						</div>

						{/* ============= Tabel Data Tabungan ==================== */}
						{sDataTabungan.no_rekening !== "" ? (
							<table className="Table">
								<thead>
									<tr>
										<th>No Rekening</th>
										<th>Jenis Tabungan</th>
										<th>Saldo</th>
										<th>Pemilik</th>
										<th>Kelompok</th>
										<th>Alamat</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{sDataTabungan.no_rekening}</td>
										<td>{sDataTabungan.jenis_tabungan}</td>
										<td align="center">{Env.format.uang(sDataTabungan.saldo)}</td>
										<td>{sDataTabungan.nama}</td>
										<td>{sDataTabungan.kelompok}</td>
										<td>{sDataTabungan.alamat}</td>
									</tr>
								</tbody>
							</table>
						) : (
							""
						)}

						{/* ============= Setor ==================== */}
						<div className=" flex">
							<div className=" w-20 md:w-32">Setor</div>
							<div className=" w-1 text-center">:</div>
							<div className="ml-1 w-64 md:w-80">
								<CompText
									_ref={refSetor}
									_value={sDataMenabung.setor}
									_dataType="ribuan"
									_onNilai={(xNilai) => {
										setsDataMenabung((xState) => {
											let iState = { ...xState };
											iState.setor = xNilai;
											iState.ref = null;
											return iState;
										});
									}}
									_onKeyEnter={(xData) => {
										if (xData !== "") {
											refKeterangan.current.focus();
										}
									}}
								/>
							</div>
						</div>
						{/* ============= Keterangan ==================== */}
						<div className=" flex">
							<div className=" w-20 md:w-32">Keterangan</div>
							<div className=" w-1 text-center">:</div>
							<div className="ml-1 flex-1">
								<CompText
									_ref={refKeterangan}
									_value={sDataMenabung.keterangan}
									_maxLength={150}
									_className=" w-full"
									_onKeyEnter={(xData) => {
										setsDataMenabung((xState) => {
											let iState = { ...xState };
											iState.keterangan = xData;
											iState.ref = null;
											return iState;
										});

										if (sDataMenabung.setor > 0 && sDataMenabung.no_rekening !== "") {
											setSAlert((xState) => {
												let iState = { ...xState };
												iState.show = true;
												iState.title = "Menabung";
												iState.keterangan = "Apakah Data Sudah Benar ?";
												return iState;
											});
										}
									}}
								/>
							</div>
						</div>
					</div>

					{/* DOCUMENT SETOR TABUNGAN */}
					<div className="flex justify-center w-full mt-4 bg-blue-100 rounded-t-md">
						<div className="text-lg"> Dokumen Setor Tabungan</div>
					</div>

					{/* TABLE */}
					<div className="flex-1 w-full   overflow-y-scroll bg-blue-100">
						<table className="Table">
							<thead>
								<tr>
									<th>No</th>
									<th>Tanggal</th>
									<th>ID Tabungan</th>
									<th>Nama</th>
									<th>Nabung</th>
									<th>Saldo</th>
									<th>Keterangan</th>
								</tr>
							</thead>
							<tbody>
								{sRsDocumentTabungan
									.map((xData, xIndex) => {
										let iTanggal = xData.tabungan_detail_id.substring(0, 8);
										iTanggal =
											iTanggal.substring(6, 8) +
											"-" +
											iTanggal.substring(4, 6) +
											"-" +
											iTanggal.substring(0, 4);
										return (
											<tr key={xIndex}>
												<td>{xIndex + 1}</td>
												<td>{iTanggal}</td>
												<td>{xData.tabungan_id}</td>
												<td>{xData.members_name}</td>
												<td align="right">
													{Env.format.uang(xData.tabungan_detail_kredit)}
												</td>
												<td align="right">
													{Env.format.uang(xData.tabungan_detail_saldo)}
												</td>
												<td>{xData.tabungan_detail_keterangan}</td>
											</tr>
										);
									})
									.reverse()}
							</tbody>
						</table>
					</div>
					<div className=" h-16 w-full "></div>
				</div>
			</div>
		);
	} catch (xErr) {
		return null;
	}
};

export default GlobalStateConsumer(Menabung);
