// import Laporan from "./laporan";
// import Home from "./home";

// export { Home, Laporan };

import { Fragment, useEffect, useState } from "react";
import {
	BrowserRouter as Router,
	Link,
	// Redirect,
	Route,
	Switch,
} from "react-router-dom";

// State Global Dengan Contex
import { GlobalStateConsumer } from "../../state.global";
import MenuBarger from "../../component/menuBarger";
import Home from "./home";
import Organisasi from "./organisasi";
import Login from "../../components/login";
import LoginRegistrasiAccount from "../../components/loginRegistrasiAccount";
import Env from "../../setting/env";

function Pages(props) {
	const [sAccount, setSAccount] = useState("");
	const [sBorderColor, setsBorderColor] = useState({
		eHome: "",
		eOrganisasi: "",
	});
	const [sShowSmUl, setSShowSmUl] = useState(false);

	useEffect(() => {
		setSShowSmUl(false);
	}, [props.state.sLayarSize]);

	return (
		<Fragment>
			<Router>
				<div className="z-100 fixed w-screen">
					<nav className="Nav">
						{/* 101 Kiri  Title*/}
						<div className="WadahFix hidden  items-center mx-1 p-1  w-auto md:flex">
							<p className="truncate">{props.state.sPages.nama}</p>
						</div>

						{/* 201 Tengah Barger Menu */}
						<div className="flex flex-col  md:justify-center">
							<div className="flex md:hidden">
								<MenuBarger
									_status={(xValue) => {
										setSShowSmUl(xValue);
									}}
									_setStatus={sShowSmUl}
								/>
							</div>

							<ul
								className="NavUl"
								style={
									props.state.sLayarSize === "sm"
										? sShowSmUl
											? { display: "flex", left: "-1px" }
											: { display: "none" }
										: { display: "flex" }
								}
							>
								<li
									className="Button  h-6 "
									style={{ borderColor: sBorderColor.eHome }}
									onClick={() => {
										setSShowSmUl(false);
									}}
								>
									<Link to="/">Home</Link>
								</li>

								{props.state.sUser.eStatus === 100 ? (
									<li
										className="Button  h-6 "
										style={{ borderColor: sBorderColor.eOrganisasi }}
										onClick={() => {
											setSShowSmUl(false);
										}}
									>
										<Link to="/organisasi">Organisasi</Link>
									</li>
								) : (
									""
								)}
							</ul>
						</div>
						<div className="WadahFix flex md:hidden items-center mx-1 p-1 ">
							<p className="truncate">{props.state.sPages.nama}</p>
						</div>

						{/* 301 Kanan */}
						<div className="flex flex-1">
							<div className="flex-1"></div>
							<div className="flex text-base mr-1 md:mr-3 space-x-1">
								<div className="Button rounded-xl  w-20 md:w-28">
									<p className="truncate">{props.state.sUser.eUserName}</p>
								</div>
								<div
									className="Button rounded-xl"
									onClick={() => {
										props.dispatch({ type: "logOut" });
										props._onLogOut();
									}}
								>
									Logout
								</div>
							</div>
						</div>
					</nav>
				</div>

				<div className="relative  w-full h-screen top-10">
					<Switch>
						<Route path="/organisasi">
							{/* <Redirect to="/" /> */}
							<Organisasi
								_onActive={() => {
									setsBorderColor((xState) => {
										let iState = { ...xState };
										iState.eHome = "";
										iState.eOrganisasi = Env.color.orangeRed02;
										return iState;
									});
								}}
							/>
						</Route>
						<Route path="/">
							<Home
								_onActive={() => {
									setsBorderColor((xState) => {
										let iState = { ...xState };
										iState.eHome = Env.color.orangeRed02;
										iState.eOrganisasi = "";
										return iState;
									});
								}}
							/>
						</Route>
					</Switch>
					{sAccount === "login" ? (
						<Login
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eLogin = "";
									return iState;
								});
							}}
						/>
					) : sAccount === "Registrasi" ? (
						<LoginRegistrasiAccount
							_close={() => {
								setSAccount("");
								setsBorderColor((xState) => {
									let iState = { ...xState };
									iState.eRegister = "";
									return iState;
								});
							}}
						/>
					) : (
						""
					)}
				</div>
			</Router>
		</Fragment>
	);
}

export default GlobalStateConsumer(Pages);
