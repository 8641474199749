import { useEffect, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";

import CompImageRead from "../../../../components/image/imageRead";
import CompImageEdit from "../../../../components/image/imageEdit";

const KelolaPhoto = (props) => {
	const [sLoading, setSLoading] = useState(true);
	const [sImages, setImages] = useState([]);
	const [sStatus, setSStatus] = useState([]);
	const [sLihatImage, setLihatImage] = useState({
		eImage: "",
		eIndex: 0,
		eEdit: false,
	});

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/photo`,

			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		).then((xRes) => {
			const iRsImages = [];
			const iRsStatus = [];
			const iDatas = xRes.data.message;
			for (let i = 0; i < iDatas.length; i++) {
				iRsImages.push({
					id: iDatas[i].id,
					url: iDatas[i].url,
					public_id: iDatas[i].public_id,
					status: 0,
				});
				iRsStatus.push(false);
			}
			setImages(iRsImages);
			setSStatus(iRsStatus);
			setSLoading(false);
		});
		return () => {
			setImages(null);
			setSStatus(null);
		};
	}, []);

	const fResetLihatImage = () => {
		setLihatImage((psLihatImage) => {
			let iLihatImage = { ...psLihatImage };
			iLihatImage.eImage = "";
			iLihatImage.eIndex = 0;
			return iLihatImage;
		});
	};

	const fResetStatus = (xIndex) => {
		setSStatus((xState) => {
			const iState = [...xState];
			iState[xIndex] = false;
			return iState;
		});
	};

	return (
		<div
			className=" bg-gray-400 fixed w-screen h-screen 
		  	flex flex-col"
		>
			{sLoading ? <CompLoading /> : null}
			<div className=" h-1 md:h-10 xl:h-1 "></div>
			<div className="flex-1 flex flex-wrap justify-center overflow-auto">
				{sImages.map((pImage, pIndex) => {
					return (
						<CompImageRead
							key={pIndex}
							_image={pImage.url.file ? pImage.url.file : pImage.url}
							_upload={pImage.url.file ? true : false}
							_index={pIndex}
							_loading={sStatus[pIndex]}
							_onNewImage={(xImage) => {
								setImages((psImages) => {
									const iDataNewImage = {
										id: 0,
										url: xImage,
										public_id: "",
									};
									const iImages = [...psImages, iDataNewImage];
									return iImages;
								});
								setSStatus((xState) => {
									const iState = [...xState, false];
									return iState;
								});
							}}
							_onUpload={() => {
								// ====== UPLOAD IMAGE ====
								setSStatus((xState) => {
									const iState = [...xState];
									iState[pIndex] = true;
									return iState;
								});
								const iData = new FormData();
								iData.append("file", pImage.url.file);
								iData.append("upload_preset", Env.cloudinary.preset.ngiring_ngayah);
								Axios.post(Env.cloudinary.upload_url, iData)
									.then((xRes) => {
										Axios.post(
											`${props.state.sPages.url}${Env.eRouteApi}/admin/photo`,
											{
												url: xRes.data.secure_url,
												public_id: xRes.data.public_id,
											},
											{
												headers: {
													authorization: `bearer ${props.state.sToken}`,
												},
											}
										)
											.then((xRes) => {
												if (xRes.status === Env.eHttpStatus.eOk) {
													const iRes = xRes.data.message;
													setImages((xState) => {
														const iState = [...xState];
														const iObj = {
															id: iRes.id,
															url: iRes.url,
															public_id: iRes.public_id,
														};
														iState[pIndex] = iObj;
														return iState;
													});

													fResetStatus(pIndex);
												}
											})
											.catch((xErr) => {
												fResetStatus(pIndex);
												alert("Gagal");
											});
									})
									.catch((xErr) => {
										fResetStatus(pIndex);
										alert("Gagal");
									});
							}}
							_onHapus={() => {
								// ==== HAPUS IMAGE
								if (pImage.url.file) {
									setImages((psImages) => {
										let iImages = [...psImages];
										iImages.splice(pIndex, 1);
										return iImages;
									});
									setSStatus((xState) => {
										const iState = [...xState];
										iState.splice(pIndex, 1);
										return iState;
									});
								} else {
									// ======DELETE UPLOADED IMAGE ====
									setSStatus((xState) => {
										const iState = [...xState];
										iState[pIndex] = true;
										return iState;
									});

									Axios.delete(`${props.state.sPages.url}${Env.eRouteApi}/admin/photo`, {
										data: { id: pImage.id, public_id: pImage.public_id },
										headers: {
											authorization: `bearer ${props.state.sToken}`,
										},
									}).then((xRes) => {
										setImages((psImages) => {
											let iImages = [...psImages];
											iImages.splice(pIndex, 1);
											return iImages;
										});
										setSStatus((xState) => {
											const iState = [...xState];
											iState.splice(pIndex, 1);
											return iState;
										});
									});
								}
							}}
							_onLihatImage={(xImage, xIndex) => {
								setLihatImage((psLihatImage) => {
									let iLihatImage = { ...psLihatImage };
									iLihatImage.eImage = xImage;
									iLihatImage.eIndex = xIndex;
									iLihatImage.eEdit = false;
									return iLihatImage;
								});
							}}
						/>
					);
				})}

				{sImages.length < 6 ? (
					<CompImageRead
						_photoOnly={true}
						_onNewImage={(xImage) => {
							setImages((psImages) => {
								const iDataNewImage = {
									id: 0,
									url: xImage,
									public_id: "",
								};
								const iImages = [...psImages, iDataNewImage];
								return iImages;
							});
							setSStatus((xState) => {
								const iState = [...xState, false];
								return iState;
							});
						}}
					/>
				) : (
					""
				)}

				{/* Lihat Image */}
				{sLihatImage.eImage ? (
					<div
						style={{
							display: "flex",
							width: "100%",
							height: "100%",
							backgroundColor: "RGBA(0,0,0,0.4)",
							position: "absolute",
						}}
					>
						<CompImageEdit
							_image={sLihatImage.eImage}
							_index={sLihatImage.eIndex}
							_onNewImage={(pImage) => {
								setLihatImage((psLihatImage) => {
									let iLihatImage = { ...psLihatImage };
									iLihatImage.eImage = pImage.file;
									iLihatImage.eEdit = true;
									return iLihatImage;
								});
							}}
							_onOK={() => {
								if (sLihatImage.eEdit === true) {
									// ====== UPLOAD IMAGE ====
									setSStatus((xState) => {
										const iState = [...xState];
										iState[sLihatImage.eIndex] = true;
										return iState;
									});

									Axios.delete(`${props.state.sPages.url}${Env.eRouteApi}/admin/photo`, {
										data: {
											public_id: sImages[sLihatImage.eIndex].public_id,
										},
										headers: {
											authorization: `bearer ${props.state.sToken}`,
										},
									})
										.then((xRes) => {
											if (xRes.status === Env.eHttpStatus.eOk) {
												// Upload to Cloudinary Image
												const iFormUpload = new FormData();
												iFormUpload.append("file", sLihatImage.eImage);
												iFormUpload.append(
													"upload_preset",
													Env.cloudinary.preset.ngiring_ngayah
												);
												Axios.post(Env.cloudinary.upload_url, iFormUpload)
													.then((xResCloudinary) => {
														const iDataCloudinary = {
															id: sImages[sLihatImage.eIndex].id,
															url: xResCloudinary.data.secure_url,
															public_id: xResCloudinary.data.public_id,
														};
														Axios.put(
															`${props.state.sPages.url}${Env.eRouteApi}/admin/photo`,
															iDataCloudinary,
															{
																headers: {
																	authorization: `bearer ${props.state.sToken}`,
																},
															}
														)
															.then((xRes) => {
																if (xRes.status === Env.eHttpStatus.eOk) {
																	setImages((xState) => {
																		const iState = [...xState];
																		iState[sLihatImage.eIndex] = iDataCloudinary;
																		return iState;
																	});
																	setSStatus((xState) => {
																		const iState = [...xState];
																		iState[sLihatImage.eIndex] = false;
																		return iState;
																	});
																}
															})
															.catch((xErr) => {
																alert("Gagal");
															});
													})
													.catch((xErr) => {
														alert("Gagal");
													});
											}
										})
										.catch((xErr) => {
											setSStatus((xState) => {
												const iState = [...xState];
												iState[sLihatImage.eIndex] = false;
												return iState;
											});
											alert("Error");
										});
								}
								fResetLihatImage();
							}}
							_onCancel={() => {
								fResetLihatImage();
							}}
						/>
					</div>
				) : (
					""
				)}
			</div>
			<div className=" h-12"></div>
		</div>
	);
};
export default GlobalStateConsumer(KelolaPhoto);
