import { useState } from "react";

import { GlobalStateConsumer } from "../../state.global";
import CompUpdate from "../../components/updateAccount";
const Account = (props) => {
	const [sUpdateAccount, setSUpdateAccount] = useState(false);

	return (
		<div className="Modal z-101 py-2 ">
			<div
				className="flex flex-col w-full max-w-screen-sm 
                bg-gradient-to-t from-genkiBlue-cl_01 to-genkiBlue-light
                border-genkiBlue-dark border-solid border-3
                rounded-xl"
			>
				<div className="flex flex-col w-full ">
					<div
						className="flex flex-col w-full  h-36 
                        bg-gradient-to-t from-genkiBlue-cl_01 to-genkiBlue-light
                        rounded-lg"
					>
						<div className=" flex ">
							<div className="flex-1 flex justify-center">
								<p className="text-xl">Akun</p>{" "}
							</div>
							<div
								className="flex justify-center items-center
                                bg-red-400 rounded-full w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100 m-auto"
								onClick={() => {
									props._onClose();
								}}
							>
								X
							</div>
						</div>
						<hr style={{ color: "gray", height: 5 }} />
						<div className="flex  px-1 mt-2 mx-2 pr-2 bg-blue-100 shadow-md rounded-lg">
							<div className=" w-24">Nama Akun</div>
							<div className=" w-2">:</div>
							<div className="flex-1 truncate">{props.state.sUser.eUserName}</div>
						</div>
						<div className="flex px-1 mt-2 mx-2 bg-blue-100 shadow-md rounded-lg">
							<div className=" w-24">User Login</div>
							<div className=" w-2">:</div>
							<div className="flex-1 truncate">{props.state.sUser.eUserLogin}</div>
						</div>
						<div className="w-full mt-2 h-6">
							<button
								className="Button mx-auto h-6"
								onClick={() => {
									setSUpdateAccount(true);
								}}
							>
								Ubah
							</button>
						</div>
					</div>

					{sUpdateAccount ? (
						<div>
							<CompUpdate
								_onBatal={() => {
									setSUpdateAccount(false);
								}}
							/>
						</div>
					) : (
						""
					)}
				</div>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(Account);
