import { Fragment, useState, useEffect } from "react";

const Select = (props) => {
	const [sValue, setsValue] = useState("");
	const [sResnValue, setSResnValue] = useState("");

	useEffect(() => {
		if (props._onClick && sResnValue !== "") {
			props._onClick(sValue);
		}
		setSResnValue(sValue);
		// eslint-disable-next-line
	}, [sValue]);

	useEffect(() => {
		if (sValue === "" && props._value) {
			setsValue(props._value);
		} else {
			setsValue(props._data[0].value);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<Fragment>
			<div className="Wadah" style={{ padding: "2px" }}>
				<div className="flex flex-col md:flex-row">
					<div className="flex mx-auto md:hidden font-medium">{props._title}</div>

					<div className="hidden md:flex font-medium items-center ">
						<div
							className="flex font-medium items-center ml-1 "
							style={{
								width: props._titleWidth || "140px",
							}}
						>
							{props._title}
						</div>
						<div className="mx-0.5">:</div>
					</div>

					<div className="flex flex-1">
						<div className="flex-1 flex-col items-center ">
							<select
								className="Text"
								ref={props._ref}
								value={sValue}
								onChange={(xData) => {
									setsValue(xData.nativeEvent.target.value);
									// if (props._onClick) {
									// 	props._onClick(xData.nativeEvent.target.value);
									// }
								}}
								onKeyUp={(xKey) => {
									if (xKey.nativeEvent.key === "ArrowUp") {
										if (props._onKeyUp) {
											if (xKey.nativeEvent.target.value === props._data[0].value) {
												props._onKeyUp(xKey.nativeEvent.target.value);
											}
										}
									}
								}}
								onKeyPress={(xKey) => {
									if (xKey.nativeEvent.key === "Enter") {
										if (props._onKeyEnter) {
											props._onKeyEnter(xKey.nativeEvent.target.value);
										}
									}
								}}
							>
								{props._data
									? props._data.map((xData, xIndex) => {
											return (
												<option key={xIndex} value={xData.value}>
													{xData.text}
												</option>
											);
									  })
									: ""}
							</select>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Select;
