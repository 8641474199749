import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";

import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompLoading from "../../../../../component/arjLoading";
import CompAlertSimpan from "../../../../../component/arjAlert";
// import CompAlertUpdate from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";
import CompAlert from "../../../../../component/arjAlert";

const KegiatanPribadi = (props) => {
	const iTanggal = Env.efTanggal();

	const refKegiatan = useRef(null);
	const refJenis = useRef(null);
	const refNilai = useRef(null);
	const refSimpan = useRef(null);
	const refTanggal = useRef(null);
	const refNama = useRef(null);
	const refKeterangan = useRef(null);

	const [sRef, setSRef] = useState(useRef(null));
	const [sRsJenisKegiatan, setSRsJenisKegiatan] = useState([]);
	// const [sRsNilaiKegiatan, setSRsNilaiKegiatan] = useState([]);
	// const [sRsGroup, setSRsGroup] = useState([]);
	const [sRsNewDataJenisKegiatan, setSRsNewDataJenisKegiatan] = useState([]);
	const [sRsKegiatan, setSRsKegiatan] = useState([]);
	const [sSaved, setSSaved] = useState(false);

	const [sRsMembers, setSRsMembers] = useState([]);
	const [sJenisKegiatan, setSJenisKegiatan] = useState({ id: 0, nama: "" });
	const [sMemberName, setSMemberName] = useState({ id: 0, nama: "" });
	const [sKegiatan, setSKegiatan] = useState("");
	const [sKeterangan, setSKeterangan] = useState("");
	const [sTanggal, setSTanggal] = useState(iTanggal);
	const [sNilaiKegiatan, setSNilaiKegiatan] = useState(0);
	const [sLoading, setSLoading] = useState(true);
	// const [sJenisFocus, setSJenisFocus] = useState(false);
	const [sAlertSimpan, setSAlertSimpan] = useState(false);
	const [sDelete, setSDelete] = useState({ index: -1, show: false });

	// const fPeserta = (xRsPeserta) => {
	// 	let iData = "";
	// 	for (let i = 0; i < xRsPeserta.length; i++) {
	// 		iData += xRsPeserta[i].group.nama + ", ";
	// 	}
	// 	return iData;
	// };

	// const fNilaiJenisKegiatan = (xJenisKegiatanID) => {
	// 	for (let i = 0; i < sRsNilaiKegiatan.length; i++) {
	// 		if (sRsNilaiKegiatan[i].jenis_id === xJenisKegiatanID) {
	// 			return sRsNilaiKegiatan[i].nilai;
	// 		}
	// 	}
	// };
	const fGetData = async () => {
		const iData = await Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/jenis_kegiatan`,
			{
				params: { req: "jenis_kegiatan", tanggal: sTanggal, members: "get" },
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		);

		if (iData.status === Env.eHttpStatus.eOk) {
			setSRsJenisKegiatan([
				{ id: "", nama: "", keterangan: "" },
				...iData.data.message.rsJenisKegiatan,
			]);

			setSRsMembers(iData.data.members);

			// =====Data kegiatan hari ini
			setSRsKegiatan(iData.data.message.rsKegiatan);
			// console.log(iData.data.message.rsKegiatan);
		}
		setSLoading(false);
	};

	useEffect(() => {
		try {
			fGetData();
			refNama.current.focus();
		} catch (xErr) {}
		// eslint-disable-next-line
	}, [sTanggal]);

	useEffect(() => {
		try {
			sRef.current.focus();
			sRef.current.select();
		} catch (error) {}
	}, [sRef]);
	return (
		<div className="flex flex-col w-full h-screen md:p-2">
			{sLoading ? <CompLoading /> : null}
			{sAlertSimpan ? (
				<CompAlertSimpan
					_title="Menyimpan Data Jenis Kegiatan"
					_keterangan="Apakah data sudah benar ?"
					_buttonCancel="Batal"
					_onCancel={() => {
						setSAlertSimpan(false);
						refKegiatan.current.focus();
						refKegiatan.current.select();
					}}
					_onOk={() => {
						if (!sLoading) {
							setSLoading(true);

							const iJenisKegiatan = [];
							for (let i = 0; i < sRsNewDataJenisKegiatan.length; i++) {
								iJenisKegiatan.push({
									id: sRsNewDataJenisKegiatan[i].jenis_kegiatan.id,
									nilai: sRsNewDataJenisKegiatan[i].nilai,
								});
							}
							Axios.post(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,
								{
									req: "post",
									tanggal: sTanggal,
									kegiatan: sKegiatan,
									group: [],
									members_id: sMemberName.id,
									keterangan: sKeterangan,
									jenis_kegiatan: iJenisKegiatan,
								},
								{
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							).then((xRes) => {
								//console.log(xRes.data);
								if (xRes.status === Env.eHttpStatus.eOk) {
									setSRsKegiatan(xRes.data.message);
									setSSaved(true);
								} else {
									alert("Error");
								}
								setSAlertSimpan(false);
								setSLoading(false);
							});
						}
					}}
				/>
			) : null}

			{sDelete.show ? (
				<CompAlertDelete
					_title="Hapus Data Kegiatan"
					_keterangan={`Apakah benar akan menhapus  ${sDelete.index + 1} ?`}
					_buttonCancel="Batal"
					_onCancel={() => {
						setSDelete({ index: -1, show: false });
					}}
					_onOk={() => {
						if (!sLoading) {
							setSDelete({ index: -1, show: false });
							setSLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/kegiatan`,

								{
									data: { id: sRsKegiatan[sDelete.index].id },
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							)
								.then((xRes) => {
									if (xRes.status === Env.eHttpStatus.eOk) {
										setSRsKegiatan(xRes.data.message);
										setSRsNewDataJenisKegiatan([]);
										setSKegiatan("");
										setSSaved(false);
										refKegiatan.current.focus();
									} else {
										alert("Error");
									}
									setSDelete({ index: -1, show: false });
									setSLoading(false);
									// console.log(xRes.data);
								})
								.catch((xErr) => {
									setSDelete({ index: -1, show: false });
									setSLoading(false);
								});
						}
					}}
				/>
			) : null}

			<div className=" flex justify-center  md:text-xl w-full mt-2 h-6 md:h-8">
				Buat Kegiatan Pribadi
			</div>
			<div className="flex-1 w-full bg-blue-300 overflow-y-scroll rounded-lg relative">
				{sSaved ? (
					// <div className=" bg-genkiGray-darkest bg-opacity-20 z-20 w-full h-full absolute"></div>
					<CompAlert
						_title="Menyimpan Data "
						_keterangan="Data Telah Tersimpan"
						_onOk={() => {
							setSSaved(false);
							setSRsNewDataJenisKegiatan([]);
							refTanggal.current.focus();
						}}
					/>
				) : null}

				<div className=" flex justify-center  md:text-lg w-full">
					{sSaved ? "Data Tersimpan" : "Data Baru"}
				</div>
				<hr />

				{/* Tanggal */}
				<div className="flex w-full mt-2 md:px-2">
					<div className=" w-20">Tanggal</div>
					<div className=" flex justify-center w-4 ">:</div>
					<div className="flex-1">
						<input
							className="Text flex h-8 w-40"
							ref={refTanggal}
							type="date"
							value={sTanggal}
							onChange={(xData) => {
								setSTanggal(xData.target.value);
								// iRefDebet.current.focus();
							}}
							onKeyUp={(xEvent) => {
								if (xEvent.nativeEvent.key === "Enter") {
									refNama.current.focus();
									refNama.current.select();
								}
							}}
							onClick={() => {
								refNama.current.focus();
								refNama.current.select();
							}}
						/>
					</div>
				</div>

				{/* Nama Members */}
				<div className="flex w-full mt-2 md:px-2">
					<div className=" w-20">Nama</div>
					<div className=" flex justify-center w-4 ">:</div>
					<CompTextDs
						_ref={refNama}
						_dataSource={sRsMembers}
						_value={sMemberName.nama}
						_onKeyEnter={(xData) => {
							if (xData[0] !== "") {
								setSMemberName({ id: xData[0], nama: xData[1] });
								refKegiatan.current.focus();
								refKegiatan.current.select();
							}
						}}
					/>
				</div>

				{/* Kegiatan */}
				<div className="flex w-full mt-2 md:px-2">
					<div className=" w-20">Kegiatan</div>
					<div className=" flex justify-center w-4 ">:</div>
					<div className="flex-1">
						<CompText
							_ref={refKegiatan}
							_value={sKegiatan}
							_onChange={(xData) => {
								setSRef(null);
								setSKegiatan(xData);
							}}
							_onKeyEnterUp={() => {
								if (sKegiatan !== "") {
									setSRef(refKeterangan);
								}
							}}
							_onFocus={() => {
								setSRef(null);
							}}
						/>
					</div>
				</div>

				{/* Keterangan */}
				<div className="flex w-full mt-2 md:px-2">
					<div className=" w-20">Keterangan</div>
					<div className=" flex justify-center w-4 ">:</div>
					<textarea
						ref={refKeterangan}
						className="Text h-20"
						value={sKeterangan}
						onFocus={() => {
							if (sKegiatan === "") {
								refKegiatan.current.focus();
								refKegiatan.current.select();
							}
						}}
						onChange={(xData) => {
							const iData = xData.nativeEvent.target.value;
							setSKeterangan(iData);
						}}
					></textarea>
				</div>

				{/* Jenis */}
				<div className=" mt-2 md:px-2 w-full bg-blue-100 p-1">
					<div className="flex justify-center w-full pb-1 md:space-x-2">
						<div className="flex flex-col flex-1  md:max-w-4xl">
							<div className="flex justify-center">Jenis</div>
							<div>
								<CompTextDs
									_ref={refJenis}
									_dataSource={sRsJenisKegiatan}
									_value={sJenisKegiatan.nama}
									// _onFocus={() => {
									// 	setSJenisFocus(true);
									// }}
									// _onBlur={() => {
									// 	setSJenisFocus(false);
									// }}
									_onKeyEnter={(xData) => {
										if (xData[0] !== "") {
											setSJenisKegiatan({ id: xData[0], nama: xData[1] });
											// setSNilaiKegiatan(fNilaiJenisKegiatan(xData[0]));
											refNilai.current.focus();
											refNilai.current.select();
										} else if (sRsNewDataJenisKegiatan.length > 0) {
											refSimpan.current.focus();
										} else {
											refKegiatan.current.focus();
											refKegiatan.current.select();
										}
									}}
								/>
							</div>
						</div>
						<div className="flex flex-col w-28 md:w-44">
							<div className="flex justify-center">Nilai</div>
							<div>
								<CompText
									_ref={refNilai}
									_dataType="ribuan"
									_value={sNilaiKegiatan}
									_onNilai={(xData) => {
										setSNilaiKegiatan(xData);
									}}
									_onKeyEnterUp={async () => {
										// if (!sLoading && sNilaiKegiatan !== 0) {
										// 	setSLoading(true);

										const iObjJenisKegiatan = {
											jenis_kegiatan: { ...sJenisKegiatan },
											nilai: sNilaiKegiatan,
										};
										setSRsNewDataJenisKegiatan((xState) => {
											let iState = [...xState];
											iState.push(iObjJenisKegiatan);
											// console.log(iState);
											return iState;
										});
										setSJenisKegiatan({ id: "", nama: "" });
										setSNilaiKegiatan(0);
										// setSLoading(false);
										refJenis.current.focus();
										// }
									}}
								/>
							</div>
						</div>
					</div>
					{/* {sJenisFocus ? <div className=" h-20"></div> : null} */}
					{/* Table Jenis Kegiatan */}
					<table className="Table">
						<thead>
							<tr>
								<th>No</th>
								<th>Jenis</th>
								<th>Nilai</th>
								<th>Hapus</th>
							</tr>
						</thead>

						<tbody>
							{sRsNewDataJenisKegiatan.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td>{xIndex + 1}</td>
										<td>{xData.jenis_kegiatan.nama}</td>
										<td>{Env.format.uang(xData.nilai)}</td>
										<td width={50}>
											{sSaved ? null : (
												<button
													className="Button h-6"
													onClick={() => {
														setSRsNewDataJenisKegiatan((xState) => {
															let iState = [...xState];
															iState.splice(xIndex, 1);
															return iState;
														});
													}}
												>
													Hapus
												</button>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
					{/* End Table Jenis Kegiatan */}

					<div className="flex justify-center pt-1">
						{sRsNewDataJenisKegiatan.length > 0 && !sSaved ? (
							<div className="z-10 rounded-xl p-0.5 bg-blue-200 ">
								<button
									className="Button  "
									ref={refSimpan}
									onClick={() => {
										setSAlertSimpan(true);
									}}
								>
									Simpan
								</button>
							</div>
						) : null}
					</div>
				</div>
			</div>

			<div className=" max-h-32 md:max-h-20 xl:max-h-40 overflow-y-auto">
				<table className="Table">
					<thead>
						<tr>
							<th>No</th>
							<th>Nama</th>
							<th>Kegiatan - Keterangan</th>
							<th>Jenis</th>
							<th>Nilai</th>
						</tr>
					</thead>
					<tbody>
						{sRsKegiatan
							.map((xData, xIndex) => {
								try {
									return (
										<tr
											key={xIndex}
											style={{
												backgroundColor:
													sDelete.index === xIndex ? Env.color.orangeRed02 : "",
											}}
										>
											<td width={40}>
												<button
													className="Button h-5 w-10"
													onClick={() => {
														setSDelete({
															index: xIndex,
															show: true,
														});
													}}
												>
													{xIndex + 1}
												</button>
											</td>

											<td>{xData.kegiatan_bayar[0].members.name}</td>
											<td>{`${xData.kegiatan} - ${xData.keterangan}`}</td>
											<td>{xData.kegiatan_details[0].kegiatan_jenis.nama}</td>
											<td align="right">
												{Env.format.uang(xData.kegiatan_details[0].nilai)}
											</td>
										</tr>
									);
								} catch (error) {
									fGetData();
									return null;
								}
							})
							.reverse()}
					</tbody>
				</table>
			</div>
			<div className=" h-14"></div>
		</div>
	);
};

export default GlobalStateConsumer(KegiatanPribadi);
