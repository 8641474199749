import { useEffect, useState, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";

import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";
import CompAlertSimpan from "../../../../component/arjAlert";
import CompInput from "./InputDataJurnal";
import CompText from "../../../../component/arjText";
import CompNota from "./nota";
import CompEditDataJurnal from "./editDataJurnal";

const Jurnal = (props) => {
	const [sDataJurnalEdit, setSDataJurnalEdit] = useState({
		eIndex: -1,
		eId: "",
		eTanggal: { value: "", status: false },
		eDebet: { value: "", akun: "", ref: "", status: false },
		eKredit: { value: "", akun: "", ref: "", status: false },
		eNilai: { value: 0, status: false },
		eKeterangan: { value: "", status: false },
		eNota: { no: 0, value: [] },
		eFocus: useRef(null),
	});

	const fDataJurnalEdit = (xIndex) => {
		let iDataJurnal = sRsDataJurnal[xIndex];
		setSDataJurnalEdit((xState) => {
			let iState = { ...xState };
			iState.eIndex = xIndex;
			iState.eTanggal.value = iDataJurnal.tanggal;
			iState.eTanggal.status = true;
			iState.eId = iDataJurnal.id;
			iState.eDebet.value = iDataJurnal.debet;
			iState.eDebet.ref = iDataJurnal.debet_ref;
			iState.eDebet.status = true;
			iState.eKredit.value = iDataJurnal.kredit;
			iState.eKredit.ref = iDataJurnal.kredit_ref;
			iState.eKredit.status = true;
			iState.eNilai.value = iDataJurnal.nilai;
			iState.eNilai.status = true;
			iState.eKeterangan.value = iDataJurnal.keterangan;
			iState.eKeterangan.status = true;
			iState.eNota.no = iDataJurnal.no_nota;
			iState.eNota.value = iDataJurnal.nota;
			// console.log(iState);
			return iState;
		});
	};
	const [sDataJurnal, setSDataJurnal] = useState({
		eTanggal: { value: Env.efTanggal(), status: false },
		eDebet: { value: "", akun: "", ref: "", status: false },
		eKredit: { value: "", akun: "", ref: "", status: false },
		eNilai: { value: 0, status: false },
		eKeterangan: { value: "", status: false },
		eNota: { no: 0, value: [] },
	});

	const [sAlertSimpan, setSAlertSimpan] = useState({
		eStatus: false,
		eRef: useRef(null),
	});
	const [sRsDataJurnal, setSRsDataJurnal] = useState([]);
	const [sRsAkun, setSRsAkun] = useState([]);
	const [sRsSeksi, setSRsSeksi] = useState([]);
	const [sSeksiDefault, setSSeksiDefault] = useState("");
	const [sNota, setSNota] = useState({ eNoNota: 0, eStatus: false });
	const [sLoading, setSLoading] = useState(true);
	const [sShowInputDataJurnal, setSShowInputDataJurnal] = useState(true);

	const iRefTanggal = useRef(null);
	const iRefDebet = useRef(null);
	const iRefKredit = useRef(null);
	const iRefNilai = useRef(null);
	const iRefKeterangan = useRef(null);
	const iRefSimpan = useRef(null);

	let mNoJurnal = 0;

	const fSort = (xData) => {
		switch (xData) {
			case "tanggal":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						const iA = xA.tanggal.toLowerCase();
						const iB = xB.tanggal.toLowerCase();
						if (iA < iB) {
							return -1;
						}
						if (iA > iB) {
							return 1;
						}
						return 0;
					});
					return iState;
				});

				break;

			case "akunDebet":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						const iA = xA.debet.toLowerCase();
						const iB = xB.debet.toLowerCase();
						if (iA < iB) {
							return -1;
						}
						if (iA > iB) {
							return 1;
						}
						return 0;
					});
					return iState;
				});

				break;

			case "refDebet":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						const iA = xA.debet_ref.toLowerCase();
						const iB = xB.debet_ref.toLowerCase();
						if (iA < iB) {
							return -1;
						}
						if (iA > iB) {
							return 1;
						}
						return 0;
					});
					return iState;
				});

				break;

			case "akunKredit":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						const iA = xA.kredit.toLowerCase();
						const iB = xB.kredit.toLowerCase();
						if (iA < iB) {
							return -1;
						}
						if (iA > iB) {
							return 1;
						}
						return 0;
					});
					return iState;
				});

				break;

			case "refKredit":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						const iA = xA.kredit_ref.toLowerCase();
						const iB = xB.kredit_ref.toLowerCase();
						if (iA < iB) {
							return -1;
						}
						if (iA > iB) {
							return 1;
						}
						return 0;
					});
					return iState;
				});

				break;
			case "nilai":
				setSRsDataJurnal((xState) => {
					let iState = [...xState];
					iState.sort((xA, xB) => {
						return xA.nilai - xB.nilai;
					});
					return iState;
				});

				break;

			default:
				break;
		}
	};

	const fRsJurnalUmum = (xTanggal) => {
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/jurnalumum`, {
			params: { req: "rs_jurnal_umum" },
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		})
			.then((xData) => {
				setSRsDataJurnal(xData.data.message);
				setSLoading(false);
			})
			.catch((xErr) => {
				alert(xErr);
			});
	};
	const fGetIsiNota = (xNota, xIndex) => {
		let iText = "";
		let iTotal = 0;
		xNota.map((xData, xIndex) => {
			let iKoma = ",";
			if (xIndex === 0) {
				iKoma = "";
			}
			iText += `${iKoma} ${Env.format.uang(xData.banyak)}${xData.satuan} ${
				xData.nama_barang
			} * ${Env.format.uang(xData.harga)} = ${Env.format.uang(xData.jumlah)}`;
			iTotal += xData.jumlah;
			return "";
		});
		return `${iText} , Jumlah = ${Env.format.uang(iTotal)}`;
	};
	const fFilterAkun = (xValue) => {
		if (sRsAkun) {
			const iRegExp = new RegExp(`^${xValue}`);
			const iDataSource = sRsAkun.filter((xData) => {
				return iRegExp.test(Object.values(xData)[0]);
			});
			if (iDataSource.length > 0) {
				return ` / ${iDataSource[0].nama}`;
			} else {
				return "";
			}
		}
	};
	useEffect(() => {
		if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
			iRefTanggal.current.focus();
		}
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/akun`, {
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
			params: { type: "Anak" },
		})
			.then((xData) => {
				const iData = xData.data.message;
				iData.push({ no_akun: "0", nama: "Saldo Laporan Lalu" });
				setSRsAkun(iData);
				// setSRsAkun(xData.data.message);
				// console.log(iData);
			})
			.catch((xErr) => {
				alert(xErr);
			});
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/seksi`, {
			params: { type: "short" },
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		})
			.then((xData) => {
				setSRsSeksi(xData.data.message.seksi);
				setSSeksiDefault(xData.data.message.default.id);
			})
			.catch((xErr) => {
				alert(xErr);
			});
		fRsJurnalUmum();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		// console.log(sDataJurnal);
		if (sDataJurnal.eDebet.ref !== sDataJurnal.eKredit.ref) {
			setSNota({ eNoNota: 0, eStatus: false });
		}
	}, [sDataJurnal]);

	useEffect(() => {
		let iDebet = null;
		let iKredit = null;
		if (sDataJurnal.eDebet.status === true) {
			iDebet = {
				value: sDataJurnal.eDebet.value,
				akun: sDataJurnal.eDebet.akun,
				ref: sDataJurnal.eDebet.ref,
				status: true,
			};
		} else {
			iDebet = { value: "", akun: "", ref: "", status: false };
		}

		if (sDataJurnal.eKredit.status === true) {
			iKredit = {
				value: sDataJurnal.eKredit.value,
				akun: sDataJurnal.eKredit.akun,
				ref: sDataJurnal.eKredit.ref,
				status: true,
			};
		} else {
			iKredit = { value: "", akun: "", ref: "", status: false };
		}

		const iDataJurnal = {
			eTanggal: { value: sDataJurnal.eTanggal.value, status: true },
			eDebet: iDebet,
			eKredit: iKredit,
			eNilai: { value: 0, status: false },
			eKeterangan: { value: "", status: false },
			eNota: { no: 0, value: [] },
		};
		setSDataJurnal(iDataJurnal);
		const iNota = { eNoNota: 0, eStatus: false };
		setSNota(iNota);
		// eslint-disable-next-line
	}, [sRsDataJurnal]);

	// useEffect(() => {}, [sDataJurnalEdit]);

	const fSetAlertSimpan = (xRef) => {
		setSAlertSimpan((xState) => {
			let iState = { ...xState };
			iState.eStatus = true;
			iState.eRef = xRef;
			return iState;
		});
	};

	try {
		return (
			<div className="flex flex-col h-screen w-full bg-gray-500 overflow-y-auto">
				{/* =============EDIT JURNAL UMUM=================================== */}
				{/* Edit Jurnal umum jika ada ID nya */}
				{sDataJurnalEdit.eId !== "" ? (
					<CompEditDataJurnal
						_rsAkun={sRsAkun}
						_rsSeksi={sRsSeksi}
						_dataJurnal={sDataJurnalEdit}
						_onUpdate={(xData) => {
							// console.log(xData);
							setSRsDataJurnal((xState) => {
								let iState = [...xState];
								iState[xData.index] = xData.message;
								return iState;
							});
							setSDataJurnalEdit((xState) => {
								let iState = { ...xState };
								iState.eId = "";
								return iState;
							});
						}}
						_onDelete={(xData) => {
							setSRsDataJurnal((xState) => {
								let iState = [...xState];
								iState.splice(xData.message.index, 1);
								return iState;
							});
							setSDataJurnalEdit((xState) => {
								let iState = { ...xState };
								iState.eId = "";
								return iState;
							});
						}}
						_onCancel={() => {
							setSDataJurnalEdit((xState) => {
								let iState = { ...xState };
								iState.eId = "";
								return iState;
							});
						}}
					/>
				) : (
					""
				)}
				{/* ==============LOADING======================================== */}
				{sLoading ? <CompLoading /> : ""}
				{/* ==============ALERT SIMPAN==================================  */}
				{sAlertSimpan.eStatus ? (
					<CompAlertSimpan
						_title="Data Belum Lengkap"
						_keterangan="Mohon Lengkapi Data"
						_onOk={() => {
							sAlertSimpan.eRef.current.focus();
							setSAlertSimpan((xState) => {
								let iState = { ...xState };
								iState.eStatus = false;
								return iState;
							});
						}}
					/>
				) : (
					""
				)}
				{/*=======================================================================  */}
				{/* MASUKKAN DATA Jurnal Umum ===================================================*/}
				<div className="h-2"></div>
				<div
					className="flex flex-col space-y-1 shadow-lg   bg-genkiBlue-cl_01
				w-full  mx-auto  p-1 rounded-xl "
				>
					{props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100 ? (
						<div className="flex w-full">
							<div className="flex flex-1 justify-center items-center h-6">
								Masukkan Data Jurnal Umum
							</div>
							<div>
								<button
									className="Button h-6"
									onClick={() => {
										setSShowInputDataJurnal((xState) => {
											return !xState;
										});
									}}
								>
									{sShowInputDataJurnal ? "Sembunyi" : "Tampil"}
								</button>
							</div>
						</div>
					) : (
						<div className="flex w-full">
							<div className="flex flex-1 justify-center items-center h-6">
								Data Jurnal Umum
							</div>
						</div>
					)}

					{sShowInputDataJurnal &&
					(props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) ? (
						<div>
							<hr style={{ color: "gray" }} />
							{/* ================TANGGAL======================================= */}
							<div className="WadahFix flex w-full">
								<div className="flex w-24 h-auto items-center">Tanggal</div>
								<div className="flex w-3 h-auto items-center">:</div>
								<div className=" w-44 p-1">
									<input
										className="Text flex h-8"
										ref={iRefTanggal}
										type="date"
										value={sDataJurnal.eTanggal.value}
										onChange={(xData) => {
											setSDataJurnal((xState) => {
												let iState = { ...xState };
												iState.eTanggal.value = xData.nativeEvent.target.value;
												iState.eTanggal.status = true;
												return iState;
											});
											// iRefDebet.current.focus();
										}}
										onKeyUp={(xEvent) => {
											if (xEvent.nativeEvent.key === "Enter") {
												iRefDebet.current.focus();
											}
										}}
										onClick={() => {
											iRefTanggal.current.focus();
										}}
									/>
								</div>
							</div>
							{/* =================DEBET========================================= */}
							<CompInput
								_title="Debet"
								_ref={iRefDebet}
								_dataSourceAkun={sRsAkun}
								_dataSourceSeksi={sRsSeksi}
								_valueRef={sSeksiDefault}
								_onKeyEnter={(xData) => {
									setSDataJurnal((xState) => {
										let iState = { ...xState };
										iState.eDebet.akun = xData[0]; // NoAkun
										iState.eDebet.value = xData[1]; // Nama Akun
										iState.eDebet.ref = xData[2]; // Ref Seksi / Pengelola Keuangan
										iState.eDebet.status = true;
										return iState;
									});
									iRefKredit.current.focus();
								}}
							/>
							{/*================= KREDIT ==========================================*/}
							<CompInput
								_title="Kredit"
								_ref={iRefKredit}
								_dataSourceAkun={sRsAkun}
								_dataSourceSeksi={sRsSeksi}
								_valueRef={sSeksiDefault}
								_onKeyEnter={(xData) => {
									if (xData[0] !== sDataJurnal.eDebet.akun) {
										setSDataJurnal((xState) => {
											let iState = { ...xState };
											iState.eKredit.akun = xData[0]; // NoAkun
											iState.eKredit.value = xData[1]; // Nama Akun
											iState.eKredit.ref = xData[2]; // Ref Seksi / Pengelola Keuangan
											iState.eKredit.status = true;
											return iState;
										});
										iRefNilai.current.focus();
									} else {
										iRefDebet.current.focus();
									}
								}}
							/>
							{/* =================NILAI=========================================== */}
							<div
								className="WadahFix flex  space-y-1 md:flex-row "
								style={{ padding: "2px" }}
							>
								<div className="w-24 flex m-auto">
									<div className="flex-1">Nilai</div>
									<div className="w-6">Rp</div>
								</div>
								<div className="w-3">:</div>
								<div className="flex-1">
									<CompText
										_ref={iRefNilai}
										_dataType="ribuan"
										_value={sDataJurnal.eNilai.value}
										_onChange={(xData) => {
											setSDataJurnal((xState) => {
												let iState = { ...xState };
												iState.eNilai.value = xData;
												if (xData > 0) {
													iState.eNilai.status = true;
												} else {
													iState.eNilai.status = false;
												}
												return iState;
											});
										}}
										_onKeyEnter={() => {
											iRefKeterangan.current.focus();
										}}
									/>
								</div>
							</div>
							{/* =================KETERANGAN======================================= */}
							<div
								className="WadahFix flex flex-col space-y-1 "
								style={{ padding: "2px" }}
							>
								{/* ============TEXT KETERANGAN===================================== */}
								<div className="flex">
									<div className="flex h-auto items-center ">
										<div className=" w-24">Keterangan</div>
										<div className="w-3">:</div>
									</div>
									<textarea
										ref={iRefKeterangan}
										className="Text h-16 flex-1"
										name=""
										id=""
										cols="30"
										rows="10"
										value={sDataJurnal.eKeterangan.value}
										// =====onChange=====
										onChange={(xData) => {
											const iData = xData.nativeEvent.target.value;
											setSDataJurnal((xState) => {
												let iState = { ...xState };
												iState.eKeterangan.value = iData;
												if (iData !== "") {
													iState.eKeterangan.status = true;
												} else {
													iState.eKeterangan.status = false;
												}
												return iState;
											});
										}}
										// =====onKeyPress
										onKeyPress={(xEvent) => {
											if (
												xEvent.nativeEvent.key === "Enter" &&
												sDataJurnal.eKeterangan.status
											) {
												iRefSimpan.current.focus();
											}
										}}
									></textarea>
								</div>
								{/*================== NOTA ==============================================*/}
								<div className="flex flex-col md:flex-row">
									<div className="flex mb-1 md:mb-0 w-full justify-center md:w-24">
										{sDataJurnal.eDebet.ref === sDataJurnal.eKredit.ref ? (
											<button
												className={
													sNota.eStatus ? "ButtonActive h-6 w-14" : "Button h-6 w-14"
												}
												onClick={() => {
													if (sNota.eStatus === false) {
														setSLoading(true);
														Axios.get(
															`${props.state.sPages.url}${Env.eRouteApi}/admin/jurnalumum`,
															{
																headers: {
																	authorization: `bearer ${props.state.sToken}`,
																},
																params: {
																	req: "no_nota",
																	debet_ref: sDataJurnal.eDebet.ref,
																},
															}
														)
															.then((xData) => {
																setSLoading(false);
																setSNota({ eNoNota: xData.data.no_nota, eStatus: true });
															})
															.catch(() => {
																alert("Data Error");
															});
													} else {
														// setSNota(!sNota);
														setSNota({ eNoNota: 0, eStatus: false });
													}
												}}
											>
												Nota
											</button>
										) : (
											""
										)}
									</div>
									{/* ============ISI NOTA =============================================*/}
									{sNota.eStatus ? (
										<CompNota
											_noNota={sNota.eNoNota}
											_debetRef={sDataJurnal.eDebet.ref}
											_dataNota={(xData) => {
												// console.log(xData);
												let iTotal = 0;
												for (let i = 0; i < xData.eUraian.length; i++) {
													iTotal += xData.eUraian[i].jumlah;
												}
												setSDataJurnal((xState) => {
													let iState = { ...xState };
													iState.eNota.no = xData.eNoNota;
													iState.eNota.value = xData.eUraian;
													iState.eNilai.value = iTotal;
													iState.eNilai.status = true;
													return iState;
												});
											}}
											_onKeyEnter={() => {
												iRefSimpan.current.focus();
											}}
										/>
									) : (
										""
									)}
								</div>
							</div>
							{/* =====================SIMPAN============================================= */}
							<div className="w-full flex justify-center items-center h-8  ">
								<button
									className="Button w-20 h-6"
									ref={iRefSimpan}
									onClick={() => {
										// =======Cek Data============================
										if (!sDataJurnal.eTanggal.status) {
											fSetAlertSimpan(iRefTanggal);
										} else if (!sDataJurnal.eDebet.status) {
											fSetAlertSimpan(iRefDebet);
										} else if (!sDataJurnal.eKredit.status) {
											fSetAlertSimpan(iRefKredit);
										} else if (!sDataJurnal.eNilai.status) {
											fSetAlertSimpan(iRefNilai);
										} else if (!sDataJurnal.eKeterangan.status) {
											fSetAlertSimpan(iRefKeterangan);
										} else {
											// ========SIMPAN DATA JURNAL UMUM=========================
											if (!sLoading) {
												setSLoading(true);
												const iData = {
													jurnal: {
														tanggal: sDataJurnal.eTanggal.value,
														debet: sDataJurnal.eDebet.akun,
														debet_ref: sDataJurnal.eDebet.ref,
														kredit: sDataJurnal.eKredit.akun,
														kredit_ref: sDataJurnal.eKredit.ref,
														nilai: sDataJurnal.eNilai.value,
														keterangan: sDataJurnal.eKeterangan.value,
														no_nota: sDataJurnal.eNota.no,
													},
													nota: sDataJurnal.eNota.value,
												};
												// console.log(iData);
												if (
													iData.jurnal.debet === "0" &&
													iData.jurnal.kredit.substring(0, 1) !== "3"
												) {
													setSLoading(false);
													alert(
														`No Akun Debet tidak Boleh: 0 dengan Akun Kredit yang berawalan tidak sama dengan 3, Data Gagal Disimpan`
													);
													iRefTanggal.current.focus();
												} else if (iData.jurnal.debet.substring(0, 1) === "3") {
													setSLoading(false);
													alert(
														`No Akun ${iData.jurnal.kredit} tidak boleh di posisi Debet, Data Gagal Disimpan`
													);
													iRefTanggal.current.focus();
												} else if (
													iData.jurnal.debet !== "0" &&
													iData.jurnal.kredit.substring(0, 1) === "3"
												) {
													setSLoading(false);
													alert(
														`No Akun Debet ${iData.jurnal.debet} tidak cocok dengan Akun Kredit ${iData.jurnal.kredit}, Data Gagal Disimpan`
													);
													iRefTanggal.current.focus();
												} else {
													Axios.post(
														`${props.state.sPages.url}${Env.eRouteApi}/bendahara/jurnalumum`,
														iData,
														{
															headers: {
																authorization: `bearer ${props.state.sToken}`,
															},
														}
													)
														.then((xData) => {
															setSLoading(false);
															setSRsDataJurnal((xState) => {
																let iState = [...xState, xData.data.message];
																return iState;
															});
															iRefTanggal.current.focus();
														})
														.catch(() => {
															setSLoading(false);
															alert("Data Gagal Disimpan");
														});
												}
											}
										}
									}}
								>
									SIMPAN
								</button>
							</div>
						</div>
					) : (
						""
					)}
				</div>
				<div className="flex h-8 pl-1 bg-genkiBlue-cl_01 mt-1 w-full">
					<table className="Table">
						<thead>
							<tr>
								<th
									className="truncate w-24 cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("tanggal");
									}}
								>
									Tanggal
								</th>
								<th
									className="truncate flex-1  cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("akunDebet");
									}}
								>
									Akun Debet
								</th>
								<th
									className="truncate w-20  cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("refDebet");
									}}
								>
									Ref
								</th>
								<th
									className="truncate flex-1  cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("akunKredit");
									}}
								>
									Akun Kredit
								</th>
								<th
									className="truncate w-20  cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("refKredit");
									}}
								>
									Ref
								</th>
								<th className="truncate w-14  ">Aliran</th>
								<th
									className="truncate w-28  cursor-pointer hover:border-genkiBlue-dark"
									onClick={() => {
										fSort("nilai");
									}}
								>
									Nilai
								</th>
							</tr>
						</thead>
					</table>
					<div className=" w-4"></div>
				</div>
				{/* TAMPILAN DATA JURNAL */}
				{sRsAkun.length > 0 ? (
					<div
						className="flex flex-1 flex-col space-y-2 shadow-lg bg-genkiBlue-cl_01
					w-full mx-auto pl-1  overflow-x-auto"
					>
						<table className="Table w-full ">
							<tbody>
								{sRsDataJurnal
									.map((xObjData, xIndex) => {
										if (xIndex === 0) {
											mNoJurnal = 0;
										}
										// console.log(xObjData);
										if (
											(xObjData.debet === "0" || xObjData.kredit === "0") &&
											xObjData.edit === false &&
											!(
												props.state.sUser.eStatus === 70 ||
												props.state.sUser.eStatus === 100
											)
										) {
											return null;
										} else {
											// Tabel Detail Jurnal Umum =========================
											let iAliranDana = { aliran: "Trf", color: "bg-blue-300" };
											mNoJurnal++;

											if (xObjData.debet === "0" || xObjData.kredit === "0") {
												iAliranDana = { aliran: "Sld-L", color: "bg-green-200" };
											} else if (
												xObjData.debet.substring(0, 1) === "1" &&
												xObjData.kredit.substring(0, 1) !== "1"
											) {
												iAliranDana = { aliran: "Masuk", color: "bg-green-300" };
											} else if (
												xObjData.kredit.substring(0, 1) === "1" &&
												xObjData.debet.substring(0, 1) !== "1"
											) {
												iAliranDana = { aliran: "Keluar", color: "bg-red-300" };
											}
											return (
												<tr key={xIndex}>
													<td>
														<table className="w-full">
															{props.state.sLayarSize === "sm" ? (
																<tbody>
																	<tr
																		className="flex w-full"
																		style={{
																			backgroundColor:
																				sDataJurnalEdit.eIndex === xIndex
																					? Env.color.orangeRed02
																					: "",
																		}}
																	>
																		<td className="w-24 ">
																			{Env.efFormatTanggal(xObjData.tanggal)}
																		</td>
																		<td
																			className={` w-48 ${iAliranDana.color} flex justify-center`}
																		>
																			{iAliranDana.aliran}
																		</td>
																		<td
																			className={`flex-1 ${iAliranDana.color} flex justify-end`}
																		>
																			{Env.format.uang(xObjData.nilai)}
																		</td>
																		<td className=" w-20">
																			{xObjData.edit &&
																			(props.state.sUser.eStatus === 70 ||
																				props.state.sUser.eStatus === 100) ? (
																				<div
																					className="flex justify-center items-center  w-full h-6 "
																					style={{ padding: "2px" }}
																				>
																					<button
																						className="Button w-full h-full "
																						onClick={() => {
																							fDataJurnalEdit(xIndex);
																						}}
																					>
																						{mNoJurnal}
																					</button>
																				</div>
																			) : (
																				<div className="flex justify-center items-center bg-blue-100 w-full">
																					{mNoJurnal}
																				</div>
																			)}
																		</td>
																	</tr>
																	<tr className="flex w-full">
																		<td className=" flex-1 bg-purple-300">
																			<p className="truncate">{`Debet : ${
																				xObjData.debet
																			}  ${fFilterAkun(xObjData.debet)} `}</p>
																		</td>
																		<td className="w-20 bg-purple-300">{` Ref: ${xObjData.debet_ref}`}</td>
																	</tr>
																	<tr className="flex w-full">
																		<td className=" flex-1 bg-blue-100">
																			<p className="truncate">{`Kredit : ${
																				xObjData.kredit
																			}  ${fFilterAkun(xObjData.kredit)} `}</p>
																		</td>
																		<td className="w-20 bg-blue-100">{` Ref: ${xObjData.kredit_ref}`}</td>
																	</tr>
																	<tr
																		className="flex w-full"
																		style={{
																			backgroundColor:
																				sDataJurnalEdit.eIndex === xIndex
																					? Env.color.orangeRed02
																					: "",
																		}}
																	>
																		<td className="flex flex-1">
																			{`Keterangan : ${xObjData.keterangan} `}
																			{xObjData.nota.length > 0
																				? ` ; Nota No.${xObjData.no_nota}${
																						xObjData.debet_ref
																				  }: ${fGetIsiNota(xObjData.nota)} `
																				: ""}
																		</td>
																	</tr>
																</tbody>
															) : (
																<tbody>
																	<tr
																		className="flex w-full"
																		style={{
																			backgroundColor:
																				sDataJurnalEdit.eIndex === xIndex
																					? Env.color.orangeRed02
																					: "",
																		}}
																	>
																		<td className="w-24 ">
																			{/* {xObjData.edit &&
																			(props.state.sUser.eStatus === 70 ||
																				props.state.sUser.eStatus === 100) ? (
																				<div
																					className="flex justify-center items-center  w-full h-6 "
																					style={{ padding: "2px" }}
																				>
																					<button
																						className="Button w-full h-full "
																						onClick={() => {
																							fDataJurnalEdit(xIndex);
																						}}
																					>
																						{mNoJurnal}
																					</button>
																				</div>
																			) : (
																				<div className="flex justify-center items-center bg-blue-100 w-full">
																					{mNoJurnal}
																				</div>
																			)} */}
																			<div
																				className="flex justify-center items-center  w-full h-6 "
																				style={{ padding: "2px" }}
																			>
																				<button
																					className="Button w-full h-full "
																					onClick={() => {
																						fDataJurnalEdit(xIndex);
																					}}
																				>
																					{mNoJurnal}
																				</button>
																			</div>
																		</td>
																		<td className=" flex-1 bg-purple-300">
																			<p className="truncate">{`Debet : ${
																				xObjData.debet
																			}  ${fFilterAkun(xObjData.debet)} `}</p>
																		</td>
																		<td className="w-20 bg-purple-300">{` Ref: ${xObjData.debet_ref}`}</td>
																		<td className=" flex-1 bg-blue-100">
																			<p className="truncate">{`Kredit : ${
																				xObjData.kredit
																			}  ${fFilterAkun(xObjData.kredit)} `}</p>
																		</td>
																		<td className="w-20 bg-blue-100">{` Ref: ${xObjData.kredit_ref}`}</td>
																		<td
																			className={`w-14 ${iAliranDana.color} flex justify-center`}
																		>
																			{iAliranDana.aliran}
																		</td>
																		<td className={`w-28 ${iAliranDana.color} flex justify-end`}>
																			{Env.format.uang(xObjData.nilai)}
																		</td>
																	</tr>
																	<tr
																		className="flex w-full"
																		style={{
																			backgroundColor:
																				sDataJurnalEdit.eIndex === xIndex
																					? Env.color.orangeRed02
																					: "",
																		}}
																	>
																		<td className="w-24 ">
																			{Env.efFormatTanggal(xObjData.tanggal)}
																		</td>
																		<td className="flex flex-1">
																			{`Keterangan : ${xObjData.keterangan} `}
																			{xObjData.nota.length > 0
																				? ` ; Nota No.${xObjData.no_nota}${
																						xObjData.debet_ref
																				  }: ${fGetIsiNota(xObjData.nota)} `
																				: ""}
																		</td>
																	</tr>
																</tbody>
															)}
														</table>
													</td>
												</tr>
											);
										}
									})
									.reverse()}
							</tbody>
						</table>
					</div>
				) : (
					""
				)}
				<div className=" h-14"></div>
			</div>
		);
	} catch (error) {
		return <div className=" m-auto">By Genky </div>;
	}
};

export default GlobalStateConsumer(Jurnal);
