import { useState, useEffect, useRef } from "react";
import CompPrint from "react-to-print";
import env from "../../../setting/env";

import { GlobalStateConsumer } from "../../../state.global";
import CompEkuitas from "./ekuitas";
import CompBukuBesar from "./bukuBesar";
import CompBukuBesarKwajiban from "./bukuBesarKwajiban";
import CompExel from "../../../component/exportToExel";

const Neraca = (props) => {
  // eslint-disable-next-line
  const [sRsPasiva, setSRsPasiva] = useState([...props._data.viewPasiva]);
  const [sRsView, setSRsView] = useState(null);
  const [sExel, setSExel] = useState(null);
  const [sViewData, setSViewData] = useState({
    data: "Neraca",
    keterangan: "NERACA",
    no_akun: "",
  });
  const refToPrint = useRef();

  const fCol = (xValue, xColMarger = 1) => {
    return { value: xValue, colMarger: xColMarger };
  };
  const fTab = (xValue) => {
    let iTab = "";
    for (let i = 0; i < xValue; i++) {
      iTab += "  ";
    }
    return iTab;
  };

  const fBukuBesar = (xNoAkun, xAkun) => {
    if (xNoAkun !== "") {
      if (xNoAkun.substring(0, 1) === "3") {
        setSViewData({ data: "Ekuitas", keterangan: xAkun, no_akun: "" });
      } else if (xNoAkun.substring(0, 1) === "2") {
        setSViewData({ data: "Kwajiban", keterangan: xAkun, no_akun: xNoAkun });
      } else {
        setSViewData({
          data: "BukuBesar",
          keterangan: xAkun,
          no_akun: xNoAkun,
        });
      }
    }
  };

  useEffect(() => {
    let iData = { ...props._data };
    if (iData.viewAktiva.length > iData.viewPasiva.length) {
      const iLengthPasiva = iData.viewPasiva.length - 2;
      while (iData.viewAktiva.length > iData.viewPasiva.length) {
        iData.viewPasiva.splice(iLengthPasiva, 0, {
          tab_view: "",
          akun: "",
          jumlah: "",
          bukuBesar: "",
        });
      }
    } else {
      const iLengthAktiva = iData.viewAktiva.length - 2;
      while (iData.viewAktiva.length < iData.viewPasiva.length) {
        iData.viewAktiva.splice(iLengthAktiva, 0, {
          tab_view: "",
          akun: "",
          jumlah: "",
          bukuBesar: "",
        });
      }
    }

    let iDataExel = [
      { row: 1, col: [fCol("LAPORN KEUANGAN", 4)] },
      { row: 2, col: [fCol(iData.identitasPemilik.nama, 4)] },
      { row: 3, col: [fCol(iData.identitasPemilik.alamat, 4)] },
      {
        row: 4,
        col: [fCol(props._pariode, 4)], //Pariode Laporan
      },
      { row: 6, col: [fCol("NERACA", 4)] },
      {
        row: 7,
        col: [
          fCol("Akun AKTIVA"),
          fCol("Jumlah"),
          fCol("Akun PASIVA"),
          fCol("Jumlah"),
        ],
      },
    ];
    let iRow = 7;
    let iAktiva = iData.viewAktiva;
    let iPasiva = iData.viewPasiva;

    for (let i = 0; i < iAktiva.length; i++) {
      iRow += 1;
      iDataExel.push({
        row: iRow,
        col: [
          fCol(`${fTab(iAktiva[i].tab_view)}${iAktiva[i].akun}`),
          fCol(`${fTab(iAktiva[i].tab_view)}${iAktiva[i].jumlah}`),
          fCol(`${fTab(iPasiva[i].tab_view)}${iPasiva[i].akun}`),
          fCol(`${fTab(iPasiva[i].tab_view)}${iPasiva[i].jumlah}`),
        ],
      });
    }

    setSExel(iDataExel);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.state.sLayarSize === "sm") {
      setSRsView((xState) => {
        let iState = null;
        if (xState) {
          iState = { ...xState };
          iState.viewPasiva = sRsPasiva;
        } else {
          iState = { ...props._data };
        }
        iState.view = props.state.sLayarSize;
        return iState;
      });
    } else {
      setSRsView(() => {
        let iState = { ...props._data };
        if (iState.viewAktiva.length > iState.viewPasiva.length) {
          const iLengthPasiva = iState.viewPasiva.length - 2;
          while (iState.viewAktiva.length > iState.viewPasiva.length) {
            iState.viewPasiva.splice(iLengthPasiva, 0, {
              tab_view: "",
              akun: "",
              jumlah: "",
              bukuBesar: "",
            });
          }
        } else {
          const iLengthAktiva = iState.viewAktiva.length - 2;
          while (iState.viewAktiva.length < iState.viewPasiva.length) {
            iState.viewAktiva.splice(iLengthAktiva, 0, {
              tab_view: "",
              akun: "",
              jumlah: "",
              bukuBesar: "",
            });
          }
        }

        iState.view = props.state.sLayarSize;
        // console.log(iState);

        return iState;
      });
    }

    // eslint-disable-next-line
  }, [props.state.sLayarSize]);

  return (
    <div className="flex flex-col bg-white   w-screen h-screen fixed top-11  pb-10 md:pb-0 left-0 overscroll-none">
      {sViewData.data === "Neraca" && sRsView ? (
        //  ========================== NERACA ===============================
        <div className="flex flex-col h-full">
          <div className="flex absolute right-1 justify-center items-center w-32 h-9 space-x-1">
            <CompExel _data={sExel} _fileName={`${props._tanggal}_NERACA`} />
            <CompPrint
              trigger={() => {
                return <div className="Button h-6">Cetak</div>;
              }}
              content={() => refToPrint.current}
            />
            <div
              className="flex justify-center items-center
                                bg-green-300 rounded-xl w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100"
              onClick={() => {
                props._onClose();
              }}
            >
              X
            </div>
          </div>
          <div
            className="flex flex-col flex-1  overflow-y-scroll mt-8 md:mt-1"
            ref={refToPrint}
          >
            <div className="flex w-full justify-center items-center">
              LAPORAN KEUANGAN
            </div>

            <div className="flex w-full justify-center items-center">
              {props._data.identitasPemilik.nama}
            </div>
            <div className="flex w-full justify-center items-center">
              {props._data.identitasPemilik.alamat}
            </div>
            {/* Pariode Laporan */}
            <div className="flex w-full justify-center items-center">
              {props._pariode}
            </div>
            <div className="bg-yellow-300 flex w-full h-8 justify-center items-center mt-2 ">
              {/* <div className="w-40"></div> */}
              <div className="flex-1 flex justify-center items-center">
                NERACA
              </div>
              {/* <div className="w-20"></div> */}
            </div>

            {sRsView.view === "sm" ? (
              // ==========LAYAR HAND PHONE====================================
              <div className="flex flex-col w-full h-full overflow-y-scroll space-y-2">
                <table className="Table">
                  <thead>
                    <tr>
                      <th>Akun AKTIVA</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sRsView.viewAktiva.map((xData, xIndex) => {
                      let iStyle = "text-gray-800";
                      if (xData.bukuBesar !== "") {
                        iStyle = "underline text-blue-900 cursor-pointer";
                      }
                      return (
                        <tr key={xIndex}>
                          <td
                            className={iStyle}
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                            onClick={() => {
                              fBukuBesar(xData.bukuBesar, xData.akun);
                            }}
                          >
                            {xData.akun}
                          </td>
                          <td
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                          >
                            {xData.jumlah}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                <table className="Table">
                  <thead>
                    <tr>
                      <th>Akun PASIVA</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sRsView.viewPasiva.map((xData, xIndex) => {
                      let iStyle = "text-gray-800";
                      if (xData.bukuBesar !== "") {
                        iStyle = "underline text-blue-900 cursor-pointer";
                      }
                      return (
                        <tr key={xIndex}>
                          <td
                            className={iStyle}
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                            onClick={() => {
                              fBukuBesar(xData.bukuBesar, xData.akun);
                            }}
                          >
                            {xData.akun}
                          </td>
                          <td
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                          >
                            {xData.jumlah}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* Mengetahui */}
                <div className="flex h-4"></div>
                <div className="flex w-full flex-col space-y-5  ">
                  <div className="flex flex-col w-full justify-center ">
                    <div className="flex w-full justify-center">
                      Disiapkan oleh
                    </div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.bendahara.jabatan}
                    </div>
                    <div className="flex w-full justify-center items-center h-20 "></div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.bendahara.nama}
                    </div>
                  </div>

                  <div className="flex flex-col w-full justify-center ">
                    <div className="flex w-full justify-center">Diketahui</div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.ketua.jabatan}
                    </div>
                    <div className="flex w-full justify-center items-center h-20 "></div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.ketua.nama}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // END ======LAYAR HAND PHONE====================================
              // ==========LAYAR KOMPUTER======================================
              // <div className="flex flex-col w-full h-full pl-2 pt-1 pr-1 overflow-y-scroll space-y-2">
              <div className="flex flex-col w-full h-full pl-2 pt-1 pr-1 space-y-2">
                <table className="Table ">
                  <thead>
                    <tr>
                      <th>Akun AKTIVA</th>
                      <th>Jumlah</th>
                      <th>Akun PASIVA</th>
                      <th>Jumlah</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sRsView.viewAktiva.map((xData, xIndex) => {
                      let iStyleAktiva = "text-gray-800";
                      if (xData.bukuBesar !== "") {
                        iStyleAktiva = "underline text-blue-900 cursor-pointer";
                      }

                      let iStylePasiva = "text-gray-800";

                      if (sRsView.viewPasiva[xIndex].bukuBesar !== "") {
                        iStylePasiva = "underline text-blue-900 cursor-pointer";
                      }

                      return (
                        <tr key={xIndex}>
                          {/* AKTIVA */}
                          <td
                            className={iStyleAktiva}
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                            onClick={() => {
                              fBukuBesar(xData.bukuBesar, xData.akun);
                            }}
                          >
                            {xData.akun}
                          </td>

                          <td
                            style={{ paddingLeft: `${20 * xData.tab_view}px` }}
                          >
                            {xData.jumlah}
                          </td>

                          {/* PASIVA */}
                          <td
                            className={iStylePasiva}
                            style={{
                              paddingLeft: `${
                                20 * sRsView.viewPasiva[xIndex].tab_view
                              }px`,
                            }}
                            onClick={() => {
                              fBukuBesar(
                                sRsView.viewPasiva[xIndex].bukuBesar,
                                sRsView.viewPasiva[xIndex].akun
                              );
                            }}
                          >
                            {sRsView.viewPasiva[xIndex].akun}
                          </td>
                          <td
                            style={{
                              paddingLeft: `${
                                20 * sRsView.viewPasiva[xIndex].tab_view
                              }px`,
                            }}
                          >
                            {sRsView.viewPasiva[xIndex].jumlah}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {/* Mengetahui */}
                <div className="flex h-4"></div>
                <div className="flex w-full ">
                  <div className="flex flex-col w-e350 ">
                    <div className="flex w-full justify-center">
                      Disiapkan oleh
                    </div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.bendahara.jabatan}
                    </div>
                    <div className="flex w-full justify-center items-center h-20 "></div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.bendahara.nama}
                    </div>
                  </div>
                  <div className="flex flex-1"></div>
                  <div className="flex flex-col w-e350 ">
                    <div className="flex w-full justify-center">Diketahui</div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.ketua.jabatan}
                    </div>
                    <div className="flex w-full justify-center items-center h-20 "></div>
                    <div className="flex w-full justify-center">
                      {props._data.identitasPemilik.ketua.nama}
                    </div>
                  </div>
                </div>
              </div>

              // END ======LAYAR KOMPUTER======================================
            )}
          </div>
          <div className="flex h-20 "></div>
        </div>
      ) : // {/* END ======================= NERACA =============================== */}
      sViewData.data === "Ekuitas" ? (
        <CompEkuitas
          _tanggal={props._tanggal}
          _data={props._data.viewEkuitas}
          _keteranganAkun={sViewData.keterangan}
          _onClose={() => {
            setSViewData({ data: "Neraca", keterangan: "NERACA" });
          }}
        />
      ) : sViewData.data === "BukuBesar" ? (
        <CompBukuBesar
          _tanggal={props._tanggal}
          _noAkun={sViewData.no_akun}
          _keteranganAkun={sViewData.keterangan}
          _onClose={() => {
            setSViewData({ data: "Neraca", keterangan: "NERACA" });
          }}
        />
      ) : sViewData.data === "Kwajiban" ? (
        <CompBukuBesarKwajiban
          _tanggal={props._tanggal}
          _noAkun={sViewData.no_akun}
          _keteranganAkun={sViewData.keterangan}
          _onClose={() => {
            setSViewData({ data: "Neraca", keterangan: "NERACA" });
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default GlobalStateConsumer(Neraca);
