import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";
import CompTextDs from "../../../../component/arjText-DataSource";
import CompText from "../../../../component/arjText";
import CompAlertDeleteKegiatan from "../../../../component/arjAlert";
import CompAlertGagalDeleteKegiatan from "../../../../component/arjAlert";
import CompAlertBerhentiBeliKegiatan from "../../../../component/arjAlert";
import { log } from "@craco/craco/lib/logger";

const BeliKegiatan = (props) => {
	const [sLoading, setSLoading] = useState(true);
	const [sRsMembers, setSRsMembers] = useState([]);
	const [sRsRecentBeliKegiatan, setSRsRecentBeliKegiatan] = useState([]);
	const [sSelect, setSSelect] = useState({
		index: -1,
		status: "",
		beliLagi: false,
	});
	const [sRsJenisKegiatan, setSRsJenisKegiatan] = useState([]);
	const [sDataBeliKegiatan, setSDataBeliKegiatan] = useState({
		tanggal: Env.efTanggal(),
		nama_kegiatan: { id: 0, nama: "" },
		nama: "",
		nilai: 0,
	});
	const [sBulanSekarang, setSBulanSekarang] = useState(0);
	const [sAlertGagalDelete, setSAlertGagalDelete] = useState({
		show: false,
		title: "",
		keterangan: "",
	});

	let iIndexRecentKegiatan = -1;
	let iNoKelompok = 0;
	let iNamaKelompok = "";
	let iBgKelompok = "";

	const iNamaBulan = [
		"Januari",
		"Februari",
		"Maret",
		"April",
		"Mei",
		"Juni",
		"Juli",
		"Agustus",
		"September",
		"Oktober",
		"Nopember",
		"Desember",
	];
	const fGetBulan = (xNilaiBulan) => {
		let iBulan = xNilaiBulan % 12;
		if (iBulan === 0) {
			iBulan = 11;
			xNilaiBulan -= 1;
		} else {
			iBulan -= 1;
		}

		let iTahun = Math.floor(xNilaiBulan / 12).toString();
		return `${iNamaBulan[iBulan]} ${iTahun}`;
	};

	const fGetDate = (xNilaiBulan) => {
		let iBulan = (xNilaiBulan % 12).toString();
		if (iBulan === "0") {
			iBulan = "12";
			xNilaiBulan -= 1;
		}
		if (iBulan.length === 1) {
			iBulan = "0" + iBulan;
		}
		// const iDate = new Date();
		// let iDay = iDate.getDay();
		// if (iDay.length === 1) {
		// 	iDay = "0" + iDay;
		// }
		let iTahun = Math.floor(xNilaiBulan / 12).toString();
		return `${iTahun}-${iBulan}-01`;
	};

	const refMulaiBerlaku = useRef(null);
	const refNamaKegiatan = useRef(null);
	const refNilai = useRef(null);
	const refBeliKegiatan = useRef(null);

	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/beli_kegiatan`,
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);

				if (iData.status === Env.eHttpStatus.eOk) {
					setSRsMembers(iData.data.message.members);
					setSRsJenisKegiatan(iData.data.message.jenis_kegiatan);
					setSRsRecentBeliKegiatan(iData.data.message.beli_kegiatan);
					setSBulanSekarang(iData.data.message.bulan);
				}

				setSLoading(false);
			};
			fGetData();
		} catch (xErr) {}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (sRsRecentBeliKegiatan.length > 0) {
			setSDataBeliKegiatan((xState) => {
				let iState = { ...xState };
				iState.nama_kegiatan = {
					id: sRsRecentBeliKegiatan[0].kegiatan_jenis_id,
					nama: sRsRecentBeliKegiatan[0].kegiatan_jenis_nama,
				};
				iState.nilai = sRsRecentBeliKegiatan[0].nilai;
				return iState;
			});
		}
	}, [sRsRecentBeliKegiatan]);

	useEffect(() => {
		try {
			if (sSelect.status === "BeliKegiatan") {
				refMulaiBerlaku.current.focus();
			}
		} catch (xErr) {}
		// eslint-disable-next-line
	}, [sRsMembers]);

	return (
		<div className="flex flex-col fixed w-full h-full">
			{/* ====LOADING=============== */}
			{sLoading ? <CompLoading /> : null}

			{/* =====ALERT GAGAL DELETE KEGIATAN */}
			{sAlertGagalDelete.show ? (
				<CompAlertGagalDeleteKegiatan
					_title={sAlertGagalDelete.title}
					_keterangan={sAlertGagalDelete.keterangan}
					_buttonOk="OK"
					_onOk={() => {
						setSAlertGagalDelete((xState) => {
							const iState = { ...xState };
							iState.show = false;
							return iState;
						});
					}}
				/>
			) : null}

			{/* ============= Beli Kegiatan ================= */}
			{sSelect.status === "BeliKegiatan" ? (
				<div className="Modal z-20 h-screen w-screen ">
					<div className=" w-full flex flex-col ">
						<div className=" h-10"></div>
						<div className="flex flex-col h-40 p-2 mt-1 w-full"></div>
						<div className="flex  w-full justify-center">
							<div
								className="flex flex-col max-w-sm  rounded-xl  
								border-blue-500 border-2 border-solid bg-blue-200 "
							>
								<div className="flex flex-col w-full justify-center  bg-blue-300 rounded-t-xl">
									<div className=" text-lg mx-auto">
										{sSelect.beliLagi ? "BELI KEGIATAN LAGI" : "BELI KEGIATAN"}
									</div>

									{/* Mulai Berlaku */}
									<div className="flex h-10 w-full px-1 items-center mt-2 bg-blue-100">
										<div className=" h-10 flex items-center w-32 text-lg">
											Mulai Berlaku
										</div>
										<div className=" mx-1">:</div>
										<div className=" flex-1">
											<input
												className="Text text-lg"
												disabled={sSelect.beliLagi ? "disabled" : null}
												ref={refMulaiBerlaku}
												type="date"
												value={sDataBeliKegiatan.tanggal}
												onChange={(xData) => {
													setSDataBeliKegiatan((xState) => {
														const iState = { ...xState };
														iState.tanggal = xData.nativeEvent.target.value;
														return iState;
													});
												}}
												onKeyUp={(xEvent) => {
													if (xEvent.nativeEvent.key === "Enter") {
														refNamaKegiatan.current.focus();
													}
												}}
											/>
										</div>
									</div>

									{/* Masa Berlaku */}
									<div className="flex h-10 w-full px-1 items-center  bg-blue-200">
										<div className=" h-10 flex items-center w-32 text-lg">
											Masa Berlaku
										</div>
										<div className=" mx-1">:</div>
										<div className=" flex-1">12 Bulan</div>
									</div>

									{/* Nama Kegiatan */}
									<div className="flex h-10 w-full px-1 items-center  bg-blue-100">
										<div className=" h-10 flex items-center w-32 text-lg">
											Nama Kegiatan
										</div>
										<div className=" mx-1">:</div>
										<div className=" flex-1">
											<CompTextDs
												_ref={refNamaKegiatan}
												_dataSource={sRsJenisKegiatan}
												_value={sDataBeliKegiatan.nama_kegiatan.nama}
												_onKeyEnter={(xData) => {
													let iNilai = 0;
													// console.log(xData[0]);
													for (let i = 0; i < sRsRecentBeliKegiatan.length; i++) {
														const xElement = sRsRecentBeliKegiatan[i];

														if (parseInt(xElement.kegiatan_jenis_id) === xData[0]) {
															iNilai = xElement.nilai;
															iIndexRecentKegiatan = i;
															break;
														} else {
															iIndexRecentKegiatan = -1;
														}
													}
													setSDataBeliKegiatan((xState) => {
														const iState = { ...xState };
														iState.nama_kegiatan = { id: xData[0], nama: xData[1] };
														iState.nilai = iNilai;
														return iState;
													});

													refNilai.current.focus();
													refNilai.current.select();
												}}
											/>
										</div>
									</div>

									{/* Nama */}
									<div className="flex  w-full px-1 py-2 items-center   bg-blue-200">
										<div className=" h-10 flex items-center w-32 text-lg">Nama</div>
										<div className=" mx-1">:</div>
										<div className=" flex-1 p-1 bg-gray-100 rounded-xl text-lg ">
											{sRsMembers[sSelect.index].name}
										</div>
									</div>

									{/* Nilai */}
									<div className="flex h-10 w-full p-1 items-center bg-blue-100">
										<div className=" h-10 flex items-center w-32 text-lg">Nilai</div>
										<div className=" mx-1">:</div>
										<div className=" flex-1">
											<CompText
												_dataType={"ribuan"}
												_value={sDataBeliKegiatan.nilai}
												_ref={refNilai}
												_onNilai={(xData) => {
													setSDataBeliKegiatan((xState) => {
														const iState = { ...xState };
														iState.nilai = xData;
														return iState;
													});
												}}
												_onKeyEnter={() => {
													refBeliKegiatan.current.focus();
												}}
											/>
										</div>
									</div>
								</div>

								<div className=" w-full  bg-black" style={{ height: "1px" }}></div>

								{/* Button */}
								<div className="flex w-full h-16 justify-evenly items-center bg-gray-500 rounded-b-xl">
									<div className="flex justify-evenly w-full">
										{/*Button Beli Kegiatan */}
										<button
											className="Button  w-28 h-10"
											ref={refBeliKegiatan}
											onClick={() => {
												if (!sLoading) {
													if (sDataBeliKegiatan.nama_kegiatan.nama === "") {
														refNamaKegiatan.current.focus();
													} else if (sDataBeliKegiatan.nilai === 0) {
														refNilai.current.focus();
													} else if (sDataBeliKegiatan.tanggal === "") {
														refMulaiBerlaku.current.focus();
													} else {
														Axios.post(
															`${props.state.sPages.url}${Env.eRouteApi}/admin/beli_kegiatan`,
															{
																tanggal: sDataBeliKegiatan.tanggal,
																nama_kegiatan_id: sDataBeliKegiatan.nama_kegiatan.id,
																members_id: sRsMembers[sSelect.index].id,
																nilai: sDataBeliKegiatan.nilai,
																recent_beli_kegiatan: sRsMembers[sSelect.index].kegiatan_beli,
															},
															{
																headers: {
																	authorization: `bearer ${props.state.sToken}`,
																},
															}
														)
															.then((xRes) => {


																setSRsRecentBeliKegiatan((xState) => {
																	const iState = [...xState];
																	if (iIndexRecentKegiatan > -1) {
																		// console.log({no:2});
																		iState[iIndexRecentKegiatan].nilai = sDataBeliKegiatan.nilai;
																	} else {
																		// console.log({no:3});
																		iState.push({
																			nilai: sDataBeliKegiatan.nilai,
																			kegiatan_jenis_id: sDataBeliKegiatan.nama_kegiatan.id,
																			kegiatan_jenis_nama: sDataBeliKegiatan.nama_kegiatan.nama,
																		});
																	}
																	return iState;
																});
																setSRsMembers((xState) => {
																	let iState = [...xState];
																	iState[sSelect.index] = xRes.data.message;
																	return iState;
																});
																
																setSLoading(false);
																setSSelect((xState) => {
																	const iState = { ...xState };
																	iState.index = -1;
																	iState.status = "";
																	return iState;
																});
															})
															.catch((xRes) => {
																alert(xRes);
																setSLoading(false);
																setSSelect((xState) => {
																	const iState = { ...xState };
																	iState.index = -1;
																	iState.status = "";
																	return iState;
																});
															});
													}
												}

												// console.log(sDataBeliKegiatan);
											}}
										>
											Beli Kegiatan
										</button>

										{/* Button Ulang */}
										<button
											className="Button w-28 h-10"
											onClick={() => {
												setSSelect((xState) => {
													const iState = { ...xState };
													iState.index = -1;
													iState.status = "";
													return iState;
												});
											}}
										>
											Ulang
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : sSelect.status === "delete_beli_kegiatan" ? (
				<CompAlertDeleteKegiatan
					_title={`Batal Beli Kegiatan an: ${sRsMembers[sSelect.index].name}`}
					_keterangan={` Apakah  benar pembelian kegiatan atas nama : ${
						sRsMembers[sSelect.index].name
					} di batalkan `}
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setSSelect((xState) => {
							const iState = { xState };
							iState.status = "";
							return iState;
						});
					}}
					_onOk={() => {
						// console.log(sRsMembers[sSelect.index]);
						if (!sLoading) {
							setSLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/beli_kegiatan`,

								{
									data: {
										members_id: sRsMembers[sSelect.index].id,
										kegiatan_beli: sRsMembers[sSelect.index].kegiatan_beli,
									},
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							)
								.then((xRes) => {
									setSLoading(false);
									if (xRes.status === Env.eHttpStatus.eOk) {
										setSRsMembers((xState) => {
											let iState = [...xState];
											iState[sSelect.index] = xRes.data.message;
											return iState;
										});
									} else {
										setSAlertGagalDelete((xState) => {
											const iState = { ...xState };
											iState.show = true;
											iState.title = xRes.data.title;
											iState.keterangan = xRes.data.message;
											return iState;
										});
									}
									// console.log(xRes.data);

									setSSelect((xState) => {
										const iState = { ...xState };
										iState.index = -1;
										iState.status = "";
										return iState;
									});
								})
								.catch(() => {
									setSLoading(false);
									setSSelect((xState) => {
										const iState = { ...xState };
										iState.index = -1;
										iState.status = "";
										return iState;
									});
								});
						}
					}}
				/>
			) : sSelect.status === "cek_beli_kegiatan" ? (
				<div className="Modal z-20 h-screen w-screen ">
					<div className=" w-full flex flex-col ">
						<div className=" h-10"></div>
						<div className="flex flex-col h-40 p-2 mt-1 w-full"></div>
						<div className="flex  w-full justify-center">
							<div
								className="flex flex-col w-96 rounded-xl  
								border-blue-500 border-2 border-solid bg-blue-200 "
							>
								<div className="flex flex-col w-full justify-center  bg-blue-300 rounded-t-xl">
									<div className=" text-lg mx-auto">BELI KEGIATAN</div>
									<div className=" mx-auto truncate">{`an: ${
										sRsMembers[sSelect.index].name
									}`}</div>
								</div>

								<div className=" w-full  bg-black" style={{ height: "1px" }}></div>

								{/* Button */}
								<div className="flex w-full h-16 justify-evenly items-center bg-gray-500 rounded-b-xl">
									<div className="flex justify-evenly w-full">
										{/*Button Beli Lagi */}
										<button
											className="Button  w-28 h-10"
											onClick={() => {
												let iTanggal =
													sRsMembers[sSelect.index].kegiatan_beli.akhir_berlaku;
												iTanggal = fGetDate(iTanggal);
												setSDataBeliKegiatan((xState) => {
													const iState = { ...xState };
													iState.tanggal = iTanggal;
													return iState;
												});

												setSSelect((xState) => {
													const iState = { ...xState };
													iState.status = "BeliKegiatan";
													iState.beliLagi = true;
													return iState;
												});
											}}
										>
											Beli Lagi
										</button>

										<button
											className="Button  w-28 h-10"
											onClick={() => {
												setSSelect((xState) => {
													const iState = { ...xState };
													iState.status = "berhenti";
													return iState;
												});
											}}
										>
											Berhenti
										</button>

										{/* Button Ulang */}
										<button
											className="Button w-28 h-10"
											onClick={() => {
												setSSelect((xState) => {
													const iState = { ...xState };
													iState.index = -1;
													iState.status = "";
													return iState;
												});
											}}
										>
											Ulang
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : sSelect.status === "berhenti" ? (
				<CompAlertBerhentiBeliKegiatan
					_title={`BERHENTI BELI KEGIATAN `}
					_keterangan={` Apakah  benar  berhenti beli kegiatan atas nama : ${
						sRsMembers[sSelect.index].name
					} ?`}
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setSSelect((xState) => {
							const iState = { xState };
							iState.status = "";
							return iState;
						});
					}}
					_onOk={() => {
						// console.log(sRsMembers[sSelect.index]);
						if (!sLoading) {
							setSLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/admin/berhenti_beli_kegiatan`,

								{
									data: {
										members_id: sRsMembers[sSelect.index].id,
										kegiatan_beli: sRsMembers[sSelect.index].kegiatan_beli,
									},
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							)
								.then((xRes) => {
									setSLoading(false);
									if (xRes.status === Env.eHttpStatus.eOk) {
										setSRsMembers((xState) => {
											let iState = [...xState];
											iState[sSelect.index] = xRes.data.message;
											return iState;
										});
									} else {
										setSAlertGagalDelete((xState) => {
											const iState = { ...xState };
											iState.show = true;
											iState.title = xRes.data.title;
											iState.keterangan = xRes.data.message;
											return iState;
										});
									}
									// console.log(xRes.data);

									setSSelect((xState) => {
										const iState = { ...xState };
										iState.index = -1;
										iState.status = "";
										return iState;
									});
								})
								.catch(() => {
									setSLoading(false);
									setSSelect((xState) => {
										const iState = { ...xState };
										iState.index = -1;
										iState.status = "";
										return iState;
									});
								});
						}
					}}
				/>
			) : null}

			{/* ================TITLE========================= */}
			<div
				className="flex justify-center items-center p-2 mt-4 md:mt-8  md: mb-4 h-8 w-60
							mx-auto bg-white rounded-xl shadow-md space-y-0 text-lg "
			>
				<p>Beli Kegiatan</p>
			</div>

			{/* ISI */}
			{/* ====TABLE DAFTAR ANGGOTA ===== */}
			<div
				className="flex flex-1  w-full md:p-2  mt-2 overflow-y-scroll 
			 rounded-xl border-solid border-4"
			>
				<table className="Table w-full">
					<thead>
						<tr>
							<th>No</th>
							<th>Nama</th>
							<th>No</th>
							<th>Kelompok</th>
							<th>Status</th>
							<th>Akhir Berlaku</th>
							<th>Sisa</th>
						</tr>
					</thead>

					<tbody>
						{sRsMembers.map((xData, xIndex) => {
							// let iJabatan = fJabatan(xData.account.status);
							if (iNamaKelompok !== xData.members_group.nama) {
								iNamaKelompok = xData.members_group.nama;
								iNoKelompok = 1;
								if (iBgKelompok === "bg-blue-200") {
									iBgKelompok = "bg-blue-400";
								} else {
									iBgKelompok = "bg-blue-200";
								}
							} else {
								iNoKelompok += 1;
							}
							const iIndex = xIndex % 2;
							const iSisaMasaBerlaku = xData.kegiatan_beli
								? xData.kegiatan_beli.akhir_berlaku - sBulanSekarang
								: 13;
							let iBgSisaMasaBerlaku = "";
							if (iSisaMasaBerlaku < 1) {
								if (iIndex === 0) {
									iBgSisaMasaBerlaku = "bg-red-400";
								} else {
									iBgSisaMasaBerlaku = "bg-red-300";
								}
							} else if (iSisaMasaBerlaku < 4) {
								if (iIndex === 0) {
									iBgSisaMasaBerlaku = "bg-yellow-400";
								} else {
									iBgSisaMasaBerlaku = "bg-yellow-300";
								}
							} else if (iSisaMasaBerlaku < 13) {
								if (iIndex === 0) {
									iBgSisaMasaBerlaku = "bg-green-400";
								} else {
									iBgSisaMasaBerlaku = "bg-green-300";
								}
							}

							return (
								<tr
									style={{
										backgroundColor:
											xIndex === sSelect.index ? Env.color.orangeRed02 : null,
									}}
									key={xIndex}
									onClick={() => {
										if (props.state.sUser.eStatus === 70) {
											setSSelect((xState) => {
												const iState = xState;
												iState.index = xIndex;
												iState.status = xData.kegiatan_beli
													? iSisaMasaBerlaku < 4
														? "cek_beli_kegiatan"
														: "delete_beli_kegiatan"
													: "BeliKegiatan";
												iState.beliLagi = false;
												return iState;
											});
											setSDataBeliKegiatan((xState) => {
												const iState = { ...xState };
												iState.tanggal = Env.efTanggal();
												return iState;
											});
											setSRsMembers((xState) => {
												return [...xState];
											});
										}
									}}
								>
									<td className="text-center ">
										{props.state.sUser.eStatus === 70 ? (
											<div className="Button h-6 w-full">{xIndex + 1}</div>
										) : (
											xIndex + 1
										)}
									</td>
									<td>{xData.name}</td>
									<td align="center" className={`${iBgKelompok}`}>
										{iNoKelompok}
									</td>
									<td>{xData.members_group.nama}</td>
									<td>{xData.kegiatan_beli ? "Beli" : "Aktif"}</td>
									<td>
										{xData.kegiatan_beli
											? fGetBulan(xData.kegiatan_beli.akhir_berlaku)
											: ""}
									</td>
									<td className={`${iBgSisaMasaBerlaku} `}>
										{xData.kegiatan_beli ? `${iSisaMasaBerlaku} bulan` : ""}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
			<div className="flex h-12"></div>
			{/* END=TABLE DAFTAR ANGGOTA ===== */}
		</div>
	);
};

export default GlobalStateConsumer(BeliKegiatan);
