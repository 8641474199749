import React, { Fragment, useState, useRef, useEffect } from "react";

import Env from "../../setting/env";

const Index = (props) => {
	const [sValue, setValue] = useState("");

	const mRefTemp = useRef(null);
	const mRefTxtInput = props._ref || mRefTemp;
	let mNilai = 0;

	useEffect(() => {
		// setValue(props._value);
		let iValue = String(props._value || "");
		if (props._dataType === "ribuan") {
			iValue = iValue.replace(".", ",");
		}
		fCekData(iValue);

		// eslint-disable-next-line
	}, [props._value]);

	const fCekData = (xData) => {
		let iValue = String(xData);
		if (props._dataType === "ribuan") {
			iValue = Env.format.ribuan(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		} else if (props._dataType === "angka") {
			iValue = Env.format.angka(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		} else if (props._dataType === "pecahan") {
			iValue = Env.format.pecahan(iValue, (xNilai) => {
				mNilai = xNilai;
			});
		}

		if (props._tanda) {
			const iLength = props._minLength_minValue || 0;
			if (
				props._dataType === "ribuan" ||
				props._dataType === "angka" ||
				props._dataType === "pecahan"
			) {
				if (iLength === 0) {
					if (mNilai > iLength) {
						fValidasi(true);
					} else {
						fValidasi(false);
					}
				} else {
					if (mNilai >= iLength) {
						fValidasi(true);
					} else {
						fValidasi(false);
					}
				}
			} else {
				if (iLength === 0) {
					if (iValue.length > iLength) {
						fValidasi(true);
					} else {
						fValidasi(false);
					}
				} else {
					if (iValue.length >= iLength) {
						fValidasi(true);
					} else {
						fValidasi(false);
					}
				}
			}
		}

		setValue(iValue);
		if (props._dataType === "ribuan") {
			return mNilai;
		} else {
			return iValue;
		}
	};

	const fValidasi = (xValue) => {
		if (props._onValidasi) {
			props._onValidasi(xValue);
		}
	};

	const fNilai = (xValue) => {
		if (
			props._dataType === "ribuan" ||
			props._dataType === "angka" ||
			props._dataType === "pecahan"
		) {
			return Env.eNilai(xValue);
		} else {
			return "";
		}
	};

	return (
		<Fragment>
			<input
				type={props._type || "text"}
				maxLength={props._maxLength || null}
				placeholder={props._placeholder}
				ref={mRefTxtInput}
				className={`Text pr-1 ${props._className}`}
				value={sValue}
				onChange={(xValue) => {
					let iValue = xValue.nativeEvent.target.value;
					iValue = fCekData(iValue);
					// setValue(iValue);

					if (props._onChange) {
						props._onChange(iValue);
					}

					if (props._onNilai) {
						props._onNilai(fNilai(iValue));
					}
				}}
				onKeyPress={(pKey) => {
					if (pKey.nativeEvent.key === "Enter") {
						if (props._onKeyEnter) {
							if (props._dataType === "pecahan") {
								let iValue = true;
								let i = sValue.indexOf("/");
								if (i === 0) {
									iValue = false;
								} else if (i > 0) {
									let ii = (i += 1);
									let iX = sValue.substring(ii);
									if (iX === "") {
										iValue = false;
									} else {
										if (parseFloat(iX.replace(",", ".")) <= 0) {
											iValue = false;
										}
									}
								}
								if (!iValue) {
									let iiValue = sValue.substring(0, (i -= 1));
									setValue(iiValue);
									if (props._onChange) {
										props._onChange(iiValue);
									}
									if (props._onNilai) {
										props._onNilai(fNilai(iiValue));
									}
								}
							}
							props._onKeyEnter(pKey.nativeEvent.target.value);
						}
					}
				}}
				onKeyUp={(pKey) => {
					if (pKey.nativeEvent.key === "ArrowUp") {
						if (props._onKeyUp) {
							props._onKeyUp();
						}
					} else if (pKey.nativeEvent.key === "Enter") {
						if (props._onKeyEnterUp) {
							props._onKeyEnterUp(pKey.nativeEvent.target.value);
						}
					}
				}}
				onKeyDown={(pKey) => {
					if (pKey.nativeEvent.key === "ArrowDown") {
						if (props._onKeyDown) {
							props._onKeyDown();
						}
					}
				}}
				onFocus={() => {
					if (props._onFocus) {
						props._onFocus();
					}
				}}
			/>
		</Fragment>
	);
};

export default Index;
