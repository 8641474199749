import { useState, useRef, useEffect } from "react";
import Axios from "axios";
import CompPrint from "react-to-print";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";

// import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompLoading from "../../../../../component/arjLoading";
import CompAlertUlang from "../../../../../component/arjAlert";

// import CompAlertKegiatan from "../../../../../component/arjAlert";

const BayarRekapKegiatan = (props) => {
	let mGroupId = 0;
	let mBg = false;
	const fClassName = () => {
		mBg = !mBg;
		if (mBg) {
			return "flex h-full w-full bg-blue-200";
		} else {
			return "flex h-full w-full bg-blue-100";
		}
	};

	const [sSelectIndex, setsSelectIndex] = useState(-1);
	const [sLoading, setSLoading] = useState(true);
	const [sRsDataBayar, setSRsDataBayar] = useState([]);
	const [sRsRekapKegiatan, setSRsRekapKegiatan] = useState([]);
	const [sShowRekapKegiatan, setSShowRekapKegiatan] = useState(true);
	const [sBayar, setSBayar] = useState({
		show: false,
		index: 0,
		tunai: 0,
		kembali: 0,
		ditabungkan: 0,
		gunakanTabungan: false,
		ref: useRef(null),
	});

	const [sUlang, setSUlang] = useState({
		show: false,
		index: 0,
	});

	const [sKegiatan, setSKegiatan] = useState({
		show: false,
		kegiatan: null,
	});

	const fSetSKegiatan = (xKegiatan) => {
		let iKegiatanTerbayar = 0;
		xKegiatan.kegiatan_bayar_detail.forEach((xElement) => {
			iKegiatanTerbayar += xElement.nilai;
		});
		let iNilaiKegiatan = xKegiatan.nilai + iKegiatanTerbayar;
		xKegiatan.nilaiAwal = iNilaiKegiatan;
		// console.log(xKegiatan);
		setSKegiatan({
			show: true,
			kegiatan: xKegiatan,
		});
	};

	const refTabungan = useRef(null);
	const refTunai = useRef(null);
	const refBtnOk = useRef(null);
	const refKembali = useRef(null);
	const refIsiRekap = useRef(null);

	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
					{
						params: { req: "all" },
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);

				if (iData.status === Env.eHttpStatus.eOk) {
					const iRsDataMembers = iData.data.message.rekap_kegiatan_detail;
					// console.log(iRsDataMembers);
					if (iRsDataMembers) {
						const iRsOlahDataBayar = [];
						for (let i = 0; i < iRsDataMembers.length; i++) {
							const iDatas = iRsDataMembers[i];
							// console.log(iDatas);
							const iMembers_group = iDatas.members_group;
							const iObj = {};
							const iKegiatan_bayar = iDatas.kegiatan_bayar;
							let iJumlah = 0;
							let iTotal = 0;
							for (let ii = 0; ii < iKegiatan_bayar.length; ii++) {
								let iDataBayar = iKegiatan_bayar[ii];
								iDataBayar.status = true;
								let iArr = [];
								if (iObj[iDataBayar.kegiatan_jenis.nama]) {
									iArr = [...iObj[iDataBayar.kegiatan_jenis.nama].detail, iDataBayar];
									iJumlah += parseInt(iDataBayar.nilai);
									iTotal += parseInt(iDataBayar.nilai);
									iObj[iDataBayar.kegiatan_jenis.nama] = {
										detail: iArr,
										jumlah: iJumlah,
									};
								} else {
									iJumlah = parseInt(iDataBayar.nilai);
									iTotal += parseInt(iDataBayar.nilai);
									iObj[iDataBayar.kegiatan_jenis.nama] = {
										detail: [iDataBayar],
										jumlah: iJumlah,
									};
								}
							}

							iRsOlahDataBayar.push({
								tabungan: {
									id: iDatas.tabungan[0].id,
									saldo: iDatas.tabungan[0].saldo,
									gunakan: 0,
								},
								members_id: iDatas.id,
								name: iDatas.name,
								total: iTotal,
								tempTotal: iTotal,
								bayar: false,
								ulang: {},
								members_group: iMembers_group,
								bayar_group: iObj,
							});
						}
						setSRsDataBayar(iRsOlahDataBayar);
						// console.log("*******************");
						// console.log(iRsOlahDataBayar);
					}

					setSRsRekapKegiatan(iData.data.message.rekap_kegiatan);
				}

				setSLoading(false);
			};
			fGetData();
		} catch (xErr) {}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		try {
			sBayar.ref.current.focus();
			sBayar.ref.current.select();
		} catch (xErr) {}
	}, [sBayar]);

	useEffect(() => {
		sRsDataBayar.map((xData, xIndex) => {
			if (xData.total > xData.tempTotal) {
				xData.total = xData.tempTotal;
				setSRsDataBayar((xState) => {
					let iStete = [...xState];
					iStete[xIndex].total = xData.tempTotal;
					return iStete;
				});
			}
			return null;
		});
		// console.log("^^^^^^^^^^^^^^^^");
		// console.log(sRsDataBayar);
	}, [sRsDataBayar]);

	const fCheckedPembayaran = (xIndex, xKey, xxxIndex) => {
		setSRsDataBayar((xState) => {
			let iState = [...xState];
			let iObjBayar = { ...iState[xIndex] };
			let iBayar_group = { ...iObjBayar.bayar_group };
			let iJenisBayar = { ...iBayar_group[xKey] };
			let iJenisBayarDetail = [...iJenisBayar.detail];
			let iJenisBayarDetailIsi = { ...iJenisBayarDetail[xxxIndex] };
			let iStatus = !iJenisBayarDetailIsi.status;
			iJenisBayarDetailIsi.status = iStatus;
			if (!iStatus) {
				iObjBayar.total -= iJenisBayarDetailIsi.nilai;
				iObjBayar.tempTotal = iObjBayar.total;
			} else {
				iObjBayar.total += iJenisBayarDetailIsi.nilai;
				iObjBayar.tempTotal = iObjBayar.total;
			}

			iJenisBayarDetail[xxxIndex] = iJenisBayarDetailIsi;
			iJenisBayar.detail = iJenisBayarDetail;
			iBayar_group[xKey] = iJenisBayar;
			iObjBayar.bayar_group = iBayar_group;
			iState[xIndex] = iObjBayar;

			return iState;
		});
		// console.log(sRsDataBayar[xIndex].bayar_group[xKey].detail[xxxIndex]);
	};

	const fBayarRekapKegiatan = () => {
		if (!sLoading) {
			setSLoading(true);
			Axios.post(
				`${props.state.sPages.url}${Env.eRouteApi}/bendahara/bayar_rekap_kegiatan`,
				{
					kegiatan_bayar: sRsDataBayar[sBayar.index],
					nilai_bayar: {
						tunai: sBayar.tunai,
						// kembali: sBayar.kembali,
						ditabungkan: sBayar.ditabungkan,
					},
				},
				{
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			).then((xRes) => {
				if (xRes.status === Env.eHttpStatus.eOk) {
					setSRsDataBayar((xState) => {
						const iState = [...xState];
						const iDataBayar = { ...iState[sBayar.index] };
						iDataBayar.bayar = true;
						iDataBayar.ulang = xRes.data.message;
						iState[sBayar.index] = iDataBayar;
						return iState;
					});
					setSRsRekapKegiatan(xRes.data.rekap_kegiatan);
				} else {
					alert("Pembayaran gagal");
				}
				setSBayar((xState) => {
					const iState = { ...xState };
					iState.show = false;
					iState.index = 0;
					iState.tunai = 0;
					iState.kembali = 0;
					iState.ditabungkan = 0;
					iState.gunakanTabungan = false;
					iState.ref = null;
					return iState;
				});
				setSLoading(false);
			});
		}
	};

	const fUlangBayarRekapKegiatan = () => {
		if (!sLoading) {
			setSLoading(true);
			Axios.post(
				`${props.state.sPages.url}${Env.eRouteApi}/bendahara/bayar_rekap_kegiatan`,
				{
					kegiatan_bayar: sRsDataBayar[sUlang.index],
					nilai_bayar: {
						tunai: 0,
						ditabungkan: 0,
					},
				},
				{
					headers: {
						authorization: `bearer ${props.state.sToken}`,
					},
				}
			).then((xRes) => {
				if (xRes.status === Env.eHttpStatus.eOk) {
					setSRsDataBayar((xState) => {
						const iState = [...xState];
						iState[sUlang.index].tabungan.gunakan = 0;

						const iDataBayar = { ...iState[sUlang.index] };
						iDataBayar.bayar = false;
						iDataBayar.ulang = {};
						iState[sUlang.index] = iDataBayar;
						return iState;
					});
					setSRsRekapKegiatan(xRes.data.rekap_kegiatan);
				} else {
					alert("Ulang Pembayaran gagal");
				}
				setSUlang((xState) => {
					const iState = { ...xState };
					iState.show = false;
					iState.index = -1;
					return iState;
				});
				setSLoading(false);
			});
		}
	};

	return (
		<div className="flex flex-col w-full h-screen">
			{sLoading ? <CompLoading /> : null}
			{sBayar.show ? (
				<div className="Modal z-20 h-screen w-screen ">
					<div className=" w-full flex flex-col ">
						<div className=" h-10"></div>
						<div className="flex flex-col h-40 p-2 mt-1 w-full"></div>
						<div className="flex  w-full justify-center">
							<div
								className="flex flex-col max-w-sm  rounded-xl  
								border-blue-500 border-2 border-solid bg-blue-200 "
							>
								<div className="flex flex-col w-full justify-center  bg-blue-300 rounded-t-xl">
									<div className=" text-lg mx-auto">RINCIAN PEMBAYARAN</div>
									<div className="flex text-lg">
										<div className=" w-20 ml-1">Nama</div> <div className="mx-1">:</div>
										<div>{sRsDataBayar[sBayar.index].name}</div>
									</div>
									<div className="flex text-lg">
										<div className=" w-20 ml-1">Tabungan</div>{" "}
										<div className="mx-1">:</div>
										<div>
											{Env.format.uang(sRsDataBayar[sBayar.index].tabungan.saldo)}
										</div>
									</div>
								</div>
								<div className=" w-full  bg-black" style={{ height: "1px" }}></div>
								<div className="flex h-10 w-full px-2 items-center bg-blue-200">
									<div className=" h-10 flex items-center w-52 text-lg">
										Total Pembayaran
									</div>
									<div className=" h-10 flex items-center w-2 text-lg">:</div>
									<div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
										{Env.format.uang(sRsDataBayar[sBayar.index].total)}
									</div>
								</div>
								<div className="flex w-full ">
									<div className=" w-52 text-lg"> </div>
									<div className=" w-2 text-lg"> </div>
									<div className="flex-1 bg-black" style={{ height: "1px" }}></div>
								</div>
								<div className="flex w-full px-2 bg-blue-100">
									<div className=" h-10 flex items-center w-52 ">
										<div className="flex-1 text-lg">Gunakan Tabungan</div>
										<div className=" flex items-center w-6">
											<input
												type="checkbox"
												checked={sBayar.gunakanTabungan}
												onChange={() => {
													if (sRsDataBayar[sBayar.index].tabungan.saldo > 0) {
														setSBayar((xState) => {
															let iState = { ...xState };
															const iCheck = !iState.gunakanTabungan;
															iState.gunakanTabungan = iCheck;
															if (iCheck) {
																iState.ref = refTabungan;
																setSRsDataBayar((xxState) => {
																	let iiState = [...xxState];
																	if (
																		iiState[sBayar.index].total <
																		iiState[sBayar.index].tabungan.saldo
																	) {
																		iiState[sBayar.index].tabungan.gunakan =
																			iiState[sBayar.index].total;
																	} else {
																		iiState[sBayar.index].tabungan.gunakan =
																			iiState[sBayar.index].tabungan.saldo;
																	}
																	iState.tunai =
																		iiState[sBayar.index].total -
																		iiState[sBayar.index].tabungan.gunakan;
																	iState.kembali = 0;
																	iState.ditabungkan = 0;
																	return iiState;
																});
															} else {
																setSRsDataBayar((xxState) => {
																	const iiState = [...xxState];
																	iiState[sBayar.index].tabungan.gunakan = 0;
																	return iiState;
																});
																iState.ref = refTunai;
																iState.tunai = sRsDataBayar[sBayar.index].total;
															}
															return iState;
														});
													} else {
														refTunai.current.focus();
													}
												}}
											/>
										</div>
									</div>
									<div className=" h-10 flex items-center  w-2 text-lg">:</div>
									<div className=" h-10 flex items-center  flex-1 text-lg justify-end">
										{sBayar.gunakanTabungan ? (
											<CompText
												_dataType="ribuan"
												_ref={refTabungan}
												_value={sRsDataBayar[sBayar.index].tabungan.gunakan}
												_className="text-right"
												_onNilai={(xValue) => {
													let iNilai = xValue;
													if (xValue > sRsDataBayar[sBayar.index].tabungan.saldo) {
														iNilai = 0;
													}
													setSRsDataBayar((xState) => {
														let iState = [...xState];
														iState[sBayar.index].tabungan.gunakan = iNilai;
														return iState;
													});
												}}
												_onKeyEnter={() => {
													refTunai.current.focus();
												}}
											/>
										) : (
											<div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
												{Env.format.uang(sRsDataBayar[sBayar.index].tabungan.gunakan)}
											</div>
										)}
									</div>
								</div>
								<div className="flex w-full px-2 bg-blue-100 ">
									<div className=" h-10 flex items-center  w-52 text-lg">Tunai</div>
									<div className=" h-10 flex items-center  w-2 text-lg">:</div>
									<div className=" h-10 flex items-center  flex-1 text-lg justify-end">
										<CompText //===========Text Tunai =======================
											_dataType={"ribuan"}
											_value={sBayar.tunai}
											_ref={refTunai}
											_className="text-right"
											_onNilai={(xNilai) => {
												setSBayar((xState) => {
													let iState = { ...xState };
													iState.tunai = xNilai;
													let iKembali =
														sRsDataBayar[iState.index].total -
														(iState.tunai + sRsDataBayar[iState.index].tabungan.gunakan);
													if (iKembali < 0) {
														//jika ada kembalian
														iState.kembali = Math.abs(iKembali);
														// iState.showKembali = true; //tampilkan text kembalian
													} else {
														// iState.showKembali = false; //Sembunyikan text kembalian
														iState.kembali = 0;
													}
													iState.ditabungkan = 0;
													iState.ref = null;
													return iState;
												});
											}}
											_onKeyEnter={() => {
												if (sBayar.kembali > 0) {
													refKembali.current.focus();
													refKembali.current.select();
												} else {
													setSBayar((xState) => {
														let iState = { ...xState };
														iState.ref = refBtnOk;
														return iState;
													});
												}
											}}
											_onFocus={() => {
												refTunai.current.select();
											}}
										/>
									</div>
								</div>
								<div className="flex w-full ">
									<div className=" w-52 text-lg"> </div>
									<div className=" w-2 text-lg"> </div>
									<div className="flex-1 bg-black" style={{ height: "1px" }}></div>
								</div>
								<div className="flex w-full px-2 bg-blue-200">
									<div className=" h-10 flex items-center  w-52 text-lg">Kembali</div>
									<div className=" h-10 flex items-center  w-2 text-lg">:</div>
									<div className=" h-10 flex items-center  flex-1 text-lg justify-end">
										{/* {sBayar.kembali > 0 ? ( */}
										<CompText
											_ref={refKembali}
											_textAlign={"right"}
											_className="text-right"
											_dataType={"ribuan"}
											_value={sBayar.kembali}
											_onNilai={(xNilai) => {
												setSBayar((xState) => {
													let iState = { ...xState };
													let iKembali =
														sRsDataBayar[iState.index].total -
														(iState.tunai + sRsDataBayar[iState.index].tabungan.gunakan);
													iKembali = Math.abs(iKembali);
													if (xNilai > iKembali) {
														iState.kembali = 0;
													} else {
														iState.kembali = xNilai;
													}
													iState.ditabungkan = iKembali - iState.kembali;
													iState.ref = null;
													return iState;
												});
											}}
											_onKeyEnter={() => {
												refBtnOk.current.focus();
											}}
										/>
									</div>
								</div>
								<div className="flex w-full">
									<div className=" w-52 text-lg"> </div>
									<div className=" w-2 text-lg"> </div>
									<div className="flex-1 bg-black" style={{ height: "1px" }}></div>
								</div>
								<div className="flex items-center w-full px-2 bg-blue-100">
									<div className=" h-10 flex items-center  w-52 text-lg">
										Ditabungkan
									</div>
									<div className=" h-10 flex items-center  w-2 text-lg">:</div>
									<div className="Text h-7 flex items-center flex-1 text-lg justify-end bg-gray-200">
										{Env.format.uang(sBayar.ditabungkan)}
									</div>
								</div>
								<div className=" w-full  bg-black" style={{ height: "1px" }}></div>

								<div className="flex w-full h-16 justify-evenly items-center bg-gray-400 rounded-b-xl">
									<div className="flex justify-evenly w-52">
										{sRsDataBayar[sBayar.index].total +
											sBayar.ditabungkan +
											sBayar.kembali ===
										sBayar.tunai + sRsDataBayar[sBayar.index].tabungan.gunakan ? (
											<button
												ref={refBtnOk}
												className="Button w-16"
												onClick={() => {
													// ====== EXECUSI BAYAR ======
													fBayarRekapKegiatan();
													// END=== EXECUSI BAYAR ======
												}}
											>
												OK
											</button>
										) : (
											""
										)}
										<button
											className="Button w-16"
											onClick={() => {
												setSBayar((xState) => {
													let iState = { ...xState };
													iState.show = false;
													iState.ref = null;
													return iState;
												});
											}}
										>
											Ulang
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{sUlang.show ? (
				<CompAlertUlang
					_title="Mengulang Bayar Kegiatan"
					_keterangan={`Apakah yakin ingin mengulang pembayaran an: ${
						sRsDataBayar[sUlang.index].name
					} ?`}
					_buttonCancel="Batal"
					_onCancel={() => {
						setSUlang({ show: false, index: -1 });
					}}
					_onOk={() => {
						fUlangBayarRekapKegiatan();
					}}
				/>
			) : null}

			{/* === NILAI KEGIATAN =========== */}
			{sKegiatan.show ? (
				<div className="Modal z-100">
					<div className="flex overflow-y-auto h-full items-center pt-12 pb-4">
						<div
							className="flex flex-col max-w-sm  bg-genkiGray-lightest mt-10
						rounded-xl border-genkiBlue-dark border-solid border-2"
						>
							<div
								// ==== TITLE ====================
								className="flex justify-center items-center px-2 text-xl
							rounded-t-xl h-10 bg-gradient-to-t from-genkiBlue-light to-genkiBlue border-1 border-solid border-genkiBlue-dark"
							>
								{`Kegiatan Tanggal: ${Env.efFormatTanggal2(
									sKegiatan.kegiatan.kegiatan.id.substring(0, 8)
								)}`}
							</div>

							<div
								// ======= KEGIATAN =======================
								className="flex  items-center justify-center w-full text-center text-lg
							 p-2 border-1 border-solid border-genkiBlue-dark"
							>
								{sKegiatan.kegiatan.kegiatan.kegiatan}
							</div>

							{/* =====KETARANGAN======= */}
							{sKegiatan.kegiatan.kegiatan.keterangan ? (
								<div className="flex  w-full  p-2 border-1 border-solid border-genkiBlue-dark">
									{`Keterangan: ${sKegiatan.kegiatan.kegiatan.keterangan}`}
								</div>
							) : null}

							{/* ====== NILAI ======== */}
							<div className="flex  w-full  p-2 border-1 border-solid border-genkiBlue-dark text-xl">
								<div className="flex-1 text-xl">Nilai :</div>
								<div className="text-xl">
									{`${Env.format.uang(sKegiatan.kegiatan.nilaiAwal)}`}
								</div>
							</div>

							{/* ====== DATA BAYAR ======== */}
							{sKegiatan.kegiatan.nilaiAwal !== sKegiatan.kegiatan.nilai ? (
								<div>
									<table className="Table">
										<thead>
											<tr>
												<th>No</th>
												<th>Tanggal</th>
												<th>Bayar</th>
											</tr>
										</thead>
										<tbody>
											{sKegiatan.kegiatan.kegiatan_bayar_detail.map((xData, xIndex) => {
												const iTanggal = Env.efFormatTanggal2(xData.id.substring(0, 8));
												const iNilai = Env.format.uang(xData.nilai);
												return (
													<tr key={xIndex}>
														<td width={30}>{xIndex + 1}</td>
														<td>{iTanggal}</td>
														<td align="right">{iNilai}</td>
													</tr>
												);
											})}
											<tr>
												<td width={30}></td>
												<td align="right">Jumlah Terbayar</td>
												<td align="right">
													{Env.format.uang(
														sKegiatan.kegiatan.nilaiAwal - sKegiatan.kegiatan.nilai
													)}
												</td>
											</tr>
											<tr>
												<td align="center" width={30}>
													*
												</td>
												<td align="right">Sisa Tunggakan</td>
												<td align="right" className=" text-lg font-bold bg-red-300">
													{Env.format.uang(sKegiatan.kegiatan.nilai)}
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							) : null}

							<div className="flex items-center justify-evenly h-10 w-full border-1 border-solid border-genkiBlue-dark rounded-b-xl">
								<button
									className="Button h-8 w-16 "
									onClick={() => {
										setSKegiatan({ show: false, kegiatan: null });
									}}
								>
									OK
								</button>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{/* TITLE */}
			<div className="flex mx-1 p-1">
				<div
					className="flex flex-1 p-2 mt-1 max-w-sm md:max-w-xl h-8
							mx-auto bg-white rounded-xl shadow-md justify-center items-center"
				>
					<p>Bayar Rakap Kegiatan</p>
				</div>

				<div className="flex justify-evenly items-center  w-40">
					<button
						className="Button w-20 h-6"
						onClick={() => {
							setSShowRekapKegiatan(!sShowRekapKegiatan);
						}}
					>
						{sShowRekapKegiatan ? "Sembunyi" : "Lihat"}
					</button>

					<CompPrint
						trigger={() => {
							return <div className="Button h-6">Cetak</div>;
						}}
						content={() => refIsiRekap.current}
					/>
				</div>
			</div>
			{sShowRekapKegiatan ? (
				<div className="flex flex-col w-full px-2">
					<table className="Table">
						<thead>
							<tr>
								<th>No</th>
								<th>Jenis</th>
								<th>jumlah</th>
								<th>Terbayar Hari Ini</th>
							</tr>
						</thead>
						<tbody>
							{sRsRekapKegiatan.map((xData, xIndex) => {
								if (xData.jenis !== "Total") {
									return (
										<tr key={xIndex}>
											<td width={30}>{xIndex + 1}</td>
											<td>{xData.jenis}</td>
											<td align="right">{Env.format.uang(xData.jumlah)}</td>
											<td align="right">{Env.format.uang(xData.bayar_hari_ini)}</td>
										</tr>
									);
								} else {
									return (
										<tr key={xIndex}>
											<td align="center" width={30}>
												*
											</td>
											<td align="right">{xData.jenis}</td>
											<td align="right">{Env.format.uang(xData.jumlah)}</td>
											<td align="right">{Env.format.uang(xData.bayar_hari_ini)}</td>
										</tr>
									);
								}
							})}
						</tbody>
					</table>
				</div>
			) : null}

			<div
				className="flex-1 md:p-2 overflow-y-auto mt-2 bg-blue-300"
				ref={refIsiRekap}
			>
				{sRsDataBayar.map((xDataBayar, xIndex) => {
					const iDataBayarGroup = xDataBayar.bayar_group;
					const iRsDataBayarJenis = Object.keys(iDataBayarGroup);
					// let iTotalBayar = 0;
					if (mGroupId !== xDataBayar.members_group.id) {
						mGroupId = xDataBayar.members_group.id;
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className="flex justify-center w-full border-gray-900 border-solid border-1 text-xl bg-red-500 text-white">
									{xDataBayar.members_group.nama}
								</div>
								<div className={fClassName()}>
									{/* Nama Anggota Penunggak */}
									<div
										className={`flex items-center w-44 md:w-72  border-gray-900 border-solid border-1 ${
											xIndex === sSelectIndex ? "bg-yellow-300" : null
										} `}
									>
										{xDataBayar.name}
									</div>

									{/* Detail Tunggakan */}
									<div className="flex-1 border-gray-900 border-solid border-1">
										{iRsDataBayarJenis.map((xKey, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? "flex items-center "
															: "flex items-center border-gray-900 border-solid border-t-2 "
													}
												>
													{/* Jenis Kegiatan */}
													<div className=" flex-1 md:pl-1">{xKey}</div>

													{/* Nilai Kegiatan */}
													<div className="flex flex-col border-gray-900 border-solid border-l-2 ">
														{iDataBayarGroup[xKey].detail.map((xBayar, xxxIndex) => {
															return (
																<div
																	key={xxxIndex}
																	className={
																		xxxIndex === 0
																			? "flex items-center border-gray-900 border-solid  border-r-2 "
																			: "flex items-center border-gray-900 border-solid border-t-2 border-r-2 "
																	}
																>
																	<button
																		className="Button h-6 flex-1"
																		onClick={async () => {
																			if (!sLoading) {
																				const iData = await Axios.get(
																					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
																					{
																						params: {
																							req: "kegiatan",
																							// id: xBayar.kegiatan.id,
																							id: xBayar.id,
																						},
																						headers: {
																							authorization: `bearer ${props.state.sToken}`,
																						},
																					}
																				);

																				fSetSKegiatan(iData.data.message[0]);
																			}
																		}}
																	>
																		{Env.format.uang(xBayar.nilai)}
																	</button>
																	<div className="flex items-center h-f mx-2 ">
																		<input
																			type="checkbox"
																			checked={xBayar.status}
																			onChange={() => {
																				if (!xDataBayar.bayar) {
																					fCheckedPembayaran(xIndex, xKey, xxxIndex);
																				}
																			}}
																		/>
																	</div>
																</div>
															);
														})}
													</div>

													{/* Jumlah Nilai Kegiatan */}
													<div className="flex w-20 md:w-32 justify-end md:pr-1">
														{Env.format.uang(iDataBayarGroup[xKey].jumlah)}
													</div>
												</div>
											);
										})}

										{/* Bayar Tunggakan */}
										<div
											className={
												xDataBayar.bayar
													? `flex-1 flex w-full border-gray-900 border-solid border-t-1 ${
															xIndex === sSelectIndex ? "bg-yellow-300" : null
													  } `
													: `flex-1 flex w-full border-gray-900 border-solid border-t-1 ${
															xIndex === sSelectIndex ? "bg-yellow-300" : "bg-white"
													  } `
											}
										>
											<div className=" flex-1"></div>
											<div className=" mx-2 mt-0.5">
												{!xDataBayar.bayar ? (
													<button
														className="Button h-6"
														onClick={() => {
															if (xDataBayar.total > 0) {
																setSBayar({
																	show: true,
																	index: xIndex,
																	tunai: sRsDataBayar[xIndex].total,
																	kembali: 0,
																	ditabungkan: 0,
																	gunakanTabungan: false,
																	ref: refTunai,
																});
															}
															setsSelectIndex(xIndex);
														}}
													>
														Bayar
													</button>
												) : (
													<button
														className="Button h-6"
														onClick={() => {
															setSUlang({
																show: true,
																index: xIndex,
															});
															setsSelectIndex(xIndex);
														}}
													>
														Ulang
													</button>
												)}
											</div>

											{xDataBayar.bayar ? (
												<div className="flex w-52 justify-end md:pr-0.5 ">
													<div className="flex flex-1  items-center mr-0.5 md:mr-1">
														Terbayar :
													</div>
													<div className="Text h-7 w-32 flex items-center text-lg justify-end ">
														{Env.format.uang(xDataBayar.total)}
													</div>
												</div>
											) : (
												<div className="flex w-32 justify-end md:pr-0.5 ">
													<CompText
														_value={xDataBayar.total}
														_dataType="ribuan"
														_className="text-right"
														_onNilai={(xNilai) => {
															setSRsDataBayar((xState) => {
																let iState = [...xState];
																iState[xIndex].total = xNilai;
																return iState;
															});
														}}
														_onKeyEnter={() => {
															if (xDataBayar.total > 0) {
																setSBayar({
																	show: true,
																	index: xIndex,
																	tunai: sRsDataBayar[xIndex].total,
																	kembali: 0,
																	ditabungkan: 0,
																	gunakanTabungan: false,
																	ref: refTunai,
																});
															}
														}}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					} else {
						return (
							<div key={xIndex} className="flex flex-col w-full">
								<div className={fClassName()}>
									{/* Nama Anggota Penunggak */}
									<div
										className={`flex items-center w-44 md:w-72  border-gray-900 border-solid border-1 ${
											xIndex === sSelectIndex ? "bg-yellow-300" : null
										} `}
									>
										{xDataBayar.name}
									</div>

									{/* Detail Tunggakan */}
									<div className="flex-1 border-gray-900 border-solid border-1">
										{iRsDataBayarJenis.map((xKey, xxIndex) => {
											return (
												<div
													key={xxIndex}
													className={
														xxIndex === 0
															? "flex items-center "
															: "flex items-center border-gray-900 border-solid border-t-2 "
													}
												>
													{/* Jenis Kegiatan */}
													<div className=" flex-1 md:pl-1">{xKey}</div>

													{/* Nilai Kegiatan */}
													<div className="flex flex-col border-gray-900 border-solid border-l-2 ">
														{iDataBayarGroup[xKey].detail.map((xBayar, xxxIndex) => {
															return (
																<div
																	key={xxxIndex}
																	className={
																		xxxIndex === 0
																			? "flex items-center border-gray-900 border-solid  border-r-2 "
																			: "flex items-center border-gray-900 border-solid border-t-2 border-r-2 "
																	}
																>
																	<button
																		className="Button h-6 flex-1"
																		onClick={async () => {
																			if (!sLoading) {
																				const iData = await Axios.get(
																					`${props.state.sPages.url}${Env.eRouteApi}/bayar_rekap_kegiatan`,
																					{
																						params: { req: "kegiatan", id: xBayar.id },
																						headers: {
																							authorization: `bearer ${props.state.sToken}`,
																						},
																					}
																				);
																				// alert(iData.data.message);
																				fSetSKegiatan(iData.data.message[0]);
																			}
																		}}
																	>
																		{Env.format.uang(xBayar.nilai)}
																	</button>
																	<div className="flex items-center h-f mx-2  ">
																		<input
																			type="checkbox"
																			checked={xBayar.status}
																			onChange={() => {
																				if (!xDataBayar.bayar) {
																					fCheckedPembayaran(xIndex, xKey, xxxIndex);
																				}
																			}}
																		/>
																	</div>
																</div>
															);
														})}
													</div>

													{/* Jumlah Nilai Kegiatan */}
													<div className="flex w-20 md:w-32 justify-end md:pr-1">
														{Env.format.uang(iDataBayarGroup[xKey].jumlah)}
													</div>
												</div>
											);
										})}

										{/* Bayar Tunggakan */}
										<div
											className={
												xDataBayar.bayar
													? `flex-1 flex w-full border-gray-900 border-solid border-t-1 ${
															xIndex === sSelectIndex ? "bg-yellow-300" : null
													  } `
													: `flex-1 flex w-full border-gray-900 border-solid border-t-1 ${
															xIndex === sSelectIndex ? "bg-yellow-300" : "bg-white"
													  } `
											}
										>
											<div className=" flex-1"></div>
											<div className=" mx-2 mt-0.5">
												{!xDataBayar.bayar ? (
													<button
														className="Button h-6"
														onClick={() => {
															if (xDataBayar.total > 0) {
																setSBayar({
																	show: true,
																	index: xIndex,
																	tunai: sRsDataBayar[xIndex].total,
																	kembali: 0,
																	ditabungkan: 0,
																	gunakanTabungan: false,
																	ref: refTunai,
																});
															}
															setsSelectIndex(xIndex);
														}}
													>
														Bayar
													</button>
												) : (
													<button
														className="Button h-6"
														onClick={() => {
															setSUlang({
																show: true,
																index: xIndex,
															});
															setsSelectIndex(xIndex);
														}}
													>
														Ulang
													</button>
												)}
											</div>

											{xDataBayar.bayar ? (
												<div className="flex w-52 justify-end md:pr-0.5 ">
													<div className="flex flex-1 items-center mr-0.5 md:mr-1">
														Terbayar :
													</div>
													<div className="Text h-7 w-32 flex items-center text-lg justify-end ">
														{Env.format.uang(xDataBayar.total)}
													</div>
												</div>
											) : (
												<div className="flex w-32 justify-end md:pr-0.5 ">
													<CompText
														_value={xDataBayar.total}
														_dataType="ribuan"
														_className="text-right"
														_onNilai={(xNilai) => {
															setSRsDataBayar((xState) => {
																let iState = [...xState];
																iState[xIndex].total = xNilai;
																return iState;
															});
														}}
														_onKeyEnter={() => {
															if (xDataBayar.total > 0) {
																setSBayar({
																	show: true,
																	index: xIndex,
																	tunai: sRsDataBayar[xIndex].total,
																	kembali: 0,
																	ditabungkan: 0,
																	gunakanTabungan: false,
																	ref: refTunai,
																});
															}
														}}
													/>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					}
				})}
			</div>

			<div className=" h-14 "></div>
		</div>
	);
};

export default GlobalStateConsumer(BayarRekapKegiatan);
