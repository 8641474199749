import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import PiutangSetting from "../../../../../setting/piutang_setting";
import Env from "../../../../../setting/env";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const ProgramPiutang = (props) => {
	const [sEdit, setSEdit] = useState({ index: -1, edit: false });
	const [sRsProgramPiutang, setSRsProgramPiutang] = useState([]);
	const [sRsAktiva, setSRsAktiva] = useState([]);
	const [sRsPasiva, setSRsPasiva] = useState([]);
	const mJenisPiutang = PiutangSetting.eJenisPiutang;
	const fGetValueJenisPiutang = (xKode) => {
		return PiutangSetting.efGetValueJenisPiutang(xKode);
	};

	const mNewData = {
		nama: "",
		jenis: { kode: 0, value: "" },
		bunga: "",
		denda: "",
		akun_sumber_dana: { no_akun: "", nama: "" },
		akun_piutang: { no_akun: "", nama: "" },
		akun_bunga: { no_akun: "", nama: "" },
		akun_denda: { no_akun: "", nama: "" },
	};
	const [sNewData, setSNewData] = useState(mNewData);
	const [sAlert, setSAlert] = useState({
		title: "Data Program Piutang",
		keterangan: "Data belum lengkap",
		focus: "",
		visible: false,
	});
	const [sAlertDelete, setSAlertDelete] = useState(false);
	const [sFocus, setSFocus] = useState("");
	const [sLoading, setSLoading] = useState(true);

	const refNama = useRef();
	const refJenis = useRef();
	const refBunga = useRef();
	const refDenda = useRef();
	const refAkunSumberDana = useRef();
	const refAkunPiutang = useRef();
	const refAkunBunga = useRef();
	const refAkunDenda = useRef();

	const refSimpan = useRef();
	const refUpdate = useRef();

	const fCekData = () => {
		let iFocus = "";
		if (sNewData.nama.length > 2) {
			if (sNewData.jenis.kode > 0) {
				if (sNewData.bunga !== "") {
					if (sNewData.denda === "") {
						iFocus = "denda";
					}
				} else {
					iFocus = "bunga";
				}
			} else {
				iFocus = "jenis";
			}
		} else {
			iFocus = "nama";
		}
		if (iFocus === "") {
			// SIMPAN atau UPDATE DATA
			if (sEdit.edit) {
				// UPDATE DATA
				setSLoading(true);
				Axios.put(
					`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_piutang`,
					{
						id: sRsProgramPiutang[sEdit.index].id,
						nama: sNewData.nama,
						jenis: sNewData.jenis.kode,
						bunga: sNewData.bunga,
						denda: sNewData.denda,
						akun_sumber_dana: sNewData.akun_sumber_dana.no_akun,
						akun_piutang: sNewData.akun_piutang.no_akun,
						akun_bunga: sNewData.akun_bunga.no_akun,
						akun_denda: sNewData.akun_denda.no_akun,
					},
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				)
					.then((xData) => {
						setSLoading(false);
						setSRsProgramPiutang((xState) => {
							let iState = [...xState];
							iState.splice(sEdit.index, 1, xData.data.message);
							return iState;
						});
						setSEdit((xState) => {
							let iState = { ...xState };
							iState.edit = false;
							return iState;
						});
						setSNewData(mNewData);
						refNama.current.focus();
					})
					.catch(() => {
						setSLoading(false);
						setSAlert((xState) => {
							let iState = { ...xState };
							iState.title = "Data Program Piutang";
							iState.keterangan = "Data Gagal DiUbah";
							iState.focus = "nama";
							iState.visible = true;
							return iState;
						});
					});
			} else {
				// SIMPAN DATA
				setSLoading(true);
				Axios.post(
					`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_piutang`,
					{
						nama: sNewData.nama,
						jenis: sNewData.jenis.kode,
						bunga: sNewData.bunga,
						denda: sNewData.denda,
						akun_sumber_dana: sNewData.akun_sumber_dana.no_akun,
						akun_piutang: sNewData.akun_piutang.no_akun,
						akun_bunga: sNewData.akun_bunga.no_akun,
						akun_denda: sNewData.akun_denda.no_akun,
					},
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				)
					.then((xData) => {
						setSLoading(false);
						if (xData.status === Env.eHttpStatus.eOk) {
							setSNewData(mNewData);
							setSEdit((xState) => {
								let iState = { ...xState };
								iState.index = -1;
								return iState;
							});
							setSRsProgramPiutang((xState) => {
								let iState = [...xState];
								iState.push(xData.data.message);
								return iState;
							});
							refNama.current.focus();
						} else {
							setSAlert((xState) => {
								let iState = { ...xState };
								iState.title = "Data Program Piutang";
								iState.keterangan = "Data Gagal Disimpan";
								iState.focus = "nama";
								iState.visible = true;
								return iState;
							});
						}
					})
					.catch(() => {
						setSLoading(false);
						setSAlert((xState) => {
							let iState = { ...xState };
							iState.title = "Data Program Piutang";
							iState.keterangan = "Data Gagal Disimpan";
							iState.focus = "nama";
							iState.visible = true;
							return iState;
						});
					});
			}
		} else {
			setSAlert((xState) => {
				let iState = { ...xState };
				iState.title = "Data Program Piutang";
				iState.keterangan = "Data belum lengkap";
				iState.focus = iFocus;
				iState.visible = true;
				return iState;
			});
		}
	};

	useEffect(() => {
		switch (sFocus) {
			case "nama":
				refNama.current.focus();
				refNama.current.select();
				break;
			case "jenis":
				refJenis.current.focus();
				refJenis.current.select();
				break;
			case "bunga":
				refBunga.current.focus();
				refBunga.current.select();
				break;
			case "denda":
				refDenda.current.focus();
				refDenda.current.select();
				break;
			default:
				break;
		}
		// eslint-disable-next-line
	}, [sFocus]);

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_piutang`,
			{
				params: { req: "rs_program_piutang" },
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		)
			.then((xData) => {
				setSLoading(false);
				setSRsProgramPiutang(xData.data.message.rsProgramPiutang);
				setSRsAktiva(xData.data.message.rsAktiva);
				setSRsPasiva(xData.data.message.rsPasiva);
			})
			.catch((xErr) => {
				setSLoading(false);
				alert(xErr);
			});

		if (refNama) {
			refNama.current.focus();
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className="flex flex-col h-full ">
			<div className="flex-1 overflow-y-scroll">
				{sAlertDelete ? (
					<CompAlertDelete
						_title="Progaram Piutang"
						_keterangan=" Apakah Benar Data Akan Dihapus"
						_buttonCancel="Ulang"
						_onCancel={() => {
							setSAlertDelete(false);
						}}
						_onOk={() => {
							setSAlertDelete(false);
							setSLoading(true);
							Axios.delete(
								`${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_piutang`,
								{
									data: { id: sRsProgramPiutang[sEdit.index].id },
									headers: {
										authorization: `bearer ${props.state.sToken}`,
									},
								}
							)
								.then((xData) => {
									setSLoading(false);
									setSRsProgramPiutang((xState) => {
										let iState = [...xState];
										iState.splice(sEdit.index, 1);
										return iState;
									});
									setSEdit((xState) => {
										let iState = { ...xState };
										iState.index = -1;
										iState.edit = false;
										return iState;
									});
									setSNewData(mNewData);
									refNama.current.focus();
								})
								.catch(() => {
									setSLoading(false);
									alert("Data Gagal Di Hapus");
									refNama.current.focus();
								});
						}}
					/>
				) : (
					""
				)}
				{sAlert.visible ? (
					<CompAlert
						_title={sAlert.title}
						_keterangan={sAlert.keterangan}
						_onOk={() => {
							setSAlert((xState) => {
								let iState = { ...xState };
								iState.visible = false;
								return iState;
							});
							setSFocus(sAlert.focus);
						}}
					/>
				) : (
					""
				)}
				{sLoading ? <CompLoading /> : ""}

				{/* TITLE */}
				<div
					className="flex flex-col p-2 mt-1 max-w-sm md:max-w-xl 
                    mx-auto bg-white rounded-xl shadow-md items-center "
				>
					<p>PROGRAM PIUTANG</p>
				</div>

				{/* FORM ISIAN DATA */}
				<div
					className="flex flex-col p-2 mt-1 w-full md:max-w-7xl
                     bg-blue-100 rounded-xl shadow-md mx-auto space-y-2"
					style={{
						backgroundColor: sEdit.edit ? Env.color.orangeRed02 : "",
					}}
				>
					<div className=" mx-auto">
						<p>{sEdit.edit ? "Edit Data" : "Masukan Data"}</p>
					</div>
					<div className="flex">
						<div className=" w-40">Nama</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refNama}
								_value={sNewData.nama}
								_onChange={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.nama = xData;
										return iState;
									});
								}}
								_onKeyEnterUp={(xData) => {
									if (xData.length > 2) {
										refJenis.current.focus();
										refJenis.current.select();
									}
								}}
							/>
						</div>
					</div>
					<div className="flex">
						<div className=" w-40">Jenis</div>
						<div className=" w-2">:</div>
						<CompTextDs
							_ref={refJenis}
							_dataSource={mJenisPiutang}
							_value={sNewData.jenis.value}
							_onKeyEnter={(xData) => {
								setSNewData((xState) => {
									let iState = { ...xState };
									iState.jenis.kode = xData[0];
									iState.jenis.value = xData[1];
									return iState;
								});
								refBunga.current.focus();
								refBunga.current.select();
							}}
						/>
					</div>
					<div className="flex">
						<div className=" w-40">Bunga</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refBunga}
								_dataType="pecahan"
								_value={sNewData.bunga}
								_onChange={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.bunga = xData;
										return iState;
									});
								}}
								_onKeyEnterUp={(xData) => {
									if (xData !== "") {
										refDenda.current.focus();
										refDenda.current.select();
									}
								}}
							/>
						</div>
						<div className="w-28 ml-1">
							<p>% / bulan</p>
						</div>
					</div>
					<div className="flex">
						<div className=" w-40">Denda</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompText
								_ref={refDenda}
								_dataType="pecahan"
								_value={sNewData.denda}
								_onChange={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.denda = xData;
										return iState;
									});
								}}
								_onKeyEnterUp={(xData) => {
									refAkunSumberDana.current.focus();
									refAkunSumberDana.current.select();
								}}
							/>
						</div>
						<div className="w-28 ml-1">
							<p>% / bulan</p>
						</div>
					</div>

					<div className="flex">
						<div className=" w-40">Akun Sumber Dana</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunSumberDana}
								_dataSource={sRsAktiva}
								_value={sNewData.akun_sumber_dana.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_sumber_dana.no_akun = xData[0];
										iState.akun_sumber_dana.nama = xData[1];
										return iState;
									});
									refAkunPiutang.current.focus();
									refAkunPiutang.current.select();
								}}
							/>
						</div>

						<p className="Text w-28 ml-1">{sNewData.akun_sumber_dana.no_akun}</p>
					</div>

					<div className="flex">
						<div className=" w-40">Akun Piutang</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunPiutang}
								_dataSource={sRsAktiva}
								_value={sNewData.akun_piutang.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_piutang.no_akun = xData[0];
										iState.akun_piutang.nama = xData[1];
										return iState;
									});
									refAkunBunga.current.focus();
									refAkunBunga.current.select();
								}}
							/>
						</div>

						<p className="Text w-28 ml-1">{sNewData.akun_piutang.no_akun}</p>
					</div>

					<div className="flex">
						<div className=" w-40">Akun Bunga Piutang</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunBunga}
								_dataSource={sRsPasiva}
								_value={sNewData.akun_bunga.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_bunga.no_akun = xData[0];
										iState.akun_bunga.nama = xData[1];
										return iState;
									});
									refAkunDenda.current.focus();
									refAkunDenda.current.select();
								}}
							/>
						</div>

						<p className="Text w-28 ml-1">{sNewData.akun_bunga.no_akun}</p>
					</div>

					<div className="flex">
						<div className=" w-40">Akun Denda Piutang</div>
						<div className=" w-2">:</div>
						<div className=" flex-1">
							<CompTextDs
								_ref={refAkunDenda}
								_dataSource={sRsPasiva}
								_value={sNewData.akun_denda.nama}
								_onKeyEnter={(xData) => {
									setSNewData((xState) => {
										let iState = { ...xState };
										iState.akun_denda.no_akun = xData[0];
										iState.akun_denda.nama = xData[1];
										return iState;
									});
									if (xData !== "") {
										sEdit.edit ? refUpdate.current.focus() : refSimpan.current.focus();
									}
								}}
							/>
						</div>

						<p className="Text w-28 ml-1">{sNewData.akun_denda.no_akun}</p>
					</div>

					<div>
						{sEdit.edit ? (
							<div className="flex max-w-sm mx-auto justify-evenly">
								<button
									ref={refUpdate}
									className="Button mx-auto"
									onClick={() => {
										fCekData();
										// setSFocus("");
									}}
								>
									Update
								</button>
								<button
									className="Button mx-auto"
									onClick={() => {
										setSAlertDelete(true);
									}}
								>
									Delete
								</button>
								<button
									className="Button mx-auto"
									onClick={() => {
										setSEdit((xState) => {
											let iState = { ...xState };
											iState.edit = false;
											return iState;
										});
										setSNewData(mNewData);
										refNama.current.focus();
										refNama.current.select();
									}}
								>
									Cancel
								</button>
							</div>
						) : (
							<button
								ref={refSimpan}
								className="Button mx-auto"
								onClick={() => {
									fCekData();
								}}
							>
								Simpan
							</button>
						)}
					</div>
				</div>

				{/* TABLE DATA */}
				<div
					className=" w-full md:max-w-7xl md:p-2 mt-2 mx-auto 
							bg-blue-100 rounded-xl shadow-md"
				>
					<table className="Table shadow-md">
						<thead>
							<tr>
								<th>No</th>
								<th>Nama</th>
								<th>Jenis</th>
								<th>Bunga</th>
								<th>Denda</th>
								<th>Akun Sumber Dana</th>
								<th>Akun Piutang</th>
								<th>Akun Bunga Piutang</th>
								<th>Akun Denda Piutang</th>
							</tr>
						</thead>
						<tbody>
							{sRsProgramPiutang.map((xData, xIndex) => {
								return (
									<tr
										key={xIndex}
										style={{
											backgroundColor: xIndex === sEdit.index ? Env.color.orangeRed02 : "",
										}}
									>
										<td className=" w-10 p-1">
											<button
												className="Button w-full h-full"
												onClick={() => {
													setSEdit((xState) => {
														let iState = { ...xState };
														iState.index = xIndex;
														iState.edit = true;
														return iState;
													});
													setSNewData((xState) => {
														let iState = { ...xState };
														iState.nama = sRsProgramPiutang[xIndex].nama;
														iState.jenis = {
															kode: sRsProgramPiutang[xIndex].jenis,
															value: fGetValueJenisPiutang(sRsProgramPiutang[xIndex].jenis),
														};
														iState.bunga = sRsProgramPiutang[xIndex].bunga;
														iState.denda = sRsProgramPiutang[xIndex].denda;
														iState.akun_sumber_dana = {
															no_akun: sRsProgramPiutang[xIndex].akun_sumber_dana.no_akun,
															nama: sRsProgramPiutang[xIndex].akun_sumber_dana.nama,
														};
														iState.akun_piutang = {
															no_akun: sRsProgramPiutang[xIndex].akun_piutang.no_akun,
															nama: sRsProgramPiutang[xIndex].akun_piutang.nama,
														};
														iState.akun_bunga = {
															no_akun: sRsProgramPiutang[xIndex].akun_bunga.no_akun,
															nama: sRsProgramPiutang[xIndex].akun_bunga.nama,
														};
														iState.akun_denda = {
															no_akun: sRsProgramPiutang[xIndex].akun_denda.no_akun,
															nama: sRsProgramPiutang[xIndex].akun_denda.nama,
														};
														return iState;
													});
													refNama.current.focus();
													refNama.current.select();
												}}
											>
												{xIndex + 1}
											</button>
										</td>
										<td>{xData.nama}</td>
										<td>{fGetValueJenisPiutang(xData.jenis)}</td>
										<td className=" text-center">{`${xData.bunga} % / Bulan`}</td>
										<td className=" text-center">{`${xData.denda} % / Bulan`}</td>
										<td>{`${xData.akun_sumber_dana.no_akun} - ${xData.akun_sumber_dana.nama}`}</td>
										<td>{`${xData.akun_piutang.no_akun} - ${xData.akun_piutang.nama}`}</td>
										<td>{`${xData.akun_bunga.no_akun} - ${xData.akun_bunga.nama}`}</td>
										<td>{`${xData.akun_denda.no_akun} - ${xData.akun_denda.nama}`}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<div className="h-12"></div>
		</div>
	);
};

export default GlobalStateConsumer(ProgramPiutang);
