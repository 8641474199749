import { GlobalStateProvider } from "./state.global";
import Pages from "./pages";

const App = (props) => {
	// useEffect(() => {
	// 	document.title = "Ngiring Ngayah";
	// }, []);
	return <Pages />;
};

export default GlobalStateProvider(App);
