import { useEffect, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import Env from "../../../setting/env";
// import CompText from "../../../component/arjText";
import CompLoading from "../../../component/arjLoading";

const Account = (props) => {
	const [sLoading, setSLoading] = useState(true);
	const [sRsAccount, setSRsAccount] = useState([]);

	useEffect(() => {
		Axios.get(
			`${props.state.sPages.url}${Env.eRouteApi}/admin/account`,

			{
				headers: {
					authorization: `bearer ${props.state.sToken}`,
				},
			}
		).then((xRes) => {
			console.log(xRes.data);
			setSRsAccount(xRes.data);
			setSLoading(false);
		});
		// eslint-disable-next-line
	}, []);

	try {
		return (
			<div className=" w-full md:p-2 flex flex-col h-screen ">
				{sLoading ? <CompLoading /> : ""}
				<div className=" my-auto w-full bg-blue-200 h-8 flex justify-center items-center">
					DAFTAR ACCOUNT
				</div>
				<div className=" overflow-y-auto flex-1">
					<table className="Table">
						<thead>
							<tr>
								<th>N0</th>
								<th>Nama User</th>
								<th>User Login</th>
								<th>Default Password</th>
							</tr>
						</thead>
						<tbody>
							{sRsAccount.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td>{xIndex + 1}</td>
										<td>{xData.user_name}</td>
										<td>{xData.user_login}</td>
										<td>{xData.password_dafault}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className=" h-20"></div>
			</div>
		);
	} catch (xErr) {
		return <div>ACCOUNT INVALID</div>;
	}
};

export default GlobalStateConsumer(Account);
