import { useEffect, useState, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../state.global";
import CompPrint from "react-to-print";
import CompLoading from "../../../component/arjLoading";
import Exel from "../../../component/exportToExel";
import Env from "../../../setting/env";

const BukuBesar = (props) => {
	const [sLoading, setSLoading] = useState(false);
	const [sViewBukuBesar, setSViewBukuBesar] = useState({});
	const [sViewExel, setSViewExel] = useState([]);
	const refComponent = useRef();

	// useEffect(() => {
	// 	if (sViewBukuBesar) {
	// 		console.log("ok");
	// 	}
	// }, [sViewBukuBesar]);

	const fGetNamaAkun = (xRsAkun, xNo_Akun) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		for (let i = 0; i < xRsAkun.length; i++) {
			if (iRegEx.test(xRsAkun[i].no_akun)) {
				return `${xRsAkun[i].no_akun}. ${xRsAkun[i].nama}`;
			}
		}
	};

	const fGetIsiNota = (xNota) => {
		let iText = "";
		let iTotal = 0;
		xNota.map((xData, xIndex) => {
			let iKoma = ",";
			if (xIndex === 0) {
				iKoma = "";
			}
			iText += `${iKoma} ${Env.format.uang(xData.banyak)}${xData.satuan} ${
				xData.nama_barang
			} * ${Env.format.uang(xData.harga)} = ${Env.format.uang(xData.jumlah)}`;
			iTotal += xData.jumlah;
			return "";
		});
		return `${iText} , Jumlah = ${Env.format.uang(iTotal)}`;
	};

	const fNilaiAkunDebet = (xRsJurnal, xNo_Akun, xNamaAkun) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		let iJumlah = 0;
		for (let i = 0; i < xRsJurnal.length; i++) {
			if (iRegEx.test(xRsJurnal[i].kredit)) {
				iJumlah += xRsJurnal[i].nilai;
				if (xNo_Akun === "0") {
					xNamaAkun(xRsJurnal[i].keterangan);
				}
			}
		}
		return iJumlah;
	};

	const fNilaiAkunKredit = (xRsJurnal, xNo_Akun, xNamaAkun) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		let iJumlah = 0;
		for (let i = 0; i < xRsJurnal.length; i++) {
			if (iRegEx.test(xRsJurnal[i].debet)) {
				iJumlah += xRsJurnal[i].nilai;
				if (xNo_Akun === "0") {
					xNamaAkun(xRsJurnal[i].keterangan);
				}
			}
		}
		return iJumlah;
	};

	const fRsAkunKredit = (xRsJurnal, xNo_Akun, xRsViewExel, xRowNo) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		let iJumlah = 0;
		let iRsAkunJurnal = [];
		let iNo = 0;
		// console.log(xRsJurnal);
		for (let i = 0; i < xRsJurnal.length; i++) {
			if (iRegEx.test(xRsJurnal[i].kredit)) {
				iJumlah += xRsJurnal[i].nilai;
				// ========================
				let iNota = "";
				if (xRsJurnal[i].nota.length > 0) {
					iNota = ` ; Nota No.${xRsJurnal[i].no_nota}${
						xRsJurnal[i].debet_ref
					}: ${fGetIsiNota(xRsJurnal[i].nota)} `;
				}
				// console.log(iNota);

				// ===========================

				iRsAkunJurnal.push({
					tanggal: Env.efFormatTanggal(xRsJurnal[i].tanggal),
					keterangan: xRsJurnal[i].keterangan + iNota,
					nilai: Env.format.uang(xRsJurnal[i].nilai),
					jumlah: Env.format.uang(iJumlah),
				});

				xRowNo += 1;
				iNo += 1;
				xRsViewExel.push({
					row: xRowNo,
					col: [
						fCol(iNo),
						fCol(Env.efFormatTanggal(xRsJurnal[i].tanggal)),
						fCol(xRsJurnal[i].keterangan + iNota),
						fCol(Env.format.uang(xRsJurnal[i].nilai)),
						fCol(Env.format.uang(iJumlah)),
					],
				});
			}
		}
		return { data: iRsAkunJurnal, row: xRowNo };
	};

	const fRsAkunDebet = (xRsJurnal, xNo_Akun, xRsViewExel, xRowNo) => {
		const iRegEx = new RegExp(`^${xNo_Akun}`);
		let iJumlah = 0;
		let iRsAkunJurnal = [];
		let iNo = 0;
		for (let i = 0; i < xRsJurnal.length; i++) {
			if (iRegEx.test(xRsJurnal[i].debet)) {
				iJumlah += xRsJurnal[i].nilai;
				// ========================
				let iNota = "";
				if (xRsJurnal[i].nota.length > 0) {
					iNota = ` ; Nota No.${xRsJurnal[i].no_nota}${
						xRsJurnal[i].debet_ref
					}: ${fGetIsiNota(xRsJurnal[i].nota)} `;
				}
				// console.log(iNota);

				// ===========================
				iRsAkunJurnal.push({
					tanggal: Env.efFormatTanggal(xRsJurnal[i].tanggal),
					keterangan: xRsJurnal[i].keterangan + iNota,
					nilai: Env.format.uang(xRsJurnal[i].nilai),
					jumlah: Env.format.uang(iJumlah),
				});

				xRowNo += 1;
				iNo += 1;
				xRsViewExel.push({
					row: xRowNo,
					col: [
						fCol(iNo),
						fCol(Env.efFormatTanggal(xRsJurnal[i].tanggal)),
						fCol(xRsJurnal[i].keterangan + iNota),
						fCol(Env.format.uang(xRsJurnal[i].nilai)),
						fCol(Env.format.uang(iJumlah)),
					],
				});
			}
		}
		return { data: iRsAkunJurnal, row: xRowNo };
	};

	const fCol = (xValue, xColMarger = 1) => {
		return { value: xValue, colMarger: xColMarger };
	};

	useEffect(() => {
		setSLoading(true);
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/laporan`, {
			params: {
				req: "bukuBesar",
				no_akun: props._noAkun,
				tanggal: props._tanggal,
			},
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		}).then((xData) => {
			const iRsData = xData.data.message;
			let iRsSaldo = [];
			let iRsRekapPenerimaan = [];
			let iRsRekapPengeluaran = [];
			let iRsDetailPenerimaan = [];
			let iRsDetailPengeluaran = [];

			let iRow = 0;
			let iRsExel = [
				{
					row: 1,
					col: [
						fCol("Buku Besar", 2),
						fCol(props._keteranganAkun),
						fCol(`Lpr: ${Env.efFormatTanggal(props._tanggal)}`, 2),
					],
				},
				{ row: 3, col: [fCol("REKAP SALDO", 5)] },
				{ row: 4, col: [fCol("No", 1), fCol("Jenis", 3), fCol("Nilai", 1)] },
				{
					row: 5,
					col: [
						fCol("1", 1),
						fCol("Penerimaan", 3),
						fCol(Env.format.uang(iRsData.jumlahDebet.debet), 1),
					],
				},
				{
					row: 6,
					col: [
						fCol("2", 1),
						fCol("Pengeluaran", 3),
						fCol(Env.format.uang(iRsData.jumlahKredit.kredit), 1),
					],
				},
				{
					row: 7,
					col: [
						fCol("*", 1),
						fCol("Saldo", 3),
						fCol(
							Env.format.uang(iRsData.jumlahDebet.debet - iRsData.jumlahKredit.kredit),
							1
						),
					],
				},
			];

			// Saldo Aktiva
			iRsSaldo = [
				{ penerimaan: Env.format.uang(iRsData.jumlahDebet.debet) },
				{ pengeluaran: Env.format.uang(iRsData.jumlahKredit.kredit) },
				{
					saldo: Env.format.uang(
						iRsData.jumlahDebet.debet - iRsData.jumlahKredit.kredit
					),
				},
			];

			// Rekap Penerimaan
			iRsExel.push({ row: 10, col: [fCol("REKAP PENERIMAAN", 5)] });
			iRsExel.push({
				row: 11,
				col: [fCol("No"), fCol("Jenis", 2), fCol("Nilai"), fCol("Jumlah")],
			});

			iRow = 11;
			const iRsPenerimaan = iRsData.akunKredit;
			let iTotalPenerimaan = 0;
			for (let i = 0; i < iRsPenerimaan.length; i++) {
				const iNoAkun = iRsPenerimaan[i].kredit;
				let iNamaAkun = fGetNamaAkun(iRsData.akun, iNoAkun);
				const iJumlah = fNilaiAkunDebet(
					iRsData.jurnal,
					iNoAkun,
					(xKeteranganAkun) => {
						iNamaAkun = xKeteranganAkun;
					}
				);
				iTotalPenerimaan += iJumlah;
				iRsRekapPenerimaan.push({
					akun: iNamaAkun,
					nilai: Env.format.uang(iJumlah),
					jumlah: Env.format.uang(iTotalPenerimaan),
				});

				iRow += 1;
				iRsExel.push({
					row: iRow,
					col: [
						fCol(i + 1),
						fCol(iNamaAkun, 2),
						fCol(Env.format.uang(iJumlah)),
						fCol(Env.format.uang(iTotalPenerimaan)),
					],
				});
			}
			// console.log(iRsRekapPenerimaan);

			// Rekap Pengeluaran
			iRow += 3;
			iRsExel.push({ row: iRow, col: [fCol("REKAP PENGELUARAN", 5)] });
			iRow += 1;
			iRsExel.push({
				row: iRow,
				col: [fCol("No"), fCol("Jenis", 2), fCol("Nilai"), fCol("Jumlah")],
			});
			const iRsPengeluaran = iRsData.akunDebet;
			let iTotalPengeluaran = 0;
			for (let i = 0; i < iRsPengeluaran.length; i++) {
				const iNoAkun = iRsPengeluaran[i].debet;
				let iNamaAkun = fGetNamaAkun(iRsData.akun, iNoAkun);
				const iJumlah = fNilaiAkunKredit(
					iRsData.jurnal,
					iNoAkun,
					(xKeteranganAkun) => {
						iNamaAkun = xKeteranganAkun;
					}
				);
				iTotalPengeluaran += iJumlah;
				iRsRekapPengeluaran.push({
					akun: iNamaAkun,
					nilai: Env.format.uang(iJumlah),
					jumlah: Env.format.uang(iTotalPengeluaran),
				});

				iRow += 1;
				iRsExel.push({
					row: iRow,
					col: [
						fCol(i + 1),
						fCol(iNamaAkun, 2),
						fCol(Env.format.uang(iJumlah)),
						fCol(Env.format.uang(iTotalPengeluaran)),
					],
				});
			}

			// console.log(iRsRekapPengeluaran);

			// Detail Penerimaan

			for (let i = 0; i < iRsPenerimaan.length; i++) {
				const iNoAkun = iRsPenerimaan[i].kredit;
				if (iNoAkun !== "0") {
					let iNamaAkun = fGetNamaAkun(iRsData.akun, iNoAkun);
					iRow += 3;
					iRsExel.push({ row: iRow, col: [fCol(`Penerimaan ${iNamaAkun}`, 5)] });
					iRow += 1;
					iRsExel.push({
						row: iRow,
						col: [
							fCol("No"),
							fCol("Tanggal"),
							fCol("Keterangan"),
							fCol("Nilai"),
							fCol("Jumlah"),
						],
					});

					const iDatasPenerimaan = fRsAkunKredit(
						iRsData.jurnal,
						iNoAkun,
						iRsExel,
						iRow
					);
					iRow = iDatasPenerimaan.row;
					iRsDetailPenerimaan.push({
						akun: iNamaAkun,
						data: iDatasPenerimaan.data,
					});
				}
			}

			// Detail Pengeluaran
			for (let i = 0; i < iRsPengeluaran.length; i++) {
				const iNoAkun = iRsPengeluaran[i].debet;
				if (iNoAkun !== "0") {
					let iNamaAkun = fGetNamaAkun(iRsData.akun, iNoAkun);
					iRow += 3;
					iRsExel.push({ row: iRow, col: [fCol(`Pengeluaran ${iNamaAkun}`, 5)] });
					iRow += 1;
					iRsExel.push({
						row: iRow,
						col: [
							fCol("No"),
							fCol("Tanggal"),
							fCol("Keterangan"),
							fCol("Nilai"),
							fCol("Jumlah"),
						],
					});
					const iDataPengeluaran = fRsAkunDebet(
						iRsData.jurnal,
						iNoAkun,
						iRsExel,
						iRow
					);
					iRow = iDataPengeluaran.row;
					iRsDetailPengeluaran.push({
						akun: iNamaAkun,
						data: iDataPengeluaran.data,
					});
				}
			}

			// console.log(iRsExel);

			const iDatas = {
				saldo: iRsSaldo,
				rekap: {
					penerimaan: iRsRekapPenerimaan,
					pengeluaran: iRsRekapPengeluaran,
				},
				detail: {
					penerimaan: iRsDetailPenerimaan,
					pengeluaran: iRsDetailPengeluaran,
				},
			};
			setSViewExel(iRsExel);
			setSViewBukuBesar(iDatas);

			// ---------------------------------------------
			// console.log(iRsData);
			// console.log(iRsSaldo);
			setSLoading(false);
		});
		// eslint-disable-next-line
	}, []);
	return (
		<div className="fixed z-10 flex flex-col w-full h-screen  bg-red-100">
			{/* LOADING */}
			{sLoading ? <CompLoading /> : ""}

			<div
				className="flex absolute right-1 justify-center
                             items-center w-28 h-8 space-x-1"
			>
				<Exel
					_data={sViewExel}
					_fileName={`${props._tanggal}_${props._keteranganAkun}`}
				/>
				<CompPrint
					trigger={() => {
						return <div className="Button h-6">cetak</div>;
					}}
					content={() => refComponent.current}
				/>
				<div
					className="flex justify-center items-center
                                bg-green-300 rounded-xl w-6 h-6  
                                border-genkiGray-light cursor-pointer
                                hover:bg-blue-100"
					onClick={() => {
						props._onClose();
					}}
				>
					X
				</div>
			</div>

			<div
				className="flex flex-col mt-1 w-full flex-1 overflow-y-scroll"
				ref={refComponent}
			>
				<div className="flex w-full h-10 bg-yellow-200 items-center mt-8 md:mt-0">
					<div className="flex items-center ml-2">Buku Besar</div>
					<div className="flex-1 flex justify-center items-center truncate mx-1">
						{props._keteranganAkun}
					</div>
					<div className="flex justify-center mr-1">{`Lpr: ${Env.efFormatTanggal(
						props._tanggal
					)}`}</div>
					<div className="flext md:w-32"></div>
				</div>

				{sViewBukuBesar.saldo ? (
					<div className="flex flex-col w-full justify-center md:p-1">
						<div className="flex w-full justify-center">REKAP SALDO</div>

						<table className="Table">
							<thead>
								<tr>
									<th>No</th>
									<th>Jenis</th>
									<th>Nilai</th>
								</tr>
							</thead>

							<tbody>
								<tr>
									<td className="w-10">1</td>
									<td>Penerimaan</td>
									<td className="text-right w-28">
										{sViewBukuBesar.saldo[0].penerimaan}
									</td>
								</tr>
								<tr>
									<td className="w-10">2</td>
									<td>Pengeluaran</td>
									<td className="text-right w-28">
										{sViewBukuBesar.saldo[1].pengeluaran}
									</td>
								</tr>
								<tr>
									<td className="w-10 text-center">*</td>
									<td className="text-right">Saldo </td>
									<td className="text-right w-28">{sViewBukuBesar.saldo[2].saldo}</td>
								</tr>
							</tbody>
						</table>

						<div className="flex w-full justify-center mt-3">REKAP PENERIMAAN</div>
						<table className="Table">
							<thead>
								<tr>
									<th>No</th>
									<th>Jenis</th>
									<th>Nilai</th>
									<th>Jumlah</th>
								</tr>
							</thead>
							<tbody>
								{sViewBukuBesar.rekap.penerimaan.map((xData, xIndex) => {
									return (
										<tr key={xIndex}>
											<td className="w-10">{xIndex + 1}</td>
											<td>{xData.akun}</td>
											<td className="text-right w-28">{xData.nilai}</td>
											<td className="text-right w-28">{xData.jumlah}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						<div className="flex w-full justify-center mt-3">REKAP PENGELUARAN</div>
						<table className="Table">
							<thead>
								<tr>
									<th>No</th>
									<th>Jenis</th>
									<th>Nilai</th>
									<th>Jumlah</th>
								</tr>
							</thead>
							<tbody>
								{sViewBukuBesar.rekap.pengeluaran.map((xData, xIndex) => {
									return (
										<tr key={xIndex}>
											<td className="w-10">{xIndex + 1}</td>
											<td>{xData.akun}</td>
											<td className="text-right w-28">{xData.nilai}</td>
											<td className="text-right w-28">{xData.jumlah}</td>
										</tr>
									);
								})}
							</tbody>
						</table>

						{/* DETAIL PENERIMAAN */}
						{sViewBukuBesar.detail.penerimaan.map((xData, xIndex) => {
							return (
								<div key={xIndex} className="w-full">
									<div className="flex w-full justify-center mt-3">{`Penerimaan :  ${xData.akun} `}</div>
									<table className="Table">
										<thead>
											<tr>
												<th>No</th>
												<th>tanggal</th>
												<th>keterangan</th>
												<th>Nilai</th>
												<th>Jumlah</th>
											</tr>
										</thead>
										<tbody>
											{xData.data.map((xxData, xxIndex) => {
												return (
													<tr key={xxIndex}>
														<td className="w-10">{xxIndex + 1}</td>
														<td className="w-24">{xxData.tanggal}</td>
														<td>{xxData.keterangan}</td>
														<td className="text-right w-24">{xxData.nilai}</td>
														<td className="text-right w-28">{xxData.jumlah}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							);
						})}

						{/* DETAIL PENGELUARAN*/}
						{sViewBukuBesar.detail.pengeluaran.map((xData, xIndex) => {
							return (
								<div key={xIndex} className="w-full">
									<div className="flex w-full justify-center mt-3">{`Pengeluaran :  ${xData.akun} `}</div>
									<table className="Table">
										<thead>
											<tr>
												<th>No</th>
												<th>tanggal</th>
												<th>keterangan</th>
												<th>Nilai</th>
												<th>Jumlah</th>
											</tr>
										</thead>
										<tbody>
											{xData.data.map((xxData, xxIndex) => {
												return (
													<tr key={xxIndex}>
														<td className="w-10">{xxIndex + 1}</td>
														<td className="w-24">{xxData.tanggal}</td>
														<td>{xxData.keterangan}</td>
														<td className="text-right w-24">{xxData.nilai}</td>
														<td className="text-right w-28">{xxData.jumlah}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							);
						})}
					</div>
				) : (
					""
				)}
			</div>
			<div className=" h-12"></div>
		</div>
	);
};

export default GlobalStateConsumer(BukuBesar);
