import { Fragment, useEffect, useRef, useState } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";
import CompTextTitle from "../../../../component/arjText-Title";
import CompButton from "../../../../component/arjButton";
import CompSelect from "../../../../component/arjSelect";
import CompAlertSimpan from "../../../../component/arjAlert";
import CompAlertDelete from "../../../../component/arjAlert";
import CompAlert from "../../../../component/arjAlert";

const mAkunType = [
	{ text: "Anak", value: "Anak" },
	{ text: "Induk", value: "Induk" },
];

const Akun = (props) => {
	//=================== Ref ============================
	const iRefNoAkun = useRef(null);
	const iRefNama = useRef(null);
	const iRefType = useRef(null);
	const iRefKeterangan = useRef(null);
	const iRefSimpan = useRef(null);

	const iRefEditNoAkun = useRef(null);
	const iRefEditNama = useRef(null);
	const iRefEditType = useRef(null);
	const iRefEditKeterangan = useRef(null);
	const iRefEditUpdate = useRef(null);
	//End =============== Ref ============================

	//=================== State ============================
	const [sRsAkun, setSRsAkun] = useState([]);
	const [sAlertSimpan, setSAlertSimpan] = useState(false);
	const [sLoading, setSLoading] = useState(false);
	const [sDataAkun, setSDataAkun] = useState({
		eNoAkun: { eText: "", eStatus: false },
		eNama: { eText: "", eStatus: false },
		eType: "",
		eKeterangan: "",
		eStatus: false,
	});
	const [sDataEditAkun, setSDataEditAkun] = useState({
		eNoAkun: { eText: "", eStatus: false },
		eNama: { eText: "", eStatus: false },
		eType: "",
		eKeterangan: "",
		eStatus: false,
	});
	const [sAlert, setSAlert] = useState({
		eTitle: "",
		eKeterangan: "",
		eShow: false,
		eRef: useRef(null),
	});

	const [sEdit, setsEdit] = useState({ eIndex: 0, eStatus: false });
	const [sFocus, setsFocus] = useState("");
	const [sDelete, setsDelete] = useState({ eIndex: 0, eStatus: false });
	//END================ State ============================

	//=================== Effect ============================

	useEffect(() => {
		if (sEdit.eStatus) {
			iRefEditNoAkun.current.focus();
			iRefEditNoAkun.current.select();
		}
		// eslint-disable-next-line
	}, [sEdit]);

	useEffect(() => {
		if (sAlert.eRef && sAlert.eShow === false) {
			if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
				iRefNoAkun.current.focus();
				iRefNoAkun.current.select();
			}
		}
		// eslint-disable-next-line
	}, [sAlert]);

	useEffect(() => {
		if (sAlertSimpan === false && sLoading === false) {
			if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
				iRefNoAkun.current.focus();
				iRefNoAkun.current.select();
			}
		}
		// eslint-disable-next-line
	}, [sAlertSimpan]);

	useEffect(() => {
		if (props._onActive) {
			props._onActive();
		}

		setSDataAkun((xState) => {
			let iState = { ...xState };
			iState.eType = mAkunType[0].value;
			return iState;
		});

		if (props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100) {
			iRefNoAkun.current.focus();
		}
		Axios.get(`${props.state.sPages.url}${Env.eRouteApi}/admin/akun`, {
			headers: {
				authorization: `bearer ${props.state.sToken}`,
			},
		})
			.then((xData) => {
				setSRsAkun(xData.data.message);
			})
			.catch((xErr) => {
				alert(xErr);
			});
		// eslint-disable-next-line
	}, []);
	//END================ Effect ============================

	return (
		<Fragment>
			{sLoading === true ? <CompLoading /> : ""}
			{sAlert.eShow === true ? (
				<CompAlert
					_title={sAlert.eTitle}
					_keterangan={sAlert.eKeterangan}
					_onOk={() => {
						if (sEdit.eStatus) {
							setsEdit((xState) => {
								let iState = { ...xState };
								iState.eStatus = false;
								return iState;
							});
						}

						setSAlert((xState) => {
							let iState = { ...xState };
							iState.eShow = false;
							return iState;
						});
					}}
				/>
			) : (
				""
			)}
			{sAlertSimpan === true ? (
				<CompAlertSimpan
					_title={`Simpan Data Akun`}
					_keterangan={` Apakah Data Sudah Benar ? `}
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setSAlertSimpan(false);
					}}
					_onOk={() => {
						setSLoading(true);
						setSAlertSimpan(false);
						let iData = {
							no_akun: sDataAkun.eNoAkun.eText,
							nama: sDataAkun.eNama.eText,
							type: sDataAkun.eType,
							keterangan: sDataAkun.eKeterangan,
						};
						Axios.post(
							`${props.state.sPages.url}${Env.eRouteApi}/bendahara/akun`,
							iData,
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									setSDataAkun((xState) => {
										let iState = { ...xState };
										iState.eNoAkun.eText = "";
										iState.eNoAkun.eStatus = false;
										iState.eNama.eText = "";
										iState.eNama.eStatus = false;
										iState.eKeterangan = "";
										iState.eStatus = false;
										return iState;
									});
									setSRsAkun(xData.data.message);
									setsFocus(xData.data.id);
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Simpan Akun";
										iState.eKeterangan = "Sukses";
										iState.eShow = true;
										iState.eRef = iRefNoAkun;

										return iState;
									});
								} else {
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = xData.data.title;
										iState.eKeterangan = xData.data.message;
										iState.eShow = true;
										iState.eRef = iRefNoAkun;

										return iState;
									});
								}
							})
							.catch((xErr) => {
								setSLoading(false);
								setSAlert((xState) => {
									let iState = { ...xState };
									iState.eTitle = "Error";
									iState.eKeterangan = "Akun Gagal Disimpan";
									iState.eShow = true;
									iState.eRef = iRefNoAkun;

									return iState;
								});
							});
					}}
				/>
			) : (
				""
			)}

			{sDelete.eStatus === true ? (
				<CompAlertDelete
					_title={`Delete Data Akun No.${sDelete.eIndex + 1}`}
					_keterangan={` Apakah Data Sudah Benar ? `}
					_buttonOk="Ya"
					_buttonCancel="Tidak"
					_onCancel={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
					}}
					_onOk={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
						setSLoading(true);

						Axios.delete(`${props.state.sPages.url}${Env.eRouteApi}/bendahara/akun`, {
							data: { id: sRsAkun[sDelete.eIndex].id, index: sDelete.eIndex },
							headers: {
								authorization: `bearer ${props.state.sToken}`,
							},
						})
							.then((xData) => {
								setSLoading(false);
								if (xData.status === Env.eHttpStatus.eOk) {
									setSRsAkun((xState) => {
										let iState = [...xState];
										iState.splice(xData.data.message.index, 1);
										return iState;
									});
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Delete Akun";
										iState.eKeterangan = "Sukses";
										iState.eShow = true;
										iState.eRef = null;

										return iState;
									});
								} else {
									setSAlert((xState) => {
										let iState = { ...xState };
										iState.eTitle = "Delete Akun";
										iState.eKeterangan = "Gagal";
										iState.eShow = true;
										iState.eRef = null;

										return iState;
									});
								}
							})
							.catch((xErr) => {
								setSAlert((xState) => {
									let iState = { ...xState };
									iState.eTitle = "Delete Akun Gagal";
									iState.eKeterangan = xErr;
									iState.eShow = true;
									iState.eRef = null;

									return iState;
								});
							});
					}}
				/>
			) : (
				""
			)}

			{/* =========================================================================== */}
			{/* EDIT DATA AKUN ============================================================*/}
			{sEdit.eStatus === true ? (
				<div className="Modal py-20 pl-2 pr-4 md:px-20 ">
					<div className="flex flex-col space-y-2 shadow-lg w-full  bg-genkiBlue-cl_01 p-1  m-auto  rounded-xl overflow-hidden">
						<div className="mx-auto">{`Edit Data Akun No.${sEdit.eIndex + 1}`} </div>
						<hr style={{ color: "gray" }} />
						<CompTextTitle
							_title="No Akun"
							_maxLength={15}
							_titleWidth="100px"
							_ref={iRefEditNoAkun}
							_value={sDataEditAkun.eNoAkun.eText}
							_dataType="angka"
							_onChange={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eNoAkun.eText = xValue;
									if (xValue.length > 0) {
										iState.eNoAkun.eStatus = true;
									} else {
										iState.eNoAkun.eStatus = false;
									}
									if (iState.eNoAkun.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataEditAkun.eNoAkun.eStatus === true) {
									iRefEditNama.current.focus();
									iRefEditNama.current.select();
								}
							}}
						/>
						<CompTextTitle
							_title="Nama"
							_maxLength={200}
							_titleWidth="100px"
							_ref={iRefEditNama}
							_value={sDataEditAkun.eNama.eText}
							_onChange={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eNama.eText = xValue;
									if (xValue.length > 2) {
										iState.eNama.eStatus = true;
									} else {
										iState.eNama.eStatus = false;
									}
									if (iState.eNoAkun.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataEditAkun.eNama.eStatus === true) {
									iRefEditKeterangan.current.focus();
									iRefEditKeterangan.current.select();
								}
							}}
							_onKeyUp={() => {
								iRefEditNoAkun.current.focus();
								iRefEditNoAkun.current.select();
							}}
						/>
						<CompSelect
							_title="type"
							_titleWidth="100px"
							_ref={iRefEditType}
							_data={mAkunType}
							_value={sDataEditAkun.eType}
							_onClick={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefEditKeterangan.current.focus();
								iRefEditKeterangan.current.select();
							}}
							_onKeyEnter={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefEditKeterangan.current.focus();
								iRefEditKeterangan.current.select();
							}}
							_onKeyUp={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefEditNama.current.focus();
								iRefEditNama.current.select();
							}}
						/>
						<CompTextTitle
							_title="Keterangan"
							_maxLength={255}
							_titleWidth="100px"
							_ref={iRefEditKeterangan}
							_value={sDataEditAkun.eKeterangan}
							_onChange={(xValue) => {
								setSDataEditAkun((xState) => {
									let iState = { ...xState };
									iState.eKeterangan = xValue;
									return iState;
								});
							}}
							_onKeyEnter={() => {
								iRefEditUpdate.current.focus();
							}}
							_onKeyUp={() => {
								iRefEditType.current.focus();
							}}
						/>

						<div className="flex items-center w-56 mx-auto ">
							<CompButton
								_text="Update"
								_ref={iRefEditUpdate}
								className="Button h-7 w-20 mx-auto"
								_imageType={
									sDataEditAkun.eStatus
										? Env.buttonImageType.ok
										: Env.buttonImageType.stop
								}
								_onKeyUp={() => {
									iRefEditKeterangan.current.focus();
									iRefEditKeterangan.current.select();
								}}
								_onClick={() => {
									setSLoading(true);
									if (sDataEditAkun.eStatus === true) {
										const iData = {
											id: sRsAkun[sEdit.eIndex].id,
											no_akun: sDataEditAkun.eNoAkun.eText,
											nama: sDataEditAkun.eNama.eText,
											type: sDataEditAkun.eType,
											keterangan: sDataEditAkun.eKeterangan,
											index: sEdit.eIndex,
										};
										Axios.put(
											`${props.state.sPages.url}${Env.eRouteApi}/bendahara/akun`,
											iData,
											{
												headers: {
													authorization: `bearer ${props.state.sToken}`,
												},
											}
										).then((xData) => {
											setSLoading(false);

											let iData = xData.data.message;
											setSRsAkun((xState) => {
												let iState = [...xState];
												iState[iData.index].no_akun = iData.no_akun;
												iState[iData.index].nama = iData.nama;
												iState[iData.index].type = iData.type;
												iState[iData.index].keterangan = iData.keterangan;
												iState[iData.index].tab_view = iData.tab_view;
												return iState;
											});

											setSAlert((xState) => {
												let iState = { ...xState };
												iState.eTitle = "Update Akun";
												iState.eKeterangan = "Sukses";
												iState.eRef = null;
												iState.eShow = true;
												return iState;
											});
										});
									}
								}}
							/>

							<CompButton
								_text="Batal"
								className="Button h-7 w-20 mx-auto"
								_onClick={() => {
									setsEdit((xState) => {
										let iState = { ...xState };
										iState.eStatus = false;
										return iState;
									});

									iRefNoAkun.current.focus();
								}}
							/>
						</div>
					</div>
				</div>
			) : (
				""
			)}

			{/*=======================================================================  */}

			{/* MASUKKAN DATA AKUN ===================================================*/}
			<div className="flex flex-col h-full ">
				<div className="h-2"></div>
				{props.state.sUser.eStatus === 70 || props.state.sUser.eStatus === 100 ? (
					<div className="flex flex-col space-y-2 shadow-lg   bg-genkiBlue-cl_01 max-w-screen-sm md:max-w-screen-md mx-auto  p-1 rounded-xl">
						<div className="m-auto">Masukkan Data Akun</div>
						<hr style={{ color: "gray" }} />
						<CompTextTitle
							_title="No Akun"
							_maxLength={15}
							_titleWidth="100px"
							_ref={iRefNoAkun}
							_value={sDataAkun.eNoAkun.eText}
							_dataType="angka"
							_onChange={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eNoAkun.eText = xValue;
									if (xValue.length > 0) {
										iState.eNoAkun.eStatus = true;
									} else {
										iState.eNoAkun.eStatus = false;
									}
									if (iState.eNoAkun.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataAkun.eNoAkun.eStatus === true) {
									iRefNama.current.focus();
									iRefNama.current.select();
								}
							}}
						/>
						<CompTextTitle
							_title="Nama"
							_maxLength={200}
							_titleWidth="100px"
							_ref={iRefNama}
							_value={sDataAkun.eNama.eText}
							_onChange={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eNama.eText = xValue;
									if (xValue.length > 2) {
										iState.eNama.eStatus = true;
									} else {
										iState.eNama.eStatus = false;
									}
									if (iState.eNoAkun.eStatus === true && iState.eNama.eStatus === true) {
										iState.eStatus = true;
									} else {
										iState.eStatus = false;
									}
									return iState;
								});
							}}
							_onKeyUpEnter={() => {
								if (sDataAkun.eNama.eStatus === true) {
									iRefKeterangan.current.focus();
									iRefKeterangan.current.select();
								}
							}}
							_onKeyUp={() => {
								iRefNoAkun.current.focus();
								iRefNoAkun.current.select();
							}}
						/>
						<CompSelect
							_title="type"
							_titleWidth="100px"
							_ref={iRefType}
							_data={mAkunType}
							_onClick={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefKeterangan.current.focus();
								iRefKeterangan.current.select();
							}}
							_onKeyEnter={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefKeterangan.current.focus();
								iRefKeterangan.current.select();
							}}
							_onKeyUp={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eType = xValue;
									return iState;
								});
								iRefNama.current.focus();
								iRefNama.current.select();
							}}
						/>
						<CompTextTitle
							_title="Keterangan"
							_maxLength={255}
							_titleWidth="100px"
							_ref={iRefKeterangan}
							_value={sDataAkun.eKeterangan.eText}
							_onChange={(xValue) => {
								setSDataAkun((xState) => {
									let iState = { ...xState };
									iState.eKeterangan = xValue;
									return iState;
								});
							}}
							_onKeyEnter={() => {
								iRefSimpan.current.focus();
							}}
							_onKeyUp={() => {
								iRefType.current.focus();
							}}
						/>
						<CompButton
							_text="Simpan"
							_ref={iRefSimpan}
							className="Button h-7 w-20 mx-auto"
							// _ref={mRefSimpan}
							// _value={sDataOrganisasi.url}
							_imageType={
								sDataAkun.eStatus ? Env.buttonImageType.ok : Env.buttonImageType.stop
							}
							_onKeyUp={() => {
								iRefKeterangan.current.focus();
								iRefKeterangan.current.select();
							}}
							_onClick={() => {
								if (sDataAkun.eStatus === true) {
									setSAlertSimpan(true);
								}
							}}
						/>
					</div>
				) : (
					""
				)}

				{/* TABLE */}
				<div className="flex-1 mt-4 mr-4 p-2 rounded-xl w-full WadahFix h-full overflow-y-scroll">
					<table className="Table">
						<thead>
							<tr className="flex">
								<th className=" w-8 ">No</th>
								<th className=" w-28 md:w-36 ">No Akun</th>
								<th className="w-36 md:w-52 ">Nama</th>
								<th className="flex-1 ">Keterangan</th>

								{props.state.sUser.eStatus === 70 ||
								props.state.sUser.eStatus === 100 ? (
									<Fragment>
										<th className="w-0 hidden md:flex md:w-12 ">Type</th>
										<th className="w-28 ">Ubah</th>
									</Fragment>
								) : (
									""
								)}
							</tr>
						</thead>
						<tbody>
							{sRsAkun
								.map((xData, xIndex) => {
									let iWarna = "";
									if (xData.type === "Induk") {
										iWarna = Env.color.orangeRed04;
									}

									if (sFocus === xData.id && xData.type === "Induk") {
										iWarna = Env.color.orangeRed01;
									} else if (sFocus === xData.id) {
										iWarna = Env.color.colorPrimer02;
									}

									return (
										<tr key={xIndex} className="flex" style={{ background: iWarna }}>
											<td className="flex w-8 items-center ">
												<div className="flex-1"></div>
												<div className="pr-1 ">{xIndex + 1}</div>
											</td>
											<td className="flex w-28 md:w-36 overflow-hidden ">
												{/* {fTabView(xData.tab_view)} */}
												<div style={{ width: `${xData.tab_view * 10}px` }}></div>
												{xData.no_akun}
											</td>
											<td className="w-36 md:w-52">{xData.nama}</td>
											<td className="flex-1  overflow-hidden">{xData.keterangan}</td>

											{props.state.sUser.eStatus === 70 ||
											props.state.sUser.eStatus === 100 ? (
												<Fragment>
													<td className="hidden w-0 md:flex md:w-12 items-center">
														{xData.type}
													</td>
													<td className="flex w-28 items-center  justify-evenly ">
														<CompButton
															_text="Edit"
															_onClick={() => {
																setSDataEditAkun((xState) => {
																	let iState = { ...xState };
																	iState.eNoAkun.eText = sRsAkun[xIndex].no_akun;
																	iState.eNama.eText = sRsAkun[xIndex].nama;
																	iState.eType = sRsAkun[xIndex].type;
																	iState.eKeterangan = sRsAkun[xIndex].keterangan;
																	iState.eNoAkun.eStatus = true;
																	iState.eNama.eStatus = true;
																	iState.eStatus = true;
																	return iState;
																});

																setsEdit((xState) => {
																	let iState = { ...xState };
																	iState.eIndex = xIndex;
																	iState.eStatus = true;
																	return iState;
																});

																setsFocus(sRsAkun[xIndex].id);
															}}
															className="Button h-6 w-12"
														/>
														<CompButton
															_text="Delete"
															_onClick={() => {
																setsDelete((xState) => {
																	let iState = { ...xState };
																	iState.eIndex = xIndex;
																	iState.eStatus = true;
																	return iState;
																});
																setsFocus(sRsAkun[xIndex].id);
															}}
															className="Button h-6"
														/>
													</td>
												</Fragment>
											) : (
												""
											)}
										</tr>
									);
								})
								.reverse()}
						</tbody>
					</table>
				</div>
				<div className="h-10"></div>
			</div>
		</Fragment>
	);
};

export default GlobalStateConsumer(Akun);
