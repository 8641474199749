// Input
// _dataSource={[]}
// _default={boolean}

// Output :
// _onKeyEnter={[sSelectData_valueIndex0,sSelectData_valueIndex1]}

import { useEffect, useState, useRef } from "react";
import env from "../../setting/env";

const ArjTextDataSource = (props) => {
	// const [sSelectData.eShowTable, setSTableDisplay] = useState(false);
	const [sOnTableDisplay, setSOnTableDisplay] = useState(false);
	const [sSelectData, setsSelectData] = useState({
		eValueIndex0: "",
		eValueIndex1: "",
		eKeyEnter: false,
		eIndexDataSource: 0,
		eDataSource: [],
		eShowTable: false,
	});
	const [sInputValue, setSInputValue] = useState("");
	const [sDataSourceKey, setSDataSourceKey] = useState([]);
	// const [sDataSource, setSDataSource] = useState([]);
	// const [sIndex, setSIndex] = useState(0);
	const [sRef, setSRef] = useState(useRef(null));

	const fGetTempDataSource = (xDataSource) => {
		let iData = [];
		for (let i = 0; i < xDataSource.length; i++) {
			const iObjLength = Object.values(props._dataSource[i]).length;

			if (iObjLength.length === 1) {
				iData.push({
					value: Object.values(props._dataSource[i])[0],
				});
			} else {
				let iNewObj = {};
				for (let ii = 0; ii < iObjLength; ii++) {
					let iValue = "";
					let iObjectValue = Object.values(props._dataSource[i])[ii];
					if (iObjectValue instanceof Object) {
						iValue = Object.values(iObjectValue)[0];
					} else {
						iValue = iObjectValue;
					}

					iNewObj[Object.keys(props._dataSource[i])[ii]] = iValue;
				}

				iData.push(iNewObj);
				// console.log("------------------------");
				// console.log(iNewObj);
			}
		}
		if (iData.length > 0) {
			const iDataSourceKey = Object.keys(iData[0]);
			setSDataSourceKey(iDataSourceKey);
		}
		return iData;
	};

	const fFilter = (xValue) => {
		try {
			xValue = xValue.toLowerCase();

			const iRegString = new RegExp(/\D/g); //semua string
			if (props._dataSource) {
				// let iTempDataSource = [...props._dataSource];
				const iTempDataSource = fGetTempDataSource(props._dataSource);

				let iDataSource = [];
				if (xValue === "") {
					iDataSource = iTempDataSource;
				} else {
					if (iRegString.test(xValue)) {
						const iRegExp = new RegExp(`${xValue}`);
						iDataSource = iTempDataSource.filter((xValues) => {
							return iRegExp.test(String(Object.values(xValues)[0]).toLowerCase());
						});

						if (iDataSource.length === 0) {
							iDataSource = iTempDataSource.filter((xValues) => {
								return iRegExp.test(String(Object.values(xValues)[1]).toLowerCase());
							});
						}
					} else {
						const iRegExp = new RegExp(`^${xValue}`);
						iDataSource = iTempDataSource.filter((xValues) => {
							return iRegExp.test(String(Object.values(xValues)[0]));
						});
					}
				}
				// console.log(iDataSource);
				setsSelectData((xState) => {
					let iState = { ...xState };
					iState.eIndexDataSource = 0;
					iState.eDataSource = iDataSource;
					iState.eKeyEnter = false;
					iState.eShowTable = true;
					return iState;
				});
			}
		} catch (error) {
			return;
		}
	};

	useEffect(() => {
		if (props._ref) {
			setSRef(props._ref);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		try {
			// console.log(`----${props._value}`);
			setSInputValue(props._value || "");
			const iTempDataSource = fGetTempDataSource(props._dataSource);

			// console.log(iTempDataSource);
			// setSDataSource(props._dataSource);
			if (iTempDataSource.length > 0) {
				if (props._value) {
					let iValue = String(props._value).toLowerCase();
					const iRegExp = new RegExp(`${iValue}`);
					const iDataSource = iTempDataSource.filter((xValues) => {
						const iCek0 = iRegExp.test(
							String(Object.values(xValues)[0]).toLowerCase()
						);
						const iCek1 = iRegExp.test(
							String(Object.values(xValues)[1]).toLowerCase()
						);
						if (iCek0 || iCek1) {
							return true;
						} else {
							return false;
						}

						// return iRegExp.test(Object.values(xValues)[0].toLowerCase());
					});

					if (iDataSource) {
						const iData = iDataSource[0];
						if (iData) {
							setsSelectData((xState) => {
								let iState = { ...xState };
								iState.eValueIndex0 = Object.values(iData)[0];
								iState.eValueIndex1 = Object.values(iData)[1];
								iState.eDataSource = iDataSource;
								iState.eKeyEnter = false;
								return iState;
							});
						}
					}
				} else if (props._default) {
					const iData = iTempDataSource[0];
					setsSelectData((xState) => {
						let iState = { ...xState };
						iState.eValueIndex0 = Object.values(iData)[0];
						iState.eValueIndex1 = Object.values(iData)[1];
						iState.eDataSource = iTempDataSource;
						iState.eKeyEnter = false;
						return iState;
					});
					setSInputValue(Object.values(iData)[1]);
				} else {
					setsSelectData((xState) => {
						let iState = { ...xState };
						iState.eValueIndex0 = "";
						iState.eValueIndex1 = "";
						iState.eDataSource = iTempDataSource;
						iState.eKeyEnter = false;
						return iState;
					});
				}
			}
		} catch (xErr) {
			return;
		}

		// eslint-disable-next-line
	}, [props._dataSource, props._value]);

	// useEffect(() => {
	// 	try {

	// 	} catch (xErr) {
	// 		return
	// 	}
	// }, [props._value]);

	useEffect(() => {
		try {
			const iDataValues = Object.values(
				sSelectData.eDataSource[sSelectData.eIndexDataSource]
			);

			let iData = [];
			for (let i = 0; i < iDataValues.length; i++) {
				iData.push(iDataValues[i]);
			}
			if (props._onData) {
				props._onData(iData);
			}
			if (sSelectData.eKeyEnter) {
				if (props._onKeyEnter) {
					props._onKeyEnter(iData);
					if (props._onLossFocus) {
						props._onLossFocus();
					}
				}
			}
		} catch (error) {}
		// eslint-disable-next-line
	}, [sSelectData]);

	return (
		<div className={"flex flex-col flex-1 relative "}>
			<input
				className="Text w-full "
				ref={sRef}
				type="text"
				onFocus={(xEvent) => {
					// setSTableDisplay(true);
					setsSelectData((xState) => {
						let iState = { ...xState };
						iState.eShowTable = true;
						iState.eKeyEnter = false;
						return iState;
					});

					fFilter(xEvent.nativeEvent.target.value);

					xEvent.nativeEvent.target.select();
					if (props._onFocus) {
						props._onFocus();
					}
				}}
				onBlur={() => {
					if (!sOnTableDisplay) {
						// setSTableDisplay(false);
						setsSelectData((xState) => {
							let iState = { ...xState };
							iState.eShowTable = false;
							iState.eKeyEnter = false;
							return iState;
						});
					}
					if (props._onBlur) {
						props._onBlur();
					}
				}}
				onKeyUp={(xEvent) => {
					if (xEvent.nativeEvent.key === "ArrowRight" && sSelectData.eShowTable) {
						if (props._onKeyRight) {
							props._onKeyRight();
						}
					} else if (
						xEvent.nativeEvent.key === "ArrowLeft" &&
						sSelectData.eShowTable
					) {
						if (props._onKeyLeft) {
							props._onKeyLeft();
						}
					} else if (
						xEvent.nativeEvent.key === "ArrowDown" &&
						sSelectData.eShowTable
					) {
						let iLength = sSelectData.eDataSource.length - 1;
						if (sSelectData.eIndexDataSource < iLength) {
							// setSIndex(sIndex + 1);
							setsSelectData((xState) => {
								let iState = { ...xState };
								iState.eIndexDataSource = iState.eIndexDataSource + 1;
								iState.eKeyEnter = false;
								return iState;
							});
						}
					} else if (
						xEvent.nativeEvent.key === "ArrowUp" &&
						sSelectData.eShowTable
					) {
						if (sSelectData.eIndexDataSource > 0) {
							// setSIndex(sIndex + 1);
							setsSelectData((xState) => {
								let iState = { ...xState };
								iState.eIndexDataSource = iState.eIndexDataSource - 1;
								iState.eKeyEnter = false;
								return iState;
							});
						} else {
							if (props._onKeyUp) {
								props._onKeyUp();
							}
						}
					} else if (xEvent.nativeEvent.key === "Enter") {
						if (props._onKeyEnter) {
							// setSTableDisplay(false);

							const iData = sSelectData.eDataSource[sSelectData.eIndexDataSource];
							if (iData) {
								setSInputValue(Object.values(iData)[1]);
							}
							setSOnTableDisplay(false);
							setsSelectData((xState) => {
								let iState = { ...xState };
								iState.eKeyEnter = true;
								iState.eShowTable = false;
								return iState;
							});
						}
					}
				}}
				value={sInputValue}
				onChange={(xEvent) => {
					const iValue = xEvent.nativeEvent.target.value;
					setSInputValue(iValue);
					if (iValue !== "" && sSelectData.eShowTable === false) {
						// setSTableDisplay(true);
					}

					fFilter(iValue);
					if (props._onChange) {
						props._onChange(iValue);
					}
				}}
			/>
			{sSelectData.eShowTable ? (
				<button
					className="w-full top-7 absolute z-10  focus:outline-none"
					onMouseOver={() => {
						setSOnTableDisplay(true);
					}}
					onMouseOut={() => {
						setSOnTableDisplay(false);
					}}
					onBlur={() => {
						if (!sOnTableDisplay) {
							// 	// setSTableDisplay(false);
							setsSelectData((xState) => {
								let iState = { ...xState };
								iState.eShowTable = false;
								iState.eKeyEnter = false;
								return iState;
							});
						}
						setsSelectData((xState) => {
							let iState = { ...xState };
							iState.eShowTable = false;
							iState.eKeyEnter = false;
							return iState;
						});
					}}
				>
					<div className=" max-h-96 overflow-y-auto">
						<table className="Table w-full">
							<thead>
								<tr>
									{sSelectData.eDataSource
										? sDataSourceKey.map((xData, xIndex) => {
												return <th key={xIndex}>{xData}</th>;
										  })
										: ""}
								</tr>
							</thead>
							<tbody>
								{sSelectData.eDataSource
									? sSelectData.eDataSource.map((xData, xIndex) => {
											let iBg = "";
											if (sSelectData.eIndexDataSource === xIndex) {
												iBg = env.color.orangeRed03;
											}
											const iDataSourceObjectValues = Object.values(xData);

											return (
												<tr
													key={`x${xIndex}`}
													style={{ background: iBg }}
													onClick={() => {
														setsSelectData((xState) => {
															let iState = { ...xState };
															iState.eValueIndex0 = Object.values(xData)[0];
															iState.eValueIndex1 = Object.values(xData)[1];
															iState.eKeyEnter = true;
															iState.eIndexDataSource = xIndex;
															iState.eShowTable = false;
															return iState;
														});
														setSInputValue(Object.values(xData)[1]);
													}}
												>
													{iDataSourceObjectValues.map((xxData, xIndex) => {
														return (
															<td className=" text-left" key={`xx${xIndex}`}>
																{xxData}
															</td>
														);
														// }
													})}
												</tr>
											);
									  })
									: ""}
							</tbody>
						</table>
					</div>
				</button>
			) : (
				""
			)}
		</div>
	);
};

export default ArjTextDataSource;
