import { useState, useEffect, useRef } from "react";

import CompTextDs from "../../../../component/arjText-DataSource";

const Input = (props) => {
	const iRef = useRef(null);

	const [sAkun, setSAkun] = useState({
		eNoAkun: props._valueAkun || "",
		eNama: "",
		eRef: "",
		eRefName: "",
		eEnter: false,
	});

	useEffect(() => {
		if (sAkun.eNama !== "" && props._onKeyEnter && sAkun.eEnter) {
			props._onKeyEnter([sAkun.eNoAkun, sAkun.eNama, sAkun.eRef]);
		}

		// eslint-disable-next-line
	}, [sAkun]);

	useEffect(() => {
		if (props._dataSourceSeksi.length > 0) {
			setSAkun((xState) => {
				let iState = { ...xState };
				iState.eRef = props._dataSourceSeksi[0].id;
				iState.eEnter = false;
				return iState;
			});
		}
		// eslint-disable-next-line
	}, [props._dataSourceSeksi]);

	useEffect(() => {
		let iSeksi = { id: "", nama: "" };
		const iDataSeksi = props._dataSourceSeksi || [];
		for (let i = 0; i < iDataSeksi.length; i++) {
			if (iDataSeksi[i].id === props._valueRef) {
				iSeksi.nama = iDataSeksi[i].nama;
				iSeksi.id = iDataSeksi[i].id;

				setSAkun((xState) => {
					let iState = { ...xState };
					iState.eRefName = iDataSeksi[i].nama;
					iState.eRef = iDataSeksi[i].id;
					// console.log(iState);
					return iState;
				});
			}
		}

		const iDataAkun = props._dataSourceAkun || [];
		for (let i = 0; i < iDataAkun.length; i++) {
			if (iDataAkun[i].no_akun === props._valueAkun) {
				setSAkun((xState) => {
					let iState = { ...xState };
					iState.eNoAkun = iDataAkun[i].no_akun;
					iState.eNama = iDataAkun[i].nama;
					// iState.eRefName = iSeksi.nama;
					// iState.eRef = iSeksi.id;
					// console.log(iState);
					return iState;
				});
			}
		}
		// eslint-disable-next-line
	}, [props._valueRef, props._dataSourceAkun]);

	return (
		<div
			className="WadahFix flex flex-col space-y-1 md:flex-row "
			style={{ padding: "1px" }}
		>
			<div className="flex flex-1 justify-center items-center">
				<div className="w-24 m-auto">{props._title}</div>
				<div className="w-3">:</div>
				<CompTextDs
					_value={sAkun.eNama}
					_ref={props._ref}
					_dataSource={props._dataSourceAkun}
					_onKeyEnter={(xData) => {
						setSAkun((xState) => {
							let iState = { ...xState };
							iState.eNoAkun = xData[0];
							iState.eNama = xData[1];
							iState.eEnter = true;
							return iState;
						});
					}}
					_onFocus={() => {
						setSAkun((xState) => {
							let iState = { ...xState };
							// iState.eNoAkun = "";
							// iState.eNama = "";
							iState.eEnter = false;
							return iState;
						});
					}}
					_onKeyRight={() => {
						iRef.current.focus();
					}}
				/>
			</div>
			<div className="flex w-full md:w-48">
				<div className="w-24 md:w-10 m-auto md:ml-2">Akun</div>
				<div className="w-3">:</div>
				<div
					className="rounded-xl flex-1 pl-1 border-2 border-solid border-genkiGray-cl_01  
		 			 bg-genkiGray-light	w-full h-7 text-lg;"
				>
					{sAkun.eNoAkun}
				</div>
			</div>
			<div className="flex w-full md:w-72 items-center">
				<div className="w-24 md:w-8 m-auto md:ml-2">Ref</div>
				<div className="w-3">:</div>
				<CompTextDs
					_value={sAkun.eRefName}
					_ref={iRef}
					_dataSource={props._dataSourceSeksi}
					_default={true}
					_onKeyEnter={(xData) => {
						setSAkun((xState) => {
							let iState = { ...xState };
							iState.eRef = xData[0];
							iState.eEnter = true;
							return iState;
						});
					}}
					_onData={(xData) => {
						setSAkun((xState) => {
							let iState = { ...xState };
							iState.eRef = xData[0];
							iState.eEnter = false;
							return iState;
						});
					}}
				/>
			</div>
		</div>
	);
};

export default Input;
