import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import CompPrint from "react-to-print";

import Env from "../../../../../setting/env";
import CompLoading from "../../../../../component/arjLoading";

const PosisiPiutang = (props) => {
  let iBg = "white";
  const refToPrint = useRef();
  const [sLoading, setSLoading] = useState(true);
  const [sDataMembers, setSDataMembers] = useState(null);
  useEffect(() => {
    try {
      const fGetData = async () => {
        const iData = await Axios.get(
          `${props.state.sPages.url}${Env.eRouteApi}/posisi_piutang`,
          {
            headers: {
              authorization: `bearer ${props.state.sToken}`,
            },
          }
        );

        if (iData.status === Env.eHttpStatus.eOk) {
          // console.log(iData.data);
          const iMembers = iData.data;
          const iDataMembers = [];
          let iTotalTabungan = 0;
          let iTotalTabunganWajib = 0;
          let iTotalBebanKegiatan = 0;
          let iTotalSisaPiutang = 0;
          let iTotalPiutang = 0;

          for (let i = 0; i < iMembers.length; i++) {
            const iiMembers = iMembers[i];
            const iPiutang = iiMembers.piutang;
            let iLength = 0;
            if (iPiutang) {
              iLength = iPiutang.length;
            }

            let iNewData = {};
            if (iLength === 0) {
              iNewData = {
                rowSpan: 1,
                no: i + 1,
                nama: iiMembers.members_name,
                tabungan: iiMembers.tabungan_saldo,
                tabungan_wajib: iiMembers.members_tabungan_wajib,
                tunggakan_kegiatan: iiMembers.jumlah_tunggakan_kegiatan,
                tanggal: "",
                pokok: "",
                sisa_pokok: "",
                bayar_pokok: "",
                bayar_bunga: "",
                angsuran: "",
              };
              iDataMembers.push(iNewData);
              iTotalTabungan += parseFloat(iNewData.tabungan);
              iTotalTabunganWajib += parseFloat(iNewData.tabungan_wajib);
              iTotalBebanKegiatan += parseFloat(iNewData.tunggakan_kegiatan);
            } else {
              for (let ii = 0; ii < iLength; ii++) {
                iNewData = {
                  rowSpan: iLength,
                  no: i + 1,
                  nama: iiMembers.members_name,
                  tabungan: iiMembers.tabungan_saldo,
                  tabungan_wajib: iiMembers.members_tabungan_wajib,
                  tunggakan_kegiatan: iiMembers.jumlah_tunggakan_kegiatan,
                  tanggal: iPiutang[ii].piutang_tanggal,
                  pokok: iPiutang[ii].piutang_nilai,
                  sisa_pokok: iPiutang[ii].piutang_sisa_piutang,
                  bayar_pokok: "",
                  bayar_bunga: "",
                  angsuran: iPiutang[ii].piutang_jangka_waktu,
                };
                const iDataBayar = iPiutang[ii].data_bayar;
                if (iDataBayar) {
                  iNewData.bayar_pokok = iDataBayar.bayar_piutang_bayar_pokok;
                  iNewData.bayar_bunga = iDataBayar.bayar_piutang_bayar_bunga;
                  iNewData.angsuran = iDataBayar.bayar_piutang_status;
                }
                if (ii === 0) {
                  iTotalTabungan += parseFloat(iNewData.tabungan);
                  iTotalTabunganWajib += parseFloat(iNewData.tabungan_wajib);
                  iTotalBebanKegiatan += parseFloat(
                    iNewData.tunggakan_kegiatan
                  );
                } else {
                  iNewData.rowSpan = 0;
                  iNewData.no = 0;
                }

                iDataMembers.push(iNewData);

                iTotalSisaPiutang += parseFloat(iNewData.sisa_pokok);
                iTotalPiutang += parseFloat(iNewData.pokok);
              }
            }
          }
          let iSpace = {
            rowSpan: 1,
            no: ".",
            nama: " ",
            tabungan: "",
            tabungan_wajib: "",
            tunggakan_kegiatan: "",
            tanggal: "",
            pokok: "",
            sisa_pokok: "",
            bayar_pokok: "",
            bayar_bunga: "",
            angsuran: "",
          };
          iDataMembers.push(iSpace);

          let iTotalNilai = {
            rowSpan: 1,
            no: "*",
            nama: "TOTAL",
            tabungan: iTotalTabungan.toString(),
            tabungan_wajib: iTotalTabunganWajib.toString(),
            tunggakan_kegiatan: iTotalBebanKegiatan.toString(),
            tanggal: "",
            pokok: iTotalPiutang.toString(),
            sisa_pokok: iTotalSisaPiutang.toString(),
            bayar_pokok: "",
            bayar_bunga: "",
            angsuran: "",
          };
          // console.log(iTotalNilai);
          iDataMembers.push(iTotalNilai);
          setSDataMembers(iDataMembers);
          // console.log(iDataMembers);
        }

        setSLoading(false);
      };
      fGetData();
    } catch (xErr) {
      setSLoading(false);
      alert(xErr);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex flex-col h-screen">
      {sLoading ? <CompLoading /> : null}
      <div className="flex absolute right-1 justify-center items-center w-32 h-9 space-x-1">
        {/* <CompExel _data={sExel} _fileName={`${props._tanggal}_NERACA`} /> */}
        <CompPrint
          trigger={() => {
            return <div className="Button h-6">Cetak</div>;
          }}
          content={() => refToPrint.current}
        />
      </div>
      <div ref={refToPrint} className="overflow-auto">
        {/* TITLE */}
        <div
          className="flex flex-col p-2 mt-1 md:mt-8 xl:mt-1  md: mb-4 max-w-sm md:max-w-xl h-8 
							mx-auto bg-white rounded-xl shadow-md items-center "
        >
          <p>Data Keuangan Anggota</p>
        </div>
        <div className=" flex-1">
          {sDataMembers ? (
            <div className=" w-screen md:px-2">
              <table className="Table2">
                <thead className=" text-sm xl:text-base">
                  <tr>
                    <th rowSpan={2}> No</th>
                    <th rowSpan={2}>Nama</th>
                    <th colSpan={2}>Saldo Tabungan</th>
                    <th rowSpan={2} width="90px">
                      Kwajiban Kegiatan
                    </th>
                    <th colSpan={6}>Piutang</th>
                  </tr>
                  <tr>
                    <th>Sukarela</th>
                    <th>Wajib</th>
                    <th width="90px">Tanggal</th>
                    <th>Pokok</th>
                    <th>Sisa Pokok</th>
                    <th>Bayar Pokok</th>
                    <th>Bayar Bunga</th>
                    <th width="85px">Angsuran </th>
                  </tr>
                </thead>
                <tbody className=" text-sm xl:text-base">
                  {sDataMembers.map((xData, xIndex) => {
                    if (xData.rowSpan !== 0) {
                      if (iBg === "white") {
                        iBg = "#c7b9b9";
                      } else {
                        iBg = "white";
                      }
                    }

                    if (xData.rowSpan !== 0) {
                      return (
                        <tr key={xIndex} style={{ backgroundColor: iBg }}>
                          <td
                            align={xData.no === "*" ? "center" : "left"}
                            rowSpan={xData.rowSpan}
                          >
                            {xData.no}
                          </td>
                          <td
                            align={xData.no === "*" ? "right" : "left"}
                            rowSpan={xData.rowSpan}
                          >
                            {xData.nama}
                          </td>
                          <td align="right" rowSpan={xData.rowSpan}>
                            {Env.format.uang(xData.tabungan)}
                          </td>
                          <td align="right" rowSpan={xData.rowSpan}>
                            {Env.format.uang(xData.tabungan_wajib)}
                          </td>
                          <td align="right" rowSpan={xData.rowSpan}>
                            {Env.format.uang(xData.tunggakan_kegiatan)}
                          </td>
                          <td align="center">{xData.tanggal}</td>
                          <td align="right">{Env.format.uang(xData.pokok)}</td>
                          <td align="right">
                            {Env.format.uang(xData.sisa_pokok)}
                          </td>
                          <td align="right">
                            {Env.format.uang(xData.bayar_pokok)}
                          </td>
                          <td align="right">
                            {Env.format.uang(xData.bayar_bunga)}
                          </td>
                          <td>
                            <div className=" pl-1">{`${xData.angsuran}`}</div>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr key={xIndex} style={{ backgroundColor: iBg }}>
                          <td align="center">{xData.tanggal}</td>
                          <td align="right">{Env.format.uang(xData.pokok)}</td>
                          <td align="right">
                            {Env.format.uang(xData.sisa_pokok)}
                          </td>
                          <td align="right">
                            {Env.format.uang(xData.bayar_pokok)}
                          </td>
                          <td align="right">
                            {Env.format.uang(xData.bayar_bunga)}
                          </td>
                          <td>
                            <div className=" pl-1">{`${xData.angsuran}`}</div>
                          </td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
      <div className=" h-16"></div>
    </div>
  );
};

export default GlobalStateConsumer(PosisiPiutang);
