import React, { useRef } from "react";
import "./imageRead.css";
import Env from "../../setting/env";
import ClipLoader from "react-spinners/ClipLoader";
// import Dom from 'react-dom';

function Index(props) {
	// const [previewImage, setPreviewImage] = useState('');
	const mCariGambarRef = useRef(null);
	const mButtonGantiRef = useRef(null);

	const fValidateImage = (e) =>
		e.target.files[0].type === "image/gif" ||
		e.target.files[0].type === "image/jpeg" ||
		e.target.files[0].type === "image/jpg" ||
		e.target.files[0].type === "image/png" ||
		e.target.files[0].type === "video/mp4";

	const fGetImage = (xFiles) => {
		let iFileType = xFiles.type;
		let iFileExt = "";
		if (iFileType.indexOf("/mp4") > 0) {
			iFileType = "video";
			iFileExt = ".mp4";
		} else {
			iFileType = "image";
			iFileExt = ".png";
		}

		if (props._photoOnly) {
			if (iFileType !== "image") {
				return;
			}
		}

		let iReader = new FileReader();

		iReader.onload = function (eReader) {
			if (iFileType === "video") {
				if (props._onNewImage) {
					props._onNewImage({
						file: eReader.target.result,
						type: iFileType,
						ext: iFileExt,
					});
				}
			} else {
				let iImage = new Image();
				iImage.onload = function () {
					let iCanvas = document.createElement("canvas");
					iCanvas.width = 450;
					iCanvas.height = 600;
					let iContex = iCanvas.getContext("2d");

					iContex.drawImage(iImage, 0, 0, iCanvas.width, iCanvas.height);
					// iContex.drawImage(iImage, 0, 0, iCanvas.height, iCanvas.width);
					if (props._onNewImage) {
						props._onNewImage({
							file: iCanvas.toDataURL(),
							type: iFileType,
							ext: iFileExt,
						});
					}
				};
				iImage.src = eReader.target.result;
			}
		};
		iReader.readAsDataURL(xFiles);
	};

	return (
		<div
			style={{
				backgroundColor: Env.color.orangeRed05,
				// display: "flex",
				alignItems: "center",
				// flexDirection: "column",
				border: `2px solid ${Env.color.orangeRed03}`,
				borderRadius: 6,
				height: 190,
				width: 120,
				margin: 4,
				padding: "3px 2px",
				flexWrap: "wrap",
			}}
		>
			<div
				style={{
					display: "flex",
					height: "160px",
					width: "100%",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<div
					className="imageRead relative"
					style={{
						backgroundColor: Env.color.orangeRed02,
						border: `1px solid ${Env.color.orangeRed01}`,
					}}
					onClick={() => {
						// if (!props._image && !props._photoOnly) {
						// 	mCariGambarRef.current.click();
						// }
						if (!props._image) {
							mCariGambarRef.current.click();
						}
					}}
				>
					{props._image ? (
						<img
							style={{
								fontSize: 14,
								height: "100%",
								width: "100%",
								borderRadius: 6,
								border: "solid",
								borderColor: "#bbbbbb",
								borderWidth: 1,
							}}
							src={props._image.file ? props._image.file : props._image}
							alt="Gambar..."
							onClick={() => {
								if (props._image && props._onLihatImage) {
									props._onLihatImage(props._image, props._index);
								}
							}}
						/>
					) : props._imageReff ? (
						<img
							style={{
								fontSize: 14,
								height: "100%",
								width: "100%",
								borderRadius: 6,
								border: "solid",
								borderColor: "#bbbbbb",
								borderWidth: 1,
							}}
							src={props._imageReff}
							alt="Gambar..."
							onClick={() => {
								if (!props._image) {
									mCariGambarRef.current.click();
								}
							}}
						/>
					) : props._photoOnly ? (
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
								padding: "22px",
							}}
						>
							+ Photo
						</div>
					) : (
						<div
							style={{
								display: "flex",
								width: "100%",
								justifyContent: "center",
								padding: "22px",
							}}
						>
							+ Photo / Video
						</div>
					)}
					<input
						type="file"
						style={{ display: "none" }}
						onChange={(e) => {
							let iFiles = e.target.files;

							if (iFiles.length === 1 && fValidateImage(e)) {
								// console.log(iFiles[0]);
								if (iFiles[0].size <= 10000000) {
									fGetImage(iFiles[0]);
								} else {
									e.target.value = "";
									alert(
										"File Terlalu Besar, ukuran File harus lebih kecil atau sama dengan 10Mb"
									);
								}
							} else {
								e.target.value = "";
								alert("Format File Tidak Mendukung");
							}
						}}
						ref={mCariGambarRef}
						accept="image/gif, image/jpeg, image/jpg, image/png, video/mp4"
					/>
					{props._loading === true ? (
						<div className=" absolute top-0 flex items-center justify-center h-full w-full">
							<ClipLoader color="#ffffff" />
						</div>
					) : null}
				</div>
			</div>

			{/* FOOTER Buton Ggnti dan Hapus Image*/}
			{props._image ? (
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						marginTop: 3,
						flex: 1,
						width: "100%",
					}}
				>
					{props._upload === true ? (
						<button
							className="btnImageRead"
							ref={mButtonGantiRef}
							onClick={() => {
								if (props._onUpload) {
									props._onUpload();
								}
							}}
						>
							Upload
						</button>
					) : null}

					<button
						className="btnImageRead"
						onClick={() => {
							if (props._onHapus) {
								props._onHapus(props._index);
							}
						}}
					>
						Hapus
					</button>
				</div>
			) : props._imageReff ? (
				<div
					style={{
						display: "flex",
						margin: "auto",
						justifyContent: "center",
						alignItems: "center",
						borderRadius: "5px",
						cursor: "pointer",
					}}
					onClick={() => {
						if (!props._image) {
							mCariGambarRef.current.click();
						}
					}}
				>
					+ Photo
				</div>
			) : (
				""
			)}
		</div>
	);
}

export default Index;
