import React, { useState, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../state.global";
import Env from "../../../../setting/env";
import CompLoading from "../../../../component/arjLoading";

const RekapKegiatan = (props) => {
	const [sLoading, setSLoading] = useState(true);
	const [sRekapKegitan, setSRekapKegitan] = useState(null);
	useEffect(() => {
		try {
			const fGetData = async () => {
				const iData = await Axios.get(
					`${props.state.sPages.url}${Env.eRouteApi}/rekap_kegiatan`,
					{
						headers: {
							authorization: `bearer ${props.state.sToken}`,
						},
					}
				);

				if (iData.status === Env.eHttpStatus.eOk) {
					// console.log(iData.data);
					setSRekapKegitan(iData.data.message);
				}

				setSLoading(false);
			};
			fGetData();
		} catch (xErr) {}
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			{sLoading ? <CompLoading /> : null}
			{/* TITLE */}
			<div
				className="flex flex-col p-2 mt-4 md:mt-8  md: mb-4 max-w-sm md:max-w-xl h-8 
							mx-auto bg-white rounded-xl shadow-md items-center "
			>
				<p>Rekap Kegiatan</p>
			</div>
			{sRekapKegitan ? (
				<div className=" w-screen md:px-2">
					<table className="Table">
						<thead>
							<tr>
								<th>No</th>
								<th>Jenis</th>
								<th>Jumlah</th>
							</tr>
						</thead>
						<tbody>
							{sRekapKegitan.rekap_kegiatan.map((xData, xIndex) => {
								return (
									<tr key={xIndex}>
										<td>{xIndex + 1}</td>
										<td>{xData.nama}</td>
										<td align="right">{Env.format.uang(xData.nilai)}</td>
									</tr>
								);
							})}
							<tr>
								<td> </td>
								<td align="right"> </td>
								<td align="right">------------------------ </td>
							</tr>
							<tr>
								<td> </td>
								<td align="right"> Total </td>
								<td align="right">{Env.format.uang(sRekapKegitan.total)} </td>
							</tr>
						</tbody>
					</table>
				</div>
			) : null}
		</div>
	);
};

export default GlobalStateConsumer(RekapKegiatan);
