import React, { useState, useEffect } from "react";
import Axios from "axios";

import Env from "../../setting/env.js";
import ImgOk from "../../assets/image/ok.svg";
import ImgTulis from "../../assets/image/tulis.svg";
import ImgMataBuka from "../../assets/image/MataBuka.svg";
import ImgMataTutup from "../../assets/image/MataTutup.svg";

const Index = (props) => {
	const [sCaptcha, setCaptcha] = useState(props._captcha);
	const [sBukaPassword, setBukaPassword] = useState(false);
	const [sValue, setSValue] = useState("");

	useEffect(() => {
		if (props._captcha) {
			Axios.get(Env.urlCaptcha).then((xData) => {
				if (xData.status === 200) {
					setCaptcha(xData.data.message);
					if (props._onNewToken) {
						props._onNewToken(xData.data.message.token);
					}
				}
			});
		}

		setSValue(props._value || "");
		// eslint-disable-next-line
	}, [props._value]);

	useEffect(() => {
		if (props._onChange) {
			props._onChange(sValue);
		}
		// eslint-disable-next-line
	}, [sValue]);

	return (
		// DIV 1.
		<div
			style={{
				display: "flex",
				margin: "2px 1px 10px 0px",
				width: "305px",
			}}
		>
			{/* DIV 1.1 */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					flex: 1,
				}}
			>
				{/* DIV 1.1.1 */}
				{/* ========= Cek Ada CAPTHA apa tidak */}
				{props._captcha ? (
					// ========== Tampilan Dengan CAPTCHA ==================

					<div
						style={{
							display: "flex",
							flex: 1,
							fontSize: 12,
							height: "26px",
							paddingLeft: "3px",
						}}
					>
						<div
							style={{
								height: "100%",
								display: "flex",
								alignItems: "center",
								marginRight: "4px",
							}}
						>
							{props._title}
						</div>
						<div>
							<img src={sCaptcha.image} alt="Kode..." />
						</div>
						<div
							style={{
								height: "100%",
								display: "flex",
								alignItems: "center",
								marginLeft: "4px",
								flex: 1,
							}}
						>
							<div
								style={{
									display: "flex",
									flex: 1,
								}}
							></div>
							<button
								className="Button"
								style={{
									height: "80%",
									width: "80px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									marginRight: "8px",
									borderRadius: "8px",
								}}
								onClick={() => {
									Axios.get(Env.urlCaptcha).then((xData) => {
										if (xData.status === 200) {
											setCaptcha(xData.data.message);
											props._ref.current.focus();
											props._ref.current.select();
											if (props._onNewToken) {
												props._onNewToken(xData.data.message.token);
											}
										}
									});
								}}
							>
								Ganti Kode
							</button>
						</div>
					</div>
				) : (
					// ====END==== Tampilan Dengan CAPTCHA ==================
					// =========== Tampilan Tanpa CAPTCHA ==================
					<div
						style={{
							display: "flex",
							flex: 1,
						}}
					>
						<div
							style={{
								display: "flex",
								flex: 1,
								fontSize: 15,
								height: "19px",
								paddingLeft: "3px",
							}}
						>
							{props._title}
						</div>

						{props._type === "password" ? (
							<div
								className="tombol"
								style={{
									display: "flex",
									width: "26px",
									height: "20px",
									borderRadius: "20px",
									justifyContent: "center",
									alignItems: "center",
									marginRight: "5px",
								}}
							>
								<img
									// className="tombol"
									src={sBukaPassword ? ImgMataTutup : ImgMataBuka}
									alt="Gambar"
									style={{
										height: "100%",
										width: "20px",
									}}
									onClick={() => {
										setBukaPassword(!sBukaPassword);
										props._ref.current.focus();
									}}
								/>
							</div>
						) : (
							""
						)}
					</div>
					// ===== END ====== Tampilan Tanpa CAPTCHA ==================
				)}

				{/* END DIV 1.1.1 */}

				{/* DIV 1.1.2 */}
				<div
					style={{
						display: "flex",
						width: "100%",
						height: "19px",
					}}
				>
					<input
						type={
							props._type === "password"
								? sBukaPassword
									? "text"
									: "password"
								: "text"
						}
						placeholder={props._placeholder}
						maxLength={props._maxLength || null}
						value={sValue}
						// style={{
						// 	marginTop: "1px",
						// 	fontSize: "17px",
						// 	borderRadius: "8px",
						// 	borderStyle: "solid",
						// 	borderColor: sTxtBorderColer,
						// 	paddingLeft: "2px",
						// 	outline: "none",
						// 	height: "19px",
						// 	flex: 1,
						// }}
						className="Text"
						ref={props._ref}
						onChange={(xEvent) => {
							const iValue = xEvent.nativeEvent.target.value;
							setSValue(iValue);
						}}
						onKeyPress={(xEvent) => {
							if (xEvent.nativeEvent.key === "Enter") {
								if (props._onEnter) {
									props._onEnter();
								}
							}
						}}
						onKeyDown={(xEvent) => {
							if (props._onKeyArrowDown) {
								if (xEvent.nativeEvent.key === "ArrowDown") {
									props._onKeyArrowDown();
								}
							}
							// console.log(xEvent.nativeEvent.key);
						}}
						onKeyUp={(xEvent) => {
							if (props._onKeyArrowUp) {
								if (xEvent.nativeEvent.key === "ArrowUp") {
									props._onKeyArrowUp();
								}
							}
							// console.log(xEvent.nativeEvent.key);
						}}
					/>
				</div>
				{/* END DIV 1.1.2 */}
			</div>
			{/* END DIV 1.1 */}

			{/* DIV 1.2 */}
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					width: "14px",
					height: "100%",
					marginRight: "2px",
				}}
			>
				<div
					style={{
						flex: 1,
					}}
				></div>
				{props._harusDiIsi === true ? (
					<img
						src={props._validasi ? ImgOk : ImgTulis}
						alt="Gambar"
						style={{
							height: "32px",
							width: "14px",
						}}
					/>
				) : (
					""
				)}
			</div>
			{/* END DIV 1.2 */}
		</div>
	);
};

export default Index;
