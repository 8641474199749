import { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { GlobalStateConsumer } from "../../../state.global";
import CompTextTitle from "../../../component/arjText-Title";
import CompText from "../../../component/arjText";
import Button from "../../../component/arjButton";
import Env from "../../../setting/env";
import CompAlert from "../../../component/arjAlert";
import CompAlertUpdate from "../../../component/arjAlert";
import CompAlertDelete from "../../../component/arjAlert";
import CompLoading from "../../../component/arjLoading";

const Organisasi = (props) => {
	const [sDataOrganisasi, setSDataOrganisasi] = useState({
		eNama: { eText: "", eStatus: "false" },
		url: { eText: "", eStatus: "false" },
		eStatus: false,
	});
	const [sRsOrganisasi, setSRsOrganisasi] = useState([]);
	const [sShowAlertInsert, setsShowAlertInsert] = useState(false);
	const [sShowLoadding, setSShowLoadding] = useState(false);
	const [sUpdate, setSUpdate] = useState({ eIndex: 0, eStatus: false });
	const [sDelete, setsDelete] = useState({ eIndex: 0, eStatus: false });

	const mRefNama = useRef(null);
	const mRefUrl = useRef(null);
	const mRefSimpan = useRef(null);

	useEffect(() => {
		if (props._onActive) {
			props._onActive();
		}
		mRefNama.current.focus();
		Axios.get(`${props.state.sPages.url}/organisasi`).then((xData) => {
			setSRsOrganisasi(xData.data.message);
			// console.log(xData.data);
		});

		// eslint-disable-next-line
	}, []);

	const fDataOrganisasiStatus = (xState) => {
		if (xState.eNama.eStatus === true && xState.url.eStatus === true) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className=" w-screen h-screen pt-1 pr-4 pl-1 relative ">
			{sShowLoadding ? <CompLoading /> : ""}
			{sUpdate.eStatus ? (
				<CompAlertUpdate
					_title={`Update Data Organisasi No.${sUpdate.eIndex + 1}`}
					_keterangan={`Nama : ${sRsOrganisasi[sUpdate.eIndex].nama}, Url: ${
						sRsOrganisasi[sUpdate.eIndex].url
					}. Apakah Data Sudah Benar ? `}
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setSUpdate((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
					}}
					_onOk={() => {
						setSUpdate((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
						setSShowLoadding(true);
						const iData = sRsOrganisasi[sUpdate.eIndex];
						Axios.put(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/organisasi`,
							iData,
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSRsOrganisasi(xData.data.message);
								setSShowLoadding(false);
							})
							.catch(() => {
								setSShowLoadding(false);
								alert("Data Gagal di Update");
							});
					}}
				/>
			) : (
				""
			)}
			{sDelete.eStatus ? (
				<CompAlertDelete
					_title={`Delete Data Organisasi No.${sDelete.eIndex + 1}`}
					_keterangan="Apakah Data Sudah Benar"
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_onCancel={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
					}}
					_onOk={() => {
						setsDelete((xState) => {
							let iState = { ...xState };
							iState.eStatus = false;
							return iState;
						});
						setSShowLoadding(true);
						Axios.delete(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/organisasi`,

							{
								data: {
									id: sRsOrganisasi[sDelete.eIndex].id,
								},
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSRsOrganisasi(xData.data.message);

								setSShowLoadding(false);
								// alert(xData.data);
							})
							.catch(() => {
								setSShowLoadding(false);
								alert("Data Gagal di Hapus");
							});
					}}
				/>
			) : (
				""
			)}
			{sShowAlertInsert ? (
				<CompAlert
					_title="Data Organisasi"
					_keterangan="Apakah Data  Sudah Benar"
					_buttonCancel="Tidak"
					_buttonOk="Ya"
					_show={sShowAlertInsert}
					_onCancel={() => {
						setsShowAlertInsert(false);
						mRefNama.current.focus();
					}}
					_onOk={() => {
						setsShowAlertInsert(false);
						setSShowLoadding(true);
						const iData = {
							nama: sDataOrganisasi.eNama.eText,
							url: sDataOrganisasi.url.eText,
						};
						Axios.post(
							`${props.state.sPages.url}${Env.eRouteApi}/admin/organisasi`,
							iData,
							{
								headers: {
									authorization: `bearer ${props.state.sToken}`,
								},
							}
						)
							.then((xData) => {
								setSShowLoadding(false);
								setSRsOrganisasi(xData.data.message);
								mRefNama.current.focus();
								mRefNama.current.select();
							})
							.catch((xErr) => {
								alert(xErr);
							});
					}}
				/>
			) : (
				""
			)}
			<div className="flex flex-col space-y-2 shadow-lg  bg-genkiBlue-cl_01 max-w-screen-sm md:max-w-screen-md mx-auto m-1 p-1 rounded-xl">
				<div className="m-auto">Masukan Data Organisasi</div>
				<hr style={{ color: "gray" }} />
				<CompTextTitle
					_title="Nama"
					_titleWidth="50px"
					_ref={mRefNama}
					_value={sDataOrganisasi.eNama.eText}
					_onChange={(xValue) => {
						setSDataOrganisasi((xState) => {
							let iState = { ...xState };
							iState.eNama.eText = xValue;
							if (xValue.length > 4) {
								iState.eNama.eStatus = true;
							} else {
								iState.eNama.eStatus = false;
							}
							iState.eStatus = fDataOrganisasiStatus(iState);

							return iState;
						});
					}}
					_onKeyEnter={() => {
						if (sDataOrganisasi.eNama.eText.length > 4) {
							mRefUrl.current.focus();
							mRefUrl.current.select();
						}
					}}
				/>
				<CompTextTitle
					_title="Url"
					_titleWidth="50px"
					_ref={mRefUrl}
					_value={sDataOrganisasi.url.eText}
					_onChange={(xValue) => {
						setSDataOrganisasi((xState) => {
							let iState = { ...xState };
							iState.url.eText = xValue;
							if (xValue.length > 10) {
								iState.url.eStatus = true;
							} else {
								iState.url.eStatus = false;
							}
							iState.eStatus = fDataOrganisasiStatus(iState);

							return iState;
						});
					}}
					_onKeyEnter={() => {
						if (sDataOrganisasi.url.eText.length > 10) {
							mRefSimpan.current.focus();
						}
					}}
					_onKeyUp={() => {
						mRefNama.current.focus();
					}}
				/>
				<Button
					_text="Simpan"
					className="Button h-7 w-20 mx-auto"
					_ref={mRefSimpan}
					_value={sDataOrganisasi.url}
					_imageType={
						sDataOrganisasi.eStatus
							? Env.buttonImageType.ok
							: Env.buttonImageType.stop
					}
					_onKeyUp={() => {
						mRefUrl.current.focus();
					}}
					_onClick={() => {
						if (sDataOrganisasi.eStatus === true) {
							setsShowAlertInsert(true);
						}
					}}
				/>
				<div>{sDataOrganisasi.eStatus}</div>
			</div>

			<div className="mt-4 mr-4 p-2 rounded-xl w-full WadahFix overflow-x-scroll">
				<table className="Table">
					<thead>
						<tr className="flex">
							<th className=" w-8 ">No</th>
							<th className="w-72 ">Nama</th>
							<th className="flex-1 ">Url</th>
							<th className="w-36 ">Ubah</th>
						</tr>
					</thead>
					<tbody>
						{sRsOrganisasi.map((xData, xIndex) => {
							return (
								<tr key={xIndex} className="flex">
									<td className="flex w-8 items-center ">
										<div className="flex-1"></div>
										<div className="pr-1 ">{xIndex + 1}</div>
									</td>
									<td className="w-72 ">
										<CompText
											_value={xData.nama}
											_onChange={(xValue) => {
												setSRsOrganisasi((xState) => {
													let iState = [...xState];
													iState[xIndex].nama = xValue;
													return iState;
												});
											}}
										/>
									</td>
									<td className="flex-1 ">
										<CompText
											_value={xData.url}
											_onChange={(xValue) => {
												setSRsOrganisasi((xState) => {
													let iState = [...xState];
													iState[xIndex].url = xValue;
													return iState;
												});
											}}
										/>
									</td>
									<td className="flex w-36 items-center  justify-evenly ">
										<Button
											_text="Update"
											_onClick={() => {
												setSUpdate((xState) => {
													let iState = { ...xState };
													iState.eIndex = xIndex;
													iState.eStatus = true;
													return iState;
												});
											}}
											className="Button h-6"
										/>
										<Button
											_text="Delete"
											_onClick={() => {
												setsDelete((xState) => {
													let iState = { ...xState };
													iState.eIndex = xIndex;
													iState.eStatus = true;
													return iState;
												});
											}}
											className="Button h-6"
										/>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default GlobalStateConsumer(Organisasi);
