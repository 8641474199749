import { useState, useRef, useEffect } from "react";
import Axios from "axios";

import { GlobalStateConsumer } from "../../../../../state.global";
import Env from "../../../../../setting/env";
import PiutangSetting from "../../../../../setting/piutang_setting";
import CompTextDs from "../../../../../component/arjText-DataSource";
import CompText from "../../../../../component/arjText";
import CompAlert from "../../../../../component/arjAlert";
import CompAlertDelete from "../../../../../component/arjAlert";
import CompLoading from "../../../../../component/arjLoading";

const RealisasiPiutang = (props) => {
  const [sTanggal, setSTanggal] = useState("");

  const mNewData = {
    nama: { id: "", nama: "", alamat: "", kelompok: "" },
    program_piutang: { id: "", nama: "", jenis: "", bunga: "", denda: "" },
    nilai: 0,
    jangka_waktu: 0,
    keterangan: "",
  };

  const [sNewData, setSNewData] = useState(mNewData);
  const [sLoading, setSLoading] = useState(true);
  const [sAnggota, setSAnggota] = useState([]);
  const [sProgramPiutang, setSProgramPiutang] = useState([]);
  const [sProgramPiutangFocus, setSProgramPiutangFocus] = useState(false);
  const [sRsDataRealisasiPiutang, setSRsDataRealisasiPiutang] = useState([]);
  const [sNamaFocus, setSNamaFocus] = useState(false);
  const [sAlert, setSAlert] = useState(false);
  const [sDelete, setSDelete] = useState({ show: false, index: 0, data: "" });

  const refTanggal = useRef();
  const refNama = useRef();
  const refProgramPiutang = useRef();
  const refNilai = useRef();
  const refJangkaWaktu = useRef();
  const refKeterangan = useRef();
  const refRealisasi = useRef();

  const fProgramPiutangName = (xData) => {
    return `${xData}. ${PiutangSetting.efGetValueJenisPiutang(xData)}`;
  };

  const fGetPreData = async () => {
    try {
      const iTanggal = await Axios.get(`${props.state.sPages.url}/date`);
      setSTanggal(iTanggal.data);

      // Realisasi piutang harus berdasarkan atas kepemilikan rekening tabungan
      const iRsAngota = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/bendahara/rekening_tabungan`,
        {
          params: { req: "members", kegiatan: "realisasi_piutang" },
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      setSAnggota(iRsAngota.data.message);
      // console.log(iRsAngota.data.message);

      const iRsProgramPiutang = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/bendahara/program_piutang`,
        {
          params: { req: "rs_data_program_piutang" },
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      setSProgramPiutang(iRsProgramPiutang.data.message);

      const iRsRealisasiPiutang = await Axios.get(
        `${props.state.sPages.url}${Env.eRouteApi}/admin/realisasi_piutang`,
        {
          params: {
            req: "waktu",
            data: { from: iTanggal.data, to: iTanggal.data },
          },
          headers: {
            authorization: `bearer ${props.state.sToken}`,
          },
        }
      );
      // console.log(iRsRealisasiPiutang);
      setSRsDataRealisasiPiutang(iRsRealisasiPiutang.data.message);

      setSLoading(false);
      refNama.current.focus();
    } catch (xErr) {
      // console.log(xErr);
      alert("Error");
    }
  };

  useEffect(() => {
    fGetPreData();
    // eslint-disable-next-line
  }, []);

  const fCekData = () => {
    if (sNewData.nama.id !== "") {
      if (sNewData.program_piutang.id !== "") {
        if (sNewData.nilai > 0) {
          if (sNewData.jangka_waktu > 0) {
            setSAlert(true);
          } else {
            refJangkaWaktu.current.focus();
          }
        } else {
          refNilai.current.focus();
        }
      } else {
        refProgramPiutang.current.focus();
      }
    } else {
      refNama.current.focus();
    }
  };

  return (
    <div className="flex flex-col h-full w-full px-2">
      {sLoading ? <CompLoading /> : ""}
      {sAlert ? (
        <CompAlert
          _title="Data Realisasi Piutang"
          _keterangan="Apakah data sudah benar ?"
          _buttonCancel="Ulang"
          _onCancel={() => {
            setSAlert(false);
            refNama.current.focus();
            refNama.current.select();
          }}
          _onOk={() => {
            setSAlert(false);
            setSLoading(true);
            Axios.post(
              `${props.state.sPages.url}${Env.eRouteApi}/bendahara/realisasi_piutang`,
              {
                tanggal: sTanggal,
                nama: sNewData.nama.id, //id : merupakan id rekening tabungan
                program_piutang: sNewData.program_piutang.id, //id : merupakan id program piutang
                nilai: sNewData.nilai, //nilai : merupakan nilai realisasi
                jangka_waktu: sNewData.jangka_waktu, //jangka_waktu : merupakan jangka waktu piutang
                keterangan: sNewData.keterangan,
              },
              {
                headers: {
                  authorization: `bearer ${props.state.sToken}`,
                },
              }
            )
              .then((xRes) => {
                if (xRes.status === Env.eHttpStatus.eOk) {
                  setSRsDataRealisasiPiutang((xState) => {
                    let iState = [...xState];
                    iState.push(xRes.data.message);
                    return iState;
                  });
                  setSNewData((xState) => {
                    let iState = { ...xState };
                    iState.nama.id = "";
                    iState.nama.nama = "";
                    iState.nama.alamat = "";
                    iState.nama.kelompok = "";
                    return iState;
                  });
                } else {
                  //console.log(xRes.data);
                  alert("Data Realisasi Piutang Gagal Disimpan");
                }
                setSLoading(false);
                refTanggal.current.focus();
              })
              .catch(() => {
                alert("Data Realisasi Piutang Gagal Disimpan");
                setSLoading(false);
              });
          }}
        />
      ) : (
        ""
      )}

      {sDelete.show ? (
        <CompAlertDelete
          _title="Menghapus Data Piutang"
          _keterangan={sDelete.data}
          _buttonCancel="Batal"
          _onCancel={() => {
            setSDelete((xState) => {
              let iState = { ...xState };
              iState.show = false;
              return iState;
            });
          }}
          _onOk={() => {
            setSLoading(true);
            Axios.delete(
              `${props.state.sPages.url}${Env.eRouteApi}/bendahara/realisasi_piutang`,
              {
                data: { id: sRsDataRealisasiPiutang[sDelete.index].id },
                headers: {
                  authorization: `bearer ${props.state.sToken}`,
                },
              }
            )
              .then((xRes) => {
                if (xRes.status === Env.eHttpStatus.eOk) {
                  setSRsDataRealisasiPiutang((xState) => {
                    let iState = [...xState];
                    iState.splice(sDelete.index, 1);
                    return iState;
                  });
                } else {
                  alert("Data Realisasi Piutang Gagal Di Hapus");
                }
                setSDelete((xState) => {
                  let iState = { ...xState };
                  iState.show = false;
                  return iState;
                });
                setSLoading(false);
              })
              .catch(() => {
                alert("Data Realisasi Piutang Gagal Di Hapus");
                setSDelete((xState) => {
                  let iState = { ...xState };
                  iState.show = false;
                  return iState;
                });
                setSLoading(false);
              });
          }}
        />
      ) : (
        ""
      )}
      <div className=" h-96 mt-1">
        {/* TITLE */}
        <div
          className="flex flex-col p-2 mt-1 w-full
                    mx-auto bg-white rounded-xl shadow-md items-center "
        >
          <p>REALISASI PIUTANG</p>
        </div>

        {/* FORM ISIAN DATA */}
        <div
          className="flex flex-col p-2 mt-2 w-full 
                     bg-blue-100 rounded-xl shadow-md mx-auto space-y-2"
        >
          <div className=" mx-auto">
            <p>{"Masukan Data"}</p>
          </div>
          <hr />

          <div className=" flex">
            <div className=" w-32">Tanggal</div>
            <div className=" w-1 text-center">:</div>
            <div className="ml-1 w-64 md:w-80 ">
              <input
                type="text"
                ref={refTanggal}
                className="Text"
                value={sTanggal}
                readOnly={true}
                onKeyUp={(xKey) => {
                  if (xKey.nativeEvent.key === "Enter") {
                    refNama.current.focus();
                  }
                }}
              />
            </div>
          </div>

          <div className=" flex">
            <div className=" w-32">Nama</div>
            <div className=" w-1 text-center">:</div>
            <div className={sNamaFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"}>
              <CompTextDs
                _ref={refNama}
                _value={sNewData.nama.nama}
                _dataSource={sAnggota}
                _onKeyEnter={(xData) => {
                  setSNewData((xState) => {
                    let iState = { ...xState };
                    iState.nama.id = xData[0];
                    iState.nama.nama = xData[1];
                    iState.nama.alamat = xData[2];
                    iState.nama.kelompok = xData[3];
                    return iState;
                  });
                  refProgramPiutang.current.focus();
                  refProgramPiutang.current.select();
                }}
                _onFocus={() => {
                  setSNamaFocus(true);
                }}
                _onLossFocus={() => {
                  setSNamaFocus(false);
                }}
              />
            </div>
            {!sNamaFocus ? (
              <div className="flex-1 pl-1 truncate">
                {`
								 Alamat: ${sNewData.nama.alamat || "....."};	
								 Kelompok: ${sNewData.nama.kelompok || "....."}`}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className=" flex">
            <div className=" w-32">Program Piutang</div>
            <div className=" w-1 text-center">:</div>
            <div
              className={
                sProgramPiutangFocus ? "ml-1 flex-1" : "ml-1 w-64 md:w-80"
              }
            >
              <CompTextDs
                _ref={refProgramPiutang}
                _dataSource={sProgramPiutang}
                _onKeyEnter={(xData) => {
                  setSNewData((xState) => {
                    let iState = { ...xState };
                    iState.program_piutang.id = xData[0];
                    iState.program_piutang.nama = xData[1];
                    iState.program_piutang.jenis = xData[2];
                    iState.program_piutang.bunga = xData[3];
                    iState.program_piutang.denda = xData[4];
                    return iState;
                  });
                  refNilai.current.focus();
                  refNilai.current.select();
                }}
                _onFocus={() => {
                  setSProgramPiutangFocus(true);
                }}
                _onLossFocus={() => {
                  setSProgramPiutangFocus(false);
                }}
              />
            </div>
            {!sProgramPiutangFocus ? (
              <div className="flex-1 pl-1 truncate">
                {`
								Jenis: ${fProgramPiutangName(sNewData.program_piutang.jenis) || "....."} ;								
								Bunga: ${sNewData.program_piutang.bunga || "....."} %/Bulan;	
								Denda: ${sNewData.program_piutang.denda || "....."} %/Bulan`}
              </div>
            ) : (
              ""
            )}
          </div>

          <div className=" flex">
            <div className=" w-32">Nilai</div>
            <div className=" w-1 text-center">:</div>
            <div className="ml-1  w-40 md:w-80">
              <CompText
                _ref={refNilai}
                _dataType="ribuan"
                _onNilai={(xNilai) => {
                  setSNewData((xData) => {
                    let iState = { ...xData };
                    iState.nilai = xNilai;
                    return iState;
                  });
                }}
                _onKeyEnterUp={() => {
                  if (sNewData.nilai > 0) {
                    refJangkaWaktu.current.focus();
                    refJangkaWaktu.current.select();
                  }
                }}
              />
            </div>
          </div>

          <div className=" flex">
            <div className=" w-32">Jangka Waktu</div>
            <div className=" w-1 text-center">:</div>
            <div className="ml-1 w-40 md:w-80">
              <CompText
                _ref={refJangkaWaktu}
                _dataType="ribuan"
                _onNilai={(xNilai) => {
                  setSNewData((xState) => {
                    let iState = { ...xState };
                    iState.jangka_waktu = xNilai;

                    return iState;
                  });
                }}
                _onKeyEnterUp={() => {
                  if (sNewData.jangka_waktu > 0) {
                    refKeterangan.current.focus();
                    refKeterangan.current.select();
                  }
                }}
              />
            </div>
            <div className=" pl-1 truncate"> Bulan</div>
          </div>

          <div className=" flex">
            <div className=" w-32">Keterangan</div>
            <div className=" w-1 text-center">:</div>
            <div className="ml-1 flex-1">
              <CompText
                _ref={refKeterangan}
                _onKeyEnterUp={(xData) => {
                  setSNewData((xState) => {
                    let iState = { ...xState };
                    iState.keterangan = xData;
                    return iState;
                  });

                  refRealisasi.current.focus();
                }}
              />
            </div>
          </div>

          <div className=" flex w-full">
            <button
              ref={refRealisasi}
              className="Button mx-auto"
              onClick={() => {
                fCekData();
              }}
            >
              Realisasi
            </button>
          </div>
        </div>
      </div>
      <div className=" flex-1 overflow-y-auto bg-blue-300 md:p-1 rounded-lg shadow-sm">
        <table className="Table">
          <thead>
            <tr>
              <th>No</th>
              <th>Nama</th>
              <th>Nilai</th>
              <th>Jangka Waktu</th>
              <th>Besar Bunga</th>
              <th>Bayar Pokok</th>
              <th>Bayar Bunga</th>
              <th>Total Bayar</th>
              <th>Keterangan</th>
            </tr>
          </thead>
          <tbody>
            {sRsDataRealisasiPiutang
              .map((xData, xIndex) => {
                try {
                  return (
                    <tr key={xIndex}>
                      <td width={40}>
                        <button
                          className="Button h-6 w-full"
                          onClick={() => {
                            setSDelete((xState) => {
                              let iState = { ...xState };
                              iState.show = true;
                              iState.index = xIndex;
                              iState.data = `Apakah benar akan menghapus data no; ${
                                xIndex + 1
                              } atas nama: ${
                                sRsDataRealisasiPiutang[xIndex].nama_members
                              }`;
                              return iState;
                            });
                          }}
                        >
                          {xIndex + 1}
                        </button>
                      </td>
                      <td width={300}>{xData.nama_members}</td>
                      <td align="right" width={130}>
                        {Env.format.uang(xData.nilai)}
                      </td>
                      <td
                        align="right"
                        width={130}
                      >{`${xData.jangka_waktu} bulan`}</td>
                      <td
                        align="right"
                        width={130}
                      >{`${xData.bunga} % / bulan`}</td>
                      <td align="right" width={150}>{`${Env.format.uang(
                        xData.bayar_pokok
                      )} / bulan`}</td>
                      <td align="right" width={150}>{`${Env.format.uang(
                        xData.bayar_bunga
                      )} / bulan`}</td>
                      <td align="right" width={150}>{`${Env.format.uang(
                        xData.bayar_pokok + xData.bayar_bunga
                      )} / bulan`}</td>
                      <td className=" truncate">{xData.keterangan}</td>
                    </tr>
                  );
                } catch (xErr) {
                  return null;
                }
              })
              .reverse()}
          </tbody>
        </table>
      </div>
      <div className="h-12"></div>
    </div>
  );
};

export default GlobalStateConsumer(RealisasiPiutang);
